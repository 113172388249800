// General
import "./tor-description-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateViewTorDescriptionDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";

const TorDescriptionDialog = () => {
  // Redux variables
  const viewTorDescriptionDialog = useSelector(
    (state) => state.dialog.viewTorDescriptionDialog
  );
  const torDescription = useSelector((state) => state.profile.torDescription);
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!viewTorDescriptionDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ViewTorDescription-Dialog",
      },
    });
  }, [viewTorDescriptionDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateViewTorDescriptionDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog custom-dialog-always-top"
      open={viewTorDescriptionDialog}
      onClose={onCloseDialog}
    >
      <div id="view-tor-description-dialog">
        <div className="max-width-container padding-container">
          <div
            className="tor-description"
            dangerouslySetInnerHTML={{ __html: torDescription }}
          ></div>
        </div>
      </div>
    </Dialog>
  );
};

export default TorDescriptionDialog;
