import { configureStore } from "@reduxjs/toolkit";
import { authApi, comApi, spiApi, paymentApi } from "../services/data.service";
import appStore from "./store/appStore";
import bannerStore from "./store/bannerStore";
import deactivateStore from "./store/deactivateStore";
import debugStore from "./store/debugStore";
import dialogStore from "./store/dialogStore";
import editProfileStore from "./store/editProfileStore";
import inboxReducer from "./store/inboxStore";
import interestReducer from "./store/interestStore";
import leaderboardReducer from "./store/leaderboardStore";
import levellingReducer from "./store/levellingStore";
import livestreamingReducer from "./store/livestreamingStore";
import navbarReducer from "./store/navbarStore";
import navigationReducer from "./store/navigationStore";
import noResetReducer from "./store/noResetStore";
import notificationReducer from "./store/notificationStore";
import paymentReducer from "./store/paymentStore";
import privateCallReducer from "./store/privateCallStore";
import productsReducer from "./store/productsStore";
import profileReducer from "./store/profileStore";
import publicReducer from "./store/publicStore";
import pusherReducer from "./store/pusherStore";
import feedReducer from "./store/feedStore";
import gameReducer from "./store/gameStore";
import searchFilterReducer from "./store/searchFilterStore";
import searchReducer from "./store/searchStore";
import signinReducer from "./store/signinStore";
import signupReducer from "./store/signupStore";
import surveyReducer from "./store/surveyStore";
import toastReducer from "./store/toastStore";
import trackingReducer from "./store/trackingStore";
import upgradeStore from "./store/upgradeStore";
import userCardReducer from "./store/userCardStore";
import userReducer from "./store/userStore";

export default configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [comApi.reducerPath]: comApi.reducer,
    [spiApi.reducerPath]: spiApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    app: appStore,
    banner: bannerStore,
    deactivate: deactivateStore,
    debug: debugStore,
    dialog: dialogStore,
    editProfile: editProfileStore,
    inbox: inboxReducer,
    interest: interestReducer,
    leaderboard: leaderboardReducer,
    levelling: levellingReducer,
    livestreaming: livestreamingReducer,
    navbar: navbarReducer,
    navigation: navigationReducer,
    noReset: noResetReducer,
    notification: notificationReducer,
    payment: paymentReducer,
    privateCall: privateCallReducer,
    products: productsReducer,
    profile: profileReducer,
    public: publicReducer,
    pusher: pusherReducer,
    feed: feedReducer,
    game: gameReducer,
    searchFilter: searchFilterReducer,
    search: searchReducer,
    signin: signinReducer,
    signup: signupReducer,
    survey: surveyReducer,
    toast: toastReducer,
    tracking: trackingReducer,
    upgrade: upgradeStore,
    userCard: userCardReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      comApi.middleware,
      spiApi.middleware,
      paymentApi.middleware
    ),
});
