// General
import "./facebook-login-button.scss";
import { useEffect } from "react";
// Services
import {
  useSigninMutation,
  useLazyGetUserAioQuery,
} from "../../../services/data.service";
import { sessionService } from "../../../services/session.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEmail } from "../../../redux/store/signupStore";
import {
  updateIsLoggedIn,
  updateApiToken,
} from "../../../redux/store/publicStore";
import {
  updateWarningToast,
  updateErrorToast,
} from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const FacebookLoginButton = () => {
  // API variables
  const [
    facebookLogin,
    {
      data: facebookLoginData,
      error: facebookLoginErrorData,
      isLoading: facebookLoginLoading,
      isSuccess: facebookLoginSuccess,
      isError: facebookLoginError,
    },
  ] = useSigninMutation();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const email = useSelector((state) => state.signup.email);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    initFacebookLogin();
  }, []);

  // Lifecycle | Check for update | Facebook Login API Response
  useEffect(() => {
    if (facebookLoginLoading) {
    } else if (facebookLoginSuccess) {
      switch (facebookLoginData?.status) {
        case 0:
          if (
            !facebookLoginData?.data?.user?.gender ||
            !facebookLoginData?.data?.user?.wanttobe
          ) {
            // New User
            dispatch(updateEmail(facebookLoginData?.data?.user?.email));
            dispatch(updateApiToken(facebookLoginData?.data?.token));
            onNavigate(routeConst.join.path);
          } else {
            // Existing User
            if (facebookLoginData?.data?.token) {
              dispatch(updateApiToken(facebookLoginData?.data?.token));
              sessionService.setEmail(email);
              dispatch(updateIsLoggedIn(true));

              getUserAio();

              // GTM (Not Tested)
              TagManager.dataLayer({
                dataLayer: {
                  event: "PWA-Login-Page-LoginSuccess",
                  value: {
                    userId: facebookLoginData?.data?.user?.id,
                    role: getRole(
                      facebookLoginData?.data?.user?.wanttobe,
                      facebookLoginData?.data?.user?.interest
                    ),
                  },
                },
              });
            } else {
              const toastObj = {
                message: "Token not available",
                autoClose: 3000,
              };
              dispatch(updateErrorToast(toastObj));
            }
          }
          break;
        case -1:
          break;
        default:
          break;
      }
    } else if (facebookLoginError) {
      const toastObj = {
        message: facebookLoginError?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
    }
  }, [facebookLoginLoading, facebookLoginSuccess, facebookLoginError]);

  // Lifecycle | Check for update | getUserAio API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      if (getUserAioData?.status === 1) {
        switch (getUserAioData?.data?.pwa?.devault_view) {
          case "live":
            onNavigate(routeConst.live.path);
          case "home":
          case "search":
          default:
            onNavigate(routeConst.search.path);
            break;
        }
      }
    } else if (getUserAioError) {
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  // Event Handlers | Button
  const onFacebookLogin = () => {
    try {
      window.FB.login(checkLoginState, {
        scope: "email",
        authType: "rerequest",
      });
    } catch (error) {
      const toastObj = {
        message:
          "It looks like your ad blocker is affecting our Facebook login. Try disabling it for a seamless experience.",
        autoClose: 6000,
      };
      dispatch(updateWarningToast(toastObj));
    }
  };

  // Facebook Functions
  const initFacebookLogin = () => {
    // create div required for fb
    const fbDiv = document.createElement("div");
    fbDiv.id = "fb-root";
    document.body.appendChild(fbDiv);

    window.fbAsyncInit = () => {
      // Now that we've initialized the JavaScript SDK, we call
      // FB.getLoginStatus(). This function gets the state of the
      // person visiting this page and can return one of three states to
      // the callback you provide. They can be:
      //
      // 1. Logged into your app ('connected')
      // 2. Logged into Facebook, but not your app ('not_authorized')
      // 3. Not logged into Facebook and can't tell if they are logged into
      // your app or not.
      //
      window.FB.Event.subscribe("auth.login", (response) => {
        statusChangeCallback(response);
      });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src =
        "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0&appId=" +
        process.env["REACT_APP_FACEBOOK_APP_ID"] +
        "&autoLogAppEvents=1";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };
  const checkLoginState = () => {
    window.FB.getLoginStatus((response) => {
      statusChangeCallback(response);
    });
  };
  const statusChangeCallback = (response) => {
    if (response?.status === "connected" && response?.authResponse !== null) {
      const obj = {
        auth_provider: "facebook",
        auth_token: response?.authResponse?.accessToken,
        utm_data: "{}",
      };
      facebookLogin(obj);
    } else {
      console.log("statusChangeCallback: ", "Not Connected");
    }
  };

  // Utility Functions
  const getRole = (wantToBe, lookingFor) => {
    let role = "";

    if (wantToBe === "sugardaddy") {
      if (lookingFor === "men") {
        role = "sbm";
      } else if (lookingFor === "women") {
        role = "sd";
      }
    } else if (wantToBe === "sugarbaby") {
      if (lookingFor === "men") {
        role = "sb";
      } else if (lookingFor === "women") {
        role = "sbm";
      }
    }

    return role;
  };

  return (
    <div id="facebook-login-button-subcomponent" onClick={onFacebookLogin}>
      <div className="facebook-logo-container">
        {facebookLoginLoading ? (
          <Spinner size={15} isPadding={false} />
        ) : (
          getIcon("facebookLogo", "facebook-logo")
        )}
      </div>
      <div className="facebook-login-label">
        {t("login.login_with_facebook")}
      </div>
    </div>
  );
};

export default FacebookLoginButton;
