// General
import "./tipping-element.scss";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const TippingElement = (props) => {
  const { profilePhoto, username, levellingBadge, tipValue, tipAction } = props;

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="chat-section-tipping-element-subcomponent">
      <div className="profile-picture-container">
        <CustomAvatar className="profile-picture" src={profilePhoto} />
      </div>

      <div className="chat-info-container">
        <div className="chat-info">
          <span className="username">{username || "-"}</span>

          {levellingBadge && (
            <img
              className="levelling-badge"
              src={levellingBadge}
              alt="levelling-badge"
            />
          )}

          <span className="joined-text">
            {t("1on1.tip_request_fixed_desc", {
              coins: tipValue,
              request: tipAction,
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TippingElement;
