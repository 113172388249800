// General
import "./report.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useReportUserMutation,
} from "../../services/data.service";
import { removeHTMLEscape } from "../../services/regex.service";
// Static Data
import routeConst from "../../const/routeConst";
import profileConst from "../../const/profileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateSuccessToast } from "../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { TextField, MenuItem, Button, CircularProgress } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../utility/custom-hooks/useCustomNavigate-hook";
import {
  updateReportDialog,
  updateUserProfileDialog,
} from "../../redux/store/dialogStore";

const Report = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    reportUser,
    {
      data: reportUserData,
      error: reportUserErrorData,
      isLoading: reportUserLoading,
      isSuccess: reportUserSuccess,
      isError: reportUserError,
    },
  ] = useReportUserMutation();

  // General variables
  const [reason, setReason] = useState("");
  const [reasonErrorMessage, setReasonErrorMessage] = useState("");
  const [reasonFocus, setReasonFocus] = useState(false);
  const [reasonValid, setReasonValid] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [issueDescription, setIssueDescription] = useState("");
  const [issueDescriptionErrorMessage, setIssueDescriptionErrorMessage] =
    useState("");
  const [issueDescriptionFocus, setIssueDescriptionFocus] = useState(false);
  const [issueDescriptionValid, setIssueDescriptionValid] = useState(false);
  const [issueDescriptionError, setIssueDescriptionError] = useState(false);

  // Redux variables
  const idInt = useSelector((state) => state.userCard.idInt);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!idInt) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Report-Page-NoUserId",
        },
      });

      onNavigate(routeConst.search.path);
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Report-Page",
        },
      });

      getAio(null, true);
    }
  }, []);

  // Lifecycle | Check for update | Send Support Ticket API Response
  useEffect(() => {
    if (reportUserLoading) {
    } else if (reportUserSuccess) {
      if (reportUserData?.status === 0) {
        const toastObj = {
          message: reportUserData?.payload?.message,
          autoClose: 3000,
        };
        dispatch(updateSuccessToast(toastObj));

        dispatch(updateReportDialog(false));
      }
    } else if (reportUserError) {
    }
  }, [reportUserLoading, reportUserSuccess, reportUserError]);

  // Event Handlers | Button
  const onSendInformation = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Report-Page-SubmitTicket-Button",
      },
    });

    const obj = {
      reason: reason,
      description: issueDescription,
      userid: idInt,
    };

    reportUser(obj);
  };

  // Event Handlers | MUI Text Fields
  const onCategoryChange = (e) => {
    setReason(e.target.value);
  };
  const onCategoryFocus = (e) => {
    setReasonFocus(true);
  };
  const onIssueDescriptionChange = (e) => {
    if (removeHTMLEscape.test(e.target.value)) {
      // Remove HTML Escape (prevent XSS)
      setIssueDescription("");

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-Report-Page-Description-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Report-Page-Description-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setIssueDescription(e.target.value);
    }
  };
  const onIssueDescriptionFocus = (e) => {
    setIssueDescriptionFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (reasonValid) {
      valid = true;
    }

    return valid;
  };
  const categoryValidation = () => {
    let valid = true;
    let error = false;

    if (!reasonFocus) {
      valid = false;
      error = false;
    } else if (reasonFocus && reason === "") {
      setReasonErrorMessage("Category is required");
      valid = false;
      error = true;
    } else {
      setReasonErrorMessage("");
      valid = true;
      error = false;
    }

    setReasonValid(valid);
    setReasonError(error);
  };

  // Lifecycle | Check for update | Validation
  useEffect(() => {
    categoryValidation();
  }, [reason, reasonFocus, reasonErrorMessage]);

  return (
    <div id="report-page">
      <div className="max-width-container">
        <div className="form-container">
          <TextField
            className="form-field custom-text-field"
            select
            label={t("profile.select_your_issue")}
            value={reason}
            error={reasonError}
            onChange={onCategoryChange}
            variant="standard"
            onFocus={onCategoryFocus}
          >
            {getAioData?.payload?.user_report_types.map((report, index) => (
              <MenuItem key={index} value={report?.category_value}>
                {report?.category_name}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="additional-info-label">
          {t("profile.additional_info")}
        </div>

        <div className="form-container">
          <TextField
            className="form-field custom-text-field"
            placeholder={t("profile.additional_info_placeholder")}
            value={issueDescription}
            onChange={onIssueDescriptionChange}
            multiline
            maxRows={10}
            variant="standard"
            onFocus={onIssueDescriptionFocus}
          />
        </div>

        <Button
          className={`send-button ${isFormValid() ? "" : "disabled-button"}`}
          variant="text"
          onClick={onSendInformation}
          disabled={!isFormValid()}
        >
          {reportUserLoading ? (
            <CircularProgress className="button-spinner" size={24} />
          ) : (
            t("common.send")
          )}
        </Button>
      </div>
    </div>
  );
};

export default Report;
