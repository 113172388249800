// General
import "./navbar-mobile-view.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import {
  useLazyGetUserAioQuery,
  useLazyGetProfileQuery,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import profileConst from "../../../../../const/profileConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEditProfilePassthrough,
  updateSaveVideoCallSettingsPassthrough,
} from "../../../../../redux/store/userStore";
import {
  updateUserProfileDialog,
  updateVerifiedCallerDescriptionDialog,
} from "../../../../../redux/store/dialogStore";
import { updateVideoCallPassthrough } from "../../../../../redux/store/privateCallStore";
import { updateSearchScrollToTopPassthrough } from "../../../../../redux/store/searchStore";
import {
  updateInboxScrollToTopPassthrough,
  updateConversationUserProfilePassthrough,
} from "../../../../../redux/store/inboxStore";
import { updateVideoCallScrollToTopPassthrough } from "../../../../../redux/store/privateCallStore";
import { updateLivestreamScrollToTopPassthrough } from "../../../../../redux/store/livestreamingStore";
import { updateInterestScrollToTopPassthrough } from "../../../../../redux/store/interestStore";
import { updateGameListPassthrough } from "../../../../../redux/store/gameStore";
import { updateLeaderboardTab } from "../../../../../redux/store/leaderboardStore";
import {
  updateDiamondsInfoDialog,
  updateMyWalletInfoDialog,
  updateSearchFilterDialog,
} from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import {
  Divider,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";
import MoreOptionsButton from "../../profile/more-options-button/more-options-button";

const NavbarMobileView = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  let [showNavbar, setShowNavbar] = useState(true);

  // Render variables
  const [mobileLeftButton, setMobileLeftButton] = useState(null);
  const [mobileMiddleSection, setMobileMiddleSection] = useState(null);
  const [mobileRightButton, setMobileRightButton] = useState(null);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const isFiltered = useSelector((state) => state.searchFilter.isFiltered); // Search Page
  const conversationUser = useSelector((state) => state.inbox.conversationUser); // Inbox Chat Page
  const ownPrivateCallEnable = useSelector(
    (state) => state.inbox.ownPrivateCallEnable
  ); // Inbox Chat Page
  const otherUserPrivateCallEnable = useSelector(
    (state) => state.inbox.otherUserPrivateCallEnable
  ); // Inbox Chat Page
  const leaderboardTitle = useSelector(
    (state) => state.leaderboard.leaderboardTitle
  ); // Leaderboard Page
  const calleeUsername = useSelector(
    (state) => state.privateCall.calleeUsername
  ); // Video Call Page
  const username = useSelector((state) => state.userCard.username); // User Card Dialog
  const navigationHistory = useSelector(
    (state) => state.navigation.navigationHistory
  ); // Navigation History
  const editProfileTab = useSelector((state) => state.user.editProfileTab); // Edit Profile Page
  const selectedGameUrl = useSelector((state) => state.game.selectedGameUrl); // Casino Games Page
  const dispatch = useDispatch();

  // MUI variables
  const [drawerState, setDrawerState] = useState({
    left: false,
  });
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t, i18n } = useTranslation();

  // Router variables
  const location = useLocation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getUserAio(null, true);
    getProfile(null, true);
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Change navbar items based on route
  // isFiltered check for Search page only
  // leaderboardTitle used in leaderboard list page only
  // selectedGameUrl used in casino games page only
  useEffect(() => {
    if (isLoggedIn) {
      if (location.pathname.includes(routeConst.search.path)) {
        switch (location.pathname) {
          case routeConst.search.path:
            setMobileLeftButton(
              <div
                className="filter-icon-container"
                onClick={() => onOpenDialog("search-filter-dialog")}
              >
                {isFiltered
                  ? getIcon("searchFilteredIcon", "filter-icon")
                  : getIcon("searchFilterIcon", "filter-icon")}
                <div className="filter-label">{t("filter.filters")}</div>
              </div>
            );
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={() =>
                  onRedirect(routeConst.search.path, routeConst.search.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );

            if (
              getUserAioData?.data?.modules
                ?.private_calls_leaderboard_enabled ||
              getUserAioData?.data?.modules?.live_streaming_leaderboard_enabled
            ) {
              setMobileRightButton(
                <div
                  className="leaderboard-new-icon-container"
                  onClick={() => onNavigate(routeConst.leaderboard.path)}
                >
                  {getIcon("leaderboardNewIcon", "leaderboard-new-icon")}
                </div>
              );
            } else {
              setMobileRightButton(<div></div>);
            }
            break;
          default:
            break;
        }
      } else if (location.pathname.includes(routeConst.inbox.path)) {
        if (
          location.pathname.includes(
            routeConst.inbox.messaging.chat.chatId.clearPath
          )
        ) {
          setMobileLeftButton(
            <div className="back-icon-container" onClick={() => onNavigate(-1)}>
              {getIcon("backIcon", "back-icon")}
            </div>
          );
          setMobileMiddleSection(
            <div className="middle-label">
              <CustomAvatar
                src={conversationUser?.profilePhoto}
                onClick={onOpenUserProfileFromConversation}
              />

              {conversationUser?.livestreamingAchievementBadge?.length > 0 && (
                <div className="livestreaming-achievement-badge-container">
                  {conversationUser?.livestreamingAchievementBadge?.map(
                    (badge, index) => (
                      <img
                        className="livestreaming-achievement-badge"
                        src={badge?.url}
                        alt={badge?.type}
                        key={index}
                      />
                    )
                  )}
                </div>
              )}

              <div className="username">{conversationUser?.username}</div>

              {conversationUser?.membershipType ===
                userConst.membershipType.premium && (
                <div className="premium-mini-badge-container">
                  {getIcon("premiumMiniBadge", "premium-mini-badge")}
                </div>
              )}

              {conversationUser?.verifiedProfile && (
                <div className="verified-profile-container">
                  {getIcon("verifiedProfileBadge", "verified-profile")}
                </div>
              )}

              {conversationUser?.membershipType ===
                userConst.membershipType.admin && (
                <div className="admin-badge-container">
                  <PersonIcon className="person-icon" />
                  <div className="admin-label">Admin</div>
                </div>
              )}

              {conversationUser?.verifiedCaller && (
                <div
                  className="verified-caller-container"
                  onClick={onOpenVerifiedCallerDialog}
                >
                  {getIcon("verifiedCallerBadge", "verified-caller")}
                </div>
              )}
            </div>
          );
          setMobileRightButton(
            <div className="inbox-button-container">
              {ownPrivateCallEnable && otherUserPrivateCallEnable && (
                <IconButton
                  className="video-call-icon-container"
                  onClick={onVideoCall}
                >
                  {getIcon("videoCallIcon", "video-call-icon")}
                </IconButton>
              )}

              <MoreOptionsButton
                userProfileType={profileConst.userProfileType.dialog}
                id={conversationUser?.userId}
                idInt={conversationUser?.userIdInt}
                privatePhotos={conversationUser?.privatePhotos}
                privatePhotoRequested={conversationUser?.privatePhotoRequested}
                privatePhotoApproval={conversationUser?.privatePhotoApproval}
                requestedMyPrivatePhoto={
                  conversationUser?.requestedMyPrivatePhoto
                }
                approvedMyPrivatePhoto={
                  conversationUser?.approvedMyPrivatePhoto
                }
                showClearMessage={true}
              />
            </div>
          );
        } else if (location.pathname.includes(routeConst.inbox.path)) {
          if (isDaddy) {
            if (getUserAioData?.data?.modules?.my_diamonds_enabled) {
              setMobileLeftButton(
                <div
                  className="sb-coin-icon-container"
                  onClick={() => onNavigate(routeConst.profile.wallet.path)}
                >
                  {getIcon("sbCoinIcon", "sb-coin-icon")}
                </div>
              );
            } else {
              setMobileLeftButton(<div></div>);
            }
          } else {
            if (getUserAioData?.data?.modules?.wallet_enabled) {
              setMobileLeftButton(
                <div
                  className="diamond-icon-container"
                  onClick={() =>
                    onNavigate(routeConst.profile.streamerCenter.path)
                  }
                >
                  {getIcon("sbDiamondIcon", "diamond-icon")}
                </div>
              );
            } else {
              setMobileLeftButton(<div></div>);
            }
          }

          setMobileMiddleSection(
            <div
              className="sugarbook-logo-container"
              onClick={() =>
                onRedirect(routeConst.inbox.path, routeConst.inbox.path)
              }
            >
              {getIcon("sugarbookLogo", "sugarbook-logo")}
            </div>
          );
          if (
            getUserAioData?.data?.modules?.private_calls_leaderboard_enabled ||
            getUserAioData?.data?.modules?.live_streaming_leaderboard_enabled
          ) {
            setMobileRightButton(
              <div
                className="leaderboard-new-icon-container"
                onClick={() => onNavigate(routeConst.leaderboard.path)}
              >
                {getIcon("leaderboardNewIcon", "leaderboard-new-icon")}
              </div>
            );
          } else {
            setMobileRightButton(<div></div>);
          }
        }
      } else if (location.pathname.includes(routeConst.live.path)) {
        switch (location.pathname) {
          case routeConst.live.path:
            if (isDaddy) {
              setMobileLeftButton(
                <div
                  className="sb-coin-icon-container"
                  onClick={() => onNavigate(routeConst.profile.wallet.path)}
                >
                  {getIcon("sbCoinIcon", "sb-coin-icon")}
                </div>
              );
            } else {
              setMobileLeftButton(
                <div
                  className="diamond-icon-container"
                  onClick={() =>
                    onNavigate(routeConst.profile.streamerCenter.path)
                  }
                >
                  {getIcon("sbDiamondIcon", "diamond-icon")}
                </div>
              );
            }
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={() =>
                  onRedirect(routeConst.live.path, routeConst.live.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            if (
              getUserAioData?.data?.modules
                ?.private_calls_leaderboard_enabled ||
              getUserAioData?.data?.modules?.live_streaming_leaderboard_enabled
            ) {
              setMobileRightButton(
                <div
                  className="leaderboard-new-icon-container"
                  onClick={() => onNavigate(routeConst.leaderboard.path)}
                >
                  {getIcon("leaderboardNewIcon", "leaderboard-new-icon")}
                </div>
              );
            } else {
              setMobileRightButton(<div></div>);
            }

            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.interest.path)) {
        switch (location.pathname) {
          case routeConst.interest.path:
            if (isDaddy) {
              if (getUserAioData?.data?.modules?.my_diamonds_enabled) {
                setMobileLeftButton(
                  <div
                    className="sb-coin-icon-container"
                    onClick={() => onNavigate(routeConst.profile.wallet.path)}
                  >
                    {getIcon("sbCoinIcon", "sb-coin-icon")}
                  </div>
                );
              } else {
                setMobileLeftButton(<div></div>);
              }
            } else {
              if (getUserAioData?.data?.modules?.wallet_enabled) {
                setMobileLeftButton(
                  <div
                    className="diamond-icon-container"
                    onClick={() =>
                      onNavigate(routeConst.profile.streamerCenter.path)
                    }
                  >
                    {getIcon("sbDiamondIcon", "diamond-icon")}
                  </div>
                );
              } else {
                setMobileLeftButton(<div></div>);
              }
            }

            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={() =>
                  onRedirect(routeConst.interest.path, routeConst.interest.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.profile.path)) {
        switch (location.pathname) {
          case routeConst.profile.overview.path:
            if (getUserAioData?.data?.modules?.levelling_enabled) {
              setMobileLeftButton(
                <div
                  className="calendar-icon-container"
                  onClick={() => onNavigate(routeConst.profile.leveling.path)}
                >
                  {getIcon("calendarIcon", "calendar-icon")}
                </div>
              );
            } else {
              setMobileLeftButton(<div></div>);
            }

            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(
              <div
                className="settings-icon-container"
                onClick={() => onNavigate(routeConst.settings.path)}
              >
                {getIcon("settingsIcon", "settings-icon")}
              </div>
            );
            break;
          case routeConst.profile.edit.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("profile.edit_profile")}</div>
            );
            setMobileRightButton(
              <div className="done-button" onClick={onEditProfile}>
                {t("common.done")}
              </div>
            );
            break;
          case routeConst.profile.wallet.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onRedirect(routeConst.profile.wallet.path, -1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("profile.my_wallet")}</div>
            );
            setMobileRightButton(
              <div
                className="info-icon-container"
                onClick={() => onOpenDialog("my-wallet-dialog")}
              >
                {getIcon("infoIcon", "info-icon")}
              </div>
            );
            break;
          case routeConst.profile.leveling.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onRedirect(routeConst.profile.leveling.path, -1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("profile.levelling_centre")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.profile.streamerCenter.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("profile.my_diamonds")}</div>
            );
            setMobileRightButton(
              <div
                className="info-icon-container"
                onClick={() => onOpenDialog("diamonds-info-dialog")}
              >
                {getIcon("infoIcon", "info-icon")}
              </div>
            );
            break;
          case routeConst.profile.streamerCenter.lastStreamSummary.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("settings.last_stream_summary")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.profile.streamerCenter.follows.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {getProfileData?.data?.username}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.settings.path)) {
        switch (location.pathname) {
          case routeConst.settings.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("settings.settings")}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.settings.updatePassword.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("settings.update_password")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.settings.updateEmail.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("settings.update_email")}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.settings.updateEmail.verification.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">Update Email Verification</div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.settings.blockedMembers.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("profile.blocked_members")}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.settings.photoPermission.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("settings.photo_permissions")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.settings.deactivateAccount.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("settings.deactivate_account")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.settings.videoCall.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("profile.1on1_video_call_settings")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.settings.videoCall.customize.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("profile.1on1_video_call_settings")}
              </div>
            );
            setMobileRightButton(
              <div className="done-button" onClick={onSaveVideoCallSettings}>
                {t("common.done")}
              </div>
            );
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.tutorials.path)) {
        switch (location.pathname) {
          case routeConst.tutorials.videoCall.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("profile.tutorial")}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.videoCall.path)) {
        switch (location.pathname) {
          case routeConst.videoCall.path:
            if (isDaddy) {
              setMobileLeftButton(
                <div
                  className="sb-coin-icon-container"
                  onClick={() => onNavigate(routeConst.profile.wallet.path)}
                >
                  {getIcon("sbCoinIcon", "sb-coin-icon")}
                </div>
              );
            } else {
              setMobileLeftButton(
                <div
                  className="diamond-icon-container"
                  onClick={() =>
                    onNavigate(routeConst.profile.streamerCenter.path)
                  }
                >
                  {getIcon("sbDiamondIcon", "diamond-icon")}
                </div>
              );
            }
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={() =>
                  onRedirect(
                    routeConst.videoCall.path,
                    routeConst.videoCall.path
                  )
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            if (
              getUserAioData?.data?.modules
                ?.private_calls_leaderboard_enabled ||
              getUserAioData?.data?.modules?.live_streaming_leaderboard_enabled
            ) {
              setMobileRightButton(
                <div
                  className="leaderboard-new-icon-container"
                  onClick={() => onNavigate(routeConst.leaderboard.path)}
                >
                  {getIcon("leaderboardNewIcon", "leaderboard-new-icon")}
                </div>
              );
            } else {
              setMobileRightButton(<div></div>);
            }
            break;
          case routeConst.videoCall.summary.path:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div className="middle-label">{t("1on1.video_call_summary")}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.videoCall.rate.path:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div className="middle-label">
                {t("1on1.rate_user", { user: calleeUsername })}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.videoCall.callHistory.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(<div></div>);
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.feed.path)) {
        switch (location.pathname) {
          case routeConst.feed.path:
            setMobileLeftButton(
              <div
                className="sb-coin-icon-container"
                onClick={() => onNavigate(routeConst.profile.wallet.path)}
              >
                {getIcon("sbCoinIcon", "sb-coin-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={() =>
                  onRedirect(
                    routeConst.videoCall.path,
                    routeConst.videoCall.path
                  )
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            if (
              getUserAioData?.data?.modules
                ?.private_calls_leaderboard_enabled ||
              getUserAioData?.data?.modules?.live_streaming_leaderboard_enabled
            ) {
              setMobileRightButton(
                <div
                  className="leaderboard-new-icon-container"
                  onClick={() => onNavigate(routeConst.leaderboard.path)}
                >
                  {getIcon("leaderboardNewIcon", "leaderboard-new-icon")}
                </div>
              );
            } else {
              setMobileRightButton(<div></div>);
            }

            break;
          default:
            break;
        }
      } else if (location.pathname.includes(routeConst.support.path)) {
        switch (location.pathname) {
          case routeConst.support.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("settings.contact_support")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.blogs.path)) {
        switch (location.pathname) {
          case routeConst.blogs.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("profile.blogs")}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.leaderboard.path)) {
        if (location.pathname.includes(routeConst.leaderboard.list.path)) {
          setMobileLeftButton(
            <div className="back-icon-container" onClick={() => onNavigate(-1)}>
              {getIcon("backIcon", "back-icon")}
            </div>
          );
          setMobileMiddleSection(
            <div className="middle-label">{leaderboardTitle}</div>
          );
          setMobileRightButton(<div></div>);
        } else if (location.pathname.includes(routeConst.leaderboard.path)) {
          setMobileLeftButton(
            <div className="back-icon-container" onClick={() => onNavigate(-1)}>
              {getIcon("backIcon", "back-icon")}
            </div>
          );
          setMobileMiddleSection(
            <div className="middle-label">
              <div className="leaderboard-icon-container">
                {getIcon("leaderboardIcon", "leaderboard-icon")}
              </div>
              {t("leaderboard.leaderboard")}
            </div>
          );
          setMobileRightButton(<div></div>);
        }
      } else if (location.pathname.includes(routeConst.privacy.path)) {
        switch (location.pathname) {
          case routeConst.privacy.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("landing.nav.privacy_policy")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.terms.path)) {
        switch (location.pathname) {
          case routeConst.terms.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("landing.nav.terms_of_use")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.safetyTips.path)) {
        switch (location.pathname) {
          case routeConst.safetyTips.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("landing.nav.safety_tips")}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.report.path)) {
        switch (location.pathname) {
          case routeConst.report.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onRedirect(routeConst.report.path, -1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("profile.report")} {username}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.upgrade.path)) {
        switch (location.pathname) {
          case routeConst.upgrade.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onRedirect(routeConst.upgrade.path, -1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("upgrade.upgrade_instantly")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.upgrade.checkout.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("upgrade.checkout")}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.upgrade.payment.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">{t("upgrade.payment_method")}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.upgrade.redirect.path:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div className="middle-label">{t("upgrade.upgrade")}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.payment.path)) {
        switch (location.pathname) {
          case routeConst.payment.add.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("purchase.add_payment_card")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          case routeConst.payment.redirect.path:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div className="middle-label">{"Secure Payment"}</div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.games.path)) {
        switch (location.pathname) {
          case routeConst.games.casino.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(routeConst.live.path)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(
              <div className="games-button-container">
                {selectedGameUrl && (
                  <div
                    className="game-selection-button-container"
                    onClick={onBackToGameSelect}
                  >
                    {getIcon("threeAceIcon", "game-selection-button")}
                  </div>
                )}

                {!selectedGameUrl && (
                  <div
                    className="leaderboard-new-icon-container"
                    onClick={onRedirectToCasinoLeaderboard}
                  >
                    {getIcon("leaderboardNewIcon", "leaderboard-new-icon")}
                  </div>
                )}
              </div>
            );
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      }
    } else {
      if (location.pathname.includes(routeConst.join.path)) {
        switch (location.pathname) {
          case routeConst.join.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(routeConst.login.path)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(<div></div>);
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.phoneAuth.path)) {
        setMobileLeftButton(
          <div className="back-icon-container" onClick={() => onNavigate(-1)}>
            {getIcon("backIcon", "back-icon")}
          </div>
        );
        setMobileMiddleSection(<div></div>);
        setMobileRightButton(<div></div>);
      } else if (location.pathname.includes(routeConst.verifyPhone.path)) {
        setMobileLeftButton(
          <div className="back-icon-container" onClick={() => onNavigate(-1)}>
            {getIcon("backIcon", "back-icon")}
          </div>
        );
        setMobileMiddleSection(<div></div>);
        setMobileRightButton(<div></div>);
      } else if (location.pathname.includes(routeConst.forgotPassword.path)) {
        switch (location.pathname) {
          case routeConst.forgotPassword.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(<div></div>);
            setMobileRightButton(<div></div>);
            break;
          default:
            break;
        }
      } else if (location.pathname.includes(routeConst.accountSignup.path)) {
        switch (location.pathname) {
          case routeConst.accountSignup.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(routeConst.join.path)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(<div></div>);
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (
        location.pathname.includes(routeConst.descriptionSignup.path)
      ) {
        switch (location.pathname) {
          case routeConst.descriptionSignup.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(routeConst.infoSignup.path)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(<div></div>);
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.detailSignup.path)) {
        switch (location.pathname) {
          case routeConst.detailSignup.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(routeConst.lifestyleSignup.path)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(<div></div>);
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.lifestyleSignup.path)) {
        switch (location.pathname) {
          case routeConst.lifestyleSignup.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(routeConst.descriptionSignup.path)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(<div></div>);
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.privacy.path)) {
        switch (location.pathname) {
          case routeConst.privacy.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("landing.nav.privacy_policy")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else if (location.pathname.includes(routeConst.terms.path)) {
        switch (location.pathname) {
          case routeConst.terms.path:
            setMobileLeftButton(
              <div
                className="back-icon-container"
                onClick={() => onNavigate(-1)}
              >
                {getIcon("backIcon", "back-icon")}
              </div>
            );
            setMobileMiddleSection(
              <div className="middle-label">
                {t("landing.nav.terms_of_use")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
          default:
            setMobileLeftButton(<div></div>);
            setMobileMiddleSection(
              <div
                className="sugarbook-logo-container"
                onClick={
                  isLoggedIn
                    ? () => onNavigate(routeConst.live.path)
                    : () => onNavigate(routeConst.landing.path)
                }
              >
                {getIcon("sugarbookLogo", "sugarbook-logo")}
              </div>
            );
            setMobileRightButton(<div></div>);
            break;
        }
      } else {
        setMobileLeftButton(<div></div>);
        setMobileMiddleSection(<div></div>);
        setMobileRightButton(<div></div>);
      }
    }
  }, [
    isLoggedIn,
    location,
    getUserAioFetching,
    getUserAioSuccess,
    isFiltered,
    conversationUser,
    leaderboardTitle,
    editProfileTab,
    selectedGameUrl,
  ]);

  // Lifecycle | Check for update | Hide navbar based on route & screen width
  useEffect(() => {
    if (
      location.pathname === routeConst.landing.path ||
      location.pathname.includes(routeConst.infoSignup.path) ||
      location.pathname.includes(routeConst.descriptionSignup.path) ||
      location.pathname.includes(routeConst.emailVerification.path) ||
      location.pathname.includes(routeConst.missingDetails.path) ||
      location.pathname.includes(routeConst.login.path) ||
      location.pathname.includes(routeConst.profile.view.clearPath) ||
      location.pathname.includes(routeConst.live.liveId.clearPath) ||
      location.pathname.includes(routeConst.live.livestreamer.path) ||
      location.pathname.includes(routeConst.videoCall.privateStandby.path) ||
      location.pathname.includes(routeConst.videoCall.room.path) ||
      location.pathname.includes(routeConst.upgrade.status.path) ||
      location.pathname.includes(routeConst.coinShop.path) ||
      location.pathname.includes(routeConst.redirectHandler.path) ||
      location.pathname.includes(routeConst.logout.path)
    ) {
      if (isMobile) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
    } else {
      setShowNavbar(true);
    }
  }, [location, isMobile]);

  // Event Handlers | Button
  const onSaveVideoCallSettings = () => {
    dispatch(updateSaveVideoCallSettingsPassthrough({}));
  };
  const onEditProfile = () => {
    dispatch(updateEditProfilePassthrough({}));
  };
  const onOpenUserProfileFromConversation = () => {
    dispatch(updateConversationUserProfilePassthrough({}));
  };
  const onVideoCall = () => {
    dispatch(updateVideoCallPassthrough({}));
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };
  const onBackToGameSelect = () => {
    dispatch(updateGameListPassthrough());
  };
  const onRedirect = (currentPage, route) => {
    TagManager.dataLayer({
      dataLayer: {
        events: `PWA-Navbar-Mobile-Redirect-${currentPage}-${route}`,
      },
    });

    mobileScrollToTop(currentPage);

    // For wallet page, there is a unique condition where it might redirect to Xenopay, and the navigation history contains website from xenopay and iPay88.
    // Because of this, we will have to redirect user to fixed location.
    if (currentPage === routeConst.profile.wallet.path) {
      if (route === -1) {
        if (isMobile) {
          onNavigate(routeConst.profile.overview.path);
        } else {
          onNavigate(routeConst.search.path);
        }
        return;
      }
    }

    if (route === -1 && navigationHistory?.length > 0) {
      onNavigate(route);
    } else {
      onNavigate(routeConst.search.path);
    }
  };
  const onRedirectToCasinoLeaderboard = () => {
    dispatch(updateLeaderboardTab(2));

    onNavigate(routeConst.leaderboard.path);
  };

  // Event Handlers | MUI Menu
  const toggleDrawer = (anchor, open) => (event) => {
    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const onLandingMenu = (index) => {
    switch (index) {
      case 0:
        onNavigate(routeConst.sugarDating.path);
        break;
      case 2:
        onNavigate(routeConst.sugarBaby.path);
        break;
      case 3:
        onNavigate(routeConst.sugarDaddy.path);
        break;
      case 4:
        break;
    }
  };

  // Event Handlers | MUI Dialogs
  const onOpenDialog = (state) => {
    switch (state) {
      case "search-filter-dialog":
        dispatch(updateSearchFilterDialog(true));
        break;
      case "my-wallet-dialog":
        dispatch(updateMyWalletInfoDialog(true));
        break;
      case "diamonds-info-dialog":
        dispatch(updateDiamondsInfoDialog(true));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const mobileScrollToTop = (currentPage) => {
    // Used in mobile page, to scroll to top when clicking on same navigation page
    switch (currentPage) {
      case routeConst.search.path:
        dispatch(updateSearchScrollToTopPassthrough({}));
        break;
      case routeConst.inbox.path:
        dispatch(updateInboxScrollToTopPassthrough({}));
        break;
      case routeConst.videoCall.path:
        dispatch(updateVideoCallScrollToTopPassthrough({}));
        break;
      case routeConst.live.path:
        dispatch(updateLivestreamScrollToTopPassthrough({}));
        break;
      case routeConst.interest.path:
        dispatch(updateInterestScrollToTopPassthrough({}));
        break;
      case routeConst.profile.overview.path:
        break;
      default:
        break;
    }
  };

  // Render Functions | MUI Drawer
  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          t("landing.drawer.how_it_works"),
          t("landing.drawer.what_it_means_to_be_a"),
          t("landing.drawer.sugar_baby"),
          t("landing.drawer.sugar_daddy"),
          t("landing.drawer.events"),
        ].map((text, index) => (
          <div key={index}>
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => onLandingMenu(index)}>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Box>
  );

  // Render Functions
  if (showNavbar) {
    return (
      <div id="navbar-navbar-mobile-view-subcomponent">
        <div className="space-around">
          <div className="mobile-view">
            <div className="left-container">{mobileLeftButton}</div>
            <div className="middle-container">{mobileMiddleSection}</div>
            <div className="right-container">{mobileRightButton}</div>

            <Drawer
              anchor="left"
              open={drawerState["left"]}
              onClose={toggleDrawer("left", false)}
            >
              {list("left")}
            </Drawer>
          </div>
        </div>
      </div>
    );
  }
};

export default NavbarMobileView;
