// General
import "./verify-email.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileCheckQuery,
  useLazyResendEmailVerificationQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateSuccessToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { Trans, useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const VerifyEmail = () => {
  // API variables
  const [
    getProfileCheck,
    {
      data: getProfileCheckData,
      error: getProfileCheckErrorData,
      isFetching: getProfileCheckFetching,
      isLoading: getProfileCheckLoading,
      isSuccess: getProfileCheckSuccess,
      isError: getProfileCheckError,
    },
  ] = useLazyGetProfileCheckQuery();
  const [
    resendEmailVerification,
    {
      data: resendEmailVerificationData,
      error: resendEmailVerificationErrorData,
      isFetching: resendEmailVerificationFetching,
      isLoading: resendEmailVerificationLoading,
      isSuccess: resendEmailVerificationSuccess,
      isError: resendEmailVerificationError,
    },
  ] = useLazyResendEmailVerificationQuery();

  // Redux variables
  const email = useSelector((state) => state.user.email);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Search-Page-VerifyEmail-Subcomponent",
      },
    });

    getProfileCheck(null, false);
  }, []);

  // Lifecycle | Check for update | Resend Email Verification API Response
  useEffect(() => {
    if (resendEmailVerificationFetching || resendEmailVerificationLoading) {
    } else if (resendEmailVerificationSuccess) {
      const toastObj = {
        message: t("signup.verification_email_sent"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));
    } else if (resendEmailVerificationError) {
    }
  }, [
    resendEmailVerificationFetching,
    resendEmailVerificationLoading,
    resendEmailVerificationSuccess,
    resendEmailVerificationError,
  ]);

  // Event Handlers | Button
  const onResendEmail = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Search-Page-VerifyEmail-Subcomponent-ResendEmail-Button",
      },
    });

    resendEmailVerification();
  };

  // Render Functions | API Response
  if (getProfileCheckFetching || getProfileCheckLoading) {
  } else if (getProfileCheckSuccess && getProfileCheckData) {
    if (getProfileCheckData?.status === 0) {
      if (!getProfileCheckData?.payload?.onboarding?.email_verification) {
        return (
          <div id="search-verify-email-subcomponent">
            <div className="left-container">
              <div className="profile-icon-container">
                {getIcon("mailIcon", "profile-icon")}
              </div>
            </div>

            <div className="description-container">
              <div className="subheader">
                {t("signup.verify_your_email_address")}
              </div>
              <div className="text">
                <Trans>{t("signup.resend_email", { email: email })}</Trans>
              </div>
            </div>

            <div className="right-container">
              <div className="resend-button" onClick={onResendEmail}>
                {resendEmailVerificationFetching ||
                resendEmailVerificationLoading ? (
                  <Spinner
                    size={15}
                    isPadding={false}
                    color={"white-spinner"}
                  />
                ) : (
                  t("common.resend")
                )}
              </div>
            </div>
          </div>
        );
      }
    }
  } else if (getProfileCheckError) {
    if (getProfileCheckErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default VerifyEmail;
