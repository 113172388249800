// General
import "./safety-tips.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../const/routeConst";
// react-gtm-module
import TagManager from "react-gtm-module";
// Components
import UtilityDesktopNavbar from "../shared/elements/utility-desktop-navbar/utility-desktop-navbar";

const SafetyTips = () => {
  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-SafetyTips-Page",
      },
    });
  }, []);

  return (
    <div id="safety-tips-page">
      <UtilityDesktopNavbar leftButton={"back"} />

      <iframe
        className="iframe-container"
        src={routeConst.safetyTips.pathAlt}
      ></iframe>
    </div>
  );
};

export default SafetyTips;
