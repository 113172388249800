// General
import "./video-player.scss";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
// Services
import { useLazyGetLivestreamingChannelQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLivestreamLoading,
  updateLivestreamBuffering,
  updateVideoPlayerMute,
  toggleVideoPlayerMute,

  // Utility Functions
  updateHasInteract,
} from "../../../../redux/store/livestreamingStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
import { updateLowPowerModeDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// hls.js
import Hls from "hls.js";
// react-device-detect
import { isMacOs, isDesktop as isDesktopDevice } from "react-device-detect";
// Material UI
import { useMediaQuery } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// Custom Hooks
import useIsMounted from "../../../utility/custom-hooks/useIsMounted-hook";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const VideoPlayer = () => {
  // API variables
  const [
    getLivestreamingChannel,
    {
      data: getLivestreamingChannelData,
      error: getLivestreamingChannelErrorData,
      isFetching: getLivestreamingChannelFetching,
      isLoading: getLivestreamingChannelLoading,
      isSuccess: getLivestreamingChannelSuccess,
      isError: getLivestreamingChannelError,
    },
  ] = useLazyGetLivestreamingChannelQuery();

  // General variables
  const [hls, setHls] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoRetryAttemptCount, setVideoRetryAttemptCount] = useState(0);
  const [hlsQualityLevel, setHlsQualityLevel] = useState(["hls"]);
  const [hlsStreamProvider, setHlsStreamProvider] = useState(null);
  const [hasInteraction, setHasInteraction] = useState(true);
  const videoRef = useRef(null);
  const isMounted = useRef(false);

  // Redux variables
  const videoPlayerMute = useSelector(
    (state) => state.livestreaming.videoPlayerMute
  );
  const playVideoPassthrough = useSelector(
    (state) => state.livestreaming.playVideoPassthrough
  );
  const livestreamPusherEvent = useSelector(
    (state) => state.livestreaming.livestreamPusherEvent
  );
  const dispatch = useDispatch();

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 1305px)");
  const isTablet = useMediaQuery("(min-width: 900px)");
  const isMobile = useMediaQuery("(max-width: 900px)");

  // Router variables
  const { id } = useParams();

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getLivestreamingChannel(id, true);
  }, []);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        // Destroy hls.js on unmounted to prevent memory leak
        if (hls) {
          hls.destroy();
        }

        // Destroy video player
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.removeAttribute("src");
          videoRef.current.load();
        }
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | getLivestreamingChannels API Response
  useEffect(() => {
    if (getLivestreamingChannelLoading) {
    } else if (getLivestreamingChannelSuccess) {
      if (getLivestreamingChannelData?.status === 1) {
        initVideoPlayer();
      }
    } else if (getLivestreamingChannelError) {
      if (getLivestreamingChannelErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingChannelFetching,
    getLivestreamingChannelLoading,
    getLivestreamingChannelSuccess,
    getLivestreamingChannelError,
  ]);

  // Lifecycle | Check for update | Check for HLS support
  useEffect(() => {
    if (!videoRef || !videoUrl) return;
    // Add Event Listeners for video player
    addDefaultListenerToVideoPlayer();

    if (Hls.isSupported()) {
      console.log("%cVIDEO PLAYER: HLS Supported", "color: green;");
      setHls(
        new Hls({
          debug: false,
          maxBufferSize: 90 * 1000 * 1000,
          // backBufferLength: 30,
        })
      );
    } else {
      console.log("%cVIDEO PLAYER: HLS NOT Supported", "color: green;");
      if (
        videoRef.current.canPlayType("application/vnd.apple.mpegURL") ||
        videoRef.current.canPlayType("application/vnd.apple.mpegurl")
      ) {
        videoRef.current.src = videoUrl;
        videoRef.current.load();
        // videoRef.current?.play();

        // Disable Loading Overlay
        dispatch(updateLivestreamLoading(false));

        // Safari does not support autoplay
        // User will need to manually unmute or it will throw error
        // Not implemented for iOS or iPadOS because we assume the user will not refresh this page**
        if (!isMacOs || !isDesktopDevice) {
          // Audio must be muted on load due to Google's policy
          // if (videoPlayerMute) {
          //   setTimeout(() => {
          //     dispatch(toggleVideoPlayerMute());
          //   }, 1000);
          // }
        }
      } else {
        const toastObj = {
          message: "Browser not supported",
          autoClose: 3000,
        };
        dispatch(updateErrorToast(toastObj));
      }
    }
  }, [videoRef, videoUrl]);

  // Lifecycle | Check for update | After Hls.js initialised
  useEffect(() => {
    if (!hls || !videoRef || videoRetryAttemptCount > 5) return;
    // General Event Listener from hls.js
    HlsGeneralEventsListener();

    // Error Event Listener from hls.js
    HlsErrorEventsListener();
    hls.loadSource(videoUrl);

    // bind them together
    hls.attachMedia(videoRef.current);

    // Safari does not support autoplay
    // User will need to manually unmute or it will throw error
    // Not implemented for iOS or iPadOS because we assume the user will not refresh this page**
    if (!isMacOs || !isDesktopDevice) {
      // Audio must be muted on load due to Google's policy
      // if (videoPlayerMute) {
      //   setTimeout(() => {
      //     dispatch(toggleVideoPlayerMute());
      //   }, 1000);
      // }
    }
  }, [hls, videoRef, videoRetryAttemptCount]);

  // Lifecycle | Check for update | videoRetryAttemptCount
  useEffect(() => {
    if (videoRetryAttemptCount > 0) {
      setTimeout(() => {
        setVideoRetryAttemptCount(0);
      }, 30000);

      if (videoRetryAttemptCount > 10) {
        onNavigate(routeConst.live.ended.path);
      }
    }
  }, [videoRetryAttemptCount]);

  // Lifecycle | Check for update | livestreamPusherEvent
  useEffect(() => {
    if (!livestreamPusherEvent) return;

    switch (livestreamPusherEvent?.type) {
      case "viewer_kicked":
      case "kicked":
        videoRef.current.pause();
        break;
      default:
        break;
    }
  }, [livestreamPusherEvent]);

  // Lifecycle | Check for update | playVideoPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (playVideoPassthrough) {
        videoRef.current?.play();
      }
    } else {
      isMounted.current = true;
    }
  }, [playVideoPassthrough]);

  // Video Player Functions
  const initVideoPlayer = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Livestream-Page-Start-Video-Player",
      },
    });

    setVideoUrl(getStreamPullChannel());
    setHlsStreamProvider(getStreamProvider());
  };
  const HlsGeneralEventsListener = () => {
    // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
      console.log(
        "%cVIDEO PLAYER: video and hls.js are now bound together !",
        "color: green;"
      );
    });

    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
      console.log(
        "%cVIDEO PLAYER: manifest loaded, found " +
          data.levels.length +
          " quality level",
        "color: green;"
      );
      videoRef.current?.play();

      // Disable Loading Overlay
      dispatch(updateLivestreamLoading(false));
    });
  };
  const HlsErrorEventsListener = () => {
    hls.on(Hls.Events.ERROR, function (event, data) {
      if (data.fatal) {
        setTimeout(() => {
          setVideoRetryAttemptCount((prev) => prev + 1);
        }, 2000);

        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.log("%cVIDEO PLAYER: HLS: NETWORK ERROR", "color: green;");
            hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log("%cVIDEO PLAYER: HLS: MEDIA ERROR", "color: green;");
            hls.recoverMediaError();
            break;
          case Hls.ErrorTypes.OTHER_ERROR:
            console.log("%cVIDEO PLAYER: HLS: OTHER ERROR", "color: green;");
            break;
          default:
            // cannot recover
            hls.destroy();
            break;
        }
      } else {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.log("%cVIDEO PLAYER: HLS: NETWORK ERROR", "color: green;");
            hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            // Buffer detected
            if (
              Hls.ErrorDetails.BUFFER_NUDGE_ON_STALL ||
              Hls.ErrorDetails.BUFFER_STALLED_ERROR
            ) {
              dispatch(updateLivestreamBuffering(true));
            } else {
              dispatch(updateLivestreamBuffering(false));
            }
            break;
          default:
            break;
        }
      }
    });
  };
  const addDefaultListenerToVideoPlayer = () => {
    const events = [
      "abort",
      "canplay",
      "canplaythrough",
      "emptied",
      "encrypted",
      "ended",
      "error",
      "interruptbegin",
      "interruptend",
      "loadeddata",
      "loadedmetadata",
      "loadstart",
      "mozaudioavailable",
      "pause",
      "play",
      "playing",
      "ratechange",
      "seeked",
      "seeking",
      "stalled",
      "suspend",
      "volumechange",
      "waiting",
    ];

    let eventFunction = function (e) {
      // Event Listener if video recovers from stalled state
      switch (e.type) {
        case "canplay":
        case "playing":
        case "canplaythrough":
          dispatch(updateLivestreamBuffering(false));
          break;
        case "waiting":
        case "seeking":
          dispatch(updateLivestreamBuffering(true));
          break;
        case "loadedmetadata":
          videoRef.current
            ?.play()
            .then(() => {
              // Autoplay started
            })
            .catch((error) => {
              if (
                error
                  .toString()
                  .includes("user didn't interact with the document")
              ) {
                // User didn't interact with document first

                // 2 Options
                // 1. Don't play the video and let user click the play button
                // dispatch(updateHasInteract(false));

                // 2. Mute video and play it
                dispatch(updateVideoPlayerMute(true));
                setTimeout(() => {
                  videoRef.current?.play();
                }, 1000);
              } else if (error.toString().includes("NotAllowedError")) {
                // Low Power Mode (iOS)
                // dispatch(updateLowPowerModeDialog(true));
              }
            });

          break;
        case "pause":
          if (e.target.error || e.target.readyState < 4) {
            // Ignore pauses due to errors or buffering
            return;
          }

          videoRef.current?.play();
          break;
        default:
          break;
      }
    };

    events.forEach((e) => {
      videoRef.current.addEventListener(e, eventFunction, false);
    });
  };

  // Utility Functions
  const getStreamPullChannel = () => {
    let pullChannels = getLivestreamingChannelData?.data?.live_stream?.pull;
    let videoUrl = null;

    for (let i = 0; i < pullChannels?.length; i++) {
      if (pullChannels[i].type === "hls") {
        videoUrl = pullChannels[i].url;
        break;
      }
    }

    return videoUrl;
  };
  const getStreamProvider = () => {
    let pullChannels = getLivestreamingChannelData?.data?.live_stream?.pull;
    let provider = null;
    for (let i = 0; i < pullChannels?.length; i++) {
      if (pullChannels[i].type === "hls") {
        provider = pullChannels[i].provider;
        break;
      }
    }

    return provider;
  };

  return (
    <div
      id="livestream-video-player-subcomponent"
      className={!isMobile ? "desktop-video-player" : "mobile-video-player"}
    >
      <video
        id="live-video"
        className={!isMobile ? "maintain-aspect-ratio" : "video-covered"}
        ref={videoRef}
        playsInline
        muted={videoPlayerMute}
      ></video>

      {hasInteraction && (
        <div className="play-overlay-container">
          <PlayArrowIcon />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
