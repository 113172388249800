// General
import "./user-list.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useDispatch } from "react-redux";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../redux/store/userCardStore";
import {
  updateVerifiedCallerDescriptionDialog,
  updateUserProfileDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import StarIcon from "@mui/icons-material/Star";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import useGetReadableDate from "../../../utility/custom-hooks/useGetReadableDate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";

const UserList = (props) => {
  const {
    // User View
    id,
    idInt,
    username,
    age,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    profilePhoto,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    lookingFor,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    timestamp,
    isBlur,

    // User Utility
    shouldCallApi,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
  } = props;

  // Redux variables
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getReadableDate = useGetReadableDate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onUserClick = () => {
    if (isBlur) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Interests-Page-UserClickUpgrade-Button",
        },
      });

      onNavigate(routeConst.upgrade.path);
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Interests-Page-ViewProfile-Button",
        },
      });

      // User View
      dispatch(updateId(id));
      dispatch(updateIdInt(idInt));
      dispatch(updateUsername(username));
      dispatch(updateAge(age));
      dispatch(updateLocation(location));
      dispatch(updateMembershipType(membershipType));
      dispatch(updateVerifiedProfile(verifiedProfile));
      dispatch(updateVerifiedCaller(verifiedCaller));
      dispatch(updateLevellingBadge(levellingBadge));
      dispatch(
        updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
      );
      dispatch(updateProfilePhoto(profilePhoto));
      dispatch(updatePublicPhotos(publicPhotos));
      dispatch(updatePrivatePhotos(privatePhotos));
      dispatch(updateTorTags(torTags));
      dispatch(updateProfileAchievementBadge(profileAchievementBadge));
      dispatch(updateAboutMe(aboutMe));
      dispatch(updateLookingFor(lookingFor));
      dispatch(updateOccupation(occupation));
      dispatch(updateLifestyle(lifestyle));
      dispatch(updateEducation(education));
      dispatch(updateRelationship(relationship));
      dispatch(updateSmoking(smoking));
      dispatch(updateDrinking(drinking));
      dispatch(updateHeight(height));
      dispatch(updateBodyType(bodyType));
      dispatch(updateEthnicity(ethnicity));
      dispatch(updateEyeColor(eyeColor));
      dispatch(updateHairColor(hairColor));
      dispatch(updateChildren(children));
      // User Utility
      dispatch(updateShouldCallApi(shouldCallApi));
      dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
      dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
      dispatch(updateIsBlocked(isBlocked));
      dispatch(updateIsSilentInteraction(isSilentInteraction));
      dispatch(updateIsFavorited(isFavorited));
      dispatch(updateIsCallEnabled(isCallEnabled));
      dispatch(updateIsSugarbaby(isSugarbaby));
      dispatch(updateRole(role));

      // Navigate to Profile View
      onNavigate(`${routeConst.profile.view.clearPath}${id}`);
      // dispatch(updateUserProfileDialog(true)); // Dialog
    }
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  return (
    <div id="user-list-shared-subcomponent" onClick={onUserClick}>
      <div className="padding-container">
        <div className="user-list">
          <div className="user-image-container">
            <CustomAvatar
              className={`user-image ${isBlur ? "blur-image" : ""}`}
              src={profilePhoto}
              role={role}
            />

            {isBlur && (
              <div className="lock-image-container">
                {getIcon("lockWhiteIcon", "lock-image")}
              </div>
            )}
          </div>

          <div className="user-description-container">
            <div className="user-top-container">
              {livestreamingAchievementBadge?.map((badge, index) => (
                <img
                  className="livestreaming-achievement-badge"
                  src={badge?.url}
                  alt={badge?.type}
                  key={index}
                />
              ))}

              <div className="user-name">{username}</div>
              <div className="misc-badge-container">
                {verifiedProfile && (
                  <div className="verified-profile-badge-container">
                    {getIcon("verifiedProfileBadge", "verified-profile-badge")}
                  </div>
                )}

                {membershipType !== userConst.membershipType.free && (
                  <StarIcon className="star-icon" />
                )}

                {verifiedCaller && (
                  <div
                    className="verified-caller-badge-container"
                    onClick={onOpenVerifiedCallerDialog}
                  >
                    {getIcon("verifiedCallerBadge", "verified-caller-badge")}
                  </div>
                )}
              </div>
            </div>

            <div className="user-bottom-container">
              <div className="user-location">{location}</div>
            </div>
          </div>

          <div className="user-last-updated-container">
            <div className="last-updated">{getReadableDate(timestamp)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
