// General
import { createSlice } from "@reduxjs/toolkit";
// Services
import { sessionService } from "../../services/session.service";
// Static Data
import { API_CONFIG_HEADERS } from "../../const/apiConst";

const initialState = {
  // General variables
  isLoggedIn: false,
  apiToken: "",

  // Timer variables
  dailyCheckInTimer: null,

  // Interval variables
  dailyCheckInInterval: null,

  // Global Snackbar variables
  globalSnackbar: {
    state: false,
    message: "",
    duration: 3000,
    includeSeverity: false,
    severity: "", // error, warning, info, success
  },

  // Redirect Handler variables
  redirectHandler: {
    url: null,
    fromCta: null,
    token: null,
  },

  // VPN variables
  vpnBlockInfo: null,

  // Initial Routing Path variables
  initialRoutingPath: null, // Not Using Yet

  // Utility variables
  userAioRan: false,
};

export const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {
    // General Functions
    updateIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;

      if (action.payload === false) {
        sessionService.deleteAllSessionData();
      }
    },
    updateApiToken: (state, action) => {
      state.apiToken = action.payload;

      API_CONFIG_HEADERS.SB_API_HEADERS.Authorization = `${action.payload}`;
      API_CONFIG_HEADERS.SB_API_HEADERS.token = `${action.payload}`;
      API_CONFIG_HEADERS.SB_API_HEADERS_FORM_DATA.Authorization = `${action.payload}`;
      API_CONFIG_HEADERS.SB_API_HEADERS_FORM_DATA.token = `${action.payload}`;
      API_CONFIG_HEADERS.SPI_HEADERS.Authorization = `${action.payload}`;

      // Transition to Bearer Token (On Hold)
      // API_CONFIG_HEADERS.SB_API_HEADERS.Authorization = `Bearer ${action.payload}`;
      // API_CONFIG_HEADERS.SB_API_HEADERS.token = `Bearer ${action.payload}`;
      // API_CONFIG_HEADERS.SB_API_HEADERS_FORM_DATA.Authorization = `Bearer ${action.payload}`;
      // API_CONFIG_HEADERS.SB_API_HEADERS_FORM_DATA.token = `Bearer ${action.payload}`;
      // API_CONFIG_HEADERS.SPI_HEADERS.Authorization = `Bearer ${action.payload}`;
      API_CONFIG_HEADERS.COM_HEADERS.token = action.payload;
      API_CONFIG_HEADERS.COM_HEADERS_FORM_DATA.token = action.payload;

      sessionService.setApiToken(action.payload);
    },

    // Timer Functions
    setDailyCheckInTimer: (state, action) => {
      state.dailyCheckInTimer = action.payload;
    },
    updateDailyCheckInTimer: (state) => {
      if (state.dailyCheckInTimer <= 0) return;

      state.dailyCheckInTimer--;
    },
    resetDailyCheckInTimer: (state) => {
      state.dailyCheckInTimer = null;
    },

    // Interval Functions
    updateDailyCheckInInterval: (state, action) => {
      if (state.dailyCheckInInterval) return;

      state.dailyCheckInInterval = action.payload;
    },
    clearDailyCheckInInterval: (state) => {
      clearInterval(state.dailyCheckInInterval);
      state.dailyCheckInInterval = null;
    },

    // Global Snackbar Functions
    updateGlobalSnackbar: (state, action) => {
      state.globalSnackbar = action.payload;
    },

    // Redirect Handler Functions
    updateRedirectHandler: (state, action) => {
      state.redirectHandler.url = action.payload.url;
      state.redirectHandler.fromCta = action.payload.fromCta;
      state.redirectHandler.token = action.payload.token;
    },

    // VPN Functions
    updateVpnBlockInfo: (state, action) => {
      state.vpnBlockInfo = action.payload;
    },

    // Initial Routing Path Functions
    updateInitialRoutingPath: (state, action) => {
      state.initialRoutingPath = action.payload;
    },

    // Utility Functions
    resetPublicStore: () => {
      return { ...initialState };
    },
    updateUserAioRan: (state, action) => {
      state.userAioRan = action.payload;
    },
  },
});

export const {
  // General Functions
  updateIsLoggedIn,
  updateApiToken,

  // Timer Functions
  setDailyCheckInTimer,
  updateDailyCheckInTimer,
  resetDailyCheckInTimer,

  // Interval Functions
  updateDailyCheckInInterval,
  clearDailyCheckInInterval,

  // Global Snackbar Functions
  updateGlobalSnackbar,

  // Redirect Handler Functions
  updateRedirectHandler,

  // VPN Functions
  updateVpnBlockInfo,

  // Initial Routing Path Functions
  updateInitialRoutingPath,

  // Utility Functions
  resetPublicStore,
  updateUserAioRan,
} = publicSlice.actions;

export default publicSlice.reducer;
