// General
import "./message-notification.scss";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Material UI
import { useMediaQuery } from "@mui/material";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const MessageNotification = (props) => {
  const { profilePhoto, username, message, conversationId } = props;

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 850px)");

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Event Handlers | Button
  const onRedirectToConversation = () => {
    // Need to solve back redirection issue
    // return;

    onNavigate(
      `${routeConst.inbox.messaging.chat.chatId.clearPath}${conversationId}`
    );
    // if (isMobile) {
    //   onNavigate(
    //     `${routeConst.inbox.messaging.chat.chatId.clearPath}${conversationId}`
    //   );
    // } else {
    //   onNavigate(routeConst.inbox.path);
    // }
  };

  return (
    <div id="message-nofitication-element" onClick={onRedirectToConversation}>
      <div className="padding-container">
        <div className="profile-picture-container">
          <CustomAvatar src={profilePhoto} />
        </div>

        <div className="message-body-container">
          <div className="username">{username}</div>
          <div className="message-body">{message}</div>
        </div>

        <div className="now-container">now</div>
      </div>
    </div>
  );
};

export default MessageNotification;
