// General
import "./loading-overlay.scss";
import { useState, useEffect } from "react";
// Static Data
import livestreamingConst from "../../../../const/livestreamingConst";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const LivestreamLoadingOverlay = () => {
  // Constant variables
  const [selectedCopywriting, setSelectedCopywriting] = useState("");

  // General variables
  const copywritingList = livestreamingConst.loadingCopywriting;

  // Redux variables
  const livestreamLoading = useSelector(
    (state) => state.livestreaming.livestreamLoading
  );

  // i18next variables
  const { t, i18n } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (livestreamLoading) {
      setSelectedCopywriting(getLoadingCopywriting());
    }
  }, [livestreamLoading]);

  // Utility Functions
  const getLoadingCopywriting = () => {
    if (i18n.language.includes("en")) {
      return copywritingList[
        Math.floor(Math.random() * copywritingList.length)
      ];
    } else {
      return t("live.loading");
    }
  };

  if (livestreamLoading) {
    return (
      <div id="livestream-loading-overlay-subcomponent">
        <Spinner type={"iOS"} />

        <div className="loading-text">{selectedCopywriting}</div>

        <div className="tips-container">{t("live.tip")}</div>
      </div>
    );
  }
};

export default LivestreamLoadingOverlay;
