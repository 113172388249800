// General
import "./private-call-waiting-room-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import {
  useLazyGetPrivateCallRequestDetailsQuery,
  useJoinPrivateCallRoomMutation,
  useCancelPrivateCallRequestMutation,
  useLazyJoinPrivateCallWaitingRoomQuery,
  useLazyGetProfileQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  updateIsPrivateStandbyMode,
  startPrivateCallWaitingRoomTimer,
  resetPrivateCallWaitingRoomTimer,
  updateCallDurationRemaining,

  // Interval Functions
  updatePrivateCallWaitingRoomInterval,
  clearPrivateCallWaitingRoomInterval,

  // TRTC Functions
  updateCallerUserSig,
  updateCalleeUserSig,
  updateRoomId,

  // Caller Functions
  updateCallerId,

  // Callee Functions
  updateCalleeId,
  updateIsCaller,
} from "../../../../redux/store/privateCallStore";
import {
  updateCoinsPackageDialog,
  updatePrivateCallWaitingRoomDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../elements/spinner/spinner";

const PrivateCallWaitingRoomDialog = () => {
  // API variables
  const [
    getPrivateCallRequestDetails,
    {
      data: getPrivateCallRequestDetailsData,
      error: getPrivateCallRequestDetailsErrorData,
      isFetching: getPrivateCallRequestDetailsFetching,
      isLoading: getPrivateCallRequestDetailsLoading,
      isSuccess: getPrivateCallRequestDetailsSuccess,
      isError: getPrivateCallRequestDetailsError,
    },
  ] = useLazyGetPrivateCallRequestDetailsQuery();
  const [
    joinPrivateCallRoom,
    {
      data: joinPrivateCallRoomData,
      error: joinPrivateCallRoomErrorData,
      isLoading: joinPrivateCallRoomLoading,
      isSuccess: joinPrivateCallRoomSuccess,
      isError: joinPrivateCallRoomError,
    },
  ] = useJoinPrivateCallRoomMutation();
  const [
    cancelPrivateCallRequest,
    {
      data: cancelPrivateCallRequestData,
      error: cancelPrivateCallRequestErrorData,
      isLoading: cancelPrivateCallRequestLoading,
      isSuccess: cancelPrivateCallRequestSuccess,
      isError: cancelPrivateCallRequestError,
    },
  ] = useCancelPrivateCallRequestMutation();
  const [
    joinPrivateCallWaitingRoom,
    {
      data: joinPrivateCallWaitingRoomData,
      error: joinPrivateCallWaitingRoomErrorData,
      isFetching: joinPrivateCallWaitingRoomFetching,
      isLoading: joinPrivateCallWaitingRoomLoading,
      isSuccess: joinPrivateCallWaitingRoomSuccess,
      isError: joinPrivateCallWaitingRoomError,
    },
  ] = useLazyJoinPrivateCallWaitingRoomQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const privateCallWaitingRoomDialog = useSelector(
    (state) => state.dialog.privateCallWaitingRoomDialog
  );
  const isCaller = useSelector((state) => state.privateCall.isCaller);
  const requestId = useSelector((state) => state.privateCall.requestId);
  const privateCallWaitingRoomTimer = useSelector(
    (state) => state.privateCall.privateCallWaitingRoomTimer
  );
  const inboxPrivateCallMinimumDuration = useSelector(
    (state) => state.privateCall.inboxPrivateCallMinimumDuration
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (privateCallWaitingRoomDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-PrivateCallWaitingRoom-Dialog",
        },
      });

      getProfile(null, true);

      const obj = {
        call_request_id: requestId,
      };
      joinPrivateCallWaitingRoom(obj, true);

      dispatch(
        updatePrivateCallWaitingRoomInterval(
          setInterval(() => {
            dispatch(startPrivateCallWaitingRoomTimer());
          }, 1000)
        )
      );
    } else {
      dispatch(clearPrivateCallWaitingRoomInterval());
      dispatch(resetPrivateCallWaitingRoomTimer());
    }
  }, [privateCallWaitingRoomDialog]);

  // Lifecycle | Check for update | Private Call Request Details API Response
  useEffect(() => {
    if (
      getPrivateCallRequestDetailsFetching ||
      getPrivateCallRequestDetailsLoading
    ) {
    } else if (getPrivateCallRequestDetailsSuccess) {
      if (getPrivateCallRequestDetailsData?.status === 0) {
        const obj = {
          call_request_id: requestId,
        };

        joinPrivateCallRoom(obj);
      }
    } else if (getPrivateCallRequestDetailsError) {
    }
  }, [
    getPrivateCallRequestDetailsFetching,
    getPrivateCallRequestDetailsLoading,
    getPrivateCallRequestDetailsSuccess,
    getPrivateCallRequestDetailsError,
  ]);

  // Lifecycle | Check for update | Join Private Call Room API Response
  useEffect(() => {
    if (joinPrivateCallRoomLoading) {
    } else if (joinPrivateCallRoomSuccess) {
      if (joinPrivateCallRoomData?.status === 0) {
        dispatch(updateIsPrivateStandbyMode(false));
        dispatch(
          updateIsCaller(
            joinPrivateCallRoomData?.data?.call_request?.caller_user_id ===
              getProfileData?.data?.id
              ? true
              : false
          )
        );
        dispatch(
          updateCallerId(
            joinPrivateCallRoomData?.data?.call_request?.caller?.id
          )
        );
        dispatch(
          updateCalleeId(
            joinPrivateCallRoomData?.data?.call_request?.callee?.id
          )
        );
        dispatch(
          updateRoomId(joinPrivateCallRoomData?.data?.call_request?.room_id)
        );
        for (
          let i = 0;
          i < joinPrivateCallRoomData?.data?.call_request?.users_sig?.length;
          i++
        ) {
          if (
            getProfileData?.data?.id ===
            joinPrivateCallRoomData?.data?.call_request?.users_sig[i]?.user_id
          ) {
            dispatch(
              updateCallerUserSig(
                joinPrivateCallRoomData?.data?.call_request?.users_sig[i]
                  ?.usersig
              )
            );
          } else {
            dispatch(
              updateCalleeUserSig(
                joinPrivateCallRoomData?.data?.call_request?.users_sig[i]
                  ?.usersig
              )
            );
          }
        }
        dispatch(
          updateCallDurationRemaining(
            joinPrivateCallRoomData?.data?.real_remaining_seconds
          )
        );

        onCloseDialog();
        onNavigate(routeConst.videoCall.room.path);
      }
    } else if (joinPrivateCallRoomError) {
    }
  }, [
    joinPrivateCallRoomLoading,
    joinPrivateCallRoomSuccess,
    joinPrivateCallRoomError,
  ]);

  // Lifecycle | Check for update | Cancel Private Call Request API Resposne
  useEffect(() => {
    if (cancelPrivateCallRequestLoading) {
    } else if (cancelPrivateCallRequestSuccess) {
      onCloseDialog();
    } else if (cancelPrivateCallRequestError) {
    }
  }, [
    cancelPrivateCallRequestLoading,
    cancelPrivateCallRequestSuccess,
    cancelPrivateCallRequestError,
  ]);

  // Lifecycle | Check for update | privateCallWaitingRoomTimer
  useEffect(() => {
    if (!privateCallWaitingRoomDialog) return;

    if (privateCallWaitingRoomTimer <= 0) {
      onCancelCall();
    }
  }, [privateCallWaitingRoomTimer]);

  // Event Handlers | Button
  const onBuyCoins = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PrivateCallWaitingRoom-Dialog-BuyCoins-Button",
      },
    });

    dispatch(updateCoinsPackageDialog(true));
  };
  const onEnterCall = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PrivateCallWaitingRoom-Dialog-EnterCall-Button",
      },
    });

    getPrivateCallRequestDetails(`?call_request_id=${requestId}`);
  };
  const onCancelCall = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PrivateCallWaitingRoom-Dialog-CancelCall-Button",
      },
    });

    const obj = {
      call_request_id: requestId,
    };

    cancelPrivateCallRequest(obj);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updatePrivateCallWaitingRoomDialog(false));
  };

  // Utility Functions
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    if (hours > 0) {
      return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
    } else {
      return formattedMinutes + ":" + formattedSeconds;
    }
  };

  return (
    <Dialog
      className="custom-fullscreen-transparent-dialog"
      fullScreen
      open={privateCallWaitingRoomDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div id="private-call-waiting-room-dialog">
        <div className="padding-container">
          <div className="waiting-room-container">
            <div className="waiting-room-animation-container">
              {getIcon("callWaitingGif", "waiting-room-animation")}
            </div>

            {isDaddy &&
              joinPrivateCallWaitingRoomData?.data
                ?.campaign_remaining_seconds <= 0 &&
              joinPrivateCallWaitingRoomData?.data?.wallet?.coins <=
                (inboxPrivateCallMinimumDuration / 60) *
                  joinPrivateCallWaitingRoomData?.data?.call_request?.rates
                    ?.coins && (
                <div className="prep-container">
                  <div className="waiting-room-label">
                    {t("inbox.calling_waiting_room")}
                  </div>

                  {!isCaller && (
                    <div className="sb-tips">
                      {t("inbox.calling_waiting_sb")}
                    </div>
                  )}

                  <div className="text">
                    <Trans
                      i18nKey="inbox.calling_ending_in"
                      values={{
                        minutes: getFormattedTime(privateCallWaitingRoomTimer),
                      }}
                      components={{ span: <span /> }}
                    />
                  </div>

                  <div className="enter-button" onClick={onEnterCall}>
                    {getPrivateCallRequestDetailsFetching ||
                    joinPrivateCallRoomLoading ? (
                      <Spinner
                        size={20}
                        isPadding={false}
                        color={"white-spinner"}
                      />
                    ) : (
                      t("inbox.calling_enter_call")
                    )}
                  </div>

                  <div className="end-call-button" onClick={onCancelCall}>
                    {cancelPrivateCallRequestLoading ? (
                      <Spinner
                        size={15}
                        isPadding={false}
                        color={"white-spinner"}
                      />
                    ) : (
                      t("inbox.calling_end_call")
                    )}
                  </div>
                </div>
              )}

            {isDaddy &&
              (joinPrivateCallWaitingRoomData?.data
                ?.campaign_remaining_seconds > 0 ||
                joinPrivateCallWaitingRoomData?.data?.wallet?.coins >
                  (inboxPrivateCallMinimumDuration / 60) *
                    joinPrivateCallWaitingRoomData?.data?.call_request?.rates
                      ?.coins) && (
                <div className="prep-container">
                  <div className="waiting-room-label">
                    {t("inbox.calling_waiting_room")}
                  </div>

                  {!isCaller && (
                    <div className="sb-tips">
                      {t("inbox.calling_waiting_sb")}
                    </div>
                  )}

                  <div className="text">
                    <Trans
                      i18nKey="inbox.calling_ending_in"
                      values={{
                        minutes: getFormattedTime(privateCallWaitingRoomTimer),
                      }}
                      components={{ span: <span /> }}
                    />
                  </div>

                  <div className="enter-button" onClick={onEnterCall}>
                    {getPrivateCallRequestDetailsFetching ||
                    joinPrivateCallRoomLoading ? (
                      <Spinner
                        size={20}
                        isPadding={false}
                        color={"white-spinner"}
                      />
                    ) : (
                      t("inbox.calling_enter_call")
                    )}
                  </div>

                  <div className="end-call-button" onClick={onCancelCall}>
                    {cancelPrivateCallRequestLoading ? (
                      <Spinner
                        size={15}
                        isPadding={false}
                        color={"white-spinner"}
                      />
                    ) : (
                      t("inbox.calling_end_call")
                    )}
                  </div>
                </div>
              )}

            {!isDaddy && (
              <div className="prep-container">
                <div className="waiting-room-label">
                  {t("inbox.calling_waiting_room")}
                </div>

                {!isCaller && (
                  <div className="sb-tips">{t("inbox.calling_waiting_sb")}</div>
                )}

                <div className="text">
                  <Trans
                    i18nKey="inbox.calling_ending_in"
                    values={{
                      minutes: getFormattedTime(privateCallWaitingRoomTimer),
                    }}
                    components={{ span: <span /> }}
                  />
                </div>

                <div className="enter-button" onClick={onEnterCall}>
                  {getPrivateCallRequestDetailsFetching ||
                  joinPrivateCallRoomLoading ? (
                    <Spinner
                      size={20}
                      isPadding={false}
                      color={"white-spinner"}
                    />
                  ) : (
                    t("inbox.calling_enter_call")
                  )}
                </div>

                <div className="end-call-button" onClick={onCancelCall}>
                  {cancelPrivateCallRequestLoading ? (
                    <Spinner
                      size={15}
                      isPadding={false}
                      color={"white-spinner"}
                    />
                  ) : (
                    t("inbox.calling_end_call")
                  )}
                </div>
              </div>
            )}

            {/* Insufficient Coins */}
            {/* {isDaddy ? (
              joinPrivateCallWaitingRoomData?.data
                ?.campaign_remaining_seconds <= 0 &&
              joinPrivateCallWaitingRoomData?.data?.wallet?.coins <=
                (inboxPrivateCallMinimumDuration / 60) *
                  joinPrivateCallWaitingRoomData?.data?.call_request?.rates
                    ?.coins && (
                <div className="not-enough-coins-container">
                  <div className="waiting-room-label">
                    {t("inbox.calling_not_enough_coin")}
                  </div>

                  <div className="not-enough-coins-container">
                    <div className="not-enough-point-1">
                      {t("inbox.calling_you_will_need_at_least")}
                    </div>

                    <div className="not-enough-point-2">
                      <div>
                        {t("inbox.calling_n_minutes_of_coins", {
                          remaining_minutes:
                            inboxPrivateCallMinimumDuration / 60,
                        })}
                      </div>

                      <div className="coins-container">
                        (<img className="coin-icon" src={Coin} />{" "}
                        {(inboxPrivateCallMinimumDuration / 60) *
                          joinPrivateCallWaitingRoomData?.data?.call_request
                            ?.rates?.coins}
                        )
                      </div>
                    </div>

                    <div className="not-enough-point-3">
                      {t("inbox.calling_to_enter_call")}
                    </div>
                  </div>

                  <div className="text">
                    <Trans
                      i18nKey="inbox.calling_ending_in"
                      values={{
                        minutes: getFormattedTime(privateCallWaitingRoomTimer),
                      }}
                      components={{ span: <span /> }}
                    />
                  </div>

                  <div className="buy-coins-button" onClick={onBuyCoins}>
                    {getPrivateCallRequestDetailsFetching ||
                    joinPrivateCallRoomLoading ? (
                      <Spinner
                        size={20}
                        isPadding={false}
                        color={"white-spinner"}
                      />
                    ) : (
                      t("inbox.calling_buy_coins")
                    )}
                  </div>

                  <div className="end-call-button" onClick={onCancelCall}>
                    {cancelPrivateCallRequestLoading ? (
                      <Spinner
                        size={15}
                        isPadding={false}
                        color={"white-spinner"}
                      />
                    ) : (
                      t("inbox.calling_end_call")
                    )}
                  </div>
                </div>
              )
            ) : (
              <div className="prep-container">
                <div className="waiting-room-label">
                  {t("inbox.calling_waiting_room")}
                </div>

                <div className="text">
                  <Trans
                    i18nKey="inbox.calling_ending_in"
                    values={{
                      minutes: getFormattedTime(privateCallWaitingRoomTimer),
                    }}
                    components={{ span: <span /> }}
                  />
                </div>

                <div className="enter-button" onClick={onEnterCall}>
                  {getPrivateCallRequestDetailsFetching ||
                  joinPrivateCallRoomLoading ? (
                    <Spinner
                      size={20}
                      isPadding={false}
                      color={"white-spinner"}
                    />
                  ) : (
                    t("inbox.calling_enter_call")
                  )}
                </div>

                <div className="end-call-button" onClick={onCancelCall}>
                  {cancelPrivateCallRequestLoading ? (
                    <Spinner
                      size={15}
                      isPadding={false}
                      color={"white-spinner"}
                    />
                  ) : (
                    t("inbox.calling_end_call")
                  )}
                </div>
              </div>
            )} */}

            {/* Normal Flow */}
            {/* {(!isDaddy ||
              joinPrivateCallWaitingRoomData?.data?.campaign_remaining_seconds >
                0 ||
              joinPrivateCallWaitingRoomData?.data?.wallet?.coins >
                (inboxPrivateCallMinimumDuration / 60) *
                  joinPrivateCallWaitingRoomData?.data?.call_request?.rates
                    ?.coins) && (
              <div className="prep-container">
                <div className="waiting-room-label">
                  {t("inbox.calling_waiting_room")}
                </div>

                {!isCaller && (
                  <div className="sb-tips">{t("inbox.calling_waiting_sb")}</div>
                )}

                <div className="text">
                  <Trans
                    i18nKey="inbox.calling_ending_in"
                    values={{
                      minutes: getFormattedTime(privateCallWaitingRoomTimer),
                    }}
                    components={{ span: <span /> }}
                  />
                </div>

                <div className="enter-button" onClick={onEnterCall}>
                  {getPrivateCallRequestDetailsFetching ||
                  joinPrivateCallRoomLoading ? (
                    <Spinner
                      size={20}
                      isPadding={false}
                      color={"white-spinner"}
                    />
                  ) : (
                    t("inbox.calling_enter_call")
                  )}
                </div>

                <div className="end-call-button" onClick={onCancelCall}>
                  {cancelPrivateCallRequestLoading ? (
                    <Spinner
                      size={15}
                      isPadding={false}
                      color={"white-spinner"}
                    />
                  ) : (
                    t("inbox.calling_end_call")
                  )}
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PrivateCallWaitingRoomDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
