// General
import "./chat-input.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetUserAioQuery,
  usePostPrivateCallRoomChatMutation,
  usePostPrivateCallRoomCoinRequestMutation,
  usePostPrivateCallSendTipCoinMutation,
  useLazyGetProfileQuery,
} from "../../../../../services/data.service";
import { removeHTMLEscape } from "../../../../../services/regex.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProductPurchaseDialog } from "../../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../../redux/store/toastStore";
import {
  updateVideoCallFloatingEmoji,
  updateDisableSwipe,
  updateTotalCoins,
} from "../../../../../redux/store/privateCallStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { TextField } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const ChatInput = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    sendMessage,
    {
      data: sendMessageData,
      error: sendMessageErrorData,
      isLoading: sendMessageLoading,
      isSuccess: sendMessageSuccess,
      isError: sendMessageError,
    },
  ] = usePostPrivateCallRoomChatMutation();
  const [
    requestCoin,
    {
      data: requestCoinData,
      error: requestCoinErrorData,
      isLoading: requestCoinLoading,
      isSuccess: requestCoinSuccess,
      isError: requestCoinError,
    },
  ] = usePostPrivateCallRoomCoinRequestMutation();
  const [
    sendCoin,
    {
      data: sendCoinData,
      error: sendCoinErrorData,
      isLoading: sendCoinLoading,
      isSuccess: sendCoinSuccess,
      isError: sendCoinError,
    },
  ] = usePostPrivateCallSendTipCoinMutation();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const [inputValue, setInputValue] = useState("");
  const [hideEmojiList, setHideEmojiList] = useState(false);

  // Redux variables
  const requestId = useSelector((state) => state.privateCall.requestId);
  const calleeId = useSelector((state) => state.privateCall.calleeId);
  const callerId = useSelector((state) => state.privateCall.callerId);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    getUserAio(null, true);
  }, []);

  // Lifecycle | Check for update | Send Message API Response
  useEffect(() => {
    if (sendMessageLoading) {
    } else if (sendMessageSuccess) {
      switch (sendMessageData?.status) {
        case -1:
          // Insufficient Coins
          const toastObj = {
            message: sendMessageData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));

          break;
        default:
          break;
      }
    } else if (sendMessageError) {
    }
  }, [sendMessageLoading, sendMessageSuccess, sendMessageError]);

  // Lifecycle | Check for update | Send Coin API Response
  useEffect(() => {
    if (sendCoinLoading) {
    } else if (sendCoinSuccess) {
      dispatch(updateVideoCallFloatingEmoji({ emoji: "coin" }));
      dispatch(updateTotalCoins(sendCoinData?.data?.wallet?.coins));
    } else if (sendCoinError) {
    }
  }, [sendCoinLoading, sendCoinSuccess, sendCoinError]);

  // Event Handlers | Button
  const onSendMessage = () => {
    if (inputValue === "") return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PrivateCall-Page-SendChat-Button",
      },
    });

    const obj = {
      call_request_id: requestId,
      message: inputValue,
    };

    sendMessage(obj);

    // Clear input after send
    setInputValue("");
  };
  const onSendEmoji = (emoji) => {
    if (isDaddy) {
      switch (emoji) {
        case "coin":
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-PrivateCall-Page-SendCoins-Button",
            },
          });

          const obj = {
            recipient_user_id:
              getProfileData?.data?.id === calleeId ? callerId : calleeId,
            related_id: requestId,
            related_module: "private_calls",
          };

          sendCoin(obj);
          break;
        case "face_blowing_a_kiss":
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-PrivateCall-Page-SendEmoji-Button",
            },
          });

          dispatch(updateVideoCallFloatingEmoji({ emoji }));
          break;
        default:
          break;
      }
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-PrivateCall-Page-RequestCoins-Button",
        },
      });

      const obj = {
        call_request_id: requestId,
        message: "Daddy, tip me pls",
      };

      requestCoin(obj);
    }
  };
  const onEnterPress = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      onSendMessage();
    }
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (e) => {
    if (removeHTMLEscape.test(e.target.value)) {
      // Remove HTML Escape (prevent XSS)
      setInputValue("");

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-VideoCall-Page-Conversation-Chat-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event:
            "PWA-VideoCall-Page-Conversation-Chat-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setInputValue(e.target.value);
    }
  };
  const onInputFocus = (focus) => {
    setHideEmojiList(focus);
  };

  // Event Handlers | MUI Dialog
  const onOpenProductsDialog = () => {
    dispatch(updateProductPurchaseDialog(true));
  };

  // Utility Functions
  const handleOnTouchOver = (event) => {
    dispatch(updateDisableSwipe(true));
  };
  const handleOnTouchEnd = (event) => {
    dispatch(updateDisableSwipe(false));
  };

  return (
    <div
      id="interactable-overlay-chat-input-subcomponent"
      onTouchMove={handleOnTouchOver}
      onTouchEnd={handleOnTouchEnd}
    >
      <div className="more-gifts-container" onClick={onOpenProductsDialog}>
        {getIcon("moreGiftBadge", "more-gifts")}
      </div>

      <div className="input-container">
        <TextField
          className="input-field"
          value={inputValue}
          placeholder={t("live.say_something")}
          onChange={onInputChange}
          onFocus={() => onInputFocus(true)}
          onBlur={() => onInputFocus(false)}
          variant="standard"
          InputProps={{ disableUnderline: true }}
          inputProps={{
            maxLength:
              getUserAioData?.data?.private_calls?.chat_messages_text_limit ||
              150,
          }}
          onKeyDown={onEnterPress}
        />
      </div>

      {(hideEmojiList || inputValue) && (
        <div className="send-icon-container" onClick={onSendMessage}>
          {getIcon("sendMessageLightIcon", "send-icon")}
        </div>
      )}

      {!hideEmojiList && !inputValue && (
        <div className="emoji-list-container">
          <div
            className="coin-icon-container"
            onClick={() => onSendEmoji("coin")}
          >
            {getIcon("sbCoinIcon", isDaddy ? "coin-icon" : "grey-emoji-icon")}
          </div>

          {isDaddy && (
            <div className="emoji-list-container">
              <div
                className="blow-kiss-emoji-container"
                onClick={() => onSendEmoji("face_blowing_a_kiss")}
              >
                {getIcon("blowKissEmoji", "blow-kiss-emoji")}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatInput;
