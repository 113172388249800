// General
import "./detail-signup.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useUpdateProfileMutation,
  useUpdateUserProfileMutation,
} from "../../../services/data.service";
import { removeHTMLEscape } from "../../../services/regex.service";
// Static Data
import routeConst from "../../../const/routeConst";
import userConst from "../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateOccupation,
  updateEducation,
  updateRelationshipStatus,
  updateChildren,
  updateSmoking,
  updateDrinking,
} from "../../../redux/store/signupStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { TextField, MenuItem, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const DetailSignup = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateProfileMutation();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateUserProfileMutation();

  // General variables
  const [occupationFocus, setOccupationFocus] = useState(false);
  const [occupationValid, setOccupationValid] = useState(false);
  const [occupationError, setOccupationError] = useState(false);
  const [educationFocus, setEducationFocus] = useState(false);
  const [educationValid, setEducationValid] = useState(false);
  const [educationError, setEducationError] = useState(false);
  const [relationshipFocus, setRelationshipFocus] = useState(false);
  const [relationshipValid, setRelationshipValid] = useState(false);
  const [relationshipError, setRelationshipError] = useState(false);
  const [childrenFocus, setChildrenFocus] = useState(false);
  const [childrenValid, setChildrenValid] = useState(false);
  const [childrenError, setChildrenError] = useState(false);
  const [smokingFocus, setSmokingFocus] = useState(false);
  const [smokingValid, setSmokingValid] = useState(false);
  const [smokingError, setSmokingError] = useState(false);
  const [drinkingFocus, setDrinkingFocus] = useState(false);
  const [drinkingValid, setDrinkingValid] = useState(false);
  const [drinkingError, setDrinkingError] = useState(false);

  // Redux variables
  const occupation = useSelector((state) => state.signup.occupation);
  const education = useSelector((state) => state.signup.education);
  const relationshipStatus = useSelector(
    (state) => state.signup.relationshipStatus
  );
  const children = useSelector((state) => state.signup.children);
  const smoking = useSelector((state) => state.signup.smoking);
  const drinking = useSelector((state) => state.signup.drinking);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DetailSignup-Page",
      },
    });

    getAio(null, true);
  }, []);

  // Lifecycle | Check for update | Profile Update API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      onNavigate(routeConst.emailVerification.path);
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | Profile Update API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      onNavigate(routeConst.emailVerification.path);
    } else if (updateUserProfileError) {
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DetailSignup-Page-Save-Button",
      },
    });

    const oldObj = {
      children,
      drinking,
      education,
      occupation,
      relationship: relationshipStatus,
      smoking,
    };
    const obj = {
      children: children,
      drinking: drinking,
      education: education,
      occupation: occupation,
      relationship: relationshipStatus,
      smoking: smoking,
    };

    updateProfile(oldObj);
    // updateUserProfile(obj); // Backend not ready yet, does not update GET profile API
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DetailSignup-Page-Skip-Button",
      },
    });

    onNavigate(routeConst.emailVerification.path);
  };

  // Event Handlers | MUI Select
  const handleSelectChange = (type) => (event) => {
    switch (type) {
      case "occupation":
        dispatch(updateOccupation(event.target.value));
        break;
      case "education":
        dispatch(updateEducation(event.target.value));
        break;
      case "relationship":
        dispatch(updateRelationshipStatus(event.target.value));
        break;
      case "children":
        dispatch(updateChildren(event.target.value));
        break;
      case "smoking":
        dispatch(updateSmoking(event.target.value));
        break;
      case "drinking":
        dispatch(updateDrinking(event.target.value));
        break;
      default:
        break;
    }
  };
  const onOccupationFocus = () => {
    setOccupationFocus(true);
  };
  const onEducationFocus = () => {
    setEducationFocus(true);
  };
  const onRelationshipFocus = () => {
    setRelationshipFocus(true);
  };
  const onChildrenFocus = () => {
    setChildrenFocus(true);
  };
  const onSmokingFocus = () => {
    setSmokingFocus(true);
  };
  const onDrinkingFocus = () => {
    setDrinkingFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    return (
      occupationValid &&
      educationValid &&
      relationshipValid &&
      childrenValid &&
      smokingValid &&
      drinkingValid
    );
  };
  const occupationValidation = () => {
    let valid = true;
    let error = false;

    if (occupation === "") {
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(occupation)) {
      valid = false;
      error = true;
      dispatch(updateOccupation(""));

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-DetailSignup-Page-Occupation-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-DetailSignup-Page-Occupation-Input-HTML-Escape-Detected",
        },
      });
    } else {
      valid = true;
      error = false;
    }

    setOccupationValid(valid);
    setOccupationError(error);
  };
  const educationValidation = () => {
    let valid = true;
    let error = false;

    if (education === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setEducationValid(valid);
    setEducationError(error);
  };
  const relationshipValidation = () => {
    let valid = true;
    let error = false;

    if (relationshipStatus === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setRelationshipValid(valid);
    setRelationshipError(error);
  };
  const childrenValidation = () => {
    let valid = true;
    let error = false;

    if (children === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setChildrenValid(valid);
    setChildrenError(error);
  };
  const smokingValidation = () => {
    let valid = true;
    let error = false;

    if (smoking === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setSmokingValid(valid);
    setSmokingError(error);
  };
  const drinkingValidation = () => {
    let valid = true;
    let error = false;

    if (drinking === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setDrinkingValid(valid);
    setDrinkingError(error);
  };

  // Check for text field validation
  useEffect(() => {
    occupationValidation();
    educationValidation();
    relationshipValidation();
    childrenValidation();
    smokingValidation();
    drinkingValidation();
  }, [
    occupation,
    occupationFocus,
    education,
    educationFocus,
    relationshipStatus,
    relationshipFocus,
    children,
    childrenFocus,
    smoking,
    smokingFocus,
    drinking,
    drinkingFocus,
  ]);

  if (getAioFetching || getAioLoading) {
    return <Spinner />;
  } else if (getAioSuccess) {
    return (
      <div id="detail-signup-page">
        <div className="max-width-container">
          <div className="steps-label">
            {t("signup.step_n_max", { n: 3, max: 3 })}
          </div>

          <div className="step-description-label">
            {t("signup.youre_almost_finished")}
          </div>

          <div className="form-field-container">
            <div className="occupation-form-field-container">
              <TextField
                className="occupation-form-field custom-text-field"
                label={t("profile.occupation")}
                value={occupation}
                error={occupationError}
                onChange={handleSelectChange("occupation")}
                variant="standard"
                onFocus={onOccupationFocus}
              ></TextField>
            </div>

            <div className="education-form-field-container">
              <TextField
                className="education-form-field custom-text-field"
                select
                label={t("profile.education")}
                value={education}
                error={educationError}
                onChange={handleSelectChange("education")}
                variant="standard"
                onFocus={onEducationFocus}
              >
                {getAioData?.payload?.dropdown?.education?.map(
                  (education, index) => (
                    <MenuItem value={education?.value} key={index}>
                      {t(`${education?.name}`)}
                    </MenuItem>
                  )
                )}
              </TextField>
            </div>

            <div className="flex-container">
              <div className="relationship-form-field-container">
                <TextField
                  className="relationship-form-field custom-text-field"
                  select
                  label={t("profile.relationship")}
                  value={relationshipStatus}
                  error={relationshipError}
                  onChange={handleSelectChange("relationship")}
                  variant="standard"
                  onFocus={onRelationshipFocus}
                >
                  {getAioData?.payload?.dropdown?.relationship?.map(
                    (relationship, index) => (
                      <MenuItem value={relationship?.value} key={index}>
                        {t(`${relationship.name}`)}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>

              <div className="children-form-field-container">
                <TextField
                  className="children-form-field custom-text-field"
                  select
                  label={t("profile.children")}
                  value={children}
                  error={childrenError}
                  onChange={handleSelectChange("children")}
                  variant="standard"
                  onFocus={onChildrenFocus}
                >
                  {getAioData?.payload?.dropdown?.children?.map(
                    (children, index) => (
                      <MenuItem value={children?.value} key={index}>
                        {children?.name}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>
            </div>

            <div className="flex-container">
              <div className="smoking-form-field-container">
                <TextField
                  className="smoking-form-field custom-text-field"
                  select
                  label={t("profile.smoking")}
                  value={smoking}
                  error={smokingError}
                  onChange={handleSelectChange("smoking")}
                  variant="standard"
                  onFocus={onSmokingFocus}
                >
                  {getAioData?.payload?.dropdown?.smoking?.map(
                    (smoking, index) => (
                      <MenuItem value={smoking?.value} key={index}>
                        {t(`${smoking?.name}`)}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>

              <div className="drinking-form-field-container">
                <TextField
                  className="drinking-form-field custom-text-field"
                  select
                  label={t("profile.drinking")}
                  value={drinking}
                  error={drinkingError}
                  onChange={handleSelectChange("drinking")}
                  variant="standard"
                  onFocus={onDrinkingFocus}
                >
                  {getAioData?.payload?.dropdown?.drinking?.map(
                    (drinking, index) => (
                      <MenuItem value={drinking?.value} key={index}>
                        {t(`${drinking?.name}`)}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>
            </div>
          </div>

          <div className="button-container">
            <Button
              className={`next-button ${
                isFormValid() ? "" : "disabled-button"
              }`}
              onClick={onSaveDetails}
              disabled={!isFormValid()}
            >
              {updateProfileLoading ? (
                <Spinner size={20} isPadding={false} />
              ) : (
                t("signup.next")
              )}
            </Button>

            <Button className="skip-button" onClick={onSkip}>
              {t("signup.skip_for_now")}
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (getAioError) {
  }
};

export default DetailSignup;
