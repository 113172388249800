// General
import "./more-options-button.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useBlockOrUnblockUserMutation,
  usePhotoPermissionRequestMutation,
  usePhotoPermissionApproveMutation,
  usePhotoPermissionRevokeMutation,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import profileConst from "../../../../../const/profileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLocalPhotoPermissionList } from "../../../../../redux/store/interestStore";
import {
  updatePrivatePhotoRequested,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
} from "../../../../../redux/store/userCardStore";
import { updateLocalSearchList } from "../../../../../redux/store/searchStore";
import {
  updateLocalLivestreamList,
  updateLocalLivestreamUserData,
} from "../../../../../redux/store/livestreamingStore";
import { updateConversationUser } from "../../../../../redux/store/inboxStore";
import {
  updateClearMessagesDialog,
  updateReportDialog,
  updateUserProfileDialog,
} from "../../../../../redux/store/dialogStore";
import { updateSuccessToast } from "../../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { IconButton, Menu, MenuItem } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const MoreOptionsButton = (props) => {
  const {
    page,
    userProfileType = profileConst.userProfileType.dialog,
    id,
    idInt,
    privatePhotos,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    showClearMessage,
  } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    blockOrUnblockUser,
    {
      data: blockOrUnblockUserData,
      error: blockOrUnblockUserErrorData,
      isLoading: blockOrUnblockUserLoading,
      isSuccess: blockOrUnblockUserSuccess,
      isError: blockOrUnblockUserError,
    },
  ] = useBlockOrUnblockUserMutation();
  const [
    photoPermissionRequest,
    {
      data: photoPermissionRequestData,
      error: photoPermissionRequestErrorData,
      isLoading: photoPermissionRequestLoading,
      isSuccess: photoPermissionRequestSuccess,
      isError: photoPermissionRequestError,
    },
  ] = usePhotoPermissionRequestMutation();
  const [
    photoPermissionApprove,
    {
      data: photoPermissionApproveData,
      error: photoPermissionApproveErrorData,
      isLoading: photoPermissionApproveLoading,
      isSuccess: photoPermissionApproveSuccess,
      isError: photoPermissionApproveError,
    },
  ] = usePhotoPermissionApproveMutation();
  const [
    photoPermissionRevoke,
    {
      data: photoPermissionRevokeData,
      error: photoPermissionRevokeErrorData,
      isLoading: photoPermissionRevokeLoading,
      isSuccess: photoPermissionRevokeSuccess,
      isError: photoPermissionRevokeError,
    },
  ] = usePhotoPermissionRevokeMutation();

  // Constant variables
  const options = [];

  // Redux variables
  // const idInt = useSelector((state) => state.userCard.idInt);
  // const privatePhotos = useSelector((state) => state.userCard.privatePhotos);
  // const privatePhotoRequested = useSelector(
  //   (state) => state.userCard.privatePhotoRequested
  // );
  // const privatePhotoApproval = useSelector(
  //   (state) => state.userCard.privatePhotoApproval
  // );
  // const requestedMyPrivatePhoto = useSelector(
  //   (state) => state.userCard.requestedMyPrivatePhoto
  // );
  // const approvedMyPrivatePhoto = useSelector(
  //   (state) => state.userCard.approvedMyPrivatePhoto
  // );
  const conversationUser = useSelector((state) => state.inbox.conversationUser);
  const isBlocked = useSelector((state) => state.userCard.isBlocked);
  const dispatch = useDispatch();

  // MUI variables
  const [anchorElChatMenu, setAnchorElChatMenu] = useState(null);
  const openChatMenu = Boolean(anchorElChatMenu);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Block & Unblock API Response
  useEffect(() => {
    if (blockOrUnblockUserLoading) {
    } else if (blockOrUnblockUserSuccess) {
      switch (blockOrUnblockUserData?.status) {
        case 0:
        case 1:
          // Update User Card
          dispatch(updateIsBlocked(!isBlocked));

          // Update Search List
          const localBlockedListObj = {
            idInt,
            isBlocked: !isBlocked,
          };
          dispatch(updateLocalSearchList(localBlockedListObj));

          // Show Toast
          const toastObj = {
            message: blockOrUnblockUserData?.message,
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(toastObj));
          break;
        default:
          break;
      }
    } else if (blockOrUnblockUserError) {
    }
  }, [
    blockOrUnblockUserLoading,
    blockOrUnblockUserSuccess,
    blockOrUnblockUserError,
  ]);

  // Lifecycle | Check for update | Photo Permission Request API Response
  useEffect(() => {
    if (photoPermissionRequestLoading) {
    } else if (photoPermissionRequestSuccess) {
      switch (photoPermissionRequestData?.status) {
        case 1:
          // Update User Card
          dispatch(updatePrivatePhotoRequested(true));

          // Update Search List
          const obj = {
            idInt,
            privatePhotoRequested: true,
          };
          dispatch(updateLocalSearchList(obj));
          dispatch(updateLocalLivestreamUserData(obj));

          // Update Conversation User
          if (conversationUser) {
            const updatedUser = {
              ...conversationUser,
              privatePhotoRequested: true,
            };
            dispatch(updateConversationUser(updatedUser));
          }
          break;
        default:
          break;
      }
    } else if (photoPermissionRequestError) {
      if (photoPermissionRequestErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    photoPermissionRequestLoading,
    photoPermissionRequestSuccess,
    photoPermissionRequestError,
  ]);

  // Lifecycle | Check for update | Photo Permission Approve API Response
  useEffect(() => {
    if (photoPermissionApproveLoading) {
    } else if (photoPermissionApproveSuccess) {
      switch (photoPermissionApproveData?.status) {
        case 0:
        case 1:
          const obj = {
            idInt,
            approvedMyPrivatePhoto: !approvedMyPrivatePhoto,
          };
          dispatch(updateLocalPhotoPermissionList(obj));
          dispatch(updateApprovedMyPrivatePhoto(!approvedMyPrivatePhoto));

          // Update Conversation User
          if (conversationUser) {
            const updatedUser = {
              ...conversationUser,
              approvedMyPrivatePhoto: !approvedMyPrivatePhoto,
            };
            dispatch(updateConversationUser(updatedUser));
          }

          // Show Toast
          const toastObj = {
            message: t("profile.photo_private_granted"),
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(toastObj));
          break;
        default:
          break;
      }
    } else if (photoPermissionApproveError) {
    }
  }, [
    photoPermissionApproveLoading,
    photoPermissionApproveSuccess,
    photoPermissionApproveError,
  ]);

  // Lifecycle | Check for update | Photo Permission Revoke API Response
  useEffect(() => {
    if (photoPermissionRevokeLoading) {
    } else if (photoPermissionRevokeSuccess) {
      switch (photoPermissionRevokeData?.status) {
        case 0:
        case 1:
          // Update User Card
          dispatch(updateApprovedMyPrivatePhoto(!approvedMyPrivatePhoto));

          // Update Search List
          const obj = {
            idInt,
            approvedMyPrivatePhoto: !approvedMyPrivatePhoto,
          };
          dispatch(updateLocalPhotoPermissionList(obj));
          dispatch(updateLocalLivestreamUserData(obj));

          // Update Conversation User
          if (conversationUser) {
            const updatedUser = {
              ...conversationUser,
              approvedMyPrivatePhoto: !approvedMyPrivatePhoto,
            };
            dispatch(updateConversationUser(updatedUser));
          }

          // Show Toast
          const toastObj = {
            message: t("profile.photo_private_revoked"),
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(toastObj));
          break;
        default:
          break;
      }
    } else if (photoPermissionRevokeError) {
    }
  }, [
    photoPermissionRevokeLoading,
    photoPermissionRevokeSuccess,
    photoPermissionRevokeError,
  ]);

  // Event Handlers | Button
  const onBlockUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UserProfile-Page-BlockUser-Button",
      },
    });

    // const obj = {
    //   userid: idInt,
    // };
    // blockUnblockUser(obj);
    const obj = {
      user_id: id,
      block: true,
    };
    blockOrUnblockUser(obj);
  };
  const onUnblockUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UserProfile-Page-UnblockUser-Button",
      },
    });

    // const obj = {
    //   userid: idInt,
    // };
    // blockUnblockUser(obj);
    const obj = {
      user_id: id,
      block: false,
    };
    blockOrUnblockUser(obj);
  };
  const onApprovePhotoRequest = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UserProfile-Page-ApprovePhotoRequest-Button",
      },
    });

    const obj = {
      user_id: id,
    };
    photoPermissionApprove(obj);
  };
  const onRevokePhotoRequest = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UserProfile-Page-RevokePhotoRequest-Button",
      },
    });

    const obj = {
      user_id: id,
    };
    photoPermissionRevoke(obj);
  };
  const onRequestPrivatePhoto = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UserProfile-Page-RequestPrivatePhoto-Button",
      },
    });

    const obj = {
      user_id: id,
    };
    photoPermissionRequest(obj);
  };

  // Event Handlers | MUI Menu
  const onOpenMenu = (state) => (event) => {
    switch (state) {
      case "chat":
        setAnchorElChatMenu(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const onCloseMenu = (state) => {
    switch (state) {
      case "request-private-photo":
        onRequestPrivatePhoto();
        break;
      case "approve-private-photo":
      case "share-private-photos":
        onApprovePhotoRequest();
        break;
      case "revoke-private-photo":
      case "unshare-private-photos":
        onRevokePhotoRequest();
        break;
      case "block-user":
        onBlockUser();
        break;
      case "unblock-user":
        onUnblockUser();
        break;
      case "report-user":
        dispatch(updateReportDialog(true));
        break;
      case "clear-messages":
        dispatch(updateClearMessagesDialog(true));
        break;
      default:
        break;
    }

    setAnchorElChatMenu(null);
  };

  // Utility Functions
  const hasOwnPrivatePhoto = () => {
    return getProfileData?.data?.private_photos?.some(
      (photo) => photo?.photo_status === "APPROVED"
    );
  };

  // Render Functions | Options
  for (let i = 0; i < profileConst.report.length; i++) {
    if (getProfileData?.data?.id_int !== idInt) {
      switch (profileConst.report[i].value) {
        case "request-private-photo":
          if (privatePhotos?.length > 0 && !privatePhotoRequested) {
            options.push(profileConst.report[i]);
          }
          break;
        case "approve-private-photo":
          if (requestedMyPrivatePhoto && !approvedMyPrivatePhoto) {
            options.push(profileConst.report[i]);
          }
          break;
        case "revoke-private-photo":
          if (approvedMyPrivatePhoto) {
            options.push(profileConst.report[i]);
          }
          break;
        case "share-private-photos":
          if (
            hasOwnPrivatePhoto() &&
            !requestedMyPrivatePhoto &&
            !approvedMyPrivatePhoto
          ) {
            options.push(profileConst.report[i]);
          }
          break;
        case "block-user":
          if (isBlocked === false) {
            options.push(profileConst.report[i]);
          }
          break;
        case "unblock-user":
          if (isBlocked) {
            options.push(profileConst.report[i]);
          }
          break;
        case "report-user":
          options.push(profileConst.report[i]);
          break;
        case "clear-messages":
          if (showClearMessage) {
            options.push(profileConst.report[i]);
          }
          break;
        default:
          break;
      }
    }
  }

  if (options?.length > 0) {
    return (
      <div id="profile-more-options-button-shared-component">
        <IconButton
          className={`info-icon-container ${
            userProfileType === profileConst.userProfileType.page
              ? "reduced-height"
              : "extra-height"
          }`}
          onClick={onOpenMenu("chat")}
        >
          {getIcon("moreVertIcon", "info-icon")}
        </IconButton>

        <Menu
          id="long-menu"
          className="custom-dialog-always-top"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorElChatMenu}
          open={openChatMenu}
          onClose={() => onCloseMenu("chat")}
        >
          {options?.map((option, index) => (
            <MenuItem key={index} onClick={() => onCloseMenu(option?.value)}>
              {t(`${option?.jsonTitle}`)}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
};

export default MoreOptionsButton;
