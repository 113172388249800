// General
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../services/data.service";
// Static Data
import userCardConst from "../../../const/userCardConst";
// Redux
import { useSelector } from "react-redux";

const useGetUserProfileStatus = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getUserAio(null, true);
  }, [isLoggedIn]);

  // Main Function
  const getProfileStatus = (user) => {
    if (getUserProfileStatusIsLive(user)) {
      return userCardConst.userProfileStatus.live;
    } else if (getUserProfileStatusIsVideoCallInPrivate(user)) {
      return userCardConst.userProfileStatus.inPrivateBusy;
    } else if (getUserProfileStatusIsVideoCallReadyToJoin(user)) {
      return userCardConst.userProfileStatus.inPrivateAvailable;
    } else if (getUserProfileStatusIsAvailableForVideoCall(user)) {
      return userCardConst.userProfileStatus.videoCall;
    }
    return userCardConst.userProfileStatus.normal;
  };

  // Utiity Functions
  const getUserProfileStatusIsLive = (user) => {
    if (user?.live_stream?.live_status === "live") {
      return true;
    }

    return false;
  };
  const getUserProfileStatusIsVideoCallInPrivate = (user) => {
    if (user?.private_call_standby?.status === "busy") {
      return true;
    }

    return false;
  };
  const getUserProfileStatusIsVideoCallReadyToJoin = (user) => {
    if (user?.private_call_standby?.status === "started") {
      return true;
    }

    return false;
  };
  const getUserProfileStatusIsAvailableForVideoCall = (user) => {
    if (getUserAioData?.status === 1 && getUserAioData?.data) {
      return (
        getUserAioData?.data?.private_call_settings
          ?.system_private_calls_enabled &&
        getUserAioData?.data?.private_call_settings?.enable_calls &&
        (user?.private_call_settings?.system_private_calls_enabled ||
          user?.private_calls_metadata?.call_settings
            ?.system_private_calls_enabled) &&
        (user?.private_call_settings?.enable_calls ||
          user?.private_calls_metadata?.call_settings?.enable_calls)
        // Might not need
        //   &&
        // user?.private_call_standby?.status !== "busy" &&
        // user?.private_call_standby?.status !== "started"
      );
    }

    return false;
  };

  return getProfileStatus;
};

export default useGetUserProfileStatus;
