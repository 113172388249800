// General
import "./viewer-info.scss";
import { useState, useEffect } from "react";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import LiveTab from "./live-tab/live-tab";
import TopFansTab from "./top-fans-tab/top-fans-tab";

const ViewerInfo = (props) => {
  const { state } = props;

  // General variables
  const [value, setValue] = useState(0);

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(value);

    emblaApi?.on("select", () => {
      setValue(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-LivestreamProfileView-Dialog-TabChangeClick-Button-${
          newValue === 0 ? "LiveTab" : "TopFansTab"
        }`,
      },
    });

    emblaApi?.scrollTo(newValue);
  };

  return (
    <div
      id="livestream-profile-view-dialog-viewer-info-subcomponent"
      className={state === "dialog" ? "viewer-screen-height" : "viewer-fix-height"}
    >
      <div className="tab-container">
        <Tabs
          className="custom-tabs"
          value={value}
          onChange={onTabChange}
          TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
          variant="fullWidth"
        >
          <Tab label={t("mini-profile-tabs.live")} />
          <Tab label={t("mini-profile-tabs.top_fans")} />
        </Tabs>

        <div ref={emblaRef} className="embla">
          <div className="embla__container">
            <div className="embla__slide">{value === 0 && <LiveTab />}</div>

            <div className="embla__slide">{value === 1 && <TopFansTab />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewerInfo;
