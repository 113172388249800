// General
import "./onboarding-live-streaming-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Services
import { sessionService } from "../../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateOnboardingLiveStreamingDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const OnboardingLiveStreamingDialog = () => {
  // General variables
  const [page, setPage] = useState(0);

  // Render variables
  let pageView;
  let titleView;
  let descriptionView;
  let backgroundImageView;

  // Redux variables
  const onboardingLiveStreamingDialog = useSelector(
    (state) => state.dialog.onboardingLiveStreamingDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t, i18n } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!onboardingLiveStreamingDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-OnboardingLiveStreaming-Dialog",
      },
    });
  }, [onboardingLiveStreamingDialog]);

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Event Handlers | Button
  const onPrevious = () => {
    setPage((prevPage) => {
      if (prevPage === 0) {
        return 0;
      }
      return prevPage - 1;
    });
  };
  const onNext = () => {
    setPage((prevPage) => {
      if (prevPage === 4) {
        return 4;
      }
      return prevPage + 1;
    });
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    // Save to local storage
    sessionService.setHasShownLiveStreamingDialog(true);
    dispatch(updateOnboardingLiveStreamingDialog(false));
  };

  // Render Functions
  switch (page) {
    case 0:
      pageView = null;
      titleView = (
        <div className="live-title">
          <Trans
            i18nKey={"onboarding.live_title"}
            components={{ br: <br /> }}
          />
        </div>
      );
      descriptionView = (
        <div className="live-description">
          <Trans i18nKey={"onboarding.live_desc"} />
        </div>
      );
      backgroundImageView = (
        <img
          className="live-background"
          src={getAsset("onboardingLive1Background")}
        />
      );
      break;
    case 1:
      pageView = <div className="page-container">{page}/4</div>;
      titleView = (
        <div className="live-title">
          <Trans
            i18nKey={"onboarding.leaderboard_title"}
            components={{ br: <br /> }}
          />
        </div>
      );
      descriptionView = (
        <div className="live-description">
          <Trans i18nKey={"onboarding.leaderboard_desc"} />
        </div>
      );
      backgroundImageView = (
        <img
          className="live-background"
          src={getAsset("onboardingLive2Background")}
        />
      );
      break;
    case 2:
      pageView = <div className="page-container">{page}/4</div>;
      titleView = (
        <div className="live-title">
          <Trans
            i18nKey={"onboarding.interaction_title"}
            components={{ br: <br /> }}
          />
        </div>
      );
      descriptionView = (
        <div className="live-description">
          <Trans i18nKey={"onboarding.interaction_desc"} />
        </div>
      );
      backgroundImageView = (
        <img
          className="live-background"
          src={getAsset("onboardingLive3Background")}
        />
      );
      break;
    case 3:
      pageView = <div className="page-container">{page}/4</div>;
      titleView = (
        <div className="live-title">
          <Trans
            i18nKey={"onboarding.gift_title"}
            components={{ br: <br /> }}
          />
        </div>
      );
      descriptionView = (
        <div className="live-description">
          <Trans i18nKey={"onboarding.gift_desc"} />
        </div>
      );
      backgroundImageView = (
        <img
          className="live-background"
          src={getAsset("onboardingLive4Background")}
        />
      );
      break;
    case 4:
      pageView = <div className="page-container">{page}/4</div>;
      titleView = (
        <div className="live-title">
          <Trans
            i18nKey={"onboarding.coin_title"}
            components={{ br: <br /> }}
          />
        </div>
      );
      descriptionView = (
        <div className="live-description">
          <Trans i18nKey={"onboarding.coin_desc"} />
        </div>
      );
      backgroundImageView = (
        <img
          className="live-background"
          src={getAsset("onboardingLive5Background")}
        />
      );
      break;
    default:
      break;
  }

  return (
    <Dialog
      className={
        isMobile ? "custom-dialog-transparent" : "custom-radius35-dialog"
      }
      fullScreen={isMobile ? true : false}
      open={onboardingLiveStreamingDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div
        id="onboarding-live-streaming-dialog"
        className={isMobile ? "" : "max-width-container"}
      >
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          {pageView}

          {titleView}

          {descriptionView}

          <div className="live-background-container">
            <div className="arrow-back-icon-container" onClick={onPrevious}>
              {page !== 0 && <ArrowBackIosIcon />}
            </div>
            {backgroundImageView}

            <div className="arrow-forward-icon-container" onClick={onNext}>
              {page !== 4 && <ArrowForwardIosIcon />}
            </div>
          </div>
        </div>

        {page === 4 && (
          <div className={`footer ${isMobile ? "add-absolute" : ""}`}>
            <div className="done-button" onClick={onCloseDialog}>
              {t("common.done")}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default OnboardingLiveStreamingDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
