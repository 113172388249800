// General
import "./overview.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetProfileCheckQuery,
  useLazyGetUserAioQuery,
  useLazyGetStreamerCenterQuery,
  useLazyGetVirtualCurrencyCoinBalanceQuery,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateUserProfileDialog } from "../../../redux/store/dialogStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateIsSilentInteraction,
  updateIsSugarbaby,
  updateRole,
} from "../../../redux/store/userCardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import useCompactNumbers from "../../utility/custom-hooks/useCompactNumbers-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Banner from "../../shared/elements/banner/banner";
import ProfileInfo from "./profile-info/profile-info";
import ProfileStats from "./profile-stats/profile-stats";

const Overview = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfileCheck,
    {
      data: getProfileCheckData,
      error: getProfileCheckErrorData,
      isFetching: getProfileCheckFetching,
      isLoading: getProfileCheckLoading,
      isSuccess: getProfileCheckSuccess,
      isError: getProfileCheckError,
    },
  ] = useLazyGetProfileCheckQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getStreamerCenter,
    {
      data: getStreamerCenterData,
      error: getStreamerCenterErrorData,
      isFetching: getStreamerCenterFetching,
      isLoading: getStreamerCenterLoading,
      isSuccess: getStreamerCenterSuccess,
      isError: getStreamerCenterError,
    },
  ] = useLazyGetStreamerCenterQuery();
  const [
    getCoins,
    {
      data: getCoinsData,
      error: getCoinsErrorData,
      isFetching: getCoinsFetching,
      isLoading: getCoinsLoading,
      isSuccess: getCoinsSuccess,
      isError: getCoinsError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getCompactNumbers = useCompactNumbers();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ProfileOverview-Page",
      },
    });

    getProfile(null, false); // TODO: Change back to true after TW testing
    getProfileCheck(null, false);
    getUserAio(null, true);
    getStreamerCenter(null, false);
    getCoins(null, false);
  }, []);

  // Event Handlers | Button
  const onViewOwnProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ProfileOverview-Page-ViewOwnProfile-Button",
      },
    });

    // User View
    dispatch(updateId(getProfileData?.data?.id));
    dispatch(updateIdInt(getProfileData?.data?.id_int));
    dispatch(updateUsername(getProfileData?.data?.username));
    dispatch(updateAge(getProfileData?.data?.appearance?.age));
    dispatch(updateLocation(getProfileData?.data?.location?.city));
    dispatch(updateMembershipType(getProfileData?.data?.membership_type));
    dispatch(updateVerifiedProfile(getProfileData?.data?.is_verified_profile));
    dispatch(updateVerifiedCaller(getProfileData?.data?.verified_caller));
    dispatch(
      updateLevellingBadge(getProfileData?.data?.levelling_profile?.badge)
    );
    dispatch(
      updateLivestreamingAchievementBadge(
        getProfileData?.data?.live_streaming_achievements?.chat_badges_set
      )
    );
    dispatch(
      updateProfilePhoto(getProfileData?.data?.profile_photo?.original_photo)
    );
    dispatch(
      updatePublicPhotos(
        getProfileData?.data?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
          };
        })
      )
    );
    dispatch(
      updatePrivatePhotos(
        getProfileData?.data?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
            thumbnailData: photo?.thumbnail_data,
          };
        })
      )
    );
    dispatch(updateTorTags(getProfileData?.data?.tor_tags));
    dispatch(
      updateProfileAchievementBadge(
        getProfileData?.data?.live_streaming_achievements?.profile_badges
      )
    );
    dispatch(updateAboutMe(getProfileData?.data?.aboutme));
    dispatch(updateLookingFor(getProfileData?.data?.lookingfor));
    dispatch(updateOccupation(getProfileData?.data?.occupation?.occupation));
    dispatch(
      updateLifestyle(getProfileData?.data?.occupation?.lifestyle_budget)
    );
    dispatch(updateEducation(getProfileData?.data?.occupation?.education));
    dispatch(
      updateRelationship(
        getProfileData?.data?.relationship?.relationship_status
      )
    );
    dispatch(updateSmoking(getProfileData?.data?.habit?.smoking));
    dispatch(updateDrinking(getProfileData?.data?.habit?.drinking));
    dispatch(updateHeight(getProfileData?.data?.appearance?.height));
    dispatch(updateBodyType(getProfileData?.data?.appearance?.body_type));
    dispatch(updateEthnicity(getProfileData?.data?.appearance?.ethnicity));
    dispatch(updateEyeColor(getProfileData?.data?.appearance?.eye_color));
    dispatch(updateHairColor(getProfileData?.data?.appearance?.hair_color));
    dispatch(updateChildren(getProfileData?.data?.relationship?.children));
    // User Utility
    dispatch(updatePrivatePhotoRequested(true));
    dispatch(updatePrivatePhotoApproval(true));
    dispatch(updateIsSilentInteraction(true));
    dispatch(updateIsSugarbaby(!isDaddy));
    dispatch(updateRole(getProfileData?.data?.role));

    // dispatch(updateUserProfileDialog(true)); // Dialog
    onNavigate(
      `${routeConst.profile.view.clearPath}${getProfileData?.data?.id}`
    ); // Page
  };
  const onNavigateToBlogs = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ProfileOverview-Page-Blogs-Button",
      },
    });

    window.open(routeConst.blogs.path, "_blank");
  };
  const onNavigateToEvents = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ProfileOverview-Page-Events-Button",
      },
    });

    window.open(routeConst.events.path, "_blank");
  };

  // Utility Functions
  const onTestGtmTag = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Test-Tag-nested",
      },
    });

    window.dataLayer.push({
      event: "PWA-Test-Tag-window",
    });
  };
  const onRefreshApp = () => {
    window.location.reload(true);
  };

  return (
    <div id="overview-page">
      <div className="padding-container">
        <ProfileInfo />

        <div className="view-profile-button" onClick={onViewOwnProfile}>
          {t("profile.view_profile")}
        </div>

        <ProfileStats />

        <Banner type={"profile-overview"} view={"carousel"} />

        <div className="buttons-list-container">
          {/* Edit Profile */}
          <div
            className="button-container"
            onClick={() => onNavigate(routeConst.profile.edit.path)}
          >
            <div className="button-label">
              {getProfileCheckData?.payload?.percent < 100
                ? t("profile.complete_profile")
                : t("profile.edit_profile")}
            </div>
            <div className="suffix">
              {getProfileCheckData?.payload?.percent < 100 && (
                <div className="suffix-label">
                  {getProfileCheckData?.payload?.percent || "-"}%
                </div>
              )}
              <NavigateNextIcon />
            </div>
          </div>

          {/* My Diamond */}
          {getUserAioData?.data?.modules?.my_diamonds_enabled && !isDaddy && (
            <div
              className="button-container"
              onClick={() => onNavigate(routeConst.profile.streamerCenter.path)}
            >
              <div className="button-label">{t("profile.my_diamonds")}</div>
              <div className="suffix">
                {getIcon("sbDiamondIcon", "diamond-icon")}
                <div className="suffix-label">
                  {getCompactNumbers(
                    getStreamerCenterData?.data?.diamonds?.balance || 0
                  ) || 0}
                </div>
                <NavigateNextIcon />
              </div>
            </div>
          )}

          {/* Wallet */}
          {getUserAioData?.data?.modules?.wallet_enabled && (
            <div
              className="button-container"
              onClick={() => onNavigate(routeConst.profile.wallet.path)}
            >
              <div className="button-label">{t("profile.wallet")}</div>
              <div className="suffix">
                {getIcon("sbCoinIcon", "live-coin")}
                <div className="suffix-label">
                  {getCoinsData?.data?.balance?.toLocaleString() || 0}
                </div>
                <NavigateNextIcon />
              </div>
            </div>
          )}

          {/* Video Call Settings */}
          {getUserAioData?.data?.modules?.private_calls_enabled && (
            <div
              className="button-container"
              onClick={() => onNavigate(routeConst.settings.videoCall.path)}
            >
              <div className="button-label">
                {t("profile.1on1_video_call_settings")}
              </div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </div>
          )}

          {/* Levelling Center */}
          {getUserAioData?.data?.modules?.levelling_enabled && (
            <div
              className="button-container"
              onClick={() => onNavigate(routeConst.profile.leveling.path)}
            >
              <div className="button-label">
                {t("profile.levelling_centre")}
              </div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </div>
          )}

          {/* Support */}
          <div
            className="button-container"
            onClick={() => onNavigate(routeConst.support.path)}
          >
            <div className="button-label">{t("profile.support")}</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>

          {/* FAQ */}
          {getUserAioData?.data?.modules?.faq_page_enabled && (
            <a
              className="button-container"
              href={routeConst.zendesk.path}
              target="_blank"
            >
              <div className="button-label">{t("profile.faq")}</div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </a>
          )}

          {/* Blogs */}
          {getUserAioData?.data?.modules?.blogs_page_enabled && (
            <div className="button-container" onClick={onNavigateToBlogs}>
              <div className="button-label">{t("profile.blogs")}</div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </div>
          )}

          {/* Events */}
          {getUserAioData?.data?.modules?.events_page_enabled && (
            <div className="button-container" onClick={onNavigateToEvents}>
              <div className="button-label">{t("profile.events")}</div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </div>
          )}

          {/* Remove after finish testing */}
          {process.env["REACT_APP_ENVIRONMENT"] === "development" && (
            <div className="button-container" onClick={onTestGtmTag}>
              <div className="button-label">Test Tag</div>
            </div>
          )}

          {/* Programatically refresh app */}
          {process.env["REACT_APP_ENVIRONMENT"] === "development" && (
            <div className="button-container" onClick={onRefreshApp}>
              <div className="button-label">Force Refresh App</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;
