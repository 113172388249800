// General
import "./email-verification.scss";
import { useEffect } from "react";
// Services
import { useLazyResendEmailVerificationQuery } from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateIsLoggedIn } from "../../../redux/store/publicStore";
import { updateSuccessToast } from "../../../redux/store/toastStore";
// Material UI
import CloseIcon from "@mui/icons-material/Close";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const EmailVerification = () => {
  // API variables
  const [
    resendEmailVerification,
    {
      data: resendEmailVerificationData,
      error: resendEmailVerificationErrorData,
      isFetching: resendEmailVerificationFetching,
      isLoading: resendEmailVerificationLoading,
      isSuccess: resendEmailVerificationSuccess,
      isError: resendEmailVerificationError,
    },
  ] = useLazyResendEmailVerificationQuery();

  // Redux variables
  const email = useSelector((state) => state.signup.email);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EmailVerification-Page",
      },
    });

    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false
    );
    return () => {
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityChange,
        false
      );
    };
  }, []);

  // Lifecycle | Check for update | Resend Email Verification API Response
  useEffect(() => {
    if (resendEmailVerificationFetching || resendEmailVerificationLoading) {
    } else if (resendEmailVerificationSuccess) {
      const toastObj = {
        message: t("signup.verification_email_sent"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));
    } else if (resendEmailVerificationError) {
    }
  }, [
    resendEmailVerificationFetching,
    resendEmailVerificationLoading,
    resendEmailVerificationSuccess,
    resendEmailVerificationError,
  ]);

  // Event Handlers | Button
  const onRedirectToApp = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EmailVerification-Page-RedirectToSearch-Button",
      },
    });

    dispatch(updateIsLoggedIn(true));
    onNavigate(routeConst.search.path);
  };
  const onResendEmail = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EmailVerification-Page-ResendEmailVerification-Button",
      },
    });

    resendEmailVerification();
  };

  // Utility Functions
  const handleVisibilityChange = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EmailVerification-Page-RedirectToSearch-Auto",
      },
    });

    dispatch(updateIsLoggedIn(true));
    onNavigate(routeConst.search.path);
  };

  return (
    <div id="email-verification-page">
      <div className="top-container">
        <div className="header-container">
          <CloseIcon className="close-button" onClick={onRedirectToApp} />
        </div>

        <div className="verify-email-label">
          {t("signup.verify_your_email_address")}
        </div>

        <div className="email-logo-container">
          {getIcon("mailOutlineIcon", "email-logo")}
        </div>
      </div>

      <div className="bottom-container">
        <div className="body-container">
          <div className="label-1">{t("signup.we_have_sent_an_email_to")}</div>

          <div className="email-label">{email}</div>

          <div className="label-1">
            {t("signup.we_have_sent_an_email_to_desc")}
          </div>

          <div className="resend-label" onClick={onResendEmail}>
            {resendEmailVerificationFetching ||
            resendEmailVerificationLoading ? (
              <Spinner size={20} isPadding={false} />
            ) : (
              t("signup.resend_verification_email")
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
