// General
import "./following-element.scss";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const FollowingElement = (props) => {
  const {
    state,
    livestreamingAchievementBadge,
    profilePhoto,
    username,
    levellingBadge,
    livestreamerUsername,
  } = props;

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  // i18next variables
  const { t } = useTranslation();

  // TODO: Need to add levelling badge, need to update translation to accomodate levelling badge
  return (
    <div id="chat-section-following-element-subcomponent">
      <div className="profile-picture-container">
        <CustomAvatar className="profile-picture" src={profilePhoto} />
      </div>

      <div
        className={`chat-info-container ${
          state === "livestream" && !isMobile ? "green" : "yellow"
        }`}
      >
        {livestreamingAchievementBadge?.length > 0 && (
          <div className="livestreaming-achievement-badge-container">
            {livestreamingAchievementBadge?.map((badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            ))}
          </div>
        )}

        {t("pusher.is_now_following", {
          user: username,
          livestreamer: livestreamerUsername,
        })}
      </div>
    </div>
  );
};

export default FollowingElement;
