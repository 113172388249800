// General
import "./interests.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateInterestTab } from "../../redux/store/interestStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Banner from "../shared/elements/banner/banner";
import Notification from "../notification/notification";
import ViewedMeTab from "./viewed-me-tab/viewed-me-tab";
import FavoritedMeTab from "./favorited-me-tab/favorited-me-tab";
import MyFavoritesTab from "./my-favorites-tab/my-favorites-tab";
import PhotoPermissionsTab from "./photo-permissions-tab/photo-permissions-tab";

const Interests = () => {
  // Redux variables
  const interestTab = useSelector((state) => state.interest.interestTab);
  const dispatch = useDispatch();

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 720px)");

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Interests-Page",
      },
    });
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(interestTab);

    emblaApi?.on("select", () => {
      dispatch(updateInterestTab(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    emblaApi?.scrollTo(newValue);
  };

  return (
    <div id="interests-page" className="interests-page-container">
      <div className="desktop-banner-container">
        <Banner type={"interests"} view={"carousel"} />
      </div>

      <div className="max-width-container">
        <div className="tab-container">
          <Tabs
            className={`custom-tabs ${isDesktop ? "add-underline" : ""}`}
            value={interestTab}
            onChange={onTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
            variant={isDesktop ? "fullWidth" : "scrollable"}
            scrollButtons={false}
          >
            <Tab label={t("interest.tabs.notifications")} />
            <Tab label={t("interest.tabs.viewed_me")} />
            <Tab label={t("interest.tabs.favorited_me")} />
            <Tab label={t("interest.tabs.my_favorites")} />
            <Tab label={t("settings.photo_permissions")} />
          </Tabs>

          {interestTab !== 0 && (
            <div className="mobile-banner-container">
              <Banner type={"interests"} view={"carousel"} />
            </div>
          )}

          <div ref={emblaRef} className="embla">
            <div className="embla__container">
              <div className="embla__slide">
                {interestTab === 0 && (
                  <Notification showBanner={isDesktop ? false : true} />
                )}
              </div>

              <div className="embla__slide">
                {interestTab === 1 && <ViewedMeTab />}
              </div>

              <div className="embla__slide">
                {interestTab === 2 && <FavoritedMeTab />}
              </div>

              <div className="embla__slide">
                {interestTab === 3 && <MyFavoritesTab />}
              </div>

              <div className="embla__slide">
                {interestTab === 4 && <PhotoPermissionsTab />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interests;
