// General
import "./financial-information.scss";
import { useEffect } from "react";
// Static Data
import userConst from "../../../../../const/userConst";
// Services
import { useLazyGetAioQuery } from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLifestyle,
  updateNetworth,
  updateAnnualIncome,
} from "../../../../../redux/store/userStore";
// Material UI
import { TextField, MenuItem } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const FinancialInformation = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();

  // Redux variables
  const oldUserProfile = useSelector((state) => state.user.oldUserProfile);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "lifestyle":
        dispatch(updateLifestyle(event.target.value));
        break;
      case "networth":
        dispatch(updateNetworth(event.target.value));
        break;
      case "annual-income":
        dispatch(updateAnnualIncome(event.target.value));
        break;
      default:
        break;
    }
  };

  return (
    <div id="edit-profile-financial-information-subcomponent">
      <div className="financial-information-label">
        {t("profile.financial_information")}
      </div>

      {/* Lifestyle */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profile?.lifestyle ? "" : "incomplete-field"
            }`}
          >
            {t("profile.lifestyle")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="form-field"
            select
            value={oldUserProfile?.profile?.lifestyle || ""}
            placeholder={t("profile.to_be_completed")}
            onChange={onInputChange("lifestyle")}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          >
            {getAioData?.payload?.dropdown?.lifestyle?.map(
              (lifestyle, index) => (
                <MenuItem key={index} value={lifestyle?.value}>
                  {t(`${lifestyle?.name}`)}
                </MenuItem>
              )
            )}
          </TextField>
        </div>
      </div>

      {/* Net Worth */}
      {isDaddy && (
        <div className="form-field-container">
          <div className="label-container">
            <div
              className={`label ${
                oldUserProfile?.profile?.networth ? "" : "incomplete-field"
              }`}
            >
              {t("profile.net_worth")}
            </div>
          </div>

          <div className="form-container">
            <TextField
              sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
              className="form-field"
              select
              value={oldUserProfile?.profile?.networth || ""}
              onChange={onInputChange("networth")}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            >
              {getAioData?.payload?.dropdown?.networth?.map(
                (networth, index) => (
                  <MenuItem key={index} value={networth?.value}>
                    {t(`${networth.name}`)}
                  </MenuItem>
                )
              )}
            </TextField>
          </div>
        </div>
      )}

      {/* Annual Income */}
      {isDaddy && (
        <div className="form-field-container">
          <div className="label-container">
            <div
              className={`label ${
                oldUserProfile?.profile?.annualincome ? "" : "incomplete-field"
              }`}
            >
              {t("profile.annual_income")}
            </div>
          </div>

          <div className="form-container">
            <TextField
              sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
              className="form-field"
              select
              value={oldUserProfile?.profile?.annualincome || ""}
              onChange={onInputChange("annual-income")}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            >
              {getAioData?.payload?.dropdown?.annual_income?.map(
                (income, index) => (
                  <MenuItem key={index} value={income?.value}>
                    {t(`${income?.name}`)}
                  </MenuItem>
                )
              )}
            </TextField>
          </div>
        </div>
      )}
    </div>
  );
};

export default FinancialInformation;
