// General
import "./edit.scss";
import { useEffect, useRef } from "react";
// Services
import {
  spiApi,
  useLazyGetProfileQuery,
  useLazyGetProfilePwaQuery,
  useUpdateUserProfileMutation,
  useUpdateProfileMutation,
  useUpdateUserLocationMutation,
} from "../../../services/data.service";
// Static Data
import userConst from "../../../const/userConst";
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Edit Profile Functions
  updateEditLocation,

  // API Response Functions
  updateOldUserProfile,
} from "../../../redux/store/userStore";
import {
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateIsSilentInteraction,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateTorTags,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateRole,
} from "../../../redux/store/userCardStore";
import { updateEditProfileTab } from "../../../redux/store/userStore";
import {
  resetDatingSearchLists,
  resetFilteredLists,
  resetRecentlyActiveLists,
  resetNearbyLists,
  resetNewestLists,
  resetCollegeLists,
  resetPremiumLists,

  // Pagination
  updateFilteredPagination,
  updateRecentlyActivePagination,
  updateNearbyPagination,
  updateNewestPagination,
  updateCollegePagination,
  updatePremiumPagination,
} from "../../../redux/store/searchStore";
import { updateUserProfileDialog } from "../../../redux/store/dialogStore";
import {
  updateSuccessToast,
  updateErrorToast,
} from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import UtilityDesktopNavbar from "../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";
import CustomAvatar from "../../shared/elements/custom-avatar/custom-avatar";
import EditProfile from "./edit-profile/edit-profile";
import Photos from "./photos/photos";
import Spinner from "../../shared/elements/spinner/spinner";
import ProfileRejectedInfoDialog from "../../shared/dialog-content/profile-rejected-info-dialog/profile-rejected-info-dialog";

const ProfileEdit = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfilePwa,
    {
      data: getProfilePwaData,
      error: getProfilePwaErrorData,
      isFetching: getProfilePwaFetching,
      isLoading: getProfilePwaLoading,
      isSuccess: getProfilePwaSuccess,
      isError: getProfilePwaError,
    },
  ] = useLazyGetProfilePwaQuery();
  const [
    updateUserProfile, // New Update API | Not using yet cause of backend issue
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateUserProfileMutation();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateProfileMutation();
  const [
    updateUserLocation,
    {
      data: updateUserLocationData,
      error: updateUserLocationErrorData,
      isLoading: updateUserLocationLoading,
      isSuccess: updateUserLocationSuccess,
      isError: updateUserLocationError,
    },
  ] = useUpdateUserLocationMutation();

  // General variables
  const isMounted = useRef(false);

  // Redux variables
  const oldUserProfile = useSelector((state) => state.user.oldUserProfile);
  const latitude = useSelector((state) => state.user.latitude);
  const longitude = useSelector((state) => state.user.longitude);
  const editLocation = useSelector((state) => state.user.editLocation);
  const editProfilePassthrough = useSelector(
    (state) => state.user.editProfilePassthrough
  );
  const editProfileTab = useSelector((state) => state.user.editProfileTab);
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EditProfile-Page",
      },
    });

    getProfile(null, true);
    getProfilePwa(null, false);
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(editProfileTab);

    emblaApi?.on("select", () => {
      dispatch(updateEditProfileTab(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        const locationObj = {
          lat: getProfileData?.data?.location?.latitude,
          lng: getProfileData?.data?.location?.longitude,
          location_data: {
            city: getProfileData?.data?.location?.city,
            state: getProfileData?.data?.location?.state,
            formatted_address:
              getProfileData?.data?.location?.full_address || "",
            country: getProfileData?.data?.location?.country,
          },
        };

        dispatch(updateEditLocation(locationObj));
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Get Profile PWA API Response
  useEffect(() => {
    if (getProfilePwaFetching || getProfilePwaLoading) {
    } else if (getProfilePwaSuccess) {
      if (getProfilePwaData?.status === 0) {
        dispatch(updateOldUserProfile(getProfilePwaData?.payload?.message));
      }
    } else if (getProfilePwaError) {
      if (getProfilePwaErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getProfilePwaFetching,
    getProfilePwaLoading,
    getProfilePwaSuccess,
    getProfilePwaError,
  ]);

  // Lifecycle | Check for update | Update Profile API Response
  useEffect(() => {
    if (updateProfileLoading || updateUserLocationLoading) {
    } else if (updateProfileSuccess && updateUserLocationSuccess) {
      if (updateProfileData?.status === 0) {
        const toastObj = {
          message: t("signup.profile_updated"),
          autoClose: 3000,
        };
        dispatch(updateSuccessToast(toastObj));

        // Reset Search Page
        dispatch(resetDatingSearchLists());
        dispatch(resetFilteredLists());
        dispatch(resetRecentlyActiveLists());
        dispatch(resetNearbyLists());
        dispatch(resetNewestLists());
        dispatch(resetCollegeLists());
        dispatch(resetPremiumLists());
        dispatch(updateFilteredPagination(null));
        dispatch(updateRecentlyActivePagination(null));
        dispatch(updateNearbyPagination(null));
        dispatch(updateNewestPagination(null));
        dispatch(updateCollegePagination(null));
        dispatch(updatePremiumPagination(null));

        // Recall GET User Profile API
        dispatch({
          type: `${spiApi.reducerPath}/invalidateTags`,
          payload: ["OwnProfile"],
        });

        onNavigate(routeConst.profile.overview.path);
      }
    } else if (updateProfileError || updateUserLocationError) {
    }
  }, [
    updateProfileLoading,
    updateProfileSuccess,
    updateProfileError,
    updateUserLocationLoading,
    updateUserLocationSuccess,
    updateUserLocationError,
  ]);

  // Lifecycle | Check for update | editProfilePassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (isDetailValid()) {
        const comObj = {
          // new_username: oldUserProfile.profile.username,
          birthday: moment(oldUserProfile.profile.birthday).format(
            "YYYY-MM-DD"
          ),
          // new_tagline: oldUserProfile?.profile?.tagline ?? "",
          networth: oldUserProfile?.profile?.networth,
          annualincome: oldUserProfile?.profile?.annualincome,
          lifestyle: oldUserProfile?.profile?.lifestyle,
          height: oldUserProfile?.profiledetails?.height,
          bodytype: oldUserProfile?.profiledetails?.bodytype,
          ethnicity: oldUserProfile?.profiledetails?.ethnicity,
          eyecolor: oldUserProfile?.profiledetails?.eyecolor,
          haircolor: oldUserProfile?.profiledetails?.haircolor,
          race: oldUserProfile?.profiledetails?.race ?? "",
          occupation: oldUserProfile?.profiledetails?.occupation,
          education: oldUserProfile?.profiledetails?.education,
          relationship: oldUserProfile?.profiledetails?.relationship,
          children: oldUserProfile?.profiledetails?.children,
          smoking: oldUserProfile?.profiledetails?.smoking,
          drinking: oldUserProfile?.profiledetails?.drinking,
          // new_aboutme: oldUserProfile?.profile?.aboutme,
          // new_lookingfor: oldUserProfile?.profile?.lookingfordetails,
        };

        if (
          getProfilePwaData?.payload?.message?.profile?.username !==
          oldUserProfile?.profile?.username
        ) {
          comObj.new_username = oldUserProfile?.profile?.username;
        }

        if (
          getProfilePwaData?.payload?.message?.profile?.aboutme !==
          oldUserProfile?.profile?.aboutme
        ) {
          comObj.new_aboutme = oldUserProfile?.profile?.aboutme;
        }

        if (
          getProfilePwaData?.payload?.message?.profile?.lookingfordetails !==
          oldUserProfile?.profile?.lookingfordetails
        ) {
          comObj.new_lookingfor = oldUserProfile?.profile?.lookingfordetails;
        }

        if (
          getProfilePwaData?.payload?.message?.profile?.tagline !==
          oldUserProfile?.profile?.tagline
        ) {
          comObj.new_tagline = oldUserProfile?.profile?.tagline;
        }

        // Update Profile API
        updateProfile(comObj);

        // Update Location API
        updateUserLocation(editLocation);
      }

      // SPI API | New Method, backend need to update before we can use this
      // const obj = {
      //   username: userProfile.username, // TBC
      //   birth_date: moment(userProfile.appearance.birth_date).format(
      //     "YYYY-MM-DD"
      //   ), // TBC
      //   tagline: userProfile.tagline,
      //   lifestyle_budget: userProfile.occupation.lifestyle_budget, // TBC
      //   net_worth: userProfile.occupation.net_worth,
      //   annual_income: userProfile.occupation.annual_income,
      //   // location: "", // TBC
      //   height: userProfile.appearance.height,
      //   body_type: userProfile.appearance.body_type,
      //   ethnicity: userProfile.appearance.ethnicity,
      //   // race: userProfile.appearance.race, // Not in new SPI API
      //   eye_color: userProfile.appearance.eye_color,
      //   hair_color: userProfile.appearance.hair_color,
      //   occupation: userProfile.occupation.occupation,
      //   education: userProfile.occupation.education,
      //   relationship: userProfile.relationship.relationship_status,
      //   children: userProfile.relationship.children,
      //   smoking: userProfile.habit.smoking,
      //   drinking: userProfile.habit.drinking,
      //   aboutme: userProfile.aboutme,
      //   lookingfor: userProfile.lookingfor,
      // };

      // // Convert Net Worth to Acceptable API Format
      // userConst.networth.forEach((networth) => {
      //   if (networth.fromApi === obj.net_worth) {
      //     obj.net_worth = networth.toSpiApi;
      //   }
      // });

      // // Convert Annual Income to Acceptable API Format
      // userConst.annualIncome.forEach((annualIncome) => {
      //   if (annualIncome.fromApi === obj.annual_income) {
      //     obj.annual_income = annualIncome.toSpiApi;
      //   }
      // });

      // updateUserProfile(obj);
    } else {
      isMounted.current = true;
    }
  }, [editProfilePassthrough]);

  // Event Handlers | Button
  const onViewOwnProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EditProfile-Page-ViewOwnProfile-Button",
      },
    });

    // User View
    dispatch(updateId(getProfileData?.data?.id));
    dispatch(updateIdInt(getProfileData?.data?.id_int));
    dispatch(updateUsername(getProfileData?.data?.username));
    dispatch(updateAge(getProfileData?.data?.appearance?.age));
    dispatch(updateLocation(getProfileData?.data?.location?.city));
    dispatch(updateMembershipType(getProfileData?.data?.membership_type));
    dispatch(updateVerifiedProfile(getProfileData?.data?.is_verified_profile));
    dispatch(updateVerifiedCaller(getProfileData?.data?.verified_caller));
    dispatch(
      updateLevellingBadge(getProfileData?.data?.levelling_profile?.badge)
    );
    dispatch(
      updateLivestreamingAchievementBadge(
        getProfileData?.data?.live_streaming_achievements?.chat_badges_set
      )
    );
    dispatch(
      updateProfilePhoto(getProfileData?.data?.profile_photo?.original_photo)
    );
    dispatch(
      updatePublicPhotos(
        getProfileData?.data?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
          };
        })
      )
    );
    dispatch(
      updatePrivatePhotos(
        getProfileData?.data?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
          };
        })
      )
    );
    dispatch(updateIsSilentInteraction(true));
    dispatch(updatePrivatePhotoRequested(true));
    dispatch(updatePrivatePhotoApproval(true));
    dispatch(updateTorTags(getProfileData?.data?.tor_tags));
    dispatch(updateAboutMe(getProfileData?.data?.aboutme));
    dispatch(updateLookingFor(getProfileData?.data?.lookingfor));
    dispatch(updateOccupation(getProfileData?.data?.occupation?.occupation));
    dispatch(
      updateLifestyle(getProfileData?.data?.occupation?.lifestyle_budget)
    );
    dispatch(updateEducation(getProfileData?.data?.occupation?.education));
    dispatch(
      updateRelationship(
        getProfileData?.data?.relationship?.relationship_status
      )
    );
    dispatch(updateSmoking(getProfileData?.data?.habit?.smoking));
    dispatch(updateDrinking(getProfileData?.data?.habit?.drinking));
    dispatch(updateHeight(getProfileData?.data?.appearance?.height));
    dispatch(updateBodyType(getProfileData?.data?.appearance?.body_type));
    dispatch(updateEthnicity(getProfileData?.data?.appearance?.ethnicity));
    dispatch(updateEyeColor(getProfileData?.data?.appearance?.eye_color));
    dispatch(updateHairColor(getProfileData?.data?.appearance?.hair_color));
    dispatch(updateChildren(getProfileData?.data?.relationship?.children));

    // User Utility
    dispatch(updateRole(getProfileData?.data?.role));

    dispatch(updateUserProfileDialog(true));
  };

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-EditProfile-Page-TabChangeClick-Button-${
          newValue === 0 ? "EditProfileTab" : "PhotosTab"
        }`,
      },
    });

    emblaApi?.scrollTo(newValue);
  };

  // Utility Functions
  const isDetailValid = () => {
    // Comment out latitude & longiture, because API does not return these value
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      if (
        oldUserProfile?.profile?.aboutme?.length >= 10 &&
        oldUserProfile?.profile?.aboutme?.length <= 500 &&
        oldUserProfile?.profile?.lookingfordetails?.length >= 10 &&
        oldUserProfile?.profile?.lookingfordetails?.length <= 500
      ) {
        return true;
      } else {
        const toastObj = {
          message: t("profile.fill_in_all_fields"),
          autoClose: 3000,
        };
        dispatch(updateErrorToast(toastObj));

        return false;
      }
    } else {
      if (
        oldUserProfile?.profile?.aboutme?.length >= 25 &&
        oldUserProfile?.profile?.aboutme?.length <= 500 &&
        oldUserProfile?.profile?.lookingfordetails?.length >= 25 &&
        oldUserProfile?.profile?.lookingfordetails?.length <= 500
      ) {
        return true;
      } else {
        const toastObj = {
          message: t("profile.fill_in_all_fields"),
          autoClose: 3000,
        };
        dispatch(updateErrorToast(toastObj));

        return false;
      }
    }
    // }
  };
  const showModerationContainer = () => {
    const profile = oldUserProfile?.profile || {};
    const isProfileApprovedOrRequired = (field) =>
      field === userConst.comUserApprovalState.approved ||
      field === userConst.comUserApprovalState.required;

    return !(
      isProfileApprovedOrRequired(profile.username_state) &&
      // isProfileApprovedOrRequired(profile.tagline_state) &&
      isProfileApprovedOrRequired(profile.aboutme_state) &&
      isProfileApprovedOrRequired(profile.lookingfordetails_state)
    );
  };

  return (
    <div id="profile-edit-page">
      <div className="max-width-container">
        <UtilityDesktopNavbar
          leftButton={"back"}
          rightButton={"save-edit-profile"}
        />
        {showModerationContainer() && (
          <div className="under-moderation-container">
            <div className="left-container">
              <div className="moderation-icon-container">
                {getIcon("inReviewIcon", "moderation-icon")}
              </div>
            </div>

            <div className="right-container">
              <div className="moderation-title">
                {t("profile.in_review_title")}
              </div>
              <div className="moderation-desc">
                {t("profile.in_review_desc")}
              </div>
            </div>
          </div>
        )}

        <div className="top-container">
          <CustomAvatar
            className="profile-photo"
            src={oldUserProfile?.profile?.profilephoto}
            role={getProfileData?.data?.role}
          />

          <div className="username-container">
            <div className="name">{oldUserProfile?.profile?.username}</div>
            {(oldUserProfile?.profile?.membershiptype ===
              userConst.membershipType.premium ||
              oldUserProfile?.profile?.membershiptype ===
                userConst.membershipType.freePremium) &&
              getIcon("premiumMiniBadge", "premium-mini-badge")}
          </div>

          <div className="preview-button" onClick={onViewOwnProfile}>
            {t("profile.preview")}
          </div>
        </div>

        <div className="tab-container">
          <Tabs
            className="custom-tabs"
            value={editProfileTab}
            onChange={onTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
            variant="fullWidth"
          >
            <Tab label={t("profile.edit_profile")} />
            <Tab label={t("profile.edit_photos")} />
          </Tabs>

          <div ref={emblaRef} className="embla">
            <div className="embla__container">
              <div className="embla__slide">
                {editProfileTab === 0 && <EditProfile />}
              </div>

              <div className="embla__slide">
                {editProfileTab === 1 && <Photos />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProfileRejectedInfoDialog />
    </div>
  );
};

export default ProfileEdit;
