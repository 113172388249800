// General
import "./floating-banners.scss";
// Material UI
import { useMediaQuery } from "@mui/material";
// Components
import Banner from "../../../../../shared/elements/banner/banner";
import FloatingGameLauncher from "../floating-game-launcher/floating-game-launcher";
import LeaderboardEventsDialog from "../../../../../shared/dialog-content/leaderboard-events-dialog/leaderboard-events-dialog";

const FloatingBanners = () => {
  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  return (
    <div id="ongoing-events-floating-banners-subcomponent">
      <Banner type={"livestream"} view={"carousel"} />

      {isMobile && <FloatingGameLauncher />}

      <LeaderboardEventsDialog />
    </div>
  );
};

export default FloatingBanners;
