// General
import "./gifts.scss";
import { useLocation } from "react-router-dom";
// Services
import { usePostPrivateCallRoomGiftRequestMutation } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateSelectedGift } from "../../../../redux/store/livestreamingStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const Gifts = (props) => {
  const { state, productHashId, giftImage, giftAmount, isEventBased } = props;

  // API variables
  const [
    requestGift,
    {
      data: requestGiftData,
      error: requestGiftErrorData,
      isLoading: requestGiftLoading,
      isSuccess: requestGiftSuccess,
      isError: requestGiftError,
    },
  ] = usePostPrivateCallRoomGiftRequestMutation();

  // Redux variables
  const selectedGift = useSelector((state) => state.livestreaming.selectedGift);
  const requestId = useSelector((state) => state.privateCall.requestId);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | Button
  const onSelectGift = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Shop-Subcomponent-SelectGift-Button",
      },
    });

    dispatch(updateSelectedGift(productHashId));

    if (location.pathname.includes("video-call")) {
      if (!isDaddy) {
        const obj = {
          call_request_id: requestId,
          message: `${t("1on1.gift_request_1")} :gift`,
          product_hash_id: productHashId,
        };

        requestGift(obj);
      }
    }
  };

  return (
    <div
      id="gifts-tab-gifts-subcomponent"
      className={`${selectedGift === productHashId ? "selected-gift" : ""}`}
      onClick={onSelectGift}
    >
      <div className="gifts-image-container">
        <img
          className={
            state === "livestream" || isDaddy
              ? "gifts-image"
              : "grey-gifts-image"
          }
          src={giftImage}
        />

        {isEventBased && (
          <div className="gift-event-container">
            <div className="event-label">{t("live.gifts_event")}</div>
          </div>
        )}
      </div>

      <div className="amount-container">
        <div className="coin-icon-container">
          {getIcon("sbCoinIcon", "coin-icon")}
        </div>
        <div className="amount">{giftAmount}</div>
      </div>
    </div>
  );
};

export default Gifts;
