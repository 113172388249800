// General
import "./messages-list.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Conversation Functions
  updateConversationId,
  updateConversationIdInt,
  updateConversationUser,
  resetConversationObj,

  // Private Call Functions
  updateOtherUserPrivateCallEnable,

  // Utility Functions
  updateConversationSelectPassthrough,
  updateSelectedConversation,
} from "../../../../redux/store/inboxStore";
import {
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsOnline,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../redux/store/userCardStore";
import { updateVerifiedCallerDescriptionDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider, useMediaQuery } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import useGetReadableDate from "../../../utility/custom-hooks/useGetReadableDate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";

const MessagesList = (props) => {
  const {
    // User Data
    userId,
    userIdInt,
    username,
    age,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    profilePhoto,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    lookingFor,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    // User Utility
    shouldCallApi,
    privatePhotoAbleToRequest,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isOnline,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,

    // Conversation Data
    conversationId,
    conversationIdInt,
    livestreamingAchievementBadge,
    privateCallSettings,
    lastMessageContent,
    lastMessageTimestamp,
    lastMessageMine,
    lastMessageSeen,
    callStatus,
    callDuration,
    ownMembershipType,
  } = props;

  // Render variables
  let lastSeenView;

  // Redux variables
  const selectedConversation = useSelector(
    (state) => state.inbox.selectedConversation
  );
  const newUserConversation = useSelector(
    (state) => state.inbox.newUserConversation
  );
  const newUserConversationPassthrough = useSelector(
    (state) => state.inbox.newUserConversationPassthrough
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 850px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getReadableDate = useGetReadableDate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | newUserConversationPassthrough
  useEffect(() => {
    if (!newUserConversationPassthrough) return;

    if (newUserConversation?.other_user?.id_int === userIdInt) {
      onMessageUser();
    }
  }, [newUserConversationPassthrough]);

  // Event Handlers | Button
  const onMessageUser = () => {
    // Resetting the conversationObj so that conversation-list.js won't have duplicate data from API too
    dispatch(resetConversationObj());

    // Assigning idInt to userCardStore
    // Use in report page
    dispatch(updateIdInt(userIdInt));

    const conversationUserObj = {
      // User Data
      userId,
      userIdInt,
      username,
      age,
      location,
      membershipType,
      verifiedProfile,
      verifiedCaller,
      levellingBadge,
      profilePhoto,
      publicPhotos,
      privatePhotos,
      torTags,
      profileAchievementBadge,
      aboutMe,
      lookingFor,
      occupation,
      lifestyle,
      education,
      relationship,
      smoking,
      drinking,
      height,
      bodyType,
      ethnicity,
      eyeColor,
      hairColor,
      children,
      // User Utility
      isSilentInteraction,

      // Conversation Data
      id: conversationId,
      idInt: conversationIdInt,
      livestreamingAchievementBadge,
    };

    dispatch(updateConversationUser(conversationUserObj));
    dispatch(updateConversationId(conversationId));
    dispatch(updateConversationIdInt(conversationIdInt));
    dispatch(
      updateOtherUserPrivateCallEnable(
        privateCallSettings?.system_private_calls_enabled &&
          privateCallSettings?.enable_calls
      )
    );

    // setSelectedConversation(conversationId);
    dispatch(updateSelectedConversation(conversationId));

    if (isMobile) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Inbox-Page-Mobile-ToConversation-Button",
        },
      });

      onNavigate(
        `${routeConst.inbox.messaging.chat.chatId.clearPath}${conversationId}`
      );
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Inbox-Page-Desktop-OpenConversation-Button",
        },
      });

      // Clearing the passthrough object of conversation ID and assigning it again after timeout
      dispatch(updateConversationSelectPassthrough({}));
      setTimeout(() => {
        dispatch(updateConversationSelectPassthrough(conversationUserObj));
      }, 500);
    }
  };
  const onViewProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Inbox-Page-ViewProfile-Button",
      },
    });

    // User View
    dispatch(updateId(userId));
    dispatch(updateIdInt(userIdInt));
    dispatch(updateUsername(username));
    dispatch(updateAge(age));
    dispatch(updateLocation(location));
    dispatch(updateMembershipType(membershipType));
    dispatch(updateVerifiedProfile(verifiedProfile));
    dispatch(updateVerifiedCaller(verifiedCaller));
    dispatch(updateLevellingBadge(levellingBadge));
    dispatch(
      updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
    );
    dispatch(updateProfilePhoto(profilePhoto));
    dispatch(updatePublicPhotos(publicPhotos));
    dispatch(updatePrivatePhotos(privatePhotos));
    dispatch(updateTorTags(torTags));
    dispatch(updateProfileAchievementBadge(profileAchievementBadge));
    dispatch(updateAboutMe(aboutMe));
    dispatch(updateLookingFor(lookingFor));
    dispatch(updateOccupation(occupation));
    dispatch(updateLifestyle(lifestyle));
    dispatch(updateEducation(education));
    dispatch(updateRelationship(relationship));
    dispatch(updateSmoking(smoking));
    dispatch(updateDrinking(drinking));
    dispatch(updateHeight(height));
    dispatch(updateBodyType(bodyType));
    dispatch(updateEthnicity(ethnicity));
    dispatch(updateEyeColor(eyeColor));
    dispatch(updateHairColor(hairColor));
    dispatch(updateChildren(children));
    // User Utility
    dispatch(updateShouldCallApi(shouldCallApi));
    dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
    dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
    dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
    dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
    dispatch(updateIsOnline(isOnline ? true : false));
    dispatch(updateIsBlocked(isBlocked ? true : false));
    dispatch(updateIsSilentInteraction(isSilentInteraction));
    dispatch(updateIsFavorited(isFavorited));
    dispatch(updateIsCallEnabled(isCallEnabled));
    dispatch(updateIsSugarbaby(isSugarbaby));
    dispatch(updateRole(role));

    onNavigate(`${routeConst.profile.view.clearPath}${userId}`);
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  // Utility Functions
  const getLastMessage = () => {
    if (callStatus) {
      // Backend does not provide translated text for call status, so FE will have to translate
      switch (callStatus) {
        case "rejected":
          return t("inbox.call_declined");
        case "cancelled":
          return t("inbox.call_cancelled");
        case "ended":
          return `${t("inbox.call_ended")} (${getCallDuration(callDuration)})`;
        case "missed":
          return t("inbox.call_not_answered");
        default:
          break;
      }
    } else if (
      lastMessageContent
        ?.toLowerCase()
        .includes("just given you access to my private photos")
    ) {
      // Backend does not provide translated text for private photos request, so FE will have to translate
      return t("inbox.i_have_just_given_you_access");
    } else if (
      lastMessageContent
        ?.toLowerCase()
        .includes("request to view your private photos")
    ) {
      // Backend does not provide translated text for private photos request, so FE will have to translate
      return t("inbox.request_to_view_your_private_photos");
    } else {
      const cleanMessage = lastMessageContent?.replace(/<br\s*\/?>/gi, " ");
      return cleanMessage;
    }
  };
  const getCallDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  // Render Functions | Last Seen View
  if (ownMembershipType === userConst.membershipType.free) {
    // Free membership users can only see if the last message was seen or not
    if (!lastMessageMine && !lastMessageSeen) {
      lastSeenView = <div className="read"></div>;
    }
  } else {
    if (!lastMessageMine && !lastMessageSeen) {
      lastSeenView = <div className="read"></div>;
    } else if (!lastMessageMine && lastMessageSeen) {
      lastSeenView = <div></div>;
    } else if (lastMessageMine && lastMessageSeen) {
      lastSeenView = getIcon("blueTickIcon", "blue-tick-icon");
    } else {
      lastSeenView = getIcon("greyTickIcon", "grey-tick-icon");
    }
  }

  return (
    <div
      id="messages-tab-messages-list-subcomponent"
      className={
        selectedConversation === conversationId ? "selected-conversation" : ""
      }
    >
      <div className="padding-container">
        <div className="message-container">
          <div className="user-image-container" onClick={onViewProfile}>
            <CustomAvatar
              className="user-image"
              src={profilePhoto}
              role={role}
              isOnline={isOnline}
            />
          </div>

          <div className="user-description-container" onClick={onMessageUser}>
            <div className="user-top-container">
              {livestreamingAchievementBadge?.length > 0 && (
                <div className="livestreaming-achievement-badge-container">
                  {livestreamingAchievementBadge?.map((badge, index) => (
                    <img
                      className="livestreaming-achievement-badge"
                      src={badge?.url}
                      alt={badge?.type}
                      key={index}
                    />
                  ))}
                </div>
              )}

              <div className="user-name">{username}</div>

              <div className="user-badge-container">
                {(membershipType === userConst.membershipType.premium ||
                  membershipType === userConst.membershipType.freePremium ||
                  membershipType === userConst.membershipType.diamond) && (
                  <div className="premium-mini-badge-container">
                    {getIcon("premiumMiniBadge", "premium-mini-badge")}
                  </div>
                )}

                {verifiedProfile && (
                  <div className="verified-profile-badge-container">
                    {getIcon("verifiedProfileBadge", "verified-profile-badge")}
                  </div>
                )}

                {verifiedCaller && (
                  <div
                    className="verified-caller-badge-container"
                    onClick={onOpenVerifiedCallerDialog}
                  >
                    {getIcon("verifiedCallerBadge", "verified-caller-badge")}
                  </div>
                )}

                {membershipType === "admin" && (
                  <div className="admin-badge-container">
                    <PersonIcon className="person-icon" />
                    <div className="admin-label">Admin</div>
                  </div>
                )}

                {levellingBadge && (
                  <div className="level-badge-container">
                    <img className="level-badge" src={levellingBadge} />
                  </div>
                )}
              </div>
            </div>

            <div className="user-bottom-container">
              <div
                className="user-last-message"
                dangerouslySetInnerHTML={{ __html: getLastMessage() }}
              ></div>
            </div>
          </div>

          <div className="user-last-updated-container" onClick={onMessageUser}>
            <div className="last-updated">
              {getReadableDate(lastMessageTimestamp)}
            </div>

            {lastSeenView}
          </div>
        </div>
      </div>

      <Divider className="divider" light />
    </div>
  );
};

export default MessagesList;
