// General
import "./chat-element.scss";
// Material UI
import { useMediaQuery } from "@mui/material";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const ChatElement = (props) => {
  const {
    state,
    livestreamingAchievementBadge,
    profilePhoto,
    username,
    levellingBadge,
    message,
  } = props;

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  return (
    <div id="chat-section-chat-element-subcomponent">
      <div className="profile-picture-container">
        <CustomAvatar className="profile-picture" src={profilePhoto} />
      </div>

      <div className="chat-info-container">
        <div className="user-info-container">
          {livestreamingAchievementBadge?.length > 0 && (
            <div className="livestreaming-achievement-badge-container">
              {livestreamingAchievementBadge?.map((badge, index) => (
                <img
                  className="livestreaming-achievement-badge"
                  src={badge?.url}
                  alt={badge?.type}
                  key={index}
                />
              ))}
            </div>
          )}

          <div
            className={`username ${
              state === "livestream" && !isMobile ? "" : "yellow"
            }`}
          >
            {username || "-"}
          </div>

          {levellingBadge && (
            <div className="levelling-badge-container">
              <img
                className="levelling-badge"
                src={levellingBadge}
                alt="levelling-badge"
              />
            </div>
          )}
        </div>

        <div
          className={`chat ${
            state === "livestream" && !isMobile ? "" : "yellow"
          }`}
        >
          {message}
        </div>
      </div>
    </div>
  );
};

export default ChatElement;
