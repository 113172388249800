// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Inbox & Interest page
  viewedMeBanners: [],

  // Profile page
  profileBanners: [],
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    // Inbox & Interest page
    updateViewedMeBanners: (state, action) => {
      state.viewedMeBanners.push(action.payload);
    },

    // Profile page
    updateProfileBanners: (state, action) => {
      state.profileBanners.push(action.payload);
    },

    // Utility Functions
    resetBannerStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // Inbox & Interest page
  updateViewedMeBanners,

  // Profile page
  updateProfileBanners,

  // Utility Functions
  resetBannerStore,
} = bannerSlice.actions;

export default bannerSlice.reducer;
