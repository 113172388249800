// General
import "./user-profile-tab.scss";
import { useState, useEffect } from "react";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import PhotosTab from "./photos-tab/photos-tab";
import AboutMeTab from "./about-me-tab/about-me-tab";
import AchievementTab from "./achievement-tab/achievement-tab";
import RequestPrivatePhotoDialog from "../../../../shared/dialog-content/request-private-photo-dialog/request-private-photo-dialog";

const UserProfileTab = (props) => {
  const { userProfileType } = props;

  // General variables
  const [value, setValue] = useState(0);

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });
  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(value);

    emblaApi?.on("select", () => {
      setValue(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-UserProfile-Page-TabChangeClick-Button-${
          newValue === 0
            ? "PhotosTab"
            : newValue === 1
            ? "AboutTab"
            : newValue === 2
            ? "AchievementTab"
            : "ReviewsTab"
        }`,
      },
    });

    emblaApi?.scrollTo(newValue);
  };

  return (
    <div id="bottom-user-container-user-profile-tab-subcomponent">
      <Tabs
        className="custom-tabs"
        value={value}
        onChange={onTabChange}
        TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
        variant="fullWidth"
      >
        <Tab
          className="user-profile-tab-label"
          icon={getIcon("profilePhotoIcon", "tab-photo-icon")}
          iconPosition="start"
          label={t("profile.edit_photos")}
        />
        <Tab
          className="user-profile-tab-label"
          icon={getIcon("profileAboutMeIcon", "tab-about-me-icon")}
          iconPosition="start"
          label={t("settings.about")}
        />
        <Tab
          className="user-profile-tab-label"
          icon={getIcon("profileAchievementIcon", "tab-achievement-icon")}
          iconPosition="start"
          label={t("profile.achievements")}
        />
        <Tab
          className="user-profile-tab-label"
          icon={<LockIcon />}
          iconPosition="start"
          label={t("profile.review")}
          disabled
        />
      </Tabs>

      <div ref={emblaRef} className="embla">
        <div className="embla__container">
          <div className="embla__slide">
            {value === 0 && <PhotosTab userProfileType={userProfileType} />}
          </div>

          <div className="embla__slide">{value === 1 && <AboutMeTab />}</div>

          <div className="embla__slide">
            {value === 2 && <AchievementTab />}
          </div>

          {/* <div className="embla__slide">
            <div>Reviews</div>
          </div> */}
        </div>
      </div>

      <RequestPrivatePhotoDialog />
    </div>
  );
};

export default UserProfileTab;
