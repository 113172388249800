// General
import "./more-info-tab.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import { updateStreamerCenterLearnMoreDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import OneOnOneVideoCall from "../../../../shared/dialog-content/one-on-one-video-call-dialog/one-on-one-video-call-dialog";

const MoreInfoTab = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getUserAio(null, true);
  }, []);

  // Event Handlers | Button
  const onOpenStreamerRulesPolicy = () => {
    TagManager.dataLayer({
      dataLayer: {
        event:
          "PWA-StreamerCenter-Page-MoreInfoTab-OpenStreamerRulesPolicy-Button",
      },
    });

    window.open(routeConst.zendesk.streamerPolicy.path, "_blank");
  };
  const onOpenStreamerPremiumCoinsPackage = () => {
    TagManager.dataLayer({
      dataLayer: {
        event:
          "PWA-StreamerCenter-Page-MoreInfoTab-OpenStreamerPremiumCoinsPackage-Button",
      },
    });

    window.open(routeConst.zendesk.premiumCoinPackage.path, "_blank");
  };
  const onOpenGuideToLivestreaming = () => {
    TagManager.dataLayer({
      dataLayer: {
        event:
          "PWA-StreamerCenter-Page-MoreInfoTab-OpenGuideToLivestreaming-Button",
      },
    });

    window.open(routeConst.zendesk.guideToLivestreaming.path, "_blank");
  };
  const onOpenVideoCallTutorial = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-StreamerCenter-Page-MoreInfoTab-VideoCallTutorial-Button",
      },
    });

    onNavigate(routeConst.tutorials.videoCall.path);
  };

  // Event Handlers | MUI Dialog
  const onOpenDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-StreamerCenter-Page-MoreInfoTab-OpenLearnMoreDialog-Button",
      },
    });

    dispatch(updateStreamerCenterLearnMoreDialog(true));
  };

  return (
    <div id="streamer-center-more-info-tab-subcomponent">
      <div className="padding-container">
        {getUserAioData?.data?.modules?.live_streaming_enabled && (
          <div className="header">{t("settings.sugarbook_live")}</div>
        )}
        {getUserAioData?.data?.modules?.live_streaming_enabled && (
          <div className="text">{t("settings.sugarbook_live_more")}</div>
        )}
        {getUserAioData?.data?.modules?.live_streaming_enabled && (
          <div className="buttons-list-container">
            <div
              className="button-container"
              onClick={onOpenStreamerRulesPolicy}
            >
              <div className="button-label">
                {t("profile.streamer_rules_policy")}
              </div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </div>

            <div
              className="button-container"
              onClick={onOpenStreamerPremiumCoinsPackage}
            >
              <div className="button-label">
                {t("profile.streamer_premium_coins_package")}
              </div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </div>

            <div
              className="button-container"
              onClick={onOpenGuideToLivestreaming}
            >
              <div className="button-label">
                {t("profile.guide_to_livestreaming")}
              </div>
              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </div>
          </div>
        )}

        <div className="header">{t("settings.sugarbook_video_call")}</div>
        <div className="text">{t("settings.sugarbook_video_call_more")}</div>

        <div className="buttons-list-container">
          <div className="button-container" onClick={onOpenVideoCallTutorial}>
            <div className="button-label">{t("profile.tutorial")}</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>

          <div className="button-container" onClick={onOpenDialog}>
            <div className="button-label">
              {t("inbox.chat_request_learn_more")}
            </div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>
        </div>
      </div>

      <OneOnOneVideoCall />
    </div>
  );
};

export default MoreInfoTab;
