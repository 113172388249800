// General
import "./info-signup.scss";
import { useState, useEffect } from "react";
// Services
import { sessionService } from "../../../services/session.service";
import {
  useUpdateUserLocationMutation,
  useUpdateProfileMutation,
  useUpdateUserProfileMutation,
  useUpdateProfilePhotoMutation,
} from "../../../services/data.service";
import { removeHTMLEscape } from "../../../services/regex.service";
// Static Data
import routeConst from "../../../const/routeConst";
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateName,
  updateDob,
  updateLatitude,
  updateLongitude,
} from "../../../redux/store/signupStore";
import { updateEditLocation } from "../../../redux/store/userStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// react-date-picker
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// Material UI
import { TextField, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// React Google Maps/api
import { Autocomplete } from "@react-google-maps/api";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
import "moment/locale/zh-tw";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import { updateGeolocationPermissionPromptDialog } from "../../../redux/store/dialogStore";

const InfoSignup = (props) => {
  const { type = utilityConst.fillingDetails.signup.type } = props;

  // API variables
  const [
    updateUserLocation,
    {
      data: updateUserLocationData,
      error: updateUserLocationErrorData,
      isLoading: updateUserLocationLoading,
      isSuccess: updateUserLocationSuccess,
      isError: updateUserLocationError,
    },
  ] = useUpdateUserLocationMutation();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateProfileMutation();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateUserProfileMutation();
  const [
    photoUpdate,
    {
      data: photoUpdateData,
      error: photoUpdateErrorData,
      isLoading: photoUpdateLoading,
      isSuccess: photoUpdateSuccess,
      isError: photoUpdateError,
    },
  ] = useUpdateProfilePhotoMutation();

  // General variables
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameFocus, setNameFocus] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [dobFocus, setDobFocus] = useState(false);
  const [dobValid, setDobValid] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [locationFocus, setLocationFocus] = useState(false);
  const [locationValid, setLocationValid] = useState(false);
  const [locationError, setLocationError] = useState(false);

  const [minDate, setMinDate] = useState(moment().subtract(70, "years"));
  const [maxDate, setMaxDate] = useState(moment().subtract(18, "years"));
  const [orientationData, setOrientationData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [searchResult, setSearchResult] = useState();

  // Redux variables
  const email = useSelector((state) => state.signup.email);
  const role = useSelector((state) => state.signup.role);
  const isPremium = useSelector((state) => state.signup.is_premium);
  const signupDate = useSelector((state) => state.signup.signup_date);

  const name = useSelector((state) => state.signup.name);
  const dob = useSelector((state) => state.signup.dob);
  const latitude = useSelector((state) => state.signup.latitude);
  const longitude = useSelector((state) => state.signup.longitude);
  const editLocation = useSelector((state) => state.user.editLocation);
  const googleMapsLoaded = useSelector(
    (state) => state.noReset.googleMapsLoaded
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-InfoSignup-Page",
      },
    });

    const locationData = sessionService.getLocationData();
    if (
      !locationData?.lat ||
      !locationData?.lng ||
      !locationData?.location_data
    ) {
      updateUserLocation({});
    } else {
      const locationObj = {
        lat: locationData?.lat,
        lng: locationData?.lng,
        location_data: locationData?.location_data,
        type: "device_gps",
      };
      updateUserLocation(locationObj);
    }
  }, []);

  // Lifecycle | Check for update | API Responses
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      const formData = new FormData();

      formData.append("new_public_photo[]", imageData.file);
      formData.append("orientation", orientationData);

      photoUpdate(formData);
    } else if (updateProfileError) {
    }
  }, [
    updateProfileLoading,
    updateProfileSuccess,
    updateProfileError,
    // updateUserProfileLoading,
    // updateUserProfileSuccess,
    // updateUserProfileError,
  ]);

  // Lifecycle | Check for update | photoUpload API Response
  useEffect(() => {
    if (photoUpdateLoading) {
    } else if (photoUpdateSuccess) {
      switch (type) {
        case utilityConst.fillingDetails.signup.type:
          let userData = {
            email,
            username: name,
            role,
            is_premium: isPremium,
            signup_date: signupDate,
          };
          window.dataLayer.push(userData);

          onNavigate(routeConst.descriptionSignup.path);
          break;
        case utilityConst.fillingDetails.missingDetails.type:
          // GTM (Not Tested)
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-Missing-Details-Form-Submit",
            },
          });

          onNavigate(routeConst.live.path);
          break;
        default:
          break;
      }
    } else if (photoUpdateError) {
    }
  }, [photoUpdateLoading, photoUpdateSuccess, photoUpdateError]);

  // Event Handlers | Buttons
  const onSaveDetails = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-InfoSignup-Page-Save-Button",
      },
    });

    const locationObj = {
      lat: latitude,
      lng: longitude,
      // ip: null,
      location_data: {
        city: "Kuala Lumpur",
        state: "Kuala Lumpur",
        formatted_address: "",
        country: "Malaysia",
        country_iso: "MY",
      },
      type: "user_defined",
    };

    const oldProfileObj = {
      birthday: dob,
      new_username: name,
    };

    const profileObj = {
      username: name,
      birth_date: dob,
    };

    // updateUserLocation(editLocation); // Moved to GPS based
    updateProfile(oldProfileObj);
    // updateUserProfile(profileObj); // Backend not ready yet, does not update GET profile API
  };
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const orientation = await getImageOrientation(file);
        saveOrientationData(orientation);

        const imageData = await readFile(file);
        setImageData((prevState) => ({
          ...prevState,
          image: imageData,
          file: file,
        }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Event Handlers | MUI Date Picker
  const onDateChange = (event) => {
    if (!event) return;

    dispatch(updateDob(moment(event).format("YYYY-MM-DD")));
  };

  // Event Handlers | MUI Text Field
  const onNameChange = (event) => {
    // TODO: Add Min & Max length
    dispatch(updateName(event.target.value));
  };
  const onNameFocus = (event) => {
    setNameFocus(true);
  };
  const onDobFocus = (event) => {
    setDobFocus(true);
  };
  const onLocationFocus = (event) => {
    setLocationFocus(true);
  };

  // Event Handlers | Google Maps Autocomplete
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      let location_data = {
        formatted_address: place?.formatted_address,
      };

      if (place?.address_components) {
        for (let i = 0; i < place?.address_components?.length; i++) {
          if (place?.address_components[i]?.types?.length > 0) {
            switch (place?.address_components[i]?.types[0]) {
              case "locality":
                location_data.city = place?.address_components[i]?.long_name;
                break;
              case "neighborhood":
                if (location_data.city !== null) {
                  location_data.neighborhood =
                    place?.address_components[i]?.long_name;
                }
                break;
              case "administrative_area_level_1":
                location_data.state = place?.address_components[i]?.short_name;
                break;
              case "country":
                location_data.country = place?.address_components[i]?.long_name;
                location_data.country_iso =
                  place?.address_components[i]?.short_name;
                break;
              default:
                break;
            }
          }
        }
      }

      const locationObj = {
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
        location_data,
        type: "user_defined",
      };

      dispatch(updateEditLocation(locationObj));
    }
  };
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  // Utility Functions
  const isFormValid = () => {
    return imageData?.file && nameValid && dobValid;
  };
  const nameValidation = () => {
    let valid = true;
    let error = false;

    if (!nameFocus) {
      valid = false;
      error = false;
    } else if (nameFocus && name === "") {
      setNameErrorMessage(t("signup.display_name_is_required"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(name)) {
      valid = false;
      error = true;
      dispatch(updateName(""));

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-InfoSignup-Page-Username-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-InfoSignup-Page-Username-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setNameErrorMessage("");
      valid = true;
      error = false;
    }

    setNameValid(valid);
    setNameError(error);
  };
  const dobValidation = () => {
    let valid = true;
    let error = false;

    if (dob === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setDobValid(valid);
    setDobError(error);
  };
  const onCalendarOpen = () => {
    if (dob) return;

    dispatch(updateDob(maxDate.format("YYYY-MM-DD")));
  };
  const locationValidation = () => {
    let valid = true;
    let error = false;

    if (!locationFocus) {
      valid = false;
      error = false;
    } else if (locationFocus && (!editLocation?.lat || !editLocation?.lng)) {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setLocationValid(valid);
    setLocationError(error);
  };
  const saveOrientationData = (orientation_data) => {
    setOrientationData(orientation_data);
  };
  const getImageOrientation = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const view = new DataView(event.target.result);
        if (view.getUint16(0, false) !== 0xffd8) {
          return resolve(-2);
        }
        const length = view.byteLength;
        let offset = 2;
        while (offset < length) {
          const marker = view.getUint16(offset, false);
          offset += 2;
          if (marker === 0xffe1) {
            const exif = view.getUint32((offset += 2), false);
            if (exif !== 0x45786966) {
              return resolve(-1);
            }
            const little = view.getUint16((offset += 6), false) === 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + i * 12, little) === 0x0112) {
                return resolve(view.getUint16(offset + i * 12 + 8, little));
              }
            }
          } else if ((marker & 0xff00) !== 0xff00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }
        return resolve(-1);
      };
      reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    });
  };
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  // Check for name validation
  useEffect(() => {
    nameValidation();
    dobValidation();
    locationValidation();
  }, [
    name,
    nameFocus,
    nameErrorMessage,
    dob,
    dobFocus,
    latitude,
    longitude,
    locationFocus,
  ]);

  // Only works outside of useEffect
  if (i18n.language.toLowerCase().includes("zh-tw")) {
    moment.locale("zh-tw");
  } else {
    moment.locale("en");
  }

  return (
    <div id="info-signup-page">
      <div className="max-width-container">
        <div className="started-label">{t("signup.lets_get_started")}</div>

        <Button className="profile-img-button" disableRipple component="label">
          <input hidden accept="image/*" type="file" onChange={onFilePicker} />
          {imageData?.image ? (
            <img className="profile-img" src={imageData?.image} alt="default" />
          ) : (
            getIcon("defaultProfilePhotoIcon", "profile-img")
          )}

          <div className="add-icon-container">
            <EditIcon className="add-icon" />
          </div>
        </Button>

        <div className="description">{t("signup.avatar")}</div>

        <div className="name-form-field-container">
          <TextField
            className="name-form-field custom-text-field"
            required
            value={name}
            error={nameError}
            onChange={onNameChange}
            label={t("signup.display_name")}
            helperText={nameErrorMessage}
            variant="standard"
            onFocus={onNameFocus}
          />
        </div>

        <div className="dob-form-field-container">
          <label className="birthday-label">{t("profile.birthday")}</label>

          <DatePicker
            name="dob"
            onChange={onDateChange}
            onCalendarOpen={onCalendarOpen}
            value={dob}
            minDate={minDate.toDate()}
            maxDate={maxDate.toDate()}
            clearIcon={null}
          />
        </div>

        {false && googleMapsLoaded && (
          <div className="location-form-field-container">
            <Autocomplete
              className="autocomplete-wrapper"
              onPlaceChanged={onPlaceChanged}
              onLoad={onLoad}
            >
              <TextField
                id="searchTextField"
                className="form-field custom-text-field"
                // error={locationError}
                label={t("signup.location")}
                placeholder={t("signup.location")}
                variant="standard"
                onFocus={onLocationFocus}
              ></TextField>
            </Autocomplete>
          </div>
        )}

        <Button
          className={`continue-button ${
            isFormValid() ? "" : "disabled-button"
          }`}
          variant="text"
          onClick={onSaveDetails}
          disabled={!isFormValid()}
        >
          {updateProfileLoading || photoUpdateLoading ? (
            <Spinner size={20} isPadding={false} color="white-spinner" />
          ) : (
            t("common.continue")
          )}
        </Button>
      </div>
    </div>
  );
};

export default InfoSignup;
