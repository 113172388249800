// General
import "./video-call-room.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetPrivateCallRoomLeaveQuery,
  useLazyGetPrivateCallRequestDetailsQuery,
  useLazyGetPrivateCallRoomKeepAliveQuery,
  useLazyGetPrivateCallSummaryQuery,
} from "../../../services/data.service";
import { sessionService } from "../../../services/session.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  startCallDurationRemainingTimer,
  startFreeSecondsRemainingTimer,
  updateShowVideoCallEndingOverlay,
  updateRequestId,
  updateIsCaller,
  updateCallDurationRemaining,
  updateFreeSecondsRemaining,
  updateEndVideoCall,

  // TRTC Functions
  clearCallerUserSig,
  clearCalleeUserSig,
  clearRoomId,
  updateCallStatus,

  // Chat Functions
  clearVideoCallChatMessages,

  // Interval Functions
  updateRoomKeepAliveInterval,
  updateCallDurationRemainingInterval,
  updateFreeSecondsRemainingInterval,
  clearRoomKeepAliveInterval,

  // Coins Functions
  updateTotalCoins,

  // Rates Functions
  updateCalleeCoinsPerMinute,

  // Caller Functions
  updateCallerUsername,
  updateCallerProfilePhoto,

  // Callee Functions
  updateCalleeUsername,

  // Caller End Video Call Summary Functions
  updateCallerEndVideoCallSummaryData,
  updateCalleeEndVideoCallSummaryData,
  updateTotalDiamonds,

  // Utility Functions
  resetPrivateCallStore,
} from "../../../redux/store/privateCallStore";
import { updatePurchaseDialogTab } from "../../../redux/store/productsStore";
import { updateVideoCallPusherSubscribe } from "../../../redux/store/pusherStore";
import { updateOnboardingVideoCallRoomDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Custom Hooks
import useIsMounted from "../../utility/custom-hooks/useIsMounted-hook";
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import useEndPrivateCall from "../../utility/custom-hooks/useEndPrivateCall-hook";
// Components
import CameraFeeds from "./camera-feeds/camera-feeds";
import GiftAnimationOverlay from "../../shared/elements/gift-animation-overlay/gift-animation-overlay";
import ProfilePhotoOverlay from "../../shared/video-call/profile-photo-overlay/profile-photo-overlay";
import EmojiOverlay from "../../shared/elements/emoji-overlay/emoji-overlay";
import InteractableOverlay from "../../shared/video-call/interactable-overlay/interactable-overlay";
import JoiningCallOverlay from "../../shared/video-call/joining-call-overlay/joining-call-overlay";
import InitializingStreamOverlay from "../../shared/video-call/initializing-stream-overlay/initializing-stream-overlay";
import EndingCallOverlay from "../../shared/video-call/ending-call-overlay/ending-call-overlay";
import GiftsSection from "../../shared/elements/gifts-section/gifts-section";
import CameraMicrophoneSettingsDialog from "../../shared/dialog-content/camera-microphone-settings-dialog/camera-microphone-settings-dialog";

const VideoCallRoom = () => {
  // API variables
  const [
    getPrivateCallRoomLeave,
    {
      data: getPrivateCallRoomLeaveData,
      error: getPrivateCallRoomLeaveErrorData,
      isFetching: getPrivateCallRoomLeaveFetching,
      isLoading: getPrivateCallRoomLeaveLoading,
      isSuccess: getPrivateCallRoomLeaveSuccess,
      isError: getPrivateCallRoomLeaveError,
    },
  ] = useLazyGetPrivateCallRoomLeaveQuery();
  const [
    getPrivateCallRoomKeepAlive,
    {
      data: getPrivateCallRoomKeepAliveData,
      error: getPrivateCallRoomKeepAliveErrorData,
      isFetching: getPrivateCallRoomKeepAliveFetching,
      isLoading: getPrivateCallRoomKeepAliveLoading,
      isSuccess: getPrivateCallRoomKeepAliveSuccess,
      isError: getPrivateCallRoomKeepAliveError,
    },
  ] = useLazyGetPrivateCallRoomKeepAliveQuery();
  const [
    // Trigger when caller joins
    // Example trigger
    // getPrivateCallRequestDetails("?call_request_id=ozXZmrg1loAZ0G3b");
    getPrivateCallRequestDetails,
    {
      data: getPrivateCallRequestDetailsData,
      error: getPrivateCallRequestDetailsErrorData,
      isFetching: getPrivateCallRequestDetailsFetching,
      isLoading: getPrivateCallRequestDetailsLoading,
      isSuccess: getPrivateCallRequestDetailsSuccess,
      isError: getPrivateCallRequestDetailsError,
    },
  ] = useLazyGetPrivateCallRequestDetailsQuery();
  const [
    getPrivateCallSummary,
    {
      data: getPrivateCallSummaryData,
      error: getPrivateCallSummaryErrorData,
      isFetching: getPrivateCallSummaryFetching,
      isLoading: getPrivateCallSummaryLoading,
      isSuccess: getPrivateCallSummarySuccess,
      isError: getPrivateCallSummaryError,
    },
  ] = useLazyGetPrivateCallSummaryQuery();

  // General variables
  const [value, setValue] = useState(1);
  const isMountedEndCall = useRef(false);

  // Redux variables
  const isPrivateStandbyMode = useSelector(
    (state) => state.privateCall.isPrivateStandbyMode
  );
  const requestId = useSelector((state) => state.privateCall.requestId);
  const calleeId = useSelector((state) => state.privateCall.calleeId);
  const freeSecondsRemainingInterval = useSelector(
    (state) => state.privateCall.freeSecondsRemainingInterval
  );
  const notifyEndVideoCallPassthrough = useSelector(
    (state) => state.privateCall.notifyEndVideoCallPassthrough
  );
  const endVideoCall = useSelector((state) => state.privateCall.endVideoCall);
  const disableSwipe = useSelector((state) => state.privateCall.disableSwipe);
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onNavigate = useCustomNavigate();
  const onEndCall = useEndPrivateCall();

  // Lifecycle | Mounted
  useEffect(() => {
    // Check if "has-shown-video-call-room-dialog" in local storage is true or false
    if (!sessionService.getHasShownVideoCallRoomDialog()) {
      dispatch(updateOnboardingVideoCallRoomDialog(true));
    }

    // Initialize video call status
    dispatch(updateCallStatus("JOIN"));

    // Initialize endVideoCall
    dispatch(updateEndVideoCall(false));

    // Clear private chat incase Unmounted did not clear it
    dispatch(clearVideoCallChatMessages());

    // Hide video call ending overlay if its open
    dispatch(updateShowVideoCallEndingOverlay(false));

    // Private Standby Mode & Private Call Mode is not the same
    if (isPrivateStandbyMode) {
      // Initialize as Caller to show view for other components
      dispatch(updateIsCaller(true));

      // Call Private Call Request Details API
      getPrivateCallRequestDetails(`?call_request_id=${requestId}`);

      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-VideoCallRoom-Page-PrivateCall",
        },
      });
    } else {
      // Subscribe to Video Call Pusher
      dispatch(updateVideoCallPusherSubscribe({}));

      // GTM (Not Tested)
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-VideoCallRoomPage-VideoCall-Initialize",
        },
      });
    }

    // Call Room Keep Alive API every 10 seconds
    dispatch(
      updateRoomKeepAliveInterval(
        setInterval(() => {
          getPrivateCallRoomKeepAlive(`?call_request_id=${requestId}`);
        }, 10000)
      )
    );

    // Start Call Duration Remaining Interval
    dispatch(
      updateCallDurationRemainingInterval(
        setInterval(() => {
          dispatch(startCallDurationRemainingTimer());
        }, 1000)
      )
    );
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(value);

    emblaApi?.on("select", () => {
      setValue(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        dispatch(clearRoomKeepAliveInterval());
        dispatch(clearCalleeUserSig());
        dispatch(clearCallerUserSig());
        dispatch(clearRoomId());

        dispatch(updatePurchaseDialogTab(0));

        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-VideoCallRoom-Page-Exit",
          },
        });
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | Private Call Request Details API Response
  useEffect(() => {
    if (
      getPrivateCallRequestDetailsFetching ||
      getPrivateCallRequestDetailsLoading
    ) {
    } else if (getPrivateCallRequestDetailsSuccess) {
      if (getPrivateCallRequestDetailsData?.status === 0) {
        // dispatch(
        //   updateCallerId(
        //     getPrivateCallRequestDetailsData?.data?.call_request?.caller?.id
        //   )
        // );
        dispatch(
          updateCallerUsername(
            getPrivateCallRequestDetailsData?.data?.call_request?.caller
              ?.username
          )
        );
        dispatch(
          updateCallerProfilePhoto(
            getPrivateCallRequestDetailsData?.data?.call_request?.caller
              ?.profile_photo?.original_photo
          )
        );
        dispatch(
          updateCalleeUsername(
            getPrivateCallRequestDetailsData?.data?.call_request?.callee
              ?.username
          )
        );
      }
    } else if (getPrivateCallRequestDetailsError) {
      if (getPrivateCallRequestDetailsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallRequestDetailsFetching,
    getPrivateCallRequestDetailsLoading,
    getPrivateCallRequestDetailsSuccess,
    getPrivateCallRequestDetailsError,
  ]);

  // Lifecycle | Check for update | Private Call Room Leave API Response
  useEffect(() => {
    if (getPrivateCallRoomLeaveFetching || getPrivateCallRoomLeaveLoading) {
    } else if (getPrivateCallRoomLeaveSuccess) {
      if (getPrivateCallRoomLeaveData?.status === 0) {
        getPrivateCallSummary(`?call_request_id=${requestId}`);
      }
    } else if (getPrivateCallRoomLeaveError) {
      if (getPrivateCallRoomLeaveErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallRoomLeaveFetching,
    getPrivateCallRoomLeaveLoading,
    getPrivateCallRoomLeaveSuccess,
    getPrivateCallRoomLeaveError,
  ]);

  // Lifecycle | Check for update | Private Call Room Keep Alive API Response
  useEffect(() => {
    if (
      getPrivateCallRoomKeepAliveFetching ||
      getPrivateCallRoomKeepAliveLoading
    ) {
    } else if (getPrivateCallRoomKeepAliveSuccess) {
      switch (getPrivateCallRoomKeepAliveData?.status) {
        case 0:
          dispatch(
            updateTotalCoins(
              getPrivateCallRoomKeepAliveData?.data?.wallet?.coins
            )
          );

          dispatch(
            updateTotalDiamonds(
              getPrivateCallRoomKeepAliveData?.data?.summary
                ?.total_diamonds_earned
            )
          );

          dispatch(
            updateCallDurationRemaining(
              getPrivateCallRoomKeepAliveData?.data?.real_remaining_seconds
            )
          );

          dispatch(
            updateFreeSecondsRemaining(
              getPrivateCallRoomKeepAliveData?.data?.campaign_remaining_seconds
            )
          );

          if (!freeSecondsRemainingInterval) {
            dispatch(
              updateFreeSecondsRemainingInterval(
                setInterval(() => {
                  dispatch(startFreeSecondsRemainingTimer());
                }, 1000)
              )
            );
          }

          dispatch(
            updateCalleeCoinsPerMinute(
              getPrivateCallRoomKeepAliveData?.data?.call_request?.rates?.coins
            )
          );
          break;
        default:
          dispatch(updateEndVideoCall(true));
          break;
      }
    } else if (getPrivateCallRoomKeepAliveError) {
      if (getPrivateCallRoomKeepAliveErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallRoomKeepAliveFetching,
    getPrivateCallRoomKeepAliveLoading,
    getPrivateCallRoomKeepAliveSuccess,
    getPrivateCallRoomKeepAliveError,
  ]);

  // Lifecycle | Check for update | Private Call Summary API Response
  useEffect(() => {
    if (getPrivateCallSummaryFetching || getPrivateCallSummaryLoading) {
    } else if (getPrivateCallSummarySuccess) {
      if (getPrivateCallSummaryData?.status === 0) {
        const callerObj = {
          callDuration: getPrivateCallSummaryData?.data?.summary?.call_duration,
          chargedMessagesSent:
            getPrivateCallSummaryData?.data?.summary?.chat_messages_count,
          giftsSent: getPrivateCallSummaryData?.data?.summary?.giftings_count,
          tipSent: getPrivateCallSummaryData?.data?.summary?.tipping_count,
          tipCoinsSent:
            getPrivateCallSummaryData?.data?.summary?.tipping_coins_count,
          tipActionRequested:
            getPrivateCallSummaryData?.data?.summary?.customized_tipping_count,
          tipActionCompleted:
            getPrivateCallSummaryData?.data?.summary
              ?.customized_tipping_count_accepted,
        };

        dispatch(updateCallerEndVideoCallSummaryData(callerObj));

        const calleeObj = {
          callDuration: getPrivateCallSummaryData?.data?.summary?.call_duration,
          totalDiamondsEarned:
            getPrivateCallSummaryData?.data?.summary?.total_diamonds_earned,
          videoCallDiamondsEarned:
            getPrivateCallSummaryData?.data?.summary?.call_charges_sum,
          chargedMessagesEarned:
            getPrivateCallSummaryData?.data?.summary?.chat_messages_sum
              ?.diamonds,
          tipsDiamondsEarned:
            getPrivateCallSummaryData?.data?.summary?.tippings_sum,
          giftsDiamondsEarned:
            getPrivateCallSummaryData?.data?.summary?.giftings_sum,
          actionCompleted:
            getPrivateCallSummaryData?.data?.summary
              ?.customized_tipping_count_accepted,
          requestsFromDaddy:
            getPrivateCallSummaryData?.data?.summary?.customized_tipping_count,
        };

        dispatch(updateCalleeEndVideoCallSummaryData(calleeObj));

        onEndCall();

        // Reset end video call to prevent rerun of API when joining back
        dispatch(updateEndVideoCall(false));
      }
    } else if (getPrivateCallSummaryError) {
      if (getPrivateCallSummaryErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallSummaryFetching,
    getPrivateCallSummaryLoading,
    getPrivateCallSummarySuccess,
    getPrivateCallSummaryError,
  ]);

  // Lifecycle | Check for update | requestId
  useEffect(() => {
    if (!requestId) {
      onEndCall();
    } else {
      // Call Room Keep Alive API for the first time
      getPrivateCallRoomKeepAlive(`?call_request_id=${requestId}`);
    }
  }, [requestId]);

  // Lifecycle | Check for update | notifyEndVideoCallPassthrough
  useEffect(() => {
    if (isMountedEndCall.current) {
      if (notifyEndVideoCallPassthrough) {
        dispatch(updateEndVideoCall(true));
      }
    } else {
      isMountedEndCall.current = true;
    }
  }, [notifyEndVideoCallPassthrough]);

  // Lifecycle | Check for update | endVideoCall
  useEffect(() => {
    if (!endVideoCall) return;

    const leaveObj = {
      call_request_id: requestId,
      caller_cam_error: "false",
    };

    getPrivateCallRoomLeave(leaveObj);
  }, [endVideoCall]);

  return (
    <div id="video-call-room-page">
      <CameraFeeds />

      {/* Disable swiping due to unstability */}
      {false && (
        <div ref={emblaRef} className="embla">
          <div className="embla__container">
            <div className="embla__slide">
              <div className="stripped-down-container">
                <div className="gifts-section-container">
                  <GiftsSection state={"private-call"} />

                  <JoiningCallOverlay />
                </div>
              </div>
            </div>

            <div className="embla__slide">
              <div className="interactable-container">
                <GiftAnimationOverlay />
                <ProfilePhotoOverlay />
                <EmojiOverlay />

                <InteractableOverlay />

                <JoiningCallOverlay />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="interactable-container">
        <GiftAnimationOverlay />
        <ProfilePhotoOverlay />
        <EmojiOverlay />

        <InteractableOverlay />

        <JoiningCallOverlay />
      </div>

      <InitializingStreamOverlay />
      <EndingCallOverlay />

      <CameraMicrophoneSettingsDialog />
    </div>
  );
};

export default VideoCallRoom;
