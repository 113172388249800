// General
import "./logging-settings.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateShowDebugInfo,
  updateShowLog,
} from "../../../../redux/store/debugStore";
// Material UI
import { Switch } from "@mui/material";

const LoggingSettings = () => {
  // Redux variables
  const showHiddenSettings = useSelector(
    (state) => state.app.showHiddenSettings
  );
  const showLog = useSelector((state) => state.debug.showLog);
  const dispatch = useDispatch();

  // Event Handlers | MUI Toggle Button
  const onToggleLoggingMode = (event) => {
    dispatch(updateShowLog(event.target.checked));
  };

  if (showHiddenSettings) {
    return (
      <div id="settings-logging-settings-subcomponent">
        <div className="padding-container">
          <div className="logging-mode-label">Show Log</div>

          <div className="logging-mode-switch-container">
            <Switch
              className="custom-switch"
              checked={showLog}
              onChange={onToggleLoggingMode}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default LoggingSettings;
