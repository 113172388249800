// General
import "./last-stream-summary.scss";
import { useEffect } from "react";
// Services
import { useLazyGetLastStreamResultQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector } from "react-redux";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider } from "@mui/material";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import LivestreamStats from "./livestream-stats/livestream-stats";
import LastStreamDiamondsProgress from "./last-stream-diamonds-progress/last-stream-diamonds-progress";
import LastStreamTopGifters from "./last-stream-top-gifters/last-stream-top-gifters";
import LastStreamDiamondsEarned from "./last-stream-diamonds-earned/last-stream-diamonds-earned";
import LastStreamViewers from "./last-stream-viewers/last-stream-viewers";
import EmptyList from "./empty-list/empty-list";

const LastStreamSummary = () => {
  // API variables
  const [
    getLastStreamSummary,
    {
      data: getLastStreamSummaryData,
      error: getLastStreamSummaryErrorData,
      isFetching: getLastStreamSummaryFetching,
      isLoading: getLastStreamSummaryLoading,
      isSuccess: getLastStreamSummarySuccess,
      isError: getLastStreamSummaryError,
    },
  ] = useLazyGetLastStreamResultQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LastStreamSumamry-Page",
      },
    });

    getLastStreamSummary(null, true);
  }, []);

  // Render Functions | API Response
  if (getLastStreamSummaryFetching || getLastStreamSummaryLoading) {
    return <Spinner />;
  } else if (getLastStreamSummarySuccess) {
    if (getLastStreamSummaryData?.status === 1) {
      return (
        <div id="streamer-center-last-stream-summary-subcomponent">
          <div className="max-width-container">
            <LivestreamStats />

            <LastStreamDiamondsProgress />

            <Divider />

            <LastStreamTopGifters />

            <Divider />

            <LastStreamDiamondsEarned />

            <Divider />

            <LastStreamViewers />
          </div>
        </div>
      );
    } else if (getLastStreamSummaryData?.status === -1) {
      return <EmptyList />;
    }
  } else if (getLastStreamSummaryError) {
    if (getLastStreamSummaryErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default LastStreamSummary;
