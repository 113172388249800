// General
import "./live.scss";
import { useState, useEffect, useRef } from "react";
// Services
import { useLazyGetLivestreamingChannelsQuery } from "../../../services/data.service";
import { sessionService } from "../../../services/session.service";
// Static Data
import routeConst from "../../../const/routeConst";
import userConst from "../../../const/userConst";
import userCardConst from "../../../const/userCardConst";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateLivestreamChannelLists,
  updateLivestreamChannelPagination,
  resetLivestreamChannelLists,
} from "../../../redux/store/livestreamingStore";
import { updateOnboardingLiveStreamingDialog } from "../../../redux/store/dialogStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useGetUserProfileStatus from "../../utility/custom-hooks/useGetUserProfileStatus-hook";
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Banner from "../../shared/elements/banner/banner";
import UserCardManager from "../../utility/manager/user-card-manager/user-card-manager";
import EmptyList from "./empty-list/empty-list";
import Spinner from "../../shared/elements/spinner/spinner";

const Live = () => {
  // API variables
  const [
    getLivestreamingChannels,
    {
      data: getLivestreamingChannelsData,
      error: getLivestreamingChannelsErrorData,
      isFetching: getLivestreamingChannelsFetching,
      isLoading: getLivestreamingChannelsLoading,
      isSuccess: getLivestreamingChannelsSuccess,
      isError: getLivestreamingChannelsError,
    },
  ] = useLazyGetLivestreamingChannelsQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const scrollContainerRef = useRef(null);
  const isMounted = useRef(false);

  // Render variables
  let liveListView = <div></div>;

  // Redux variables
  const livestreamChannelLists = useSelector(
    (state) => state.livestreaming.livestreamChannelLists
  );
  const livestreamChannelPagination = useSelector(
    (state) => state.livestreaming.livestreamChannelPagination
  );
  const livestreamScrollToTopPassthrough = useSelector(
    (state) => state.livestreaming.livestreamScrollToTopPassthrough
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 425px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getProfileStatus = useGetUserProfileStatus();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Live-Page",
      },
    });

    // Livestream Channel is always refresh when mounted
    setFirstLoad(true);
    dispatch(resetLivestreamChannelLists());

    getLivestreamingChannels();

    // Check if "has-shown-live-streaming-dialog" in local storage is true or false
    if (!sessionService.getHasShownLiveStreamingDialog()) {
      dispatch(updateOnboardingLiveStreamingDialog(true));
    }
  }, []);

  // Lifecycle | Check for update | Livestreaming Channels API Response
  useEffect(() => {
    if (getLivestreamingChannelsFetching || getLivestreamingChannelsLoading) {
    } else if (getLivestreamingChannelsSuccess) {
      if (getLivestreamingChannelsData?.status === 1) {
        setFirstLoad(false);

        dispatch(
          updateLivestreamChannelPagination(
            getLivestreamingChannelsData?.data?.pagination?.next_cursor
          )
        );

        if (getLivestreamingChannelsData?.data?.results?.length > 0) {
          // Use to block infiniteScrollFunc from running into infinite loop
          setIsApiLoading(false);

          dispatch(
            updateLivestreamChannelLists(
              getLivestreamingChannelsData?.data?.results
            )
          );
        }
      }
    } else if (getLivestreamingChannelsError) {
      if (getLivestreamingChannelsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingChannelsFetching,
    getLivestreamingChannelsLoading,
    getLivestreamingChannelsSuccess,
    getLivestreamingChannelsError,
  ]);

  // Lifecycle | Check for update | livestreamScrollToTopPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (livestreamScrollToTopPassthrough) {
        scrollContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [livestreamScrollToTopPassthrough]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!livestreamChannelPagination) return;
    setIsApiLoading(true);

    setTimeout(() => {
      getLivestreamingChannels(
        `?pagination_token=${livestreamChannelPagination}`,
        true
      );
    }, 1000);
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetLivestreamChannelLists());
    dispatch(updateLivestreamChannelPagination(null));
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(getLivestreamingChannels());
    });
  };

  // Render Functions
  if (livestreamChannelLists?.length > 0) {
    liveListView = (
      <div
        className={`infinite-scroller-container ${
          !isMobile || livestreamChannelLists?.length >= 10
            ? "multi-column"
            : ""
        }`}
      >
        {livestreamChannelLists?.map((channel, index) => (
          <UserCardManager
            // Config
            type={channel?.type}
            // User View
            id={channel?.user?.id}
            idInt={channel?.user?.id_int}
            username={channel?.user?.username}
            age={channel?.user?.appearance?.age}
            location={
              channel?.user?.location?.display_address ||
              channel?.user?.location?.city ||
              channel?.user?.location?.state
            }
            membershipType={channel?.user?.membership_type}
            verifiedProfile={channel?.user?.is_verified_profile}
            verifiedCaller={channel?.user?.verified_caller}
            levellingBadge={channel?.user?.levelling_profile?.badge}
            livestreamingAchievementBadge={
              channel?.user?.live_streaming_achievements?.chat_badges_set
            }
            profilePhoto={channel?.user?.profile_photo?.original_photo}
            publicPhotos={channel?.user?.public_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
              };
            })}
            privatePhotos={channel?.user?.private_photos.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
                thumbnailData: photo?.thumbnail_data,
              };
            })}
            torTags={channel?.user?.tor_tags}
            profileAchievementBadge={
              channel?.user?.live_streaming_achievements?.profile_badges
            }
            aboutMe={channel?.user?.aboutme}
            lookingFor={channel?.user?.lookingfor}
            tagline={channel?.user?.tagline}
            annualIncome={channel?.user?.occupation?.annual_income}
            networth={channel?.user?.occupation?.net_worth}
            occupation={channel?.user?.occupation?.occupation}
            lifestyle={channel?.user?.occupation?.lifestyle_budget}
            education={channel?.user?.occupation?.education}
            relationship={channel?.user?.relationship?.relationship_status}
            smoking={channel?.user?.habit?.smoking}
            drinking={channel?.user?.habit?.drinking}
            height={channel?.user?.appearance?.height}
            bodyType={channel?.user?.appearance?.body_type}
            ethnicity={channel?.user?.appearance?.ethnicity}
            eyeColor={channel?.user?.appearance?.eye_color}
            hairColor={channel?.user?.appearance?.hair_color}
            children={channel?.user?.relationship?.children}
            // User Utility
            profileStatus={getProfileStatus(
              channel?.live_stream ? channel : channel?.user
            )}
            privatePhotoAbleToRequest={
              channel?.user?.interaction?.private_photo_requested === false
                ? true
                : false
            }
            privatePhotoRequested={
              channel?.user?.interaction?.private_photo_requested
            }
            privatePhotoApproval={
              channel?.user?.interaction?.private_photo_approval
            }
            requestedMyPrivatePhoto={
              channel?.user?.interaction?.requested_my_private_photo
            }
            approvedMyPrivatePhoto={
              channel?.user?.interaction?.approved_my_private_photo
            }
            isOnline={channel?.user?.activity?.is_online}
            isBlocked={channel?.user?.interaction?.is_blocked}
            isSilentInteraction={
              channel?.user?.interaction?.should_silent_interactions
            }
            isFavorited={channel?.user?.interaction?.is_favorited}
            isCallEnabled={
              channel?.user?.private_call_settings?.enable_calls &&
              channel?.user?.private_call_settings?.system_private_calls_enabled
            }
            isSugarbaby={
              channel?.user?.role === userConst.userRole.sugarbaby ||
              channel?.user?.role === userConst.userRole.maleSugarbaby
            }
            role={channel?.user?.role}
            // Livestream
            channelId={channel?.live_stream?.channel_id}
            livestreamerId={channel?.user?.id}
            followerCount={channel?.user?.live_streaming_meta?.follower_count}
            viewerCount={channel?.live_stream?.viewer_count}
            diamondCount={
              channel?.user?.live_streaming_meta?.lifetime_diamonds_earned
            }
            isFollowing={channel?.user?.live_streaming_meta?.is_following}
            // Card Utility
            cardStatus={userCardConst.cardStatus.live}
            // Gaming View
            backgroundImage={channel?.logo}
            key={index}
          />
        ))}
      </div>
    );
  } else if (
    firstLoad &&
    (getLivestreamingChannelsFetching || getLivestreamingChannelsLoading)
  ) {
    liveListView = (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  } else {
    liveListView = <EmptyList />;
  }

  return (
    <div
      id="live-page"
      className="live-page-container"
      ref={scrollContainerRef}
    >
      <div className="max-width-container">
        <PullToRefresh
          className="pull-to-refresh-container"
          onRefresh={pullToRefreshFunc}
          refreshingContent={t("common.release")}
          pullingContent={t("common.pull_to_refresh")}
          isPullable={
            !getLivestreamingChannelsFetching &&
            !getLivestreamingChannelsLoading
          }
        >
          <div className="padding-container">
            <div className="header">{t("live.live")}</div>

            <Banner type={"live"} view={"carousel"} />

            <Banner type={"live-gaming"} view={"carousel"} />

            <div className="livestream-channel-list-container">
              <InfiniteScroll
                pageStart={0}
                loadMore={infiniteScrollFunc}
                hasMore={livestreamChannelPagination ? true : false}
                loader={<Spinner key={0} />}
                useWindow={false}
                getScrollParent={() => document.getElementById("live-page")}
                threshold={10}
              >
                {liveListView}
              </InfiniteScroll>
            </div>
          </div>
        </PullToRefresh>
      </div>
    </div>
  );
};

export default Live;
