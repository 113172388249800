// General
import "./phone-auth.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useLazyGetPhoneValidationQuery,
  useSendPhoneVerificationCodeMutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCountry,
  updatePhone,
  updateSessionToken,
} from "../../../redux/store/signupStore";
import {
  updateSuccessToast,
  updateErrorToast,
} from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Avatar, TextField, MenuItem, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Firebase
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const PhoneAuth = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    getPhoneValidation,
    {
      data: getPhoneValidationData,
      error: getPhoneValidationErrorData,
      isFetching: getPhoneValidationFetching,
      isLoading: getPhoneValidationLoading,
      isSuccess: getPhoneValidationSuccess,
      isError: getPhoneValidationError,
    },
  ] = useLazyGetPhoneValidationQuery();
  const [
    sendPhoneVerificationCode,
    {
      data: sendPhoneVerificationCodeData,
      error: sendPhoneValidationCodeErrorData,
      isLoading: sendPhoneVerificationCodeLoading,
      isSuccess: sendPhoneVerificationCodeSuccess,
      isError: sendPhoneVerificationCodeError,
    },
  ] = useSendPhoneVerificationCodeMutation();

  // General variables
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("000000");

  // Redux variables
  const country = useSelector((state) => state.signup.country);
  const phone = useSelector((state) => state.signup.phone);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PhoneAuth-Page",
      },
    });

    getAio();
  }, []);

  // Lifecycle | Check for update | AIO API Response
  useEffect(() => {
    if (getAioFetching || getAioLoading) {
    } else if (getAioSuccess) {
      if (getAioData?.status === 0) {
        dispatch(updateCountry(getAioData?.payload?.default_country_data));

        // Check if phone auth mode is "firebase"
        if (getAioData?.payload?.phone_auth?.mode === "firebase") {
          initFirebase();
        }
      }
    } else if (getAioError) {
    }
  }, [getAioFetching, getAioLoading, getAioSuccess, getAioError]);

  // Lifecycle | Check for update | Phone Validation API Response
  useEffect(() => {
    if (getPhoneValidationFetching || getPhoneValidationLoading) {
    } else if (getPhoneValidationSuccess) {
      switch (getPhoneValidationData?.status) {
        case -2:
          setPhoneError(true);
          setPhoneErrorMessage(getPhoneValidationData?.message);
          break;
        default:
          break;
      }
    } else if (getPhoneValidationError) {
    }
  }, [
    getPhoneValidationFetching,
    getPhoneValidationLoading,
    getPhoneValidationSuccess,
    getPhoneValidationError,
  ]);

  // Lifecycle | Check for update | Phone Verification API Response
  useEffect(() => {
    if (sendPhoneVerificationCodeLoading) {
    } else if (sendPhoneVerificationCodeSuccess) {
      switch (sendPhoneVerificationCodeData?.status) {
        case 0:
          dispatch(
            updateSessionToken(
              sendPhoneVerificationCodeData?.data?.session_token
            )
          );
          break;
        default:
          break;
      }

      const toastObj = {
        message: t("signup.verification_code_sent"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));

      onNavigate(routeConst.verifyPhone.path);
    } else if (sendPhoneVerificationCodeError) {
      const toastObj = {
        message: sendPhoneValidationCodeErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
    }
  }, [
    sendPhoneVerificationCodeLoading,
    sendPhoneVerificationCodeSuccess,
    sendPhoneVerificationCodeError,
  ]);

  // Event Handlers | Button
  const onNextPage = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PhoneAuth-Page-Save-Button",
      },
    });

    const obj = {
      phone_country_iso_code: country?.country_iso_code,
      phone_number: phone,
      recaptcha_token: recaptchaToken,
    };

    sendPhoneVerificationCode(obj);
  };
  const onNavigateToTerms = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PhoneAuth-Page-Terms-Button",
      },
    });

    onNavigate(routeConst.terms.path);
  };
  const onNavigateToPrivacyPolicy = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PhoneAuth-Page-PrivacyPolicy-Button",
      },
    });

    onNavigate(routeConst.privacy.path);
  };

  // Event Handlers | MUI Text Field
  const onCountryChange = (event) => {
    dispatch(updateCountry(event.target.value));
  };
  const onPhoneChange = (event) => {
    if (isNaN(event.target.value)) return;

    setPhoneError(false);
    setPhoneErrorMessage("");

    dispatch(updatePhone(event.target.value.trim().toLowerCase()));
  };
  const onPhoneFocus = (event) => {
    setPhoneFocus(true);
  };

  // Utility Functions
  const initFirebase = () => {
    var firebaseConfig = {
      apiKey: process.env["REACT_APP_FIREBASE_API_KEY"],
      authDomain: process.env["REACT_APP_FIREBASE_AUTH_DOMAIN"],
      databaseURL: process.env["REACT_APP_FIREBASE_DATABASE_URL"],
      projectId: process.env["REACT_APP_FIREBASE_PROJECT_ID"],
      storageBucket: process.env["REACT_APP_FIREBASE_STORAGE_BUCKET"],
      messagingSenderId: process.env["REACT_APP_FIREBASE_MESSAGING_SENDER_ID"],
      appId: process.env["REACT_APP_FIREBASE_APP_ID"],
      measurementId: process.env["REACT_APP_FIREBASE_MEASUREMENT_ID"],
    };

    let firebaseApp2;
    if (!getApps().find((app) => app.name === "phoneAuthFirebase")) {
      firebaseApp2 = initializeApp(firebaseConfig, "phoneAuthFirebase");
    } else {
      firebaseApp2 = getApp("phoneAuthFirebase");
    }

    let auth2 = getAuth(firebaseApp2);

    window.recaptchaVerifier = new RecaptchaVerifier(
      "googleRecaptchaContainer",
      {
        size: "invisible",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          setRecaptchaToken(response);
          document.getElementById("googleRecaptchaToken").value = response;
        },
      },
      auth2
    );

    window.recaptchaVerifier.render().then(function (widgetId) {
      window.grecaptcha.getResponse(widgetId);
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute();
      });
    });
  };
  const isFormValid = () => {
    let valid = true;
    if (phoneValid) {
      valid = true;
    }

    return valid;
  };
  const phoneValidation = () => {
    let valid = true;
    let error = false;

    if (!phoneFocus) {
      valid = false;
      error = false;
    } else if (phoneFocus && phone === "") {
      setPhoneErrorMessage("Phone cannot be empty");
      valid = false;
      error = true;
    } else {
      setPhoneErrorMessage("");
      valid = true;
      error = false;
    }

    setPhoneValid(valid);
    setPhoneError(error);
  };
  const onValidatePhone = (event) => {
    if (phone?.length > 5) {
      getPhoneValidation(
        `?country_iso_code=${country?.country_iso_code}&phone_number=${phone}`
      );
    }
  };

  // Check for email and password validation
  useEffect(() => {
    // phoneValidation();
  }, [phone, phoneFocus, phoneErrorMessage]);

  if (getAioFetching || getAioLoading) {
    return <Spinner />;
  } else if (getAioSuccess) {
    return (
      <div id="phone-auth-page">
        <div className="max-width-container">
          <div className="header">
            {t("phone_auth.enter_your_mobile_number")}
          </div>

          <div className="phone-container">
            <div className="country-form-field-container">
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "16px" } }}
                className="country-form-field custom-text-field"
                select
                value={country}
                onChange={onCountryChange}
                variant="standard"
                SelectProps={{
                  renderValue: (value) => value?.phone_country_code,
                }}
              >
                {getAioData?.payload?.country_list_with_iso_code?.map(
                  (country, index) => (
                    <MenuItem key={index} value={country}>
                      <div className="select-output">
                        <div className="flag-icon-container">
                          <Avatar
                            className="flag-icon"
                            src={country?.country_flag}
                            alt={country?.country_name}
                          />
                        </div>
                        <div className="country-name">
                          {country?.country_name}
                        </div>
                        <div className="country-name">
                          {country?.phone_country_code}
                        </div>
                      </div>
                    </MenuItem>
                  )
                )}
              </TextField>
            </div>

            <div className="phone-form-field-container">
              <TextField
                className="phone-form-field custom-text-field"
                required
                value={phone}
                error={phoneError}
                onChange={onPhoneChange}
                label={t("login.mobile_number")}
                placeholder={t("login.mobile_number")}
                helperText={phoneErrorMessage}
                variant="standard"
                onFocus={onPhoneFocus}
                onKeyUp={onValidatePhone}
              />
            </div>
          </div>

          <Button
            className={`continue-button ${
              isFormValid() ? "" : "disabled-button"
            }`}
            variant="text"
            onClick={onNextPage}
            disabled={!isFormValid()}
          >
            {sendPhoneVerificationCodeLoading ? (
              <Spinner size={20} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.continue")
            )}
          </Button>

          <div className="terms-and-condition">
            {t("signup.signup_agree")}{" "}
            <span className="terms" onClick={onNavigateToTerms}>
              {t("signup.signup_terms")}
            </span>{" "}
            &{" "}
            <span
              className="privacy-policy"
              onClick={onNavigateToPrivacyPolicy}
            >
              {t("signup.signup_policy")}
            </span>
            . {t("signup.signup_desc")}
          </div>

          <div className="hidden-google-recaptcha-container">
            <input type="hidden" name="" id="googleRecaptchaToken" value="" />
            <div id="googleRecaptchaContainer"></div>
          </div>
        </div>
      </div>
    );
  } else if (getAioError) {
  }
};

export default PhoneAuth;
