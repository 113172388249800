// General
import "./chat-section.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetProfileQuery,
  usePostFollowUserMutation,
  usePostUnfollowUserMutation,
} from "../../../../services/data.service";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLivestreamerIsFollowing } from "../../../../redux/store/livestreamingStore";
// Static Data
import routeConst from "../../../../const/routeConst";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { CircularProgress, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../custom-avatar/custom-avatar";
import CallMinuteElement from "./call-minute-element/call-minute-element";
import ChatElement from "./chat-element/chat-element";
import CoinRequestElement from "./coin-request-element/coin-request-element";
import EmojiElement from "./emoji-element/emoji-element";
import GiftElement from "./gift-element/gift-element";
import GiftRequestElement from "./gift-request-element/gift-request-element";
import TippingCoinElement from "./tipping-coin-element/tipping-coin-element";
import TippingElement from "./tipping-element/tipping-element";
import TippingReceiveElement from "./tipping-receive-element/tipping-receive-element";
import TipRequestElement from "./tip-request-element/tip-request-element";
import TipRequestRejectedElement from "./tip-request-rejected-element/tip-request-rejected-element";
import TipRequestAcceptedElement from "./tip-request-accepted-element/tip-request-accepted-element";
import JoiningElement from "./joining-element/joining-element";
import FollowingElement from "./following-element/following-element";

const ChatSection = (props) => {
  const { state } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    postFollowUser,
    {
      data: postFollowUserData,
      error: postFollowUserErrorData,
      isLoading: postFollowUserLoading,
      isSuccess: postFollowUserSuccess,
      isError: postFollowUserError,
    },
  ] = usePostFollowUserMutation();
  const [
    postUnfollowUser,
    {
      data: postUnfollowUserData,
      error: postUnfollowUserErrorData,
      isLoading: postUnfollowUserLoading,
      isSuccess: postUnfollowUserSuccess,
      isError: postUnfollowUserError,
    },
  ] = usePostUnfollowUserMutation();

  // General variables
  const [isManualScroll, setIsManualScroll] = useState(false);

  // Redux variables
  const livestreamerId = useSelector(
    (state) => state.livestreaming.livestreamerId
  );
  const livestreamerProfilePhoto = useSelector(
    (state) => state.livestreaming.livestreamerProfilePhoto
  );
  const livestreamerUsername = useSelector(
    (state) => state.livestreaming.livestreamerUsername
  );
  const livestreamerIsFollowing = useSelector(
    (state) => state.livestreaming.livestreamerIsFollowing
  );
  const livestreamChatMessages = useSelector(
    (state) => state.livestreaming.livestreamChatMessages
  );
  const videoCallChatMessages = useSelector(
    (state) => state.privateCall.videoCallChatMessages
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 900px)");
  const isTablet = useMediaQuery("(min-width: 1305px)");
  const isMobile = useMediaQuery("(max-width: 900px)");

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  const chatEndRef = useRef(null);

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);

    setTimeout(() => {
      scrollToBottom();
    }, 3000);
  }, []);

  // Lifecycle | Check for update | postFollowUser API Response
  useEffect(() => {
    if (postFollowUserLoading) {
    } else if (postFollowUserSuccess) {
      if (postFollowUserData?.status === 1) {
        dispatch(updateLivestreamerIsFollowing(true));
      }
    } else if (postFollowUserError) {
      if (postFollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postFollowUserLoading, postFollowUserSuccess, postFollowUserError]);

  // Lifecycle | Check for update | postUnfollowUser API Response
  useEffect(() => {
    if (postUnfollowUserLoading) {
    } else if (postUnfollowUserSuccess) {
      if (postUnfollowUserData?.status === 1) {
        dispatch(updateLivestreamerIsFollowing(false));
      }
    } else if (postUnfollowUserError) {
      if (postUnfollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postUnfollowUserLoading, postUnfollowUserSuccess, postUnfollowUserError]);

  // Lifecycle | Check for update | livestreamChatMessages
  useEffect(() => {
    if (isManualScroll) return;

    scrollToBottom();
  }, [livestreamChatMessages]);

  // Lifecycle | Check for update | videoCallChatMessage
  useEffect(() => {
    if (isManualScroll) return;

    scrollToBottom();
  }, [videoCallChatMessages]);

  // Event Handlers | Button
  const onFollowLivestreamer = () => {
    const obj = {
      user_id: livestreamerId,
    };

    if (livestreamerIsFollowing) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Livestreaming-Page-FollowLivestreamer-Button",
        },
      });

      postUnfollowUser(obj);
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Livestreaming-Page-UnfollowLivestreamer-Button",
        },
      });

      postFollowUser(obj);
    }
  };
  const onScrollToBottom = () => {
    setIsManualScroll(false);
    scrollToBottom();
  };

  // Utility Functions
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const getHeight = () => {
    if (state === "private-call") {
      return "private-call-height";
    } else if (state === "livestream") {
      if (isDesktop) {
        return "livestream-height";
      } else if (isTablet) {
        return "livestream-height-tablet";
      } else if (isMobile) {
        return "private-call-height";
      } else {
        return "default-height";
      }
    }
  };
  const getMask = () => {
    if (state === "private-call") {
      return "shade-away";
    } else if (state === "livestream") {
      if (isMobile) {
        return "shade-away";
      } else {
        return "default-height";
      }
    }
  };
  const onScrollChat = (event) => {
    const scrollElement = event.target; // The div that is being scrolled
    const scrollTop = scrollElement?.scrollTop; // Current scroll position from top
    const scrollHeight = scrollElement?.scrollHeight; // Total scrollable height
    const clientHeight = scrollElement?.clientHeight; // Visible height

    if (scrollElement && scrollTop && scrollHeight && clientHeight) {
      if (scrollTop + clientHeight >= scrollHeight - 150) {
        // Bottom of div
        setIsManualScroll(false);
      } else {
        setIsManualScroll(true);
      }
    }
  };

  return (
    <div
      id="interactable-overlay-chat-subcomponent"
      className={`${getHeight()} ${getMask()}`}
    >
      <div className="live-chat-padding-container">
        <div className="live-chat-container" onScroll={onScrollChat}>
          {/* Livestreaming Chat */}
          {state === "livestream" && (
            <div className="livestream-chat-container">
              <div
                className={`follow-label ${
                  state === "livestream" && !isMobile ? "" : "yellow"
                }`}
              >
                {t("pusher.remind_follow", { name: livestreamerUsername })}
              </div>

              <div
                className="follow-button-container"
                onClick={onFollowLivestreamer}
              >
                {postFollowUserLoading || postUnfollowUserLoading ? (
                  <CircularProgress
                    className="following-loading-spinner"
                    size={20}
                  />
                ) : livestreamerIsFollowing ? (
                  <CheckIcon />
                ) : (
                  <AddIcon />
                )}

                <div className="text">
                  {livestreamerIsFollowing
                    ? t("live-follow-button.following")
                    : t("live-follow-button.follow")}
                </div>

                <div className="livestreamer-profile-photo-container">
                  <CustomAvatar
                    className="livestreamer-profile-photo"
                    src={livestreamerProfilePhoto}
                  />
                </div>
              </div>

              {livestreamChatMessages.map((message, index) => {
                switch (message?.type) {
                  case "message.v2":
                    return (
                      <ChatElement
                        state={state}
                        livestreamingAchievementBadge={message?.chat_badges_set}
                        profilePhoto={message?.user_photo}
                        username={message?.username}
                        levellingBadge={message?.levelling_profile?.badge}
                        message={message?.data?.message}
                        key={index}
                      />
                    );
                  case "gifting":
                    return (
                      <GiftElement
                        state={state}
                        livestreamingAchievementBadge={message?.chat_badges_set}
                        profilePhoto={message?.user_photo}
                        username={message?.username}
                        levellingBadge={message?.levelling_profile?.badge}
                        giftName={message?.data?.gift_name}
                        giftImage={message?.data?.gift_graphic}
                        key={index}
                      />
                    );
                  case "joining":
                    return (
                      <JoiningElement
                        state={state}
                        livestreamingAchievementBadge={message?.chat_badges_set}
                        profilePhoto={message?.user_photo}
                        username={message?.username}
                        levellingBadge={message?.levelling_profile?.badge}
                        key={index}
                      />
                    );
                  case "following":
                    return (
                      <FollowingElement
                        state={state}
                        livestreamingAchievementBadge={message?.chat_badges_set}
                        profilePhoto={message?.user_photo}
                        username={message?.username}
                        levellingBadge={message?.levelling_profile?.badge}
                        livestreamerUsername={livestreamerUsername}
                        key={index}
                      />
                    );
                  case "emoji":
                    return (
                      <EmojiElement
                        state={state}
                        livestreamingAchievementBadge={message?.chat_badges_set}
                        profilePhoto={message?.user_photo}
                        username={message?.username}
                        levellingBadge={message?.levelling_profile?.badge}
                        emoji={message?.data?.emoji_unicode}
                        key={index}
                      />
                    );
                  default:
                    break;
                }
              })}
            </div>
          )}

          {/* Private Call Chat */}
          {state === "private-call" && (
            <div className="private-call-chat-container">
              {releaseChannel === utilityConst.releaseChannel.nightly && (
                <div
                  className={`follow-label ${
                    state === "private-call" && !isMobile ? "" : "yellow"
                  }`}
                >
                  {t("pusher.remind_follow", { name: livestreamerUsername })}
                </div>
              )}

              {releaseChannel === utilityConst.releaseChannel.nightly && (
                <div
                  className="follow-button-container"
                  onClick={onFollowLivestreamer}
                >
                  {postFollowUserLoading || postUnfollowUserLoading ? (
                    <CircularProgress
                      className="following-loading-spinner"
                      size={20}
                    />
                  ) : livestreamerIsFollowing ? (
                    <CheckIcon />
                  ) : (
                    <AddIcon />
                  )}

                  <div className="text">
                    {livestreamerIsFollowing
                      ? t("live-follow-button.following")
                      : t("live-follow-button.follow")}
                  </div>

                  <div className="livestreamer-profile-photo-container">
                    <CustomAvatar
                      className="livestreamer-profile-photo"
                      src={livestreamerProfilePhoto}
                    />
                  </div>
                </div>
              )}

              {!isDaddy && (
                <div className="tip-container">
                  <div className="tip-label">{t("1on1.tip_n", { n: 1 })}</div>
                  <div className="tip-description-container">
                    <Trans
                      i18nKey={"1on1.tip_1"}
                      values={{ diamond_count: t("1on1.diamond_count") }}
                      components={{
                        diamondicon: getIcon("sbDiamondIcon", "diamond-icon"),
                        diamond: <span className="highlight-yellow"></span>,
                      }}
                    />
                  </div>
                </div>
              )}

              {videoCallChatMessages.map((message, index) => {
                switch (message?.type) {
                  case "message":
                    return (
                      <ChatElement
                        profilePhoto={message?.user_photo}
                        username={message?.username}
                        levellingBadge={message?.levelling_profile?.badge}
                        message={message?.data?.message}
                        key={index}
                      />
                    );
                  case "call_minutes_earned":
                    if (!isDaddy) {
                      return (
                        <CallMinuteElement
                          diamondsValue={
                            message?.data?.call_request?.rates?.diamonds
                          }
                          key={index}
                        />
                      );
                    }
                    break;
                  case "tipping_coins_sent":
                    return (
                      <TippingCoinElement
                        isDaddy={isDaddy}
                        profilePhoto={
                          getProfileData?.data?.id ===
                          message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.caller?.profile_photo
                                ?.original_photo
                            : message?.data?.call_request?.callee?.profile_photo
                                ?.original_photo
                        }
                        username={
                          getProfileData?.data?.id ===
                          message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.caller?.username
                            : message?.data?.call_request?.callee?.username
                        }
                        levellingBadge={
                          getProfileData?.data?.id ===
                          message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.caller
                                ?.levelling_profile?.badge
                            : message?.data?.call_request?.callee
                                ?.levelling_profile?.badge
                        }
                        diamondsValue={
                          message?.data?.tipping_coins?.diamonds_value
                        }
                        key={index}
                      />
                    );
                    break;
                  case "emoji_animation":
                    return (
                      <EmojiElement
                        profilePhoto={message?.user_photo}
                        username={message?.username}
                        levellingBadge={message?.level_badge}
                        emoji={message?.data?.emoji_unicode}
                        key={index}
                      />
                    );
                  case "gifting":
                    return (
                      <GiftElement
                        state={state}
                        profilePhoto={message?.user_photo}
                        username={message?.username}
                        levellingBadge={message?.levelling_profile?.badge}
                        giftName={message?.data?.gift_name}
                        giftValue={message?.data?.gift_diamonds_value}
                        giftImage={message?.data?.gift_graphic}
                        key={index}
                      />
                    );
                  case "gift_request":
                    return (
                      <GiftRequestElement
                        giftImage={message?.data?.gift_graphic}
                        key={index}
                      />
                    );
                  case "coin_request":
                    return <CoinRequestElement key={index} />;
                  case "tipping_sent":
                    if (isDaddy) {
                      return (
                        <TippingElement
                          profilePhoto={
                            getProfileData?.data?.id ===
                            message?.data?.call_request?.caller_user_id
                              ? message?.data?.call_request?.caller
                                  ?.profile_photo?.original_photo
                              : message?.data?.call_request?.callee
                                  ?.profile_photo?.original_photo
                          }
                          username={
                            getProfileData?.data?.id ===
                            message?.data?.call_request?.caller_user_id
                              ? message?.data?.call_request?.caller?.username
                              : message?.data?.call_request?.callee?.username
                          }
                          levellingBadge={
                            getProfileData?.data?.id ===
                            message?.data?.call_request?.caller_user_id
                              ? message?.data?.call_request?.caller
                                  ?.levelling_profile?.badge
                              : message?.data?.call_request?.callee
                                  ?.levelling_profile?.badge
                          }
                          tipValue={message?.data?.tipping?.tipping_item?.value}
                          tipAction={
                            message?.data?.tipping?.tipping_item?.description
                          }
                          key={index}
                        />
                      );
                    } else {
                      return (
                        <TippingReceiveElement
                          diamondsValue={
                            message?.data?.tipping?.tipping_item?.diamonds_value
                          }
                          tipAction={
                            message?.data?.tipping?.tipping_item?.description
                          }
                          key={index}
                        />
                      );
                    }
                    break;
                  case "tipping_request_sent":
                    return (
                      <TipRequestElement
                        profilePhoto={
                          message?.data?.call_request?.paying_user_id ===
                          message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.caller?.profile_photo
                                ?.original_photo
                            : message?.data?.call_request?.callee?.profile_photo
                                ?.original_photo
                        }
                        username={
                          message?.data?.call_request?.paying_user_id ===
                          message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.caller?.username
                            : message?.data?.call_request?.callee?.username
                        }
                        levellingBadge={
                          message?.data?.call_request?.paying_user_id ===
                          message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.caller
                                ?.levelling_profile?.badge
                            : message?.data?.call_request?.callee
                                ?.levelling_profile?.badge
                        }
                        tipAction={message?.data?.tipping_request.description}
                        tipValue={message?.data?.tipping_request?.value}
                        role={getProfileData?.data?.role}
                        key={index}
                      />
                    );
                  case "tipping_request_rejected":
                    return (
                      <TipRequestRejectedElement
                        username={
                          isDaddy
                            ? getProfileData?.data?.id ===
                              message?.data?.call_request?.caller_user_id
                              ? message?.data?.call_request?.callee?.username
                              : message?.data?.call_request?.caller?.username
                            : getProfileData?.data?.id ===
                              message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.caller?.username
                            : message?.data?.call_request?.callee?.username
                        }
                        levellingBadge={
                          getProfileData?.data?.id ===
                          message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.caller
                                ?.levelling_profile?.badge
                            : message?.data?.call_request?.callee
                                ?.levelling_profile?.badge
                        }
                        role={getProfileData?.data?.role}
                        key={index}
                      />
                    );
                  case "tipping_request_accepted":
                    return (
                      <TipRequestAcceptedElement
                        profilePhoto={
                          message?.data?.call_request?.paying_user_id ===
                          message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.callee?.profile_photo
                                ?.original_photo
                            : message?.data?.call_request?.caller?.profile_photo
                                ?.original_photo
                        }
                        username={
                          message?.data?.call_request?.paying_user_id ===
                          message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.callee?.username
                            : message?.data?.call_request?.caller?.username
                        }
                        levellingBadge={
                          message?.data?.call_request?.paying_user_id ===
                          message?.data?.call_request?.caller_user_id
                            ? message?.data?.call_request?.callee
                                ?.levelling_profile?.badge
                            : message?.data?.call_request?.caller
                                ?.levelling_profile?.badge
                        }
                        role={getProfileData?.data?.role}
                        key={index}
                      />
                    );
                  default:
                    break;
                }
              })}
            </div>
          )}

          <div className="scroll-to-bottom" ref={chatEndRef} />
        </div>

        {isManualScroll && (
          <div className="new-chat-button" onClick={onScrollToBottom}>
            <div className="new-chat-label">{t("live.new_chat")}</div>
            <div className="chev-down-icon-container">
              <KeyboardArrowDownIcon className="chev-down-icon" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatSection;
