// General
import "./college-view.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetDatingCollegeQuery } from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import searchConst from "../../../../../const/searchConst";
import userCardConst from "../../../../../const/userCardConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCollegeLists,
  resetCollegeLists,

  // Pagination Functions
  updateCollegePagination,
} from "../../../../../redux/store/searchStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// Material UI
import { Divider, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import useGetUserProfileStatus from "../../../../utility/custom-hooks/useGetUserProfileStatus-hook";
// Components
import UserCardManager from "../../../../utility/manager/user-card-manager/user-card-manager";
import EmptyList from "../empty-list/empty-list";
import SkeletonUserCardList from "../../../../shared/skeleton-user-card-list/skeleton-user-card-list";
import Spinner from "../../../../shared/elements/spinner/spinner";
import ResultEndCard from "../../../../shared/elements/result-end-card/result-end-card";

const CollegeView = () => {
  // API variables
  const [
    getDatingCollege,
    {
      data: getDatingCollegeData,
      error: getDatingCollegeErrorData,
      isFetching: getDatingCollegeFetching,
      isLoading: getDatingCollegeLoading,
      isSuccess: getDatingCollegeSuccess,
      isError: getDatingCollegeError,
    },
  ] = useLazyGetDatingCollegeQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(false);

  // Render variables
  let collegeListView = <div></div>;

  // Redux variables
  const selectedCategoryTab = useSelector(
    (state) => state.search.selectedCategoryTab
  );
  const selectedView = useSelector((state) => state.search.selectedView);
  const collegeLists = useSelector((state) => state.search.collegeLists);
  const collegePagination = useSelector(
    (state) => state.search.collegePagination
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Param variables
  const body = {
    size: 16,
    location: {},
    sorting: "college",
    filters: {},
  };

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getProfileStatus = useGetUserProfileStatus();

  // Lifecycle | Check for update | Dating College API Response
  useEffect(() => {
    if (getDatingCollegeFetching || getDatingCollegeLoading) {
    } else if (getDatingCollegeSuccess) {
      dispatch(
        updateCollegePagination(
          getDatingCollegeData?.data?.pagination?.next_cursor
        )
      );

      switch (getDatingCollegeData?.status) {
        case 0:
        case 1:
        case -1:
          if (getDatingCollegeData?.data?.results?.length > 0) {
            setFirstLoad(false);

            // Use to block infiniteScrollFunc from running into infinite loop
            setIsApiLoading(false);

            dispatch(updateCollegeLists(getDatingCollegeData?.data?.results));
          }
          break;
        default:
          break;
      }
    } else if (getDatingCollegeError) {
      if (getDatingCollegeErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getDatingCollegeFetching,
    getDatingCollegeLoading,
    getDatingCollegeSuccess,
    getDatingCollegeError,
  ]);

  // Lifecycle | Check for update | Selected Category Tab
  useEffect(() => {
    if (selectedCategoryTab === "college") {
      if (collegeLists.length <= 0) {
        if (collegePagination) {
          setFirstLoad(false);
        } else {
          setFirstLoad(true);
        }

        getDatingCollege(
          {
            param: collegePagination
              ? `?pagination_token=${collegePagination}`
              : null,
            body,
          },
          false
        );
      } else {
        setFirstLoad(false);
      }
    }
  }, [selectedCategoryTab]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!isApiLoading && collegePagination) {
      setIsApiLoading(true);
      getDatingCollege(
        {
          param: collegePagination
            ? `?pagination_token=${collegePagination}`
            : null,
          body,
        },
        false
      );
    }
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetCollegeLists());
    dispatch(updateCollegePagination(null));
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(getDatingCollege({ param: null, body }));
    });
  };

  // Render Functions | Dating College List Data
  if (selectedCategoryTab === "college") {
    if (collegeLists?.length > 0) {
      collegeListView = (
        <div className={`infinite-scroller-container ${selectedView}`}>
          {collegeLists?.map((user, index) => (
            <div className="list-container" key={index}>
              <UserCardManager
                // Config
                type={user?.type}
                // User View
                cardView={selectedView}
                id={user?.user?.id}
                idInt={user?.user?.id_int}
                username={user?.user?.username}
                age={user?.user?.appearance?.age}
                location={
                  user?.user?.location?.display_address ||
                  user?.user?.location?.city ||
                  user?.user?.location?.state
                }
                membershipType={user?.user?.membership_type}
                verifiedProfile={user?.user?.is_verified_profile}
                verifiedCaller={user?.user?.verified_caller}
                levellingBadge={user?.user?.levelling_profile?.badge}
                livestreamingAchievementBadge={
                  user?.user?.live_streaming_achievements?.chat_badges_set
                }
                profilePhoto={user?.user?.profile_photo?.original_photo}
                publicPhotos={user?.user?.public_photos?.map((photo) => {
                  return {
                    photoUrl: photo?.original_photo,
                  };
                })}
                privatePhotos={user?.user?.private_photos?.map((photo) => {
                  return {
                    photoUrl: photo?.original_photo,
                    thumbnailData: photo?.thumbnail_data,
                  };
                })}
                torTags={user?.user?.tor_tags}
                profileAchievementBadge={
                  user?.user?.live_streaming_achievements?.profile_badges
                }
                aboutMe={user?.user?.aboutme}
                lookingFor={user?.user?.lookingfor}
                tagline={user?.user?.tagline}
                annualIncome={user?.user?.occupation?.annual_income}
                networth={user?.user?.occupation?.net_worth}
                occupation={user?.user?.occupation?.occupation}
                lifestyle={user?.user?.occupation?.lifestyle_budget}
                education={user?.user?.occupation?.education}
                relationship={user?.user?.relationship?.relationship_status}
                smoking={user?.user?.habit?.smoking}
                drinking={user?.user?.habit?.drinking}
                height={user?.user?.appearance?.height}
                bodyType={user?.user?.appearance?.body_type}
                ethnicity={user?.user?.appearance?.ethnicity}
                eyeColor={user?.user?.appearance?.eye_color}
                hairColor={user?.user?.appearance?.hair_color}
                children={user?.user?.relationship?.children}
                // User Utility
                profileStatus={getProfileStatus(user?.user)}
                privatePhotoAbleToRequest={
                  user?.user?.interaction?.private_photo_requested === false
                    ? true
                    : false
                }
                privatePhotoRequested={
                  user?.user?.interaction?.private_photo_requested
                }
                privatePhotoApproval={
                  user?.user?.interaction?.private_photo_approval
                }
                requestedMyPrivatePhoto={
                  user?.user?.interaction?.requested_my_private_photo
                }
                approvedMyPrivatePhoto={
                  user?.user?.interaction?.approved_my_private_photo
                }
                isOnline={user?.user?.activity?.is_online}
                isBlocked={user?.user?.interaction?.is_blocked}
                isSilentInteraction={
                  user?.user?.interaction?.should_silent_interactions
                }
                isFavorited={user?.user?.interaction?.is_favorited}
                isCallEnabled={
                  user?.user?.private_call_settings?.enable_calls &&
                  user?.user?.private_call_settings
                    ?.system_private_calls_enabled
                }
                isSugarbaby={isDaddy}
                role={user?.user?.role}
                // Livestream
                channelId={user?.user?.live_stream?.channel_id}
                livestreamerId={user?.user?.id}
                followerCount={user?.user?.live_streaming_meta?.follower_count}
                viewerCount={user?.user?.live_stream?.viewer_count}
                diamondCount={
                  user?.user?.live_streaming_meta?.lifetime_diamonds_earned
                }
                isFollowing={user?.user?.live_streaming_meta?.is_following}
                // Card Utility
                cardStatus={userCardConst.cardStatus.default}
                // Gaming View
                backgroundImage={user?.logo}
              />

              {selectedView === searchConst.userCardDisplay.list && <Divider />}
            </div>
          ))}
        </div>
      );
    } else if (
      firstLoad &&
      (getDatingCollegeFetching || getDatingCollegeLoading)
    ) {
      collegeListView = (
        <SkeletonUserCardList numberOfCards={4} selectedView={selectedView} />
      );
    } else {
      collegeListView = <EmptyList />;
    }

    return (
      <div
        id="search-card-container-college-view-subcomponent"
        className={collegeLists?.length <= 0 ? "full-height" : ""}
      >
        <PullToRefresh
          className="pull-to-refresh-container"
          onRefresh={pullToRefreshFunc}
          refreshingContent={t("common.release")}
          pullingContent={t("common.pull_to_refresh")}
          isPullable={!getDatingCollegeFetching && !getDatingCollegeLoading}
        >
          <div className="college-list-container">
            <InfiniteScroll
              pageStart={0}
              loadMore={infiniteScrollFunc}
              hasMore={collegePagination ? true : false}
              loader={<Spinner key={0} />}
              useWindow={false}
              getScrollParent={() => document.getElementById("search-page")}
              threshold={isMobile ? 10 : 50}
            >
              {collegeListView}

              {collegeLists?.length > 0 && !collegePagination && (
                <ResultEndCard />
              )}
            </InfiniteScroll>
          </div>
        </PullToRefresh>
      </div>
    );
  }
};

export default CollegeView;
