// General
import "./callee-camera-feed-overlay.scss";
import { useState, useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  updateNetworkQuality,
  updateUplinkQualiy,
  updateDownlinkQuality,
  updateShowVideoCallStreamInitializingOverlay,

  // Utility Functions
  updateToggleRemoteCameraPassthrough,
} from "../../../../../redux/store/privateCallStore";
// Material UI
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
// Custom Hooks
import useEndPrivateCall from "../../../../utility/custom-hooks/useEndPrivateCall-hook";

const CalleeCameraFeed = (props) => {
  const { trtcClient } = props;

  // General variables
  const [remoteStream, setRemoteStream] = useState(null);

  // Redux variables
  const callStatus = useSelector((state) => state.privateCall.callStatus);
  const swapVideoViewPassthrough = useSelector(
    (state) => state.privateCall.swapVideoViewPassthrough
  );
  const toggleCameraPassthrough = useSelector(
    (state) => state.privateCall.toggleCameraPassthrough
  );
  const toggleRemoteCameraPassthrough = useSelector(
    (state) => state.privateCall.toggleRemoteCameraPassthrough
  );
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onEndCall = useEndPrivateCall();

  // Lifecycle | Check for update | trtcClient
  useEffect(() => {
    if (!trtcClient) return;

    trtcEventsListener();
  }, [trtcClient]);

  // Lifecycle | Check for update | remoteStream
  useEffect(() => {
    if (!remoteStream) return;

    switch (swapVideoViewPassthrough) {
      case "mini":
        remoteStream.play("callee-video");
        break;
      case "full":
        remoteStream.play("caller-video");
        break;
      default:
        remoteStream.play("callee-video");
        break;
    }
  }, [remoteStream]);

  // Lifecycle | Check for update | swapVideoViewPassthrough
  useEffect(() => {
    if (!swapVideoViewPassthrough || !remoteStream) return;

    switch (swapVideoViewPassthrough) {
      case "mini":
        remoteStream.play("callee-video");
        break;
      case "full":
        remoteStream.play("caller-video");
        break;
      default:
        remoteStream.play("callee-video");
        break;
    }
  }, [swapVideoViewPassthrough]);

  // TRTC Functions
  const trtcEventsListener = () => {
    trtcClient.on("error", (err) => {
      console.log(err);
    });

    trtcClient.on("client-banned", (err) => {
      console.log("TRTC Event Listener: CLIENT-BANNED: ", err);
    });

    // trtcClient.on("peer-join", (event) => {
    //   console.log("TRTC Event Listener: PEER-JOIN: ", event);
    // });

    trtcClient.on("peer-leave", async (event) => {
      console.log("TRTC Event Listener: PEER-LEAVE: ", event);
    });

    trtcClient.on("stream-added", (event) => {
      console.log("TRTC Event Listener: STREAM-ADDED: ", event);

      trtcClient.subscribe(event?.stream).catch((err) => {
        console.log("Subscribe stream failed: ", err);
      });
    });

    trtcClient.on("stream-subscribed", (event) => {
      console.log("TRTC Event Listener: STREAM-SUBSCRIBED: ", event);

      dispatch(updateShowVideoCallStreamInitializingOverlay(false));
      setRemoteStream(event?.stream);
    });

    trtcClient.on("stream-removed", (event) => {
      console.log("TRTC Event Listener: STREAM-REMOVED: ", event);

      dispatch(updateShowVideoCallStreamInitializingOverlay(false));
      // onEndCall(); // Not sure with conflict with privateStandbyMode
    });

    trtcClient.on("stream-updated", (event) => {
      console.log("TRTC Event Listener: STREAM-UPDATED: ", event);

      // Handle Stream Updated
    });

    trtcClient.on("network-quality", (event) => {
      dispatch(updateNetworkQuality(event?.uplinkNetworkQuality));
      dispatch(updateUplinkQualiy(event?.uplinkRTT));
      dispatch(updateDownlinkQuality(event?.downlinkRTT));

      // console.log(
      //   "TRTC",
      //   "NETWORK QUALITY",
      //   "\n",
      //   `upload: ${event.uplinkNetworkQuality}, download: ${event.downlinkNetworkQuality}`,
      //   "\n",
      //   `upload ping: ${event.uplinkRTT}, upload loss: ${event.uplinkLoss}`,
      //   "\n",
      //   `download ping: ${event.downlinkRTT}, download loss: ${event.downlinkLoss}`
      // );
    });

    trtcClient.on("mute-video", (event) => {
      console.log("TRTC Event Listener: MUTE-VIDEO: ", event);

      dispatch(updateToggleRemoteCameraPassthrough(false));
    });

    trtcClient.on("unmute-video", (event) => {
      console.log("TRTC Event Listener: UNMUTE-VIDEO: ", event);

      dispatch(updateToggleRemoteCameraPassthrough(true));
    });
  };

  return (
    <div id="video-call-room-callee-camera-feed-overlay-subcomponent">
      <div id="callee-video"></div>
      {((callStatus === "JOIN" &&
        (!swapVideoViewPassthrough || swapVideoViewPassthrough === "mini") &&
        !toggleRemoteCameraPassthrough) ||
        (swapVideoViewPassthrough === "full" && toggleCameraPassthrough)) && (
        <div className="camera-disabled-container">
          <VideocamOffIcon className="camera-disabled-icon" />
        </div>
      )}
    </div>
  );
};

export default CalleeCameraFeed;
