// General
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  usePurchaseProductMutation,
  usePostPaymentTagMutation,
} from "../../../services/data.service";
// Static Data
import paymentConst from "../../../const/paymentConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePaymentErrorInfo,
  updatePaymentState,
} from "../../../redux/store/paymentStore";
import { updateVideoCallPrejoinPaymentPassthrough } from "../../../redux/store/privateCallStore";
import {
  updatePaymentProcessDialog,
  updatePaymentErrorDialog,
  updateBuyCoinsDialog,
  updateThankYouForPurchaseDialog,
  updateCoinsPackageDialog,
} from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";

// TODO: Redo this hook
// Should not have any API calling in hook, making things complex
const useSecurionPaymentFlow = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    purchaseProduct,
    {
      data: purchaseProductData,
      error: purchaseProductErrorData,
      isLoading: purchaseProductLoading,
      isSuccess: purchaseProductSuccess,
      isError: purchaseProductError,
    },
  ] = usePurchaseProductMutation();
  const [
    postPaymentTag,
    {
      data: postPaymentTagData,
      error: postPaymentTagErrorData,
      isLoading: postPaymentTagLoading,
      isSuccess: postPaymentTagSuccess,
      isError: postPaymentTagError,
    },
  ] = usePostPaymentTagMutation();

  // Redux variables
  const cardDetail = useSelector((state) => state.payment.cardDetail);
  const securionPayTokenDetails = useSelector(
    (state) => state.payment.securionPayTokenDetails
  );
  const selectedCoinPackage = useSelector(
    (state) => state.products.selectedCoinPackage
  );
  const buyCoinsDialog = useSelector((state) => state.dialog.buyCoinsDialog);
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!buyCoinsDialog) return;

    getProfile(null, true);
  }, [buyCoinsDialog]);

  // Lifecycle | Check for update | Purchase Product API Response
  useEffect(() => {
    if (purchaseProductLoading) {
    } else if (purchaseProductSuccess) {
      // Close loading dialog
      dispatch(updatePaymentProcessDialog(false));

      switch (purchaseProductData?.status) {
        case 0:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-QuickPurchaseCoinsPaymentState-success",
              category: "Purchase",
              action: "Quick Purchase Coins Actions",
              label: "Quick Purchase Coins Payment State - success",
              value: {},
              currency: purchaseProductData?.data?.paid_currency,
              product_name: purchaseProductData?.data?.product_description,
              user_id: getProfileData?.data?.id_int,
              username: getProfileData?.data?.username,
              amount: purchaseProductData?.data?.paid_amount,
            },
          });

          // Close buy coin dialog
          dispatch(updateBuyCoinsDialog(false));
          // Close coins package dialog
          dispatch(updateCoinsPackageDialog(false));
          // Open thank you dialog
          dispatch(updateThankYouForPurchaseDialog(true));

          // Notify Pre Join API to refetch data
          dispatch(updateVideoCallPrejoinPaymentPassthrough({}));

          const obj = {
            payment_tag: "pwa-coin-wallet",
            trans_id: purchaseProductData?.data?.order_id,
          };
          postPaymentTag(obj);
          break;
        case -1:
          const errorObj = {
            title: purchaseProductData?.data?.default_error_message?.title,
            message: purchaseProductData?.message,
            subtitle:
              purchaseProductData?.data?.default_error_message?.sub_title,
            solutions:
              purchaseProductData?.data?.default_error_message?.solutions,
            cta: purchaseProductData?.data?.default_error_message?.cta,
          };
          dispatch(updatePaymentErrorInfo(errorObj));

          // Open payment error dialog
          dispatch(updatePaymentErrorDialog(true));
          break;
        default:
          break;
      }

      if (purchaseProductData?.status === 0) {
        // Close buy coin dialog
        dispatch(updateBuyCoinsDialog(false));
        // Close coins package dialog
        dispatch(updateCoinsPackageDialog(false));
        // Open thank you dialog
        dispatch(updateThankYouForPurchaseDialog(true));

        // Notify Pre Join API to refetch data
        dispatch(updateVideoCallPrejoinPaymentPassthrough({}));
        const obj = {
          payment_tag: "pwa-coin-wallet",
          trans_id: purchaseProductData?.data?.order_id,
        };
        postPaymentTag(obj);
      } else if (purchaseProductData?.status === -1) {
      }
    } else if (purchaseProductError) {
    }
  }, [purchaseProductLoading, purchaseProductSuccess, purchaseProductError]);

  // Main Function
  const securionPaymentFlow = () => {
    submitPayment();
  };

  // Part 1 | Attempt 3DS with card_id instead of token_id
  const submitPayment = () => {
    // Open loading dialog
    dispatch(updatePaymentProcessDialog(true));

    // Update Payment State to Store
    const paymentObj = {
      state: paymentConst.paymentState.pendingSecure3d,
      token: securionPayTokenDetails.id,
    };
    dispatch(updatePaymentState(paymentObj));

    const param3Ds = {
      amount:
        selectedCoinPackage?.discounted_amount
          ?.toFixed(2)
          .toString()
          .replace(/\./g, "") ??
        selectedCoinPackage?.amount?.toFixed(2).toString().replace(/\./g, ""),
      currency: selectedCoinPackage?.currency,
      card: cardDetail[0].card_id,
    };

    // Open frame with 3D Secure process
    Securionpay.setPublicKey(process.env["REACT_APP_SP_PUBLIC_KEY"]);
    Securionpay.verifyThreeDSecure(param3Ds, verifyThreeDSecureCallback);
  };

  // Part 2 | Attempt 3DSecure when SecurionPay says good.
  const verifyThreeDSecureCallback = (token) => {
    if (token?.error) {
      // Close loading dialog
      dispatch(updatePaymentProcessDialog(false));

      // display error message
      // re-enable form submit button

      // Update Payment State to Store
      const paymentObj = {
        attempt: false,
        state: paymentConst.paymentState.decline,
        resultMessage: token.error.message,
        token: null,
      };
      dispatch(updatePaymentState(paymentObj));
    } else {
      // re-enable form submit button

      // Update Payment State to Store
      const paymentObj = {
        state: paymentConst.paymentState.pendingBackendSubmission,
        token: token.id,
      };
      dispatch(updatePaymentState(paymentObj));

      const purchaseObj = {
        payment_gateway: "securionpay",
        payment_token: token.id,
        product_hash_id: selectedCoinPackage?.product_hash_id,
      };
      purchaseProduct(purchaseObj);
    }
  };

  return securionPaymentFlow;
};

export default useSecurionPaymentFlow;
