// General
import "./terms-of-relationship.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Redux
import { useDispatch } from "react-redux";
import { updateUpdateTorDialog } from "../../../../../redux/store/dialogStore";
// i18next
import { useTranslation } from "react-i18next";
// Material UI
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import TorTag from "../../../../shared/elements/tor-tag/tor-tag";

const TermsOfRelationship = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | Button
  const onSelectTor = () => {
    dispatch(updateUpdateTorDialog(true));
  };

  if (getProfileFetching || getProfileLoading) {
    return <Spinner />;
  } else if (getProfileSuccess) {
    return (
      <div id="edit-profile-terms-of-relationship-subcomponent">
        <div className="tor-label">{t("profile.terms_of_relationship")}</div>

        <div className="tor-list-container" onClick={onSelectTor}>
          {getProfileData?.data?.tor_tags ? (
            getProfileData?.data?.tor_tags?.map((tor, index) => (
              <TorTag
                torName={tor?.locale}
                description={tor?.description}
                blur={false}
                interactable={false}
                readOnly={true}
                theme={tor?.theme}
                key={index}
              />
            ))
          ) : (
            <div className="to-be-completed-text">
              {t("profile.to_be_completed")}
            </div>
          )}

          {getProfileData?.data?.tor_tags?.length >= 5 ? (
            <div className="tor-edit">
              <EditIcon className="edit-icon" />
            </div>
          ) : (
            <div className="tor-add">
              <AddIcon className="add-icon" />
            </div>
          )}
        </div>
      </div>
    );
  } else if (getProfileError) {
  }
};

export default TermsOfRelationship;
