// General
import "./account-settings.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const AccountSettings = () => {
  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Event Handlers | Button
  const onRedirect = (route) => {
    let routeName = "";

    switch (route) {
      case routeConst.settings.updatePassword.path:
        routeName = "UpdatePassword";
        break;
      case routeConst.settings.updateEmail.path:
        routeName = "UpdateEmail";
        break;
      case routeConst.settings.blockedMembers.path:
        routeName = "BlockedMembers";
        break;
      case routeConst.settings.photoPermission.path:
        routeName = "PhotoPermissions";
        break;
      case routeConst.settings.deactivateAccount.path:
        routeName = "DeactivateAccount";
        break;
      default:
        break;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-Settings-Page-${routeName}-Button`,
      },
    });

    onNavigate(route);
  };

  return (
    <div id="settings-account-settings-subcomponent">
      <div className="padding-container">
        <div className="account-label">{t("settings.account")}</div>

        <div className="buttons-list-container">
          <div
            className="button-container"
            onClick={() => onRedirect(routeConst.settings.updatePassword.path)}
          >
            <div className="button-label">{t("settings.update_password")}</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>

          <div
            className="button-container"
            onClick={() => onRedirect(routeConst.settings.updateEmail.path)}
          >
            <div className="button-label">{t("settings.update_email")}</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>

          <div
            className="button-container"
            onClick={() => onRedirect(routeConst.settings.blockedMembers.path)}
          >
            <div className="button-label">{t("settings.blocked_members")}</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>

          <div
            className="button-container"
            onClick={() => onRedirect(routeConst.settings.photoPermission.path)}
          >
            <div className="button-label">
              {t("settings.photo_permissions")}
            </div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>

          <div
            className="button-container"
            onClick={() =>
              onRedirect(routeConst.settings.deactivateAccount.path)
            }
          >
            <div className="button-label">
              {t("settings.deactivate_account")}
            </div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
