// General
import "./coins-list.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProductCoinsQuery,
  useLazyGetCardDetailsQuery,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCardDetail } from "../../../redux/store/paymentStore";
import { updateSelectedCoinPackage } from "../../../redux/store/productsStore";
import {
  updateAddPaymentMethodDialog,
  updateBuyCoinsDialog,
} from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../elements/spinner/spinner";
import BuyCoinDialog from "../dialog-content/buy-coin-dialog/buy-coin-dialog";

const CoinsList = () => {
  // API variables
  const [
    getProductCoins,
    {
      data: getProductCoinsData,
      error: getProductCoinsErrorData,
      isFetching: getProductCoinsFetching,
      isLoading: getProductCoinsLoading,
      isSuccess: getProductCoinsSuccess,
      isError: getProductCoinsError,
    },
  ] = useLazyGetProductCoinsQuery();
  const [
    getCardDetails,
    {
      data: getCardDetailsData,
      error: getCardDetailsErrorData,
      isFetching: getCardDetailsFetching,
      isLoading: getCardDetailsLoading,
      isSuccess: getCardDetailsSuccess,
      isError: getCardDetailsError,
    },
  ] = useLazyGetCardDetailsQuery();

  // Render variables
  let coinsListView;

  // Redux variables
  const [selectedCoin, setSelectedCoin] = useState(null);
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const cardDetail = useSelector((state) => state.payment.cardDetail);
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getProductCoins(null, true);
    getCardDetails(null, true);
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Card Details API Response
  useEffect(() => {
    if (getCardDetailsFetching || getCardDetailsLoading) {
    } else if (getCardDetailsSuccess) {
      if (getCardDetailsData?.status === 0) {
        dispatch(updateCardDetail(getCardDetailsData?.data));
      }
    } else if (getCardDetailsError) {
    }
  }, [
    getCardDetailsFetching,
    getCardDetailsLoading,
    getCardDetailsSuccess,
    getCardDetailsError,
  ]);

  // Lifecycle | Check for update | Product Coins API Response
  useEffect(() => {
    if (getProductCoinsFetching || getProductCoinsLoading) {
    } else if (getProductCoinsSuccess) {
      if (getProductCoinsData?.status === 0) {
        for (let i = 0; i < getProductCoinsData?.data?.length; i++) {
          if (getProductCoinsData?.data[i]?.is_selected) {
            setSelectedCoin(getProductCoinsData?.data[i]?.product_hash_id);
            break;
          }
        }
      }
    } else if (getProductCoinsError) {
    }
  }, [
    getProductCoinsFetching,
    getProductCoinsLoading,
    getProductCoinsSuccess,
    getProductCoinsError,
  ]);

  // Event Handlers | Button
  const onSelectCoinPackage = (product) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Coins-Page-SelectCoinPackage-Button",
      },
    });

    setSelectedCoin(product?.product_hash_id);

    dispatch(updateSelectedCoinPackage(product));

    if (cardDetail?.length > 0) {
      onOpenDialog();
    } else {
      // Route based
      // onNavigate(routeConst.payment.add.path);

      // Dialog based
      dispatch(updateAddPaymentMethodDialog(true));
    }
  };

  // Event Handlers | MUI Dialog
  const onOpenDialog = () => {
    dispatch(updateBuyCoinsDialog(true));
  };

  // Utility Functions
  const getBottomTextBackgroundColor = (colorCode) => {
    switch (colorCode) {
      case "orange-to-red_gradient":
        return "custom-orange-to-red-gradient";
      case "green_gradient":
        return "custom-green-gradient";
      case "pink_gradient":
        return "custom-pink-gradient";
      case "purple-gradient":
        return "custom-purple-gradient";
      case "blue-gradient":
        return "custom-blue-gradient";
      case "yellow-gradient":
        return "custom-yellow-gradient";
      default:
        return "custom-orange-to-red-gradient";
    }
  };

  // Render Functions | API Response
  if (
    getProductCoinsFetching ||
    getProductCoinsLoading ||
    getCardDetailsFetching ||
    getCardDetailsLoading
  ) {
    return <Spinner />;
  } else if (getProductCoinsSuccess && getCardDetailsSuccess) {
    if (getProductCoinsData?.status === 0) {
      if (getProductCoinsData?.data?.length > 0) {
        coinsListView = getProductCoinsData?.data?.map((coin, index) => {
          return (
            <div
              className={`coins-container ${
                selectedCoin === coin?.product_hash_id ? "selected-coin" : ""
              }`}
              style={{
                background:
                  selectedCoin === coin?.product_hash_id
                    ? coin?.params?.main_selected_background_color
                    : coin?.params?.main_background_color,
                borderColor:
                  selectedCoin === coin?.product_hash_id
                    ? coin?.params?.main_selected_border_color
                    : coin?.params?.main_border_color,
              }}
              onClick={() => onSelectCoinPackage(coin)}
              key={index}
            >
              <div className="first-row">
                <div className="limited-time-badge">
                  <img
                    className="limited-time-image"
                    src={coin?.params?.top_left_sticker}
                  />
                </div>

                <div className="savings-container">
                  {coin?.params?.coin_extra_amount && (
                    <div
                      className="note-1"
                      style={{
                        background: coin?.params?.coin_extra_background_color,
                        color: coin?.params?.coin_extra_text_color,
                      }}
                    >
                      {coin?.note_1}
                    </div>
                  )}

                  {coin?.discount_text && (
                    <div
                      className="discount-text"
                      style={{
                        background:
                          coin?.params?.discount_text_background_color,
                        color: coin?.params?.discount_text_color,
                      }}
                    >
                      {coin?.discount_text}
                    </div>
                  )}
                </div>
              </div>

              <div className="second-row">
                <div className="coin-image-container">
                  {getIcon("sbCoinIcon", "coin-image")}
                </div>

                <div
                  className="quantity-label"
                  style={{ color: coin?.params?.quantity_text_color }}
                >
                  {coin?.quantity?.toLocaleString()}
                </div>
              </div>

              <div className="third-row">
                <div
                  className={`price-label ${
                    coin?.discounted_amount ? "discounted-price-label" : ""
                  }`}
                  style={{
                    color:
                      coin?.params?.display_currency_text_color ??
                      coin?.params?.amount_text_color,
                  }}
                >
                  {coin?.display_currency}{" "}
                  {coin?.discounted_amount?.toLocaleString() ??
                    coin?.amount?.toLocaleString()}
                </div>
              </div>

              <div className="fourth-row">
                {coin?.discounted_amount && (
                  <div
                    className="original-price-label"
                    style={{ color: coin?.params?.amount_text_color }}
                  >
                    {coin?.display_currency} {coin?.amount?.toLocaleString()}
                  </div>
                )}
              </div>

              {coin?.params?.bottom_floating_text && (
                <div
                  className={`bottom-floating-text ${getBottomTextBackgroundColor(
                    coin?.params?.bottom_floating_background_color
                  )}`}
                  style={{
                    color: coin?.params?.bottom_floating_text_color,
                  }}
                >
                  {coin?.params?.bottom_floating_text}
                </div>
              )}
            </div>
          );
        });
      }
    }
  } else if (getProductCoinsError || getCardDetailsError) {
    if (
      getProductCoinsErrorData?.status === 401 ||
      getCardDetailsErrorData?.status === 401
    ) {
      onNavigate(routeConst.logout.path);
    }
  }

  return (
    <div id="coins-list-shared-subcomponent">
      {coinsListView}

      <BuyCoinDialog />
    </div>
  );
};

export default CoinsList;
