// General
import "./basic-information.scss";
import { useState, useEffect } from "react";
// Services
import { removeHTMLEscape } from "../../../../../services/regex.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUsername,
  updateBirthday,
  updateTagline,
} from "../../../../../redux/store/userStore";
import { updateProfileRejectedInfoDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// react-date-picker
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// Material UI
import { TextField } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";

const BasicInformation = () => {
  // General variables
  const [isLoaded, setIsLoaded] = useState(false);
  const [minDate, setMinDate] = useState(moment().subtract(70, "years"));
  const [maxDate, setMaxDate] = useState(moment().subtract(18, "years"));

  // Redux variables
  const oldUserProfile = useSelector((state) => state.user.oldUserProfile);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!oldUserProfile) return;
    if (isLoaded) return;

    setIsLoaded(true);
    // checkTaglineValue(); // Deprecated
  }, [oldUserProfile]);

  // Event Handlers | Button
  const onRejectReasonClick = () => {
    dispatch(updateProfileRejectedInfoDialog(true));
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "username":
        if (removeHTMLEscape.test(event.target.value)) {
          // Remove HTML Escape (prevent XSS)
          dispatch(updateUsername(""));

          // Send to Sentry
          Sentry.captureMessage(
            "PWA-EditProfile-Page-Username-Input-HTML-Escape-Detected"
          );

          // Send to GTM
          TagManager.dataLayer({
            dataLayer: {
              event:
                "PWA-EditProfile-Page-Username-Input-HTML-Escape-Detected",
            },
          });
        } else {
          dispatch(updateUsername(event.target.value));
        }
        break;
      case "birthday":
        dispatch(updateBirthday(moment(event).format("YYYY-MM-DD")));
        break;
      case "tagline":
        dispatch(updateTagline(event.target.value));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const checkTaglineValue = () => {
    if (
      oldUserProfile?.profile?.tagline_state ===
      userConst.comUserApprovalState.rejected
    ) {
      dispatch(updateTagline(t("common.rejected")));
    } else {
      if (oldUserProfile?.profile?.tagline) {
        return oldUserProfile?.profile?.tagline;
      } else {
        return "";
      }
    }
  };
  const returnTaglineColor = (type) => {
    switch (type) {
      case "title":
        if (
          !oldUserProfile?.profile?.tagline ||
          oldUserProfile?.profile?.tagline_state ===
            userConst.comUserApprovalState.rejected
        ) {
          return "incomplete-field";
        } else {
        }
        break;
      case "value":
        if (
          oldUserProfile?.profile?.tagline_state ===
          userConst.comUserApprovalState.rejected
        ) {
          return "rejected-value";
        } else {
        }
        break;
      default:
        break;
    }
  };
  const readableDate = (date) => {
    if (date === "0000-00-00 00:00:00") {
      return null;
    } else {
      return moment(date);
    }
  };

  return (
    <div id="edit-profile-basic-information-subcomponent">
      <div className="basic-information-label">
        {t("profile.basic_information")}
      </div>

      {/* Email */}
      <div className="form-field-container">
        <div className="label-container">
          <div className="label">{t("settings.email")}</div>
        </div>

        <div className="form-container">
          <div className="email-label">{oldUserProfile?.profile?.email}</div>
        </div>
      </div>

      {/* Username */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profile?.username ? "" : "incomplete-field"
            }`}
          >
            {t("profile.username")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="form-field"
            value={oldUserProfile?.profile?.username || ""}
            placeholder={t("profile.to_be_completed")}
            onChange={onInputChange("username")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          ></TextField>
        </div>
      </div>

      {/* Birthday */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profile?.birthday ? "" : "incomplete-field"
            }`}
          >
            {oldUserProfile?.profile?.birthday !== "0000-00-00 00:00:00"
              ? t("common.age")
              : t("profile.birthday")}
          </div>
        </div>

        <div className="form-container">
          <div className="date-picker-container">
            {oldUserProfile?.profile?.birthday !== "0000-00-00 00:00:00" ? (
              <div className="age">{oldUserProfile?.profile?.age}</div>
            ) : (
              <DatePicker
                onChange={onInputChange("birthday")}
                value={
                  readableDate(oldUserProfile?.profile?.birthday) ||
                  moment().toDate()
                }
                minDate={minDate.toDate()}
                maxDate={maxDate.toDate()}
                clearIcon={null}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
