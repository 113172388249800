// General
import { useState, useEffect, useRef } from "react";
// Services
import { API_CONFIG_HEADERS } from "../../../../const/apiConst";
import { sessionService } from "../../../../services/session.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Chat Functions
  updateLivestreamChatMessages,

  // Emoji Functions
  updateLivestreamFloatingEmoji,

  // Gift Animation Functions
  updateLivestreamGiftAnimation,

  // PK Functions
  updateContestant1,
  updateContestant2,
  updateCurrentRound,
  updatePkWinner,

  // Pusher Event Functions
  updateLivestreamPusherEvent,

  // PK Functions
  updatePkIntervalPassthrough,

  // Utility Functions
  updatePinnedChatRefreshPassthrough,
} from "../../../../redux/store/livestreamingStore";
import { updateLivestreamKickedDialog } from "../../../../redux/store/dialogStore";
import { updateGlobalSnackbar } from "../../../../redux/store/publicStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
import {
  updateLiveViewPusherState,
  updateLiveViewChannelState,
  updateLiveViewPublicChannelState,
  updateLiveViewPublicChannelGlobalState,
} from "../../../../redux/store/debugStore";
// Pusher-js
import Pusher from "pusher-js";
// Custom Hooks
import useCustomNavigate from "../../custom-hooks/useCustomNavigate-hook";

const LiveViewPusher = () => {
  // General variables
  const subscribeIsMounted = useRef(false);
  const unsubscribeIsMounted = useRef(false);
  const resetIsMounted = useRef(false);
  const destroyIsMounted = useRef(false);

  // Redux variables
  const liveViewPusherSubscribe = useSelector(
    (state) => state.pusher.liveViewPusherSubscribe
  );
  const liveViewPusherUnsubscribe = useSelector(
    (state) => state.pusher.liveViewPusherUnsubscribe
  );
  const liveViewPusherTrigger = useSelector(
    (state) => state.pusher.liveViewPusherTrigger
  );
  const liveViewPusherReset = useSelector(
    (state) => state.pusher.liveViewPusherReset
  );
  const liveViewPusherDestroy = useSelector(
    (state) => state.pusher.liveViewPusherDestroy
  );
  const channelId = useSelector((state) => state.livestreaming.channelId);
  const showLog = useSelector((state) => state.debug.showLog);
  const dispatch = useDispatch();

  // Pusher variables
  let authEndpoint = `${process.env["REACT_APP_SPI_API"]}broadcasting/auth`;
  let [pusherInstance, setPusherInstance] = useState(null);
  let [channel, setChannel] = useState(null);
  let [publicChannel, setPublicChannel] = useState(null);
  let [publicChannelGlobal, setPublicChannelGlobal] = useState(null);

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Initiate
  useEffect(() => {
    if (subscribeIsMounted.current) {
      if (!liveViewPusherSubscribe || pusherInstance) return;

      let headers = {
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        Authorization: `${sessionService.getApiToken()}`,
      };

      setPusherInstance(
        new Pusher(process.env["REACT_APP_PUSHER_APP_KEY"], {
          authEndpoint: authEndpoint,
          cluster: "ap1",
          auth: {
            headers: headers,
          },
        })
      );
    } else {
      subscribeIsMounted.current = true;
    }
  }, [liveViewPusherSubscribe]);

  // Lifecycle | Check for update | Subscribe
  useEffect(() => {
    if (!pusherInstance || !channelId) return;

    pusherInstance?.connection?.bind("state_change", (state) => {
      dispatch(updateLiveViewPusherState(state.current));

      switch (state.current) {
        case "initialized":
          break;
        case "connecting":
          break;
        case "connected":
          break;
        case "disconnected":
          break;
        case "unavailable":
          break;
        case "failed":
          break;
        case "disconnected":
          break;
        default:
          break;
      }
    });

    setChannel(pusherInstance.subscribe("private-stream-" + channelId));
    setPublicChannel(pusherInstance.subscribe("general-stream-" + channelId));
    setPublicChannelGlobal(pusherInstance.subscribe("general-stream-global"));
  }, [pusherInstance, channelId]);

  // Lifecycle | Check for update | Unsubscribe
  useEffect(() => {
    if (unsubscribeIsMounted.current) {
      if (!liveViewPusherUnsubscribe) return;

      channel.unsubscribe();
      publicChannel.unsubscribe();
      publicChannelGlobal.unsubscribe();

      dispatch(updateLiveViewChannelState(false));
      dispatch(updateLiveViewPublicChannelState(false));
      dispatch(updateLiveViewPublicChannelGlobalState(false));

      if (pusherInstance) {
        // Disconnect Pusher and its channels
        pusherInstance.disconnect();
        setPusherInstance(null);
      }
    } else {
      unsubscribeIsMounted.current = true;
    }
  }, [liveViewPusherUnsubscribe]);

  // Lifecycle | Check for update | Private Payload
  useEffect(() => {
    if (!channel) return;

    // Event Listener | State
    channel?.bind("pusher:subscription_succeeded", () => {
      // console.log("Subscribed to channel");
      dispatch(updateLiveViewChannelState(true));
    });
    channel?.bind("pusher:subscription_error", (error) => {
      // console.log("Error subscribing to channel: ", error);
      dispatch(updateLiveViewChannelState(false));
    });

    // Event Listener | Response
    channel.bind("client-chat", (payload) => {
      if (showLog) {
        console.log("client-chat", payload);
      }

      if (payload?.message?.type) {
        switch (payload?.message?.type) {
          case "emoji":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: Emoji");
            }

            dispatch(updateLivestreamChatMessages(payload?.message));
            break;
          case "emoji_animation":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: Emoji Animation");
            }

            dispatch(updateLivestreamFloatingEmoji(payload?.message));
          case "message": // Deprecated, replaced with message.v2
            break;
          default:
            break;
        }
      }
    });
    channel.bind("chat", (payload) => {
      if (showLog) {
        console.log("chat", payload);
      }
    });
  }, [channel]);

  // Lifecycle | Check for update | Public Payload
  useEffect(() => {
    if (!publicChannel) return;

    // Event Listener | State
    publicChannel?.bind("pusher:subscription_succeeded", () => {
      // console.log("Subscribed to channel");
      dispatch(updateLiveViewPublicChannelState(true));
    });
    publicChannel?.bind("pusher:subscription_error", (error) => {
      // console.log("Error subscribing to channel: ", error);
      dispatch(updateLiveViewPublicChannelState(false));
    });

    // Event Listener | Response
    publicChannel.bind("general-message", (payload) => {
      if (showLog) {
        console.log("general-message", payload);
      }

      if (payload?.message?.type) {
        switch (payload?.message?.type) {
          case "joining":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: Joining");
            }

            dispatch(updateLivestreamChatMessages(payload?.message));
            break;
          case "message.v2":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: Message V2");
            }

            dispatch(updateLivestreamChatMessages(payload?.message));
            break;
          case "gifting":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: Gifting");
            }

            dispatch(updateLivestreamChatMessages(payload?.message));
            dispatch(updateLivestreamGiftAnimation(payload?.message));
            break;
          case "following":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: Following");
            }

            dispatch(updateLivestreamChatMessages(payload?.message));
          case "stream_paused":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: Stream Paused");
            }

            // Deprecated due to business logic
            // Show pause overlay
            break;
          case "stream_resume":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: Stream Resume");
            }

            // Deprecated due to business logic
            // Hide pause overlay
            break;
          case "stream_ended":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: Stream Ended");
            }

            // If livestream ended, navigate to live ended page
            onNavigate(routeConst.live.ended.path);
            break;
          case "viewer_kicked":
          case "kicked":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: KICKED");
            }

            // Pause or stop livestream and open kicked dialog
            dispatch(updateLivestreamPusherEvent(payload?.message));
            dispatch(updateLivestreamKickedDialog(true));

            onNavigate(routeConst.live.path);
            break;
          case "refresh":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: REFRESH");
            }

            // Refresh pinned message
            dispatch(updatePinnedChatRefreshPassthrough());
          default:
            break;
        }
      }
    });
  }, [publicChannel]);

  // Lifecycle | Check for update | Public Global Payload
  useEffect(() => {
    if (!publicChannelGlobal) return;

    // Event Listener | State
    publicChannelGlobal?.bind("pusher:subscription_succeeded", () => {
      // console.log("Subscribed to channel");
      dispatch(updateLiveViewPublicChannelGlobalState(true));
    });
    publicChannelGlobal?.bind("pusher:subscription_error", (error) => {
      // console.log("Error subscribing to channel: ", error);
      dispatch(updateLiveViewPublicChannelGlobalState(false));
    });

    // Event Listener | Response
    publicChannelGlobal.bind("general-message", (payload) => {
      if (showLog) {
        console.log("general-message", payload);
      }

      if (payload?.message?.type) {
        switch (payload?.message?.type) {
          case "pk":
            if (showLog) {
              console.log("LIVESTREAM PUSHER: PK");
            }

            // Update PK state
            dispatch(updateContestant1(payload?.message?.data?.users[0]));
            dispatch(updateContestant2(payload?.message?.data?.users[1]));
            dispatch(updateCurrentRound(payload?.message?.data?.current_round));
            dispatch(updatePkWinner(payload?.message?.data?.final_winner));

            // Settings interval not possible in pusher, so we need to pass it to the component
            dispatch(
              updatePkIntervalPassthrough({
                startEpoch: payload?.message?.data?.start_at_epoch,
                endEpoch: payload?.message?.data?.end_at_epoch,
              })
            );
            break;
          default:
            break;
        }
      }
    });
  }, [publicChannelGlobal]);

  // Lifecycle | Check for update | Channel Trigger
  useEffect(() => {
    if (!liveViewPusherTrigger || !channel) return;

    channel.trigger("client-chat", liveViewPusherTrigger);
  }, [liveViewPusherTrigger]);

  // Lifecycle | Check for update | Reset Pusher
  useEffect(() => {
    if (resetIsMounted.current) {
      if (!liveViewPusherReset) return;

      if (pusherInstance) {
        // Disconnect Pusher and its channels
        pusherInstance.disconnect();
        setPusherInstance(null);

        // Update State
        dispatch(updateLiveViewChannelState(false));
        dispatch(updateLiveViewPublicChannelState(false));
        dispatch(updateLiveViewPublicChannelGlobalState(false));
      } else {
        const toastObj = {
          message: "Live View Pusher Instance is unavailable",
          autoClose: 3000,
        };
        dispatch(updateErrorToast(toastObj));
      }
    } else {
      resetIsMounted.current = true;
    }
  }, [liveViewPusherReset]);

  // Lifecycle | Check for update | Destroy Pusher
  useEffect(() => {
    if (destroyIsMounted.current) {
      if (!liveViewPusherDestroy) return;

      if (pusherInstance) {
        pusherInstance.disconnect();
        setPusherInstance(null);

        // Update State
        dispatch(updateLiveViewChannelState(false));
        dispatch(updateLiveViewPublicChannelState(false));
        dispatch(updateLiveViewPublicChannelGlobalState(false));
      }
    } else {
      destroyIsMounted.current = true;
    }
  }, [liveViewPusherDestroy]);

  return <div id="live-view-pusher-shadow-component"></div>;
};

export default LiveViewPusher;
