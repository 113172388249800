// General
import "./blocked-list.scss";
import { useEffect } from "react";
// Services
import { useBlockOrUnblockUserMutation } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import { updateLocalBlockedUsersList } from "../../../../redux/store/userStore";
import { updateLocalSearchList } from "../../../../redux/store/searchStore";
import { updateLocalLivestreamUserData } from "../../../../redux/store/livestreamingStore";
import {
  updateInfoToast,
  updateWarningToast,
} from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../../shared/elements/custom-avatar/custom-avatar";
import Spinner from "../../../shared/elements/spinner/spinner";

const BlockedList = (props) => {
  const { id, idInt, isBlocked, profilePhoto, username, role } = props;

  // API variables
  const [
    blockOrUnblockUser,
    {
      data: blockOrUnblockUserData,
      error: blockOrUnblockUserErrorData,
      isLoading: blockOrUnblockUserLoading,
      isSuccess: blockOrUnblockUserSuccess,
      isError: blockOrUnblockUserError,
    },
  ] = useBlockOrUnblockUserMutation();

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Unblock User API Response
  useEffect(() => {
    if (blockOrUnblockUserLoading) {
    } else if (blockOrUnblockUserSuccess) {
      switch (blockOrUnblockUserData?.status) {
        case 1:
          const infoToastObj = {
            message: blockOrUnblockUserData?.message,
            autoClose: 3000,
          };
          dispatch(updateInfoToast(infoToastObj));

          const localBlockedListObj = {
            idInt,
            isBlocked: !isBlocked,
          };
          dispatch(updateLocalBlockedUsersList(localBlockedListObj));
          dispatch(updateLocalSearchList(localBlockedListObj));
          dispatch(updateLocalLivestreamUserData(localBlockedListObj));
          break;
        default:
          const warningToastObj = {
            message: blockOrUnblockUserData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
      }
    } else if (blockOrUnblockUserError) {
      if (blockOrUnblockUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    blockOrUnblockUserLoading,
    blockOrUnblockUserSuccess,
    blockOrUnblockUserError,
  ]);

  // Event Handlers | Button
  const onBlockUnblockUser = (block) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-BlockedMembers-Page-UnblockUser-Button",
      },
    });

    const obj = {
      user_id: id,
      block: !block,
    };
    blockOrUnblockUser(obj);
  };

  return (
    <div id="blocked-members-blocked-list-subcomponent">
      <div className="padding-container">
        <div className="user-container">
          <div className="user-image-container">
            <CustomAvatar
              className="user-image"
              src={profilePhoto}
              role={role}
            />
          </div>

          <div className="user-name-container">
            <div className="user-name">{username}</div>
          </div>

          <div className="button-container">
            <div
              className="unblock-button"
              onClick={() => onBlockUnblockUser(isBlocked)}
            >
              {blockOrUnblockUserLoading ? (
                <Spinner size={20} isPadding={false} color={"white-spinner"} />
              ) : isBlocked ? (
                t("profile.unblock")
              ) : (
                t("profile.block")
              )}
            </div>
          </div>
        </div>
      </div>

      <Divider className="divider" light />
    </div>
  );
};

export default BlockedList;
