// General
import "./utility-desktop-navbar.scss";
import { useState } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
import profileConst from "../../../../const/profileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEditProfilePassthrough } from "../../../../redux/store/userStore";
import { updateVideoCallPassthrough } from "../../../../redux/store/privateCallStore";
import {
  updateClearMessagesDialog,
  updateReportDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import MoreOptionsButton from "../profile/more-options-button/more-options-button";

const UtilityDesktopNavbar = (props) => {
  const { leftButton, rightButton, backgroundColor = "white" } = props;

  // Constant variables
  const options = profileConst.reportConversation;

  // Render variables
  let leftButtonView = <div></div>;
  let rightButtonView = <div></div>;

  // Redux variables
  const ownPrivateCallEnable = useSelector(
    (state) => state.inbox.ownPrivateCallEnable
  ); // Inbox Chat Page
  const otherUserPrivateCallEnable = useSelector(
    (state) => state.inbox.otherUserPrivateCallEnable
  ); // Inbox Chat Page
  const editProfileTab = useSelector((state) => state.user.editProfileTab); // Edit Profile Page
  const conversationUser = useSelector((state) => state.inbox.conversationUser); // Inbox Chat Page
  const dispatch = useDispatch();

  // MUI variables
  const [anchorElChatMenu, setAnchorElChatMenu] = useState(null);
  const openChatMenu = Boolean(anchorElChatMenu);
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onEditProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UtilityNavbar-Subcomponent-Desktop-EditProfile-Button",
      },
    });

    dispatch(updateEditProfilePassthrough({}));
  };
  const onVideoCall = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UtilityNavbar-Subcomponent-Desktop-VideoCall-Button",
      },
    });

    dispatch(updateVideoCallPassthrough({}));
  };

  // Event Handlers | MUI Menu
  const onCloseMenu = (state) => {
    switch (state) {
      case "report":
        dispatch(updateReportDialog(true));
        break;
      case "clear-messages":
        dispatch(updateClearMessagesDialog(true));
        break;
      default:
        break;
    }

    setAnchorElChatMenu(null);
  };

  // Render Functions
  switch (leftButton) {
    case "back":
      leftButtonView = (
        <div className="back-icon-container" onClick={() => onNavigate(-1)}>
          {getIcon("backIcon", "back-icon")}
        </div>
      );
      break;
    default:
      break;
  }

  switch (rightButton) {
    case "save-edit-profile":
      if (editProfileTab !== 1) {
        rightButtonView = (
          <div className="done-button" onClick={onEditProfile}>
            {t("common.done")}
          </div>
        );
      }

      break;
    case "chat-conversation":
      rightButtonView = (
        <div className="inbox-button-container">
          {ownPrivateCallEnable && otherUserPrivateCallEnable && (
            <IconButton
              className="video-call-icon-container"
              onClick={onVideoCall}
            >
              {getIcon("videoCallIcon", "video-call-icon")}
            </IconButton>
          )}

          <MoreOptionsButton
            userProfileType={profileConst.userProfileType.dialog}
            id={conversationUser?.userId}
            idInt={conversationUser?.userIdInt}
            privatePhotos={conversationUser?.privatePhotos}
            privatePhotoRequested={conversationUser?.privatePhotoRequested}
            privatePhotoApproval={conversationUser?.privatePhotoApproval}
            requestedMyPrivatePhoto={conversationUser?.requestedMyPrivatePhoto}
            approvedMyPrivatePhoto={conversationUser?.approvedMyPrivatePhoto}
            showClearMessage={true}
          />
        </div>
      );
      break;
    default:
      break;
  }

  if (!isMobile) {
    return (
      <div
        id="utility-desktop-navbar-shared-component"
        style={{ backgroundColor }}
      >
        <div className="buttons-container">
          <div className="left-container">{leftButtonView}</div>

          <div className="right-container">{rightButtonView}</div>
        </div>

        <Divider />
      </div>
    );
  }
};

export default UtilityDesktopNavbar;
