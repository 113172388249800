// General
import "./kick-user-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import { useKickPrivateCallUserMutation } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateKickUserDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const KickUserDialog = () => {
  // API variables
  const [
    kickPrivateCallUser,
    {
      data: kickPrivateCallUserData,
      error: kickPrivateCallUserErrorData,
      isLoading: kickPrivateCallUserLoading,
      isSuccess: kickPrivateCallUserSuccess,
      isError: kickPrivateCallUserError,
    },
  ] = useKickPrivateCallUserMutation();

  // Redux variables
  const kickUserDialog = useSelector((state) => state.dialog.kickUserDialog);
  const requestId = useSelector((state) => state.privateCall.requestId);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 450px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!kickUserDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-KickUser-Dialog",
      },
    });
  }, [kickUserDialog]);

  // Lifecycle | Check for update | Kick User API Response
  useEffect(() => {
    if (kickPrivateCallUserLoading) {
    } else if (kickPrivateCallUserSuccess) {
      onCloseDialog();
    } else if (kickPrivateCallUserError) {
    }
  }, [
    kickPrivateCallUserLoading,
    kickPrivateCallUserSuccess,
    kickPrivateCallUserError,
  ]);

  // Event Handlers | Button
  const onKickOtherUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-KickUser-Dialog-KickOtherUser-Button",
      },
    });

    const obj = {
      call_request_id: requestId,
    };

    kickPrivateCallUser(obj, true);

    onCloseDialog();
  };
  const onCancel = () => {
    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateKickUserDialog(false));
  };

  return (
    <Dialog
      className={`${
        isMobile ? "custom-bottom-sheet-dialog" : "custom-radius35-dialog"
      }`}
      fullScreen={isMobile ? true : false}
      open={kickUserDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="kick-user-dialog">
        <div className="padding-container">
          <div className="text">Are you sure you want to kick user?</div>

          <div className="end-call-button" onClick={onKickOtherUser}>
            Kick User
          </div>

          <div className="cancel-button" onClick={onCancel}>
            {t("common.cancel")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default KickUserDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
