// General
import { useState, useEffect } from "react";
// Redux
import { useSelector } from "react-redux";
// Material UI
import { Portal, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const GlobalSnackbarMounted = () => {
  // Redux variables
  const globalSnackbar = useSelector((state) => state.public.globalSnackbar);

  // MUI variables
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Lifecycle | Check for update | MUI Snackbar | Global
  useEffect(() => {
    if (globalSnackbar && globalSnackbar.state) {
      onOpenSnackbar();
    }
  }, [globalSnackbar]);

  // Event Handlers | MUI Snackbar
  const onOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const onCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Portal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={globalSnackbar.duration}
        onClose={onCloseSnackbar}
        message={globalSnackbar.message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top" + "center"}
      >
        {globalSnackbar?.includeSeverity ? (
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={onCloseSnackbar}
            severity={globalSnackbar?.severity ?? null}
            sx={{ width: "100%" }}
          >
            {globalSnackbar?.message}
          </MuiAlert>
        ) : null}
      </Snackbar>
    </Portal>
  );
};

export default GlobalSnackbarMounted;
