// General
import "./video-call-settings.scss";
import { useEffect } from "react";
// Services
import { sessionService } from "../../../../services/session.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVideoCallSettingsTutorialDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Banner from "../../../shared/elements/banner/banner";
import VideoCallSettingsTutorialDialog from "../../../shared/dialog-content/video-call-settings-tutorial-dialog/video-call-settings-tutorial-dialog";

const VideoCallSettings = () => {
  // Redux variables
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VideoCallSettings-Page",
      },
    });

    // Check if "has-shown-video-call-settings-tutorial-dialog" in local storage is true or false
    if (
      !isDaddy &&
      !sessionService.getHasShownVideoCallSettingsTutorialDialog()
    ) {
      dispatch(updateVideoCallSettingsTutorialDialog(true));
    }
  }, []);

  // Event Handlers | Button
  const onRedirect = (route) => {
    let routeName = "";

    switch (route) {
      case routeConst.settings.videoCall.customize.path:
        routeName = "VideoCallSettingsCustomize";
        break;
      case routeConst.settings.blockedMembers.path:
        routeName = "BlockedMembers";
        break;
      case routeConst.profile.streamerCenter.path:
        routeName = "StremerCenter";
        break;
      case routeConst.tutorials.videoCall.path:
        routeName = "Tutorial";
        break;
      default:
        break;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-VideoCallSettings-Page-${routeName}-Button`,
      },
    });

    onNavigate(route);
  };

  return (
    <div id="video-call-settings-page">
      <div className="max-width-container">
        <div className="padding-container">
          <Banner type={"video-call-settings"} view={"carousel"} />
        </div>

        <div className="padding-container">
          <div className="video-call-label">{t("profile.1on1_video_call")}</div>

          <div className="buttons-list-container">
            <div
              className="button-container"
              onClick={() =>
                onRedirect(routeConst.settings.videoCall.customize.path)
              }
            >
              <div className="button-label-container">
                <div className="button-label">{t("profile.settings")}</div>
              </div>

              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </div>

            <div
              className="button-container"
              onClick={() =>
                onRedirect(routeConst.settings.blockedMembers.path)
              }
            >
              <div className="button-label-container">
                <div className="button-label">
                  {t("profile.blocked_members")}
                </div>
              </div>

              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </div>

            <div
              className="button-container"
              onClick={() => onRedirect(routeConst.videoCall.callHistory.path)}
            >
              <div className="button-label-container">
                <div className="button-label">{t("profile.call_history")}</div>
              </div>

              <div className="suffix">
                <NavigateNextIcon />
              </div>
            </div>
          </div>
        </div>

        <Divider />

        {!isDaddy && (
          <div className="padding-container">
            <div className="video-call-label">{t("profile.more_info")}</div>

            <div className="buttons-list-container">
              {!isDaddy && (
                <div
                  className="button-container"
                  onClick={() =>
                    onRedirect(routeConst.profile.streamerCenter.path)
                  }
                >
                  <div className="button-label-container">
                    <div className="button-label">
                      {t("settings.my_diamonds")}
                    </div>
                    <div className="icon-container">
                      {getIcon("sbDiamondIcon", "icon")}
                    </div>
                  </div>

                  <div className="suffix">
                    <NavigateNextIcon />
                  </div>
                </div>
              )}

              <div
                className="button-container"
                onClick={() => onRedirect(routeConst.tutorials.videoCall.path)}
              >
                <div className="button-label-container">
                  <div className="button-label">{t("profile.tutorial")}</div>
                </div>

                <div className="suffix">
                  <NavigateNextIcon />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <VideoCallSettingsTutorialDialog />
    </div>
  );
};

export default VideoCallSettings;
