// General
import "./photos.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfilePwaQuery,
  useUpdateProfilePhotoMutation,
} from "../../../../services/data.service";
// Redux
import { useDispatch } from "react-redux";
import { updateProfilePreviewPhotoInfo } from "../../../../redux/store/profileStore";
import {
  updateGeneralLoadingDialog,
  updatePreviewPhotoDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const Photos = () => {
  // API variables
  const [
    getProfilePwa,
    {
      data: getProfilePwaData,
      error: getProfilePwaErrorData,
      isFetching: getProfilePwaFetching,
      isLoading: getProfilePwaLoading,
      isSuccess: getProfilePwaSuccess,
      isError: getProfilePwaError,
    },
  ] = useLazyGetProfilePwaQuery();
  const [
    photoUpdate,
    {
      data: photoUpdateData,
      error: photoUpdateErrorData,
      isLoading: photoUpdateLoading,
      isSuccess: photoUpdateSuccess,
      isError: photoUpdateError,
    },
  ] = useUpdateProfilePhotoMutation();

  // General variables
  const [orientationData, setOrientationData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageState, setImageState] = useState(null);

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfilePwa();
  }, []);

  // Lifecycle | Check for update | Photo Update API Response
  useEffect(() => {
    if (photoUpdateLoading) {
    } else if (photoUpdateSuccess) {
      // Close Spinner
      dispatch(updateGeneralLoadingDialog(false));

      setOrientationData(null);
      setImageData(null);
      setImageState(null);

      getProfilePwa();
    } else if (photoUpdateError) {
      // Close Spinner
      dispatch(updateGeneralLoadingDialog(false));
    }
  }, [photoUpdateLoading, photoUpdateSuccess, photoUpdateError]);

  // Lifecycle | Check for update | imageData & orientationData
  useEffect(() => {
    if (!imageData || !orientationData || !imageState) return;

    let formData = new FormData();

    formData.append(imageState, imageData.file);
    formData.append("orientation", orientationData);

    // Open Spinner
    dispatch(updateGeneralLoadingDialog(true));

    photoUpdate(formData);
  }, [imageData, orientationData, imageState]);

  // Event Handlers | Button
  const onFilePicker = (state) => async (event) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EditProfile-Page-SelectPhoto-Button",
      },
    });

    setImageState(state);

    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const orientation = await getImageOrientation(file);
        saveOrientationData(orientation);

        const imageData = await readFile(file);
        setImageData((prevState) => ({
          ...prevState,
          image: imageData,
          file: file,
        }));
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onPreviewPhoto = (photo) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EditProfile-Page-PreviewPhoto-Button",
      },
    });

    const obj = {
      id: photo?.id,
      photoUrl: photo?.path,
      isProfilePhoto: photo?.is_profile_photo,
      isApproved: photo?.isapproved,
    };
    dispatch(updateProfilePreviewPhotoInfo(obj));
    dispatch(updatePreviewPhotoDialog(true));
  };

  // Utility Functions
  const saveOrientationData = (orientation_data) => {
    setOrientationData(orientation_data);
  };
  const getImageOrientation = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const view = new DataView(event.target.result);
        if (view.getUint16(0, false) !== 0xffd8) {
          return resolve(-2);
        }
        const length = view.byteLength;
        let offset = 2;
        while (offset < length) {
          const marker = view.getUint16(offset, false);
          offset += 2;
          if (marker === 0xffe1) {
            const exif = view.getUint32((offset += 2), false);
            if (exif !== 0x45786966) {
              return resolve(-1);
            }
            const little = view.getUint16((offset += 6), false) === 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + i * 12, little) === 0x0112) {
                return resolve(view.getUint16(offset + i * 12 + 8, little));
              }
            }
          } else if ((marker & 0xff00) !== 0xff00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }
        return resolve(-1);
      };
      reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    });
  };
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  if (getProfilePwaFetching || getProfilePwaLoading) {
    return <Spinner />;
  } else if (getProfilePwaSuccess) {
    return (
      <div id="profile-edit-photos-subcomponent">
        <div className="padding-container">
          <div className="public-photos-container">
            <div className="public-photos-label">
              {t("profile.public_photos")}
            </div>

            {getProfilePwaData?.payload?.message?.publicphotos?.length > 0 ? (
              <div className="photos-container">
                {getProfilePwaData?.payload?.message?.publicphotos?.map(
                  (photo, index) => (
                    <div
                      className="photo-container"
                      onClick={() => onPreviewPhoto(photo)}
                      key={index}
                    >
                      <img className="public-photo" src={photo?.path} />

                      {!photo?.isapproved && !photo.isdenied && (
                        <div className="in-review-overlay">
                          {t("profile.in_review")}
                        </div>
                      )}

                      {!photo?.isapproved && photo.isdenied && (
                        <div className="in-review-overlay">
                          {t("profile.rejected")}
                        </div>
                      )}
                    </div>
                  )
                )}

                <Button
                  className="add-photo-container"
                  disableRipple
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={onFilePicker("new_public_photo[]")}
                  />
                  {getIcon("addPhotoIcon", "add-photo")}
                </Button>
              </div>
            ) : (
              <Button
                className="upload-photo-container"
                disableRipple
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={onFilePicker("new_public_photo[]")}
                />
                <div className="upload-icon-container">
                  {getIcon("uploadIcon", "upload-icon")}
                </div>

                <div className="upload-photo-label">
                  {t("profile.upload_photo")}
                </div>
              </Button>
            )}
          </div>

          <Divider />

          <div className="private-photos-container">
            <div className="private-photos-label">
              {t("profile.private_photos")}
            </div>

            {getProfilePwaData?.payload?.message?.privatephotos?.length > 0 ? (
              <div className="photos-container">
                {getProfilePwaData?.payload?.message?.privatephotos?.map(
                  (photo, index) => (
                    <div
                      className="photo-container"
                      onClick={() => onPreviewPhoto(photo)}
                      key={index}
                    >
                      <img className="private-photo" src={photo?.path} />

                      {!photo?.isapproved && !photo.isdenied && (
                        <div className="in-review-overlay">
                          {t("profile.in_review")}
                        </div>
                      )}

                      {!photo?.isapproved && photo.isdenied && (
                        <div className="in-review-overlay">
                          {t("profile.rejected")}
                        </div>
                      )}
                    </div>
                  )
                )}

                <Button
                  className="add-photo-container"
                  disableRipple
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={onFilePicker("new_private_photo[]")}
                  />
                  {getIcon("addPhotoIcon", "add-photo")}
                </Button>
              </div>
            ) : (
              <Button
                className="upload-photo-container"
                disableRipple
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={onFilePicker("new_private_photo[]")}
                />
                <div className="upload-icon-container">
                  {getIcon("uploadIcon", "upload-icon")}
                </div>

                <div className="upload-photo-label">
                  {t("profile.upload_photo")}
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  } else if (getProfilePwaError) {
  }
};

export default Photos;
