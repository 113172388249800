// General
import "./streamer-center.scss";
import { useState, useEffect } from "react";
// Services
import { sessionService } from "../../../../services/session.service";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateOnboardingDiamondsInfoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import OverallTab from "./overall-tab/overall-tab";
import MoreInfoTab from "./more-info-tab/more-info-tab";

const StreamerCenter = () => {
  // General variables
  const [value, setValue] = useState(0);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-StreamerCenter-Page",
      },
    });

    // Check if "has-shown-diamonds-info-dialog" in local storage is true or false
    if (!sessionService.getHasShownDiamondsInfoDialog()) {
      dispatch(updateOnboardingDiamondsInfoDialog(true));
    }
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(value);

    emblaApi?.on("select", () => {
      setValue(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-StreamerCenter-Page-TabChangeClick-Button-${
          newValue === 0 ? "Overall" : "MoreInfo"
        }`,
      },
    });

    emblaApi?.scrollTo(newValue);
  };

  return (
    <div id="streamer-center-page">
      <div className="max-width-container">
        <div className="tab-container">
          <Tabs
            className="custom-tabs"
            value={value}
            onChange={onTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
            variant="fullWidth"
          >
            <Tab label={t("settings.overall")} />
            <Tab label={t("settings.more_info")} />
          </Tabs>

          <div ref={emblaRef} className="embla">
            <div className="embla__container">
              <div className="embla__slide">
                {value === 0 && <OverallTab />}
              </div>

              <div className="embla__slide">
                {value === 1 && <MoreInfoTab />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamerCenter;
