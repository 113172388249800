// General
import "./monthly-tab.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetLivestreamingFansLeaderboardQuery } from "../../../../../../../services/data.service";
// Static Data
import leaderboardConst from "../../../../../../../const/leaderboardConst";
import routeConst from "../../../../../../../const/routeConst";
import userConst from "../../../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateTopFansMonthly,
  resetTopFansMonthly,
} from "../../../../../../../redux/store/livestreamingStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../../../elements/spinner/spinner";
import TopFanEmptyList from "../../../../../elements/top-fans-empty-list/top-fans-empty-list";
import LeaderboardUser from "../../../../../elements/leaderboard-user/leaderboard-user";

const MonthlyTab = () => {
  // API variables
  const [
    getLivestreamingFansLeaderboard,
    {
      data: livestreamingFansLeaderboardData,
      error: livestreamingFansLeaderboardErrorData,
      isFetching: livestreamingFansLeaderboardFetching,
      isLoading: livestreamingFansLeaderboardLoading,
      isSuccess: livestreamingFansLeaderboardSuccess,
      isError: livestreamingFansLeaderboardError,
    },
  ] = useLazyGetLivestreamingFansLeaderboardQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);

  // Render variables
  let topFansListView = <div></div>;

  // Redux variables
  const livestreamerId = useSelector(
    (state) => state.livestreaming.livestreamerId
  );
  const topFansMonthly = useSelector(
    (state) => state.livestreaming.topFansMonthly
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  let fansLeaderboardParam = {
    user_id: livestreamerId,
    board_period_type: leaderboardConst.topFans.monthly,
    pagination_token: null,
  };

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!livestreamerId) return;

    if (topFansMonthly?.length <= 0) {
      getLivestreamingFansLeaderboard(
        `?user_id=${fansLeaderboardParam.user_id}&board_period_type=${
          fansLeaderboardParam.board_period_type
        }${
          fansLeaderboardParam.pagination_token
            ? `&pagination_token=${fansLeaderboardParam.pagination_token}`
            : ""
        }`
      );
    }
  }, [livestreamerId]);

  // Lifecycle | Check for update | Top Fans API Response
  useEffect(() => {
    if (
      livestreamingFansLeaderboardFetching ||
      livestreamingFansLeaderboardLoading
    ) {
    } else if (livestreamingFansLeaderboardSuccess) {
      setFirstLoad(false);

      if (
        livestreamingFansLeaderboardData?.data?.top_gifters?.results?.length > 0
      ) {
        fansLeaderboardParam.pagination_token =
          livestreamingFansLeaderboardData?.data?.top_gifters?.pagination?.next_cursor;

        // Update top fans list
        dispatch(
          updateTopFansMonthly(
            livestreamingFansLeaderboardData?.data?.top_gifters?.results
          )
        );
      }
    } else if (livestreamingFansLeaderboardError) {
      if (livestreamingFansLeaderboardErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    livestreamingFansLeaderboardFetching,
    livestreamingFansLeaderboardLoading,
    livestreamingFansLeaderboardSuccess,
    livestreamingFansLeaderboardError,
  ]);

  // Lifecycle | Check for update | livestreamerId
  useEffect(() => {
    if (!livestreamerId) return;

    fansLeaderboardParam.user_id = livestreamerId;
  }, [livestreamerId]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (
      livestreamingFansLeaderboardData?.data?.top_gifters?.pagination
        ?.next_cursor
    ) {
      fansLeaderboardParam.pagination_token =
        livestreamingFansLeaderboardData?.data?.top_gifters?.pagination?.next_cursor;

      getLivestreamingFansLeaderboard(
        `?user_id=${fansLeaderboardParam.user_id}&board_period_type=${
          fansLeaderboardParam.board_period_type
        }${
          fansLeaderboardParam.pagination_token
            ? `&pagination_token=${fansLeaderboardParam.pagination_token}`
            : ""
        }`,
        true
      );
    }
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetTopFansMonthly());
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(
        getLivestreamingFansLeaderboard(
          `?user_id=${fansLeaderboardParam.user_id}&board_period_type=${fansLeaderboardParam.board_period_type}`
        )
      );
    });
  };

  // Render Functions | Loading
  if (
    firstLoad &&
    (livestreamingFansLeaderboardFetching ||
      livestreamingFansLeaderboardLoading)
  ) {
    return <Spinner />;
  } else if (livestreamingFansLeaderboardError) {
    if (livestreamingFansLeaderboardErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }

  // Render Functions | Top Fans List View
  if (topFansMonthly?.length > 0) {
    topFansListView = (
      <div className="top-fans-list-container">
        {topFansMonthly?.map((user, index) => (
          <LeaderboardUser
            index={index}
            // User View
            id={user?.user?.id}
            idInt={user?.user?.id_int}
            username={user?.user?.username}
            age={user?.user?.appearance?.age}
            location={
              user?.user?.location?.display_address ||
              user?.user?.location?.city ||
              user?.user?.location?.state
            }
            membershipType={user?.user?.membership_type}
            verifiedProfile={user?.user?.is_verified_profile}
            verifiedCaller={user?.user?.verified_caller}
            levellingBadge={user?.user?.levelling_profile?.badge}
            livestreamingAchievementBadge={
              user?.user?.live_streaming_achievements?.chat_badges_set
            }
            profilePhoto={user?.user?.profile_photo?.original_photo}
            publicPhotos={user?.user?.public_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
              };
            })}
            privatePhotos={user?.user?.private_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
                thumbnailData: photo?.thumbnail_data,
              };
            })}
            torTags={user?.user?.tor_tags}
            profileAchievementBadge={
              user?.user?.live_streaming_achievements?.profile_badges
            }
            aboutMe={user?.user?.aboutme}
            lookingFor={user?.user?.lookingfor}
            occupation={user?.user?.occupation?.occupation}
            lifestyle={user?.user?.occupation?.lifestyle_budget}
            education={user?.user?.occupation?.education}
            relationship={user?.user?.relationship?.relationship_status}
            smoking={user?.user?.habit?.smoking}
            drinking={user?.user?.habit?.drinking}
            height={user?.user?.appearance?.height}
            bodyType={user?.user?.appearance?.body_type}
            ethnicity={user?.user?.appearance?.ethnicity}
            eyeColor={user?.user?.appearance?.eye_color}
            hairColor={user?.user?.appearance?.hair_color}
            children={user?.user?.relationship?.children}
            userType={"fan"}
            amount={user?.total_amount_spent}
            isFollowing={user?.user?.live_streaming_meta?.is_following}
            // User Utility
            shouldCallApi={false}
            privatePhotoRequested={
              user?.user?.interaction?.private_photo_requested
            }
            privatePhotoApproval={
              user?.user?.interaction?.private_photo_approval
            }
            requestedMyPrivatePhoto={
              user?.user?.interaction?.requested_my_private_photo
            }
            approvedMyPrivatePhoto={
              user?.user?.interaction?.approved_my_private_photo
            }
            isBlocked={user?.user?.interaction?.is_blocked}
            isSilentInteraction={
              user?.user?.interaction?.should_silent_interactions
            }
            isFavorited={user?.user?.interaction?.is_favorited}
            isCallEnabled={
              user?.user?.private_call_settings?.enable_calls &&
              user?.user?.private_call_settings?.system_private_calls_enabled
            }
            isSugarbaby={
              user?.user?.role === userConst.userRole.sugarbaby ||
              user?.user?.role === userConst.userRole.maleSugarbaby
            }
            role={user?.user?.role}
            key={index}
          />
        ))}
      </div>
    );
  } else {
    return <TopFanEmptyList />;
  }

  return (
    <div id="top-fans-tab-monthly-tab-subcomponent">
      <PullToRefresh
        className="pull-to-refresh-container"
        onRefresh={pullToRefreshFunc}
        refreshingContent={t("common.release")}
        pullingContent={t("common.pull_to_refresh")}
        isPullable={
          !livestreamingFansLeaderboardFetching &&
          !livestreamingFansLeaderboardLoading
        }
      >
        <div className="top-fans-container">
          <InfiniteScroll
            pageStart={0}
            loadMore={infiniteScrollFunc}
            hasMore={
              livestreamingFansLeaderboardData?.data?.top_gifters?.pagination
                ?.next_cursor
            }
            loader={<Spinner key={0} />}
            useWindow={false}
          >
            {topFansListView}
          </InfiniteScroll>
        </div>
      </PullToRefresh>
    </div>
  );
};

export default MonthlyTab;
