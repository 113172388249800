// General
import "./text-message-element.scss";
import { useEffect } from "react";
// Services
import { usePostSingleConversationMutation } from "../../../services/data.service";
// Static Data
import userConst from "../../../const/userConst";
import routeConst from "../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import { updateNewUserConversation } from "../../../redux/store/inboxStore";
import {
  updateNotificationIsSeen,
  updateShowNotificationBadge,

  // Utility Functions
  updateCloseNotificationTabPassthrough,
} from "../../../redux/store/notificationStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../shared/elements/custom-avatar/custom-avatar";

const TextMessageElement = (props) => {
  const {
    notificationId,
    id,
    idInt,
    conversationId,
    profilePhoto,
    username,
    isVerifiedProfile,
    membershipType,
    isLock,
    timestamp,
    isSeen,
  } = props;

  // API variables
  const [
    getConversationDetails,
    {
      data: getConversationDetailsData,
      error: getConversationDetailsErrorData,
      isLoading: getConversationDetailsLoading,
      isSuccess: getConversationDetailsSuccess,
      isError: getConversationDetailsError,
    },
  ] = usePostSingleConversationMutation();

  // Redux variables
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 850px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | conversationDetails API Response
  useEffect(() => {
    if (getConversationDetailsLoading) {
    } else if (getConversationDetailsSuccess) {
      if (getConversationDetailsData?.status === 0) {
        const obj = {
          conversation_accepted: false,
          conversation_id:
            getConversationDetailsData?.data?.single_conversation?.id,
          conversation_id_int: null,
          last_message: null,
          other_user:
            getConversationDetailsData?.data?.single_conversation?.other_user,
        };

        dispatch(updateNewUserConversation(obj));
        onNavigate(routeConst.inbox.path);
      }
    } else if (getConversationDetailsError) {
    }
  }, [
    getConversationDetailsLoading,
    getConversationDetailsSuccess,
    getConversationDetailsError,
  ]);

  // Event Handlers | Button
  const onRedirectToConversation = () => {
    markAsSeen();
    onCloseNotificationTab();

    if (isMobile) {
      onNavigate(
        `${routeConst.inbox.messaging.chat.chatId.clearPath}${conversationId}`
      );
    } else {
      const obj = {
        recipient_user_id: id,
      };
      getConversationDetails({ id: null, body: obj }, false);
      // const obj = {
      //   conversation_accepted: false,
      //   conversation_id: conversationId,
      //   conversation_id_int: null,
      //   last_message: null,
      //   other_user:
      //     getConversationDetailsData?.data?.single_conversation?.other_user,
      // };
      // dispatch(updateNewUserConversation(obj));
      // onNavigate(routeConst.inbox.path);
    }
  };

  // Utility Functions
  const markAsSeen = () => {
    const obj = {
      notificationId,
      isSeen: true,
    };
    dispatch(updateNotificationIsSeen(obj));
    setTimeout(() => {
      dispatch(updateShowNotificationBadge());
    }, 1000);
  };
  const onCloseNotificationTab = () => {
    dispatch(updateCloseNotificationTabPassthrough());
  };

  return (
    <div
      id="notification-text-message-element-subcomponent"
      className={isSeen ? "" : "notification-unseen"}
      onClick={onRedirectToConversation}
    >
      <div className="avatar-container">
        <CustomAvatar
          className="profile-photo"
          src={profilePhoto}
          isBlur={isLock}
          isLock={isLock}
        />
      </div>

      <div className="text-message-body-container">
        <span className="username">{username || "-"}</span>{" "}
        {membershipType !== userConst.membershipType.free && (
          <span className="premium-mini-badge-container">
            {getIcon("premiumMiniBadge", "premium-mini-badge")}
          </span>
        )}
        {isVerifiedProfile && (
          <span className="notification-verified-profile-badge-container">
            {getIcon(
              "verifiedProfileBadge",
              "notification-verified-profile-badge"
            )}
          </span>
        )}
        {t("notification.user_send_you_a_message")}{" "}
        <span className="timestamp">{timestamp}</span>
        {false && <span className="red-dot"></span>}
      </div>
    </div>
  );
};

export default TextMessageElement;
