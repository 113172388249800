// General
import "./video-call-tutorials-dialog.scss";
import { useState, useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVideoCallTutorialDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, useMediaQuery } from "@mui/material";
import ChevronRightIcon from "../../../../assets/icon/clickable/shared/chevron-icon-right.svg";
import CloseLightIcon from "../../../../assets/icon/clickable/shared/close-dark-overlay-icon.svg";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const VideoCallTutorialsDialog = (props) => {
  const { type, pages } = props;

  // General variables
  const [page, setPage] = useState(1);

  // MUI variables
  const fullScreen = useMediaQuery("(max-width: 720px)");

  // Render variables
  let backgroundOverlayStyle;
  let imageView;
  let buttonView;
  let titleView;
  let descriptionView;

  // Redux variables
  const videoCallTutorialDialog = useSelector(
    (state) => state.dialog.videoCallTutorialDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!videoCallTutorialDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VideoCallTutorial-Dialog",
      },
    });
  }, [videoCallTutorialDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateVideoCallTutorialDialog(false));
    setPage(1);
  };
  const onNext = () => {
    setPage((prevPage) => prevPage + 1);

    if (page >= pages) {
      onCloseDialog();
    }
  };

  // Render Functions
  switch (type) {
    case "introduction":
      titleView = t("tutorial.privatecall_title");
      descriptionView = t("tutorial.privatecall_desc");
      if (page === 1) {
        backgroundOverlayStyle = {
          background: `url(${getAsset(
            "vcOverlayProfileBackground"
          )}) center center / cover no-repeat`,
        };
        imageView = (
          <img
            className="overlay"
            src={getAsset("vcOverlayProfileBackground")}
            alt="tutorial-overlay"
          />
        );
        buttonView = (
          <img className="right-icon" src={ChevronRightIcon} alt="right-icon" />
        );
      } else if (page === 2) {
        backgroundOverlayStyle = {
          background: `url(${getAsset(
            "vcOverlayMessagingBackground"
          )}) center center / cover no-repeat`,
        };
        imageView = (
          <img
            className="overlay"
            src={getAsset("vcOverlayMessagingBackground")}
            alt="tutorial-overlay"
          />
        );
        buttonView = (
          <img className="right-icon" src={CloseLightIcon} alt="right-icon" />
        );
      }
      break;
    case "tip-menu":
      titleView = t("tutorial.tipmenu_title");
      descriptionView = t("tutorial.tipmenu_desc");
      if (page === 1) {
        backgroundOverlayStyle = {
          background: `url(${getAsset(
            "vcOverlayCustomizeMenuBackground"
          )}) center center / cover no-repeat`,
        };
        imageView = (
          <img
            className="overlay"
            src={getAsset("vcOverlayCustomizeMenuBackground")}
            alt="tutorial-overlay"
          />
        );
        buttonView = (
          <img className="right-icon" src={CloseLightIcon} alt="right-icon" />
        );
      }
      break;
    case "settings":
      titleView = t("tutorial.manage_call_title");
      descriptionView = t("tutorial.manage_call_desc");
      if (page === 1) {
        backgroundOverlayStyle = {
          background: `url(${getAsset(
            "vcOverlaySettingsBackground"
          )}) center center / cover no-repeat`,
        };
        imageView = (
          <img
            className="overlay"
            src={getAsset("vcOverlaySettingsBackground")}
            alt="tutorial-overlay"
          />
        );
        buttonView = (
          <img className="right-icon" src={CloseLightIcon} alt="right-icon" />
        );
      }
      break;
    case "earnings":
      titleView = t("tutorial.earning_title");
      descriptionView = t("tutorial.earning_desc");
      if (page === 1) {
        backgroundOverlayStyle = {
          background: `url(${getAsset(
            "vcOverlayTipMenuBackground"
          )}) center center / cover no-repeat`,
        };
        imageView = (
          <img
            className="overlay"
            src={getAsset("vcOverlayTipMenuBackground")}
            alt="tutorial-overlay"
          />
        );
        buttonView = (
          <img className="right-icon" src={CloseLightIcon} alt="right-icon" />
        );
      }
      break;
    case "gift-request":
      if (page === 1) {
        titleView = t("tutorial.quick_gift_title");
        descriptionView = t("tutorial.quick_gift_desc");
        backgroundOverlayStyle = {
          background: `url(${getAsset(
            "onboardingVideoCallQuickGiftBackground"
          )}) center center / cover no-repeat`,
        };
        imageView = (
          <img
            className="overlay"
            src={getAsset("onboardingVideoCallQuickGiftBackground")}
            alt="tutorial-overlay"
          />
        );
        buttonView = (
          <img className="right-icon" src={ChevronRightIcon} alt="right-icon" />
        );
      } else if (page === 2) {
        titleView = t("tutorial.my_gift_title");
        descriptionView = t("tutorial.my_gift_desc");
        backgroundOverlayStyle = {
          background: `url(${getAsset(
            "onboardingVideoCallMoreGiftBackground"
          )}) center center / cover no-repeat`,
        };
        imageView = (
          <img
            className="overlay"
            src={getAsset("onboardingVideoCallMoreGiftBackground")}
            alt="tutorial-overlay"
          />
        );
        buttonView = (
          <img className="right-icon" src={CloseLightIcon} alt="right-icon" />
        );
      }
      break;
    default:
      break;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={videoCallTutorialDialog}
      onClose={onCloseDialog}
    >
      <div id="video-call-tutorials-dialog">
        <div
          className="background-overlay"
          style={backgroundOverlayStyle}
        ></div>
        {imageView}

        <div
          className={`interactable-container ${
            type === "earnings" ? "push-down" : ""
          }`}
        >
          <div className="button-container" onClick={onNext}>
            {buttonView}
          </div>

          <div className="description-container">
            <div className="padding-container">
              <div className="subheader">{titleView}</div>

              <div className="text">{descriptionView}</div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VideoCallTutorialsDialog;
