// General
import "./account-signup.scss";
import { useState, useEffect } from "react";
// Services
import { useSignupMutation } from "../../../services/data.service";
import { sessionService } from "../../../services/session.service";
import { emailRegex, removeHTMLEscape } from "../../../services/regex.service";
// Static Data
import routeConst from "../../../const/routeConst";
import userConst from "../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmail,
  updatePassword,
  updateIsPremium,
  updateSignupDate,
  updateUserId,
} from "../../../redux/store/signupStore";
import { updateApiToken } from "../../../redux/store/publicStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import {
  Box,
  TextField,
  Snackbar,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import utilityConst from "../../../const/utilityConst";

const AccountSignup = () => {
  // API variables
  const [
    signup,
    {
      data: signupData,
      error: signupErrorData,
      isLoading: signupLoading,
      isSuccess: signupSuccess,
      isError: signupError,
    },
  ] = useSignupMutation();

  // General variables
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Redux variables
  const country = useSelector((state) => state.signup.country);
  const phone = useSelector((state) => state.signup.phone);
  const email = useSelector((state) => state.signup.email);
  const password = useSelector((state) => state.signup.password);
  const gender = useSelector((state) => state.signup.gender);
  const lookingFor = useSelector((state) => state.signup.lookingFor);
  const role = useSelector((state) => state.signup.role);
  const utmSource = useSelector((state) => state.tracking.utmSource);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Signup API Response
  useEffect(() => {
    if (signupLoading) {
    } else if (signupSuccess) {
      dispatch(updateApiToken(signupData?.data?.token));
      sessionService.setEmail(email);

      // GTM Use
      dispatch(
        updateIsPremium(
          signupData?.data?.user?.membershiptype ===
            userConst.membershipType.premium ||
            signupData?.data?.user?.membershiptype ===
              userConst.membershipType.freePremium ||
            signupData?.data?.user?.membershiptype ===
              userConst.membershipType.diamond
        )
      );
      dispatch(updateSignupDate(moment().utc().format("YYYY-MM-DD HH:mm:ss")));
      dispatch(updateUserId(signupData?.data?.user?.id));

      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-RegistrationCompleted",
          email: signupData?.data?.input?.email,
          role,
          is_premium:
            signupData?.data?.user?.membershiptype ===
              userConst.membershipType.premium ||
            signupData?.data?.user?.membershiptype ===
              userConst.membershipType.freePremium ||
            signupData?.data?.user?.membershiptype ===
              userConst.membershipType.diamond,
          signup_date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
          user_id: signupData?.data?.user?.id,
        },
      });

      onNavigate(routeConst.infoSignup.path);
    } else if (signupError) {
      onOpenSnackbar();
    }
  }, [signupLoading, signupSuccess, signupError]);

  // Event Handlers | Button
  const onSignup = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-AccountSignup-Page-Signup-Button",
      },
    });

    const documentReferrer = document.referrer;
    const url = new URL(window.location);
    let obj = {};

    if (phone && phone !== "") {
      obj = {
        action: "signup",
        auth_provider: utilityConst.authMethod.phonePassword,
        phone_country_iso_code: country?.country_iso_code,
        phone_number: phone,
        email: email,
        gender,
        looking_for: lookingFor,
        password: password,
        reference_url: documentReferrer || url?.origin || "",
        utm_data: utmSource?.stringyfiedSearchParam || "{}",
      };
    } else {
      obj = {
        action: "signup",
        auth_provider: utilityConst.authMethod.emailPassword,
        email: email,
        gender,
        looking_for: lookingFor,
        password: password,
        reference_url: documentReferrer || url?.origin || "",
        utm_data: utmSource?.stringyfiedSearchParam || "{}",
      };
    }

    signup(obj);
  };
  // Event Handlers | Button
  const onNavigateToTerms = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-AccountSignup-Page-Terms-Button",
      },
    });

    onNavigate(routeConst.terms.path);
  };
  const onNavigateToPrivacyPolicy = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-AccountSignup-Page-PrivacyPolicy-Button",
      },
    });

    onNavigate(routeConst.privacy.path);
  };
  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Event Handlers | MUI Text Fields
  const onEmailChange = (event) => {
    dispatch(updateEmail(event.target.value.trim().toLowerCase()));
  };
  const onEmailFocus = (event) => {
    setEmailFocus(true);
  };
  const onPasswordChange = (event) => {
    dispatch(updatePassword(event.target.value.trim()));
  };
  const onPasswordFocus = (event) => {
    setPasswordFocus(true);
  };

  // Event Handlers | MUI Snackbar
  const onOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const onCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (emailValid && passwordValid) {
      valid = true;
    }

    return valid;
  };
  const emailValidation = () => {
    let valid = true;
    let error = false;

    const regex = emailRegex;

    if (email === "") {
      setEmailErrorMessage(t("login.email_address_is_required"));
      valid = false;
      error = true;
    } else if (!regex.test(email)) {
      setEmailErrorMessage(t("login.email_address_is_not_valid"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(email)) {
      dispatch(updateEmail(""));
      valid = false;
      error = true;

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-AccountSignup-Page-Email-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-AccountSignup-Page-Email-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setEmailErrorMessage("");
      valid = true;
      error = false;
    }

    setEmailValid(valid);
    setEmailError(error);
  };
  const passwordValidation = () => {
    let valid = true;
    let error = false;

    if (password === "") {
      setPasswordErrorMessage(t("login.password_is_required"));
      valid = false;
      error = true;
    } else if (password.length < 6) {
      setPasswordErrorMessage(
        t("login.password_must_be_n_characters", { n: 6 })
      );
      valid = false;
      error = true;
    } else {
      setPasswordErrorMessage("");
      valid = true;
      error = false;
    }

    setPasswordValid(valid);
    setPasswordError(error);
  };

  // Check for email and password validation
  useEffect(() => {
    emailValidation();
    passwordValidation();
  }, [
    email,
    password,
    emailFocus,
    passwordFocus,
    emailErrorMessage,
    passwordErrorMessage,
  ]);

  return (
    <Box
      id="account-signup-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="create-account-label">
          {t("signup.create_new_account")}
        </div>
        <div className="email-form-field-container">
          <TextField
            className="email-form-field custom-text-field"
            required
            value={email}
            error={emailError}
            onChange={onEmailChange}
            label={t("signup.email_address")}
            placeholder={t("signup.email_address")}
            helperText={emailErrorMessage}
            variant="standard"
            onFocus={onEmailFocus}
          />
        </div>

        <div className="password-form-field-container">
          <TextField
            className="password-form-field custom-text-field"
            required
            value={password}
            error={passwordError}
            onChange={onPasswordChange}
            label={t("signup.password")}
            type={showPassword ? "text" : "password"}
            placeholder={t("signup.password")}
            helperText={passwordErrorMessage}
            variant="standard"
            onFocus={onPasswordFocus}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onShowPassword}>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <Button
          className={`signup-button ${isFormValid() ? "" : "disabled-button"}`}
          variant="text"
          onClick={onSignup}
          disabled={!isFormValid()}
        >
          {signupLoading ? (
            <CircularProgress className="button-spinner" size={24} />
          ) : (
            t("signup.sign_up")
          )}
        </Button>

        <div className="terms-and-condition">
          {t("signup.signup_agree")}{" "}
          <span className="terms" onClick={onNavigateToTerms}>
            {t("signup.signup_terms")}
          </span>{" "}
          &{" "}
          <span className="privacy-policy" onClick={onNavigateToPrivacyPolicy}>
            {t("signup.signup_policy")}
          </span>
          . {t("signup.signup_desc")}
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        message={signupErrorData?.data?.message}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={"bottom" + "center"}
      ></Snackbar>
    </Box>
  );
};

export default AccountSignup;
