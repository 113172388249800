// General
import "./incoming-private-call-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import {
  useLazyGetPrivateCallRequestDetailsQuery,
  useAcceptPrivateCallRequestMutation,
  useRejectPrivateCallRequestMutation,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  startPrivateCallCallingTimer,
  resetPrivateCallCallingTimer,

  // Interval Functions
  updatePrivateCallCallingInterval,
  clearPrivateCallCallingInterval,
} from "../../../../redux/store/privateCallStore";
import { updateIncomingPrivateCallDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Divider } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";
import Spinner from "../../elements/spinner/spinner";

const IncomingPrivateCallDialog = () => {
  // API variables
  const [
    getPrivateCallRequestDetails,
    {
      data: getPrivateCallRequestDetailsData,
      error: getPrivateCallRequestDetailsErrorData,
      isFetching: getPrivateCallRequestDetailsFetching,
      isLoading: getPrivateCallRequestDetailsLoading,
      isSuccess: getPrivateCallRequestDetailsSuccess,
      isError: getPrivateCallRequestDetailsError,
    },
  ] = useLazyGetPrivateCallRequestDetailsQuery();
  const [
    acceptPrivateCallRequest,
    {
      data: acceptPrivateCallRequestData,
      error: acceptPrivateCallRequestErrorData,
      isLoading: acceptPrivateCallRequestLoading,
      isSuccess: acceptPrivateCallRequestSuccess,
      isError: acceptPrivateCallRequestError,
    },
  ] = useAcceptPrivateCallRequestMutation();
  const [
    rejectPrivateCallRequest,
    {
      data: rejectPrivateCallRequestData,
      error: rejectPrivateCallRequestErrorData,
      isLoading: rejectPrivateCallRequestLoading,
      isSuccess: rejectPrivateCallRequestSuccess,
      isError: rejectPrivateCallRequestError,
    },
  ] = useRejectPrivateCallRequestMutation();

  // Redux variables
  const incomingPrivateCallDialog = useSelector(
    (state) => state.dialog.incomingPrivateCallDialog
  );
  const privateCallCallingTimer = useSelector(
    (state) => state.privateCall.privateCallCallingTimer
  );
  const isCaller = useSelector((state) => state.privateCall.isCaller);
  const requestId = useSelector((state) => state.privateCall.requestId);
  const callerUsername = useSelector(
    (state) => state.privateCall.callerUsername
  );
  const callerProfilePhoto = useSelector(
    (state) => state.privateCall.callerProfilePhoto
  );
  const calleeUsername = useSelector(
    (state) => state.privateCall.calleeUsername
  );
  const calleeProfilePhoto = useSelector(
    (state) => state.privateCall.calleeProfilePhoto
  );
  const callerCoins = useSelector((state) => state.privateCall.callerCoins);
  const calleeDiamonds = useSelector(
    (state) => state.privateCall.calleeDiamonds
  );
  const calleeRatePerMinute = useSelector(
    (state) => state.privateCall.calleeRatePerMinute
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (incomingPrivateCallDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-IncomingPrivateCall-Dialog",
        },
      });

      dispatch(
        updatePrivateCallCallingInterval(
          setInterval(() => {
            dispatch(startPrivateCallCallingTimer());
          }, 1000)
        )
      );
    } else {
      dispatch(clearPrivateCallCallingInterval());
      dispatch(resetPrivateCallCallingTimer());
    }
  }, [incomingPrivateCallDialog]);

  // Lifecycle | Check for update | Private Call Request Details API Response
  useEffect(() => {
    if (
      getPrivateCallRequestDetailsFetching ||
      getPrivateCallRequestDetailsLoading
    ) {
    } else if (getPrivateCallRequestDetailsSuccess) {
      const obj = {
        call_request_id: requestId,
      };

      acceptPrivateCallRequest(obj);
    } else if (getPrivateCallRequestDetailsError) {
    }
  }, [
    getPrivateCallRequestDetailsFetching,
    getPrivateCallRequestDetailsLoading,
    getPrivateCallRequestDetailsSuccess,
    getPrivateCallRequestDetailsError,
  ]);

  // Lifecycle | Check for update | Accept Private Call Request API Response
  useEffect(() => {
    if (acceptPrivateCallRequestLoading) {
    } else if (acceptPrivateCallRequestSuccess) {
      onCloseDialog();
    } else if (acceptPrivateCallRequestError) {
    }
  }, [
    acceptPrivateCallRequestLoading,
    acceptPrivateCallRequestSuccess,
    acceptPrivateCallRequestError,
  ]);

  // Lifecycle | Check for update | Reject Private Call Request API Response
  useEffect(() => {
    if (rejectPrivateCallRequestLoading) {
    } else if (rejectPrivateCallRequestSuccess) {
      onCloseDialog();
    } else if (rejectPrivateCallRequestError) {
    }
  }, [
    rejectPrivateCallRequestLoading,
    rejectPrivateCallRequestSuccess,
    rejectPrivateCallRequestError,
  ]);

  // Event Handlers | Button
  const onDeclineCall = () => {
    if (rejectPrivateCallRequestLoading) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-IncomingPrivateCall-Dialog-DeclineCall-Button",
      },
    });

    const obj = {
      call_request_id: requestId,
    };

    rejectPrivateCallRequest(obj);
  };
  const onAcceptCall = () => {
    if (getPrivateCallRequestDetailsFetching || acceptPrivateCallRequestLoading)
      return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-IncomingPrivateCall-Dialog-AcceptCall-Button",
      },
    });

    getPrivateCallRequestDetails(`?call_request_id=${requestId}`);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateIncomingPrivateCallDialog(false));
  };

  return (
    <Dialog
      className="custom-fullscreen-transparent-dialog"
      fullScreen
      open={incomingPrivateCallDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div id="incoming-private-call-dialog">
        <div className="call-request-container">
          <div className="padding-container">
            <div className="call-request-animation-container">
              {getIcon("callRequestGif", "call-request-animation")}
            </div>

            <div className="profile-photos-container">
              <CustomAvatar
                className="caller-picture"
                src={isCaller ? callerProfilePhoto : calleeProfilePhoto}
              />

              <CustomAvatar
                className="callee-picture"
                src={!isCaller ? callerProfilePhoto : calleeProfilePhoto}
              />
            </div>

            <div className="call-request-header">
              {t("inbox.chat_request_you_accept", {
                user: isCaller ? calleeUsername : callerUsername,
              })}
            </div>

            <div className="call-request-rate-description">
              {isDaddy ? (
                <Trans
                  i18nKey={"inbox.chat_request_charging"}
                  values={{ coins: callerCoins }}
                  components={{
                    coinicon: getIcon("sbCoinIcon", "coin-icon"),
                    br: <br />,
                    coin: <span className="coin-text" />,
                  }}
                />
              ) : (
                <Trans
                  i18nKey={"inbox.chat_request_earning"}
                  values={{
                    diamond: `+${calleeDiamonds}`,
                    amount: `(USD$ ${calleeRatePerMinute})`,
                  }}
                  components={{
                    diamondicon: getIcon("sbDiamondIcon", "diamond-icon"),
                    br: <br />,
                    amount: <span className="amount-text" />,
                    diamond: <span className="diamond-text" />,
                  }}
                />
              )}
            </div>
          </div>

          <Divider />

          <div className="padding-container">
            <div className="tips-title">
              {t("inbox.chat_request_tips_title")}
            </div>

            <ul className="tips-list">
              <li>
                {isDaddy
                  ? t("inbox.chat_request_sd_tip_1")
                  : t("inbox.chat_request_sb_tip_1")}
              </li>
              <li>
                {isDaddy
                  ? t("inbox.chat_request_sd_tip_2")
                  : t("inbox.chat_request_sb_tip_2")}
              </li>
              <li>
                {isDaddy
                  ? t("inbox.chat_request_sd_tip_3")
                  : t("inbox.chat_request_sb_tip_3")}
              </li>
            </ul>
          </div>
        </div>

        <div className="button-container">
          <div className="decline-button" onClick={onDeclineCall}>
            {rejectPrivateCallRequestLoading ? (
              <Spinner size={20} isPadding={false} color={"white-spinner"} />
            ) : (
              t("inbox.call_decline")
            )}
          </div>
          <div className="accept-button" onClick={onAcceptCall}>
            {getPrivateCallRequestDetailsFetching ||
            acceptPrivateCallRequestLoading ? (
              <Spinner size={20} isPadding={false} color={"white-spinner"} />
            ) : (
              `${t("inbox.call_accept")}
              ${privateCallCallingTimer} s`
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default IncomingPrivateCallDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
