// General
import "./top-fans-tab.scss";
import { useState, useEffect } from "react";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import DailyTab from "./daily-tab/daily-tab";
import WeeklyTab from "./weekly-tab/weekly-tab";
import MonthlyTab from "./monthly-tab/monthly-tab";
import OverallTab from "./overall-tab/overall-tab";

const TopFansTab = () => {
  // General variables
  const [value, setValue] = useState(0);

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(value);

    emblaApi?.on("select", () => {
      setValue(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-LivestreamProfileView-Dialog-TopFansTab-TabChangeClick-Button-${
          newValue === 0
            ? "DailyTab"
            : newValue === 1
            ? "WeeklyTab"
            : newValue === 2
            ? "MonthlyTab"
            : "OverallTab"
        }`,
      },
    });

    emblaApi?.scrollTo(newValue);
  };

  return (
    <div id="viewer-info-top-fans-tab-subcomponent">
      <div className="tab-container">
        <Tabs
          className="custom-tabs"
          value={value}
          onChange={onTabChange}
          TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
          variant="fullWidth"
        >
          <Tab label={t("top-fan.tabs.daily")} />
          <Tab label={t("top-fan.tabs.weekly")} />
          <Tab label={t("top-fan.tabs.monthly")} />
          <Tab label={t("top-fan.tabs.overall")} />
        </Tabs>

        <div ref={emblaRef} className="embla">
          <div className="embla__container">
            <div className="embla__slide">{value === 0 && <DailyTab />}</div>

            <div className="embla__slide">{value === 1 && <WeeklyTab />}</div>

            <div className="embla__slide">{value === 2 && <MonthlyTab />}</div>

            <div className="embla__slide">{value === 3 && <OverallTab />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopFansTab;
