// General
import "./logout.scss";
import { useEffect } from "react";
// Services
import { useLogoutMutation } from "../../services/data.service";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useLogout from "../utility/custom-hooks/useLogout-hook";
// Components
import Spinner from "../shared/elements/spinner/spinner";

const Logout = () => {
  // API variables
  const [
    logout,
    {
      data: logoutData,
      error: logoutErrorData,
      isLoading: logoutLoading,
      isSuccess: logoutSuccess,
      isError: logoutError,
    },
  ] = useLogoutMutation();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onLogout = useLogout();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Logout-Page",
      },
    });

    logout();
  }, []);

  // Lifecycle | Check for update | Logout API Response
  useEffect(() => {
    if (logoutLoading) {
    } else if (logoutSuccess) {
      switch (logoutData?.status) {
        case 0:
        case -1:
        case -2:
          onLogout();
          break;
        default:
          break;
      }
    } else if (logoutError) {
    }
  }, [logoutLoading, logoutSuccess, logoutError]);

  return (
    <div id="logout-page">
      <div className="padding-container">
        <Spinner type={"alt"} />
        <div className="logout-label">{t("logout.logging_you_out")}</div>
      </div>
    </div>
  );
};

export default Logout;
