// General
import "./language-settings.scss";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateManualLanguage,
  updateLanguage,
} from "../../../../redux/store/noResetStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// i18next
import { useTranslation } from "react-i18next";

const LanguageSettings = () => {
  // Redux variables
  const manualLanguage = useSelector((state) => state.noReset.manualLanguage);
  const language = useSelector((state) => state.noReset.language);
  const dispatch = useDispatch();

  // i18next variables
  const { i18n, t } = useTranslation();

  // Event Handlers | MUI Toggle Button
  const onLanguageChange = (event) => {
    switch (event.target.value) {
      case utilityConst.language.system:
        const lng = navigator.language;

        let transformedLanguage = lng.toLowerCase();

        if (transformedLanguage.includes("ja")) {
          transformedLanguage = utilityConst.language.jaJp;
        } else if (transformedLanguage.includes("zh-tw")) {
          transformedLanguage = utilityConst.language.zhTw;
        } else {
          transformedLanguage = utilityConst.language.en;
        }

        // Set language config in Redux
        dispatch(updateManualLanguage(false));
        dispatch(updateLanguage(event.target.value));

        // Set language in i18next
        i18n.changeLanguage(lng);

        // Reload page
        window.location.reload(true);
        break;
      case utilityConst.language.en:
      case utilityConst.language.zhTw:
        // Set language config in Redux
        dispatch(updateManualLanguage(true));
        dispatch(updateLanguage(event.target.value));

        // Set language in i18next
        i18n.changeLanguage(event.target.value);

        // Reload page
        window.location.reload(true);
        break;
      default:
        break;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-Settings-Page-Manual-Language-Change-${event.target.value}`,
      },
    });
  };

  return (
    <div id="settings-language-settings-subcomponent">
      <div className="padding-container">
        <div className="language-label">{t("settings.language")}</div>

        <div className="language-description-container">
          <ErrorOutlineIcon className="info-icon" />
          <div className="language-description">
            {t("settings.app_will_restart_automatically")}
          </div>
        </div>

        <div className="radio-button-container">
          <FormControl>
            <RadioGroup value={language} onChange={onLanguageChange}>
              <FormControlLabel
                value={utilityConst.language.system}
                control={<Radio />}
                label={t("settings.system")}
              />
              <FormControlLabel
                value={utilityConst.language.en}
                control={<Radio />}
                label={t("settings.english")}
              />
              <FormControlLabel
                value={utilityConst.language.zhTw}
                control={<Radio />}
                label={t("settings.traditional_chinese")}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default LanguageSettings;
