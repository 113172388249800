// General
import "./daily-tasks.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetUserAioQuery,
  useLazyGetLevellingTasksQuery,
  useClaimLevellingTasksMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import { updateProfileLevellingTab } from "../../../../redux/store/levellingStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const DailyTasks = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getLevellingTasks,
    {
      data: getLevellingTasksData,
      error: getLevellingTasksErrorData,
      isFetching: getLevellingTasksFetching,
      isLoading: getLevellingTasksLoading,
      isSuccess: getLevellingTasksSuccess,
      isError: getLevellingTasksError,
    },
  ] = useLazyGetLevellingTasksQuery();
  const [
    claimLevellingTasks,
    {
      data: claimLevellingTasksData,
      error: claimLevellingTasksErrorData,
      isLoading: claimLevellingTasksLoading,
      isSuccess: claimLevellingTasksSuccess,
      isError: claimLevellingTasksError,
    },
  ] = useClaimLevellingTasksMutation();

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();

  // Constant variables
  const badgeList = [
    getAsset("level1"),
    getAsset("level12"),
    getAsset("level23"),
    getAsset("level34"),
    getAsset("level45"),
    getAsset("level55"),
  ];

  // Lifecycle | Mounted
  useEffect(() => {
    getUserAio(null, true);
    getLevellingTasks(null, true);
  }, []);

  // Lifecycle | Check for update | Claim Levelling Task API Response
  useEffect(() => {
    if (claimLevellingTasksLoading) {
    } else if (claimLevellingTasksSuccess) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Levelling-Page-ClaimLevellingTaskClick-Success",
        },
      });
    } else if (claimLevellingTasksError) {
      if (claimLevellingTasksErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    claimLevellingTasksLoading,
    claimLevellingTasksSuccess,
    claimLevellingTasksError,
  ]);

  // Event Handlers | Button
  const onClaimed = (task_id) => {
    if (task_id === 3) {
      dispatch(updateProfileLevellingTab(0));
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Levelling-Page-ClaimLevellingTaskClick-Button",
        },
      });

      const obj = {
        task_id: task_id,
      };

      claimLevellingTasks(obj);
    }
  };

  if (getLevellingTasksFetching || getLevellingTasksLoading) {
    return <Spinner />;
  } else if (getLevellingTasksSuccess) {
    return (
      <div id="levelling-daily-tasks-subcomponent">
        <div className="padding-container">
          {getUserAioData?.data?.modules?.live_streaming_enabled && (
            <div className="boost-container">
              <div className="boost-label">{t("profile.boost_your_exp")}</div>

              <div className="boost-description">
                {t("profile.boost_your_exp_desc")}
              </div>

              <div className="boost-task-list-container">
                {getLevellingTasksData?.data?.tasks?.map((task, index) => (
                  <div className="boost-task" key={index}>
                    <div className="exp-icon-container">EXP</div>

                    <div className="boost-description-container">
                      <div className="boost-description-header">
                        {task.locale_title}
                      </div>
                      <div className="boost-description-text">
                        {task.locale_subtitle}
                      </div>
                    </div>

                    <Button
                      className={`claim-button ${
                        !task.completed ? "disabled-button" : ""
                      }`}
                      variant="text"
                      onClick={() => onClaimed(task.task_id)}
                      disabled={
                        !task?.check_ins && (!task?.completed || task?.claimed)
                      }
                    >
                      {task.check_ins
                        ? t("common.view")
                        : task.claimed
                        ? t("profile.claimed")
                        : t("profile.claim")}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="lifetime-container">
            <div className="lifetime-label">
              {t("profile.lifetime_rewards")}
            </div>

            <div className="lifetime-description">
              {t("profile.lifetime_rewards_desc")}
            </div>

            <div className="lifetime-task-list-container">
              {getLevellingTasksData?.data?.lifetime_tasks?.map(
                (task, index) => (
                  <div className="lifetime-task" key={index}>
                    <div className="exp-icon-container">EXP</div>

                    <div className="lifetime-description-container">
                      <div className="lifetime-description-header">
                        {task.locale_title}
                      </div>
                      <div className="lifetime-description-text">
                        {task.locale_subtitle}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="badge-container">
            <div className="badge-label">{t("profile.badge_recognition")}</div>

            <div className="badge-description">
              {t("profile.badge_recognition_desc")}
            </div>

            <div className="badge-list-container">
              {badgeList.map((badge, index) => (
                <div className="badge-icon-container" key={index}>
                  <img className="badge-icon" src={badge} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (getLevellingTasksError) {
  }
};

export default DailyTasks;
