// General
import "./user-info.scss";
import { useEffect } from "react";
// Services
import {
  usePostFollowUserMutation,
  usePostUnfollowUserMutation,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLivestreamerIsFollowing } from "../../../../../redux/store/livestreamingStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateAnnualIncome,
  updateNetworth,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  updateShowLastActive,
  updateLastActive,
  updateShowMemberSince,
  updateMemberSince,

  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../../redux/store/userCardStore";
import { updateUserProfileDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { CircularProgress, Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../../elements/custom-avatar/custom-avatar";

const UserInfo = () => {
  // API variables
  const [
    postFollowUser,
    {
      data: postFollowUserData,
      error: postFollowUserErrorData,
      isLoading: postFollowUserLoading,
      isSuccess: postFollowUserSuccess,
      isError: postFollowUserError,
    },
  ] = usePostFollowUserMutation();
  const [
    postUnfollowUser,
    {
      data: postUnfollowUserData,
      error: postUnfollowUserErrorData,
      isLoading: postUnfollowUserLoading,
      isSuccess: postUnfollowUserSuccess,
      isError: postUnfollowUserError,
    },
  ] = usePostUnfollowUserMutation();

  // Redux variables
  const livestreamerUserObj = useSelector(
    (state) => state.livestreaming.livestreamerUserObj
  );
  const livestreamerId = useSelector(
    (state) => state.livestreaming.livestreamerId
  );
  const livestreamerIdInt = useSelector(
    (state) => state.livestreaming.livestreamerIdInt
  );
  const livestreamerProfilePhoto = useSelector(
    (state) => state.livestreaming.livestreamerProfilePhoto
  );
  const livestreamerUsername = useSelector(
    (state) => state.livestreaming.livestreamerUsername
  );
  const livestreamerLevellingBadge = useSelector(
    (state) => state.livestreaming.livestreamerLevellingBadge
  );
  const livestreamerFollowers = useSelector(
    (state) => state.livestreaming.livestreamerFollowers
  );
  const livestreamerViewers = useSelector(
    (state) => state.livestreaming.livestreamerViewers
  );
  const livestreamerDiamonds = useSelector(
    (state) => state.livestreaming.livestreamerDiamonds
  );
  const livestreamerIsFollowing = useSelector(
    (state) => state.livestreaming.livestreamerIsFollowing
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Follow user API Response
  useEffect(() => {
    if (postFollowUserLoading) {
    } else if (postFollowUserSuccess) {
      if (postFollowUserData?.status === 1) {
        dispatch(updateLivestreamerIsFollowing(true));
      }
    } else if (postFollowUserError) {
      if (postFollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postFollowUserLoading, postFollowUserSuccess, postFollowUserError]);

  // Lifecycle | Check for update | Unfollow user API Response
  useEffect(() => {
    if (postUnfollowUserLoading) {
    } else if (postUnfollowUserSuccess) {
      if (postUnfollowUserData?.status === 1) {
        dispatch(updateLivestreamerIsFollowing(false));
      }
    } else if (postUnfollowUserError) {
      if (postUnfollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postUnfollowUserLoading, postUnfollowUserSuccess, postUnfollowUserError]);

  // Event Handlers | Button
  const onViewProfileCard = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LivestreamProfileView-Dialog-ViewProfileCard-Button",
      },
    });

    dispatch(updateId(livestreamerUserObj?.id));
    dispatch(updateIdInt(livestreamerUserObj?.id_int));
    dispatch(updateUsername(livestreamerUserObj?.username));
    dispatch(updateAge(livestreamerUserObj?.appearance?.age));
    dispatch(updateLocation(livestreamerUserObj?.location?.city));
    dispatch(updateMembershipType(livestreamerUserObj?.membership_type));
    dispatch(updateVerifiedProfile(livestreamerUserObj?.is_verified_profile));
    dispatch(updateVerifiedCaller(livestreamerUserObj?.verified_caller));
    dispatch(
      updateLevellingBadge(livestreamerUserObj?.levelling_profile?.badge)
    );
    dispatch(
      updateLivestreamingAchievementBadge(
        livestreamerUserObj?.live_streaming_achievements?.chat_badges_set
      )
    );
    dispatch(
      updateProfilePhoto(livestreamerUserObj?.profile_photo?.original_photo)
    );
    dispatch(
      updatePublicPhotos(
        livestreamerUserObj?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
          };
        })
      )
    );
    dispatch(
      updatePrivatePhotos(
        livestreamerUserObj?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            thumbnailData: photo?.thumbnail_data,
          };
        })
      )
    );
    dispatch(updateTorTags(livestreamerUserObj?.tor_tags));
    dispatch(
      updateProfileAchievementBadge(
        livestreamerUserObj?.live_streaming_achievements?.profile_badges
      )
    );
    dispatch(updateAboutMe(livestreamerUserObj?.aboutme));
    dispatch(updateLookingFor(livestreamerUserObj?.lookingfor));
    dispatch(
      updateAnnualIncome(livestreamerUserObj?.occupation?.annual_income)
    );
    dispatch(updateNetworth(livestreamerUserObj?.occupation?.net_worth));
    dispatch(updateOccupation(livestreamerUserObj?.occupation?.occupation));
    dispatch(
      updateLifestyle(livestreamerUserObj?.occupation?.lifestyle_budget)
    );
    dispatch(updateEducation(livestreamerUserObj?.occupation?.education));
    dispatch(
      updateRelationship(livestreamerUserObj?.relationship?.relationship_status)
    );
    dispatch(updateSmoking(livestreamerUserObj?.habit?.smoking));
    dispatch(updateDrinking(livestreamerUserObj?.habit?.drinking));
    dispatch(updateHeight(livestreamerUserObj?.appearance?.height));
    dispatch(updateBodyType(livestreamerUserObj?.appearance?.body_type));
    dispatch(updateEthnicity(livestreamerUserObj?.appearance?.ethnicity));
    dispatch(updateEyeColor(livestreamerUserObj?.appearance?.eye_color));
    dispatch(updateHairColor(livestreamerUserObj?.appearance?.hair_color));
    dispatch(updateChildren(livestreamerUserObj?.relationship?.children));
    dispatch(
      updateShowLastActive(livestreamerUserObj?.activity?.activity_display)
    );
    dispatch(
      updateLastActive(livestreamerUserObj?.activity?.last_seen_timestamp)
    );
    dispatch(
      updateShowMemberSince(livestreamerUserObj?.activity?.join_date_display)
    );
    dispatch(
      updateMemberSince(livestreamerUserObj?.activity?.joined_timestamp)
    );
    dispatch(updateShouldCallApi(false));
    dispatch(
      updatePrivatePhotoRequested(
        livestreamerUserObj?.interaction?.private_photo_requested
      )
    );
    dispatch(
      updatePrivatePhotoApproval(
        livestreamerUserObj?.interaction?.private_photo_approval
      )
    );
    dispatch(
      updateRequestedMyPrivatePhoto(
        livestreamerUserObj?.interaction?.requested_my_private_photo
      )
    );
    dispatch(
      updateApprovedMyPrivatePhoto(
        livestreamerUserObj?.interaction?.approved_my_private_photo
      )
    );
    dispatch(
      updateIsBlocked(
        livestreamerUserObj?.interaction?.is_blocked ? true : false
      )
    );
    dispatch(
      updateIsSilentInteraction(
        livestreamerUserObj?.interaction?.should_silent_interactions
      )
    );
    dispatch(updateIsFavorited(livestreamerUserObj?.interaction?.is_favorited));
    dispatch(
      updateIsCallEnabled(
        livestreamerUserObj?.private_call_settings?.enable_calls &&
          livestreamerUserObj?.private_call_settings
            ?.system_private_calls_enabled
      )
    );
    dispatch(
      updateIsSugarbaby(
        livestreamerUserObj?.role?.toUpperCase() ===
          userConst.userRole.sugarbaby ||
          livestreamerUserObj?.role?.toUpperCase() ===
            userConst.userRole.maleSugarbaby
      )
    );
    dispatch(updateRole(livestreamerUserObj?.role));

    dispatch(updateUserProfileDialog(true));
  };
  const onFollowUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LivestreamProfileView-Dialog-FollowUser-Button",
      },
    });

    const obj = {
      user_id: livestreamerId,
    };

    postFollowUser(obj);
  };
  const onUnfollowUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LivestreamProfileView-Dialog-UnfollowUser-Button",
      },
    });

    const obj = {
      user_id: livestreamerId,
    };

    postUnfollowUser(obj);
  };

  // Utility Functions
  const onGetDiamonds = (diamonds) => {
    if (diamonds >= 1000) {
      return `${(diamonds / 1000).toFixed(0)}K`;
    } else if (diamonds >= 1000000) {
      return `${(diamonds / 1000000).toFixed(0)}M`;
    } else if (diamonds >= 1000000000) {
      return `${(diamonds / 1000000000).toFixed(0)}B`;
    } else {
      return diamonds;
    }
  };

  return (
    <div id="livestream-profile-view-dialog-user-info-subcomponent">
      <div className="padding-container">
        <div className="user-info-container">
          <div className="profile-picture-container">
            <CustomAvatar
              className="profile-picture"
              src={livestreamerProfilePhoto}
              role={userConst.userRole.sugarbaby}
              onClick={onViewProfileCard}
            />

            {livestreamerLevellingBadge && (
              <div className="levelling-badge-container">
                <img
                  className="levelling-badge"
                  src={livestreamerLevellingBadge}
                  alt="levellling-badge"
                />
              </div>
            )}
          </div>

          <div className="user-details-container">
            <div className="top-container">
              <div className="username-container">
                {livestreamerUserObj?.live_streaming_achievements
                  ?.chat_badges_set?.length > 0 && (
                  <div className="achievement-container">
                    {livestreamerUserObj?.live_streaming_achievements?.chat_badges_set?.map(
                      (badge, index) => (
                        <img
                          className="livestreamer-badge"
                          src={badge?.url}
                          key={index}
                        />
                      )
                    )}
                  </div>
                )}
                <div className="username">{livestreamerUsername || "-"}</div>
              </div>

              {!livestreamerIsFollowing && (
                <div className="follow-button" onClick={onFollowUser}>
                  {postFollowUserLoading ? (
                    <CircularProgress
                      className="button-spinner-red"
                      size={12}
                    />
                  ) : (
                    t("common.follow")
                  )}
                </div>
              )}

              {livestreamerIsFollowing && (
                <div className="unfollow-button" onClick={onUnfollowUser}>
                  {postUnfollowUserLoading ? (
                    <CircularProgress
                      className="button-spinner-white"
                      size={12}
                    />
                  ) : (
                    t("common.unfollow")
                  )}
                </div>
              )}
            </div>

            <div className="user-stats-container">
              <div className="follower-container">
                <div className="followers-count">
                  {livestreamerFollowers || 0}
                </div>
                <div className="followers-label">{t("live.followers")}</div>
              </div>

              <Divider
                className="vertical-divider"
                orientation="vertical"
                flexItem
              />

              <div className="viewer-container">
                <div className="viewers-count">{livestreamerViewers || 0}</div>
                <div className="viewers0label">{t("live.viewers")}</div>
              </div>

              <Divider
                className="vertical-divider"
                orientation="vertical"
                flexItem
              />

              <div className="diamonds-container">
                <div className="diamonds-count">
                  {onGetDiamonds(livestreamerDiamonds) || 0}
                </div>
                <div className="diamonds-label">{t("live.diamonds")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
