// General
import "./recent-list.scss";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useDispatch } from "react-redux";
import { updateVideoCallInfoDialog } from "../../../../../redux/store/dialogStore";
import { updateVideoCallHistoryInfo } from "../../../../../redux/store/privateCallStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useGetReadableDate from "../../../../utility/custom-hooks/useGetReadableDate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";

const RecentList = (props) => {
  const {
    id,
    idInt,
    profilePhoto,
    username,
    membershipType,
    levellingBadge,
    livestreamingAchievementsBadge,
    verifiedProfile,
    status,
    callStartAt,
    callEndAt,
    callUpdatedAt,
    duration,
    conversationId,
  } = props;

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getReadableDate = useGetReadableDate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onViewVideoCallInfo = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VideoCall-Page-RecentTab-ViewVideoCallInfo-Button",
      },
    });

    const obj = {
      id,
      idInt,
      profilePhoto,
      username,
      membershipType,
      levellingBadge,
      livestreamingAchievementsBadge,
      verifiedProfile,
      status,
      callStartAt,
      callEndAt,
      duration,
      conversationId,
    };
    dispatch(updateVideoCallHistoryInfo(obj));
    dispatch(updateVideoCallInfoDialog(true));
  };

  // Utility Functions
  const getLastMessage = (callStatus) => {
    switch (callStatus) {
      case "rejected":
        return t("inbox.call_declined");
      case "cancelled":
        return t("inbox.call_cancelled");
      case "ended":
        return `${t("inbox.call_ended")} (${getCallDuration(duration)})`;
      case "missed":
        return t("inbox.call_not_answered");
      default:
        break;
    }
  };
  const getCallDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div
      id="video-call-recent-tab-recent-list-subcomponent"
      onClick={onViewVideoCallInfo}
    >
      <div className="padding-container">
        <div className="recent-container">
          <div className="user-image-container">
            <CustomAvatar className="user-image" src={profilePhoto} />

            {/* Architecture not ready */}
            {false && (
              <div className="video-call-available-icon-container">
                {getIcon("videoCallAvailableIcon", "video-call-available-icon")}
              </div>
            )}

            {/* Architecture not ready */}
            {false && (
              <div className="video-call-missed-icon-container">
                {getIcon("videoCallMissedIcon", "video-call-missed-icon")}
              </div>
            )}
          </div>

          <div className="user-description-container">
            <div className="user-top-container">
              <div className="livestreaming-achievement-badge-container">
                {livestreamingAchievementsBadge?.length > 0 &&
                  livestreamingAchievementsBadge?.map((badge, index) => (
                    <img
                      className="livestreaming-achievement-badge"
                      src={badge?.url}
                      alt="badge"
                      key={index}
                    />
                  ))}
              </div>

              <div className="user-name">{username}</div>

              <div className="user-badge-container">
                {verifiedProfile && (
                  <div className="verified-badge-container">
                    {getIcon("verifiedProfileBadge", "verified-badge")}
                  </div>
                )}

                {membershipType === userConst.membershipType.admin && (
                  <div className="admin-badge-container">
                    <PersonIcon className="person-icon" />
                    <div className="admin-label">Admin</div>
                  </div>
                )}

                {membershipType !== userConst.membershipType.free &&
                  membershipType !== userConst.membershipType.admin && (
                    <div className="premium-mini-badge-container">
                      {getIcon("premiumMiniBadge", "premium-mini-badge")}
                    </div>
                  )}

                {levellingBadge && (
                  <div className="level-badge-container">
                    <img
                      className="level-badge"
                      src={levellingBadge}
                      alt="level"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="user-bottom-container">
              <div className="user-last-message">{getLastMessage(status)}</div>
            </div>
          </div>

          <div className="user-last-updated-container">
            <div className="last-updated">{getReadableDate(callUpdatedAt)}</div>
          </div>

          <div className="info-icon-container">
            <ErrorOutlineIcon className="info-icon" />
          </div>
        </div>
      </div>

      <Divider light />
    </div>
  );
};

export default RecentList;
