// General
import "./navbar-desktop-view.scss";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetProfileCheckQuery,
  useLazyGetUserAioQuery,
  useLazyGetBadgeCountQuery,
  useLazyGetStreamerCenterQuery,
  useLazyGetVirtualCurrencyCoinBalanceQuery,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateInterestTab } from "../../../../../redux/store/interestStore";
import { updateUserProfileDialog } from "../../../../../redux/store/dialogStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../../redux/store/userCardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import {
  Divider,
  Menu,
  MenuItem,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Badge,
  useMediaQuery,
} from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import useCompactNumbers from "../../../../utility/custom-hooks/useCompactNumbers-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";
import NotificationButton from "./notification-button/notification-button";

const NavbarDesktopView = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getProfileCheck,
    {
      data: getProfileCheckData,
      error: getProfileCheckErrorData,
      isFetching: getProfileCheckFetching,
      isLoading: getProfileCheckLoading,
      isSuccess: getProfileCheckSuccess,
      isError: getProfileCheckError,
    },
  ] = useLazyGetProfileCheckQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getBadgeCount,
    {
      data: getBadgeCountData,
      error: getBadgeCountErrorData,
      isFetching: getBadgeCountFetching,
      isLoading: getBadgeCountLoading,
      isSuccess: getBadgeCountSuccess,
      isError: getBadgeCountError,
    },
  ] = useLazyGetBadgeCountQuery();
  const [
    getStreamerCenter,
    {
      data: getStreamerCenterData,
      error: getStreamerCenterErrorData,
      isFetching: getStreamerCenterFetching,
      isLoading: getStreamerCenterLoading,
      isSuccess: getStreamerCenterSuccess,
      isError: getStreamerCenterError,
    },
  ] = useLazyGetStreamerCenterQuery();
  const [
    getCoins,
    {
      data: getCoinsData,
      error: getCoinsErrorData,
      isFetching: getCoinsFetching,
      isLoading: getCoinsLoading,
      isSuccess: getCoinsSuccess,
      isError: getCoinsError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();

  // General variables
  const [showNavbar, setShowNavbar] = useState(true);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const releaseChannel = useSelector((state) => state.public.releaseChannel);
  const showVideoCall = useSelector((state) => state.app.showVideoCall);
  const dispatch = useDispatch();

  // MUI variables
  const [drawerState, setDrawerState] = useState({
    left: false,
  });
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const openSettingsMenu = Boolean(anchorElSettings);
  const isTablet = useMediaQuery("(min-width: 960px)");
  const showLogo = useMediaQuery("(min-width: 860px)");

  // Router variables
  const location = useLocation();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getCompactNumbers = useCompactNumbers();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getProfile(null, true);
    getProfileCheck(null, true);
    getUserAio(null, true);
    getBadgeCount();
    getStreamerCenter(null, false);
    getCoins(null, false);
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Hide navbar based on route
  useEffect(() => {
    if (
      location.pathname.includes(routeConst.videoCall.privateStandby.path) ||
      location.pathname.includes(routeConst.videoCall.room.path) ||
      location.pathname.includes(routeConst.verify.emailVerification.path) ||
      location.pathname.includes(routeConst.verify.emailVerification.altPath) ||
      location.pathname.includes(routeConst.verify.mobileVerification.path) ||
      location.pathname.includes(
        routeConst.verify.mobileVerification.altPath
      ) ||
      location.pathname.includes(routeConst.phoneAuthentication.path) ||
      location.pathname.includes(routeConst.phoneAuthentication.altPath)
    ) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }, [location]);

  // Event Handlers | Button
  const onRedirect = (path) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-DesktopNavbar-RedirectTo-${path}`,
      },
    });

    getBadgeCount();

    if (path === routeConst.interest.path) {
      dispatch(updateInterestTab(1));
    }
  };
  const onRedirectToLanding = () => {
    // check if domain has .my
    const isMyDomain = window.location.hostname.includes(".my");

    if (isMyDomain) {
      window.open("https://sugarbook.com.my", "_self");
    } else {
      window.open("https://sugarbook.com", "_self");
    }
  };
  const onRedirectToStreamerCenter = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DesktopNavbar-StreamerCenter-Button",
      },
    });
  };
  const onRedirectToWallet = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DesktopNavbar-Wallet-Button",
      },
    });
  };
  const onRedirectToLeaderboard = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DesktopNavbar-Leaderboard-Button",
      },
    });
  };
  const onUpgrade = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DesktopNavbar-Upgrade-Button",
      },
    });
  };
  const onViewProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Navbar-Desktop-ViewOwnProfile-Button",
      },
    });

    // User View
    dispatch(updateId(getProfileData?.data?.id));
    dispatch(updateIdInt(getProfileData?.data?.id_int));
    dispatch(updateUsername(getProfileData?.data?.username));
    dispatch(updateAge(getProfileData?.data?.appearance?.age));
    dispatch(updateLocation(getProfileData?.data?.location?.city));
    dispatch(updateMembershipType(getProfileData?.data?.membership_type));
    dispatch(updateVerifiedProfile(getProfileData?.data?.is_verified_profile));
    dispatch(updateVerifiedCaller(getProfileData?.data?.verified_caller));
    dispatch(
      updateLevellingBadge(getProfileData?.data?.levelling_profile?.badge)
    );
    dispatch(
      updateLivestreamingAchievementBadge(
        getProfileData?.data?.live_streaming_achievements?.chat_badges_set
      )
    );
    dispatch(
      updateProfilePhoto(getProfileData?.data?.profile_photo?.original_photo)
    );
    dispatch(
      updatePublicPhotos(
        getProfileData?.data?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
          };
        })
      )
    );
    dispatch(
      updatePrivatePhotos(
        getProfileData?.data?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            status: photo?.photo_status,
            thumbnailData: photo?.thumbnail_data,
          };
        })
      )
    );
    dispatch(updateTorTags(getProfileData?.data?.tor_tags));
    dispatch(
      updateProfileAchievementBadge(
        getProfileData?.data?.live_streaming_achievements?.profile_badges
      )
    );
    dispatch(updateAboutMe(getProfileData?.data?.aboutme));
    dispatch(updateLookingFor(getProfileData?.data?.lookingfor));
    dispatch(updateOccupation(getProfileData?.data?.occupation?.occupation));
    dispatch(
      updateLifestyle(getProfileData?.data?.occupation?.lifestyle_budget)
    );
    dispatch(updateEducation(getProfileData?.data?.occupation?.education));
    dispatch(
      updateRelationship(
        getProfileData?.data?.relationship?.relationship_status
      )
    );
    dispatch(updateSmoking(getProfileData?.data?.habit?.smoking));
    dispatch(updateDrinking(getProfileData?.data?.habit?.drinking));
    dispatch(updateHeight(getProfileData?.data?.appearance?.height));
    dispatch(updateBodyType(getProfileData?.data?.appearance?.body_type));
    dispatch(updateEthnicity(getProfileData?.data?.appearance?.ethnicity));
    dispatch(updateEyeColor(getProfileData?.data?.appearance?.eye_color));
    dispatch(updateHairColor(getProfileData?.data?.appearance?.hair_color));
    dispatch(updateChildren(getProfileData?.data?.relationship?.children));
    // User Utility
    dispatch(updateShouldCallApi(false));
    dispatch(updatePrivatePhotoRequested(true));
    dispatch(updatePrivatePhotoApproval(true));
    dispatch(updateIsBlocked(false));
    dispatch(updateIsSilentInteraction(true));
    dispatch(updateIsFavorited(false));
    dispatch(updateIsCallEnabled(false));
    dispatch(updateIsSugarbaby(false));
    dispatch(updateRole(getProfileData?.data?.role));

    // dispatch(updateUserProfileDialog(true)); // Dialog
    onNavigate(
      `${routeConst.profile.view.clearPath}${getProfileData?.data?.id}`
    );
  };
  const onNavigateToBlogs = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DesktopNavbar-Blogs-Button",
      },
    });

    window.open(routeConst.blogs.path, "_blank");
  };
  const onNavigateToEvents = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DesktopNavbar-Events-Button",
      },
    });

    window.open(routeConst.events.path, "_blank");
  };
  const onLogout = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DesktopNavbar-Logout-Button",
      },
    });

    onNavigate(routeConst.logout.path);
  };

  // Event Handlers | MUI Menu
  const onOpenMenu = (state) => (event) => {
    switch (state) {
      case "chat":
        break;
      case "sugar":
        break;
      case "event":
        break;
      case "language":
        break;
      case "settings":
        setAnchorElSettings(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const onCloseMenu = (state) => {
    switch (state) {
      case "chat":
        break;
      case "sugar":
        break;
      case "event":
        break;
      case "language":
        break;
      case "settings":
        setAnchorElSettings(null);
        break;
      case "sugarbook-verified":
        setAnchorElSettings(null);
        break;
      default:
        break;
    }
  };

  if (showNavbar) {
    return (
      <div id="navbar-navbar-desktop-view-subcomponent">
        <div className="space-around">
          {isLoggedIn && (
            <div className="login-view">
              <div className="logo-navlink-container">
                {showLogo && (
                  <div
                    className="sugarbook-logo-container"
                    onClick={
                      isLoggedIn
                        ? () => onNavigate(routeConst.search.path)
                        : () => onNavigate(routeConst.landing.path)
                    }
                  >
                    {isTablet
                      ? getIcon("sugarbookLogo", "sugarbook-logo")
                      : getIcon("sugarbookIcon", "sugarbook-logo")}
                  </div>
                )}

                <div
                  className={`navigation-left-container ${
                    showLogo ? "default-gap" : "reduce-gap"
                  }`}
                >
                  <Link
                    className="link-container"
                    to={routeConst.search.path}
                    onClick={() => onRedirect(routeConst.search.path)}
                  >
                    <Badge
                      className="custom-badge"
                      badgeContent={0}
                      variant="dot"
                    >
                      <div className="link-logo-container">
                        {getIcon("searchDesktopIcon", "search-icon")}
                      </div>
                    </Badge>

                    <div className="link-label">{t("common.search")}</div>
                  </Link>

                  {getUserAioData?.data?.modules?.messaging_enabled && (
                    <Link
                      className="link-container"
                      to={routeConst.inbox.path}
                      onClick={() => onRedirect(routeConst.inbox.path)}
                    >
                      <Badge
                        className="custom-badge search-badge"
                        badgeContent={
                          releaseChannel !==
                            utilityConst.releaseChannel.stable &&
                          getBadgeCountData?.data?.counts?.new_message
                            ? 1
                            : 0
                        }
                        variant="dot"
                      >
                        <div className="link-logo-container">
                          {getIcon("messageDesktopIcon", "message-icon")}
                        </div>
                      </Badge>

                      <div className="link-label">{t("inbox.messages")}</div>
                    </Link>
                  )}

                  <Link
                    className="link-container"
                    to={routeConst.interest.path}
                    onClick={() => onRedirect(routeConst.interest.path)}
                  >
                    <Badge
                      className="custom-badge search-badge"
                      badgeContent={
                        releaseChannel !== utilityConst.releaseChannel.stable &&
                        (getBadgeCountData?.data?.counts?.new_favorited_me ||
                          getBadgeCountData?.data?.counts?.new_viewed_me)
                          ? 1
                          : 0
                      }
                      variant="dot"
                    >
                      <div className="link-logo-container">
                        {getIcon("favoriteDesktopIcon", "favorite-icon")}
                      </div>
                    </Badge>

                    <div className="link-label">{t("profile.favorites")}</div>
                  </Link>

                  {showVideoCall &&
                    getUserAioData?.data?.modules?.private_calls_enabled && (
                      <Link
                        className="link-container"
                        to={routeConst.videoCall.path}
                        onClick={() => onRedirect(routeConst.videoCall.path)}
                      >
                        <Badge
                          className="custom-badge search-badge"
                          badgeContent={0}
                          variant="dot"
                        >
                          <div className="link-logo-container">
                            {getIcon("videoCallDesktopIcon", "video-icon")}
                          </div>
                        </Badge>

                        <div className="link-label">{t("1on1.video_call")}</div>
                      </Link>
                    )}

                  {getUserAioData?.data?.modules?.live_streaming_enabled && (
                    <Link
                      className="link-container"
                      to={routeConst.live.path}
                      onClick={() => onRedirect(routeConst.live.path)}
                    >
                      <Badge
                        className="custom-badge search-badge"
                        badgeContent={0}
                        variant="dot"
                      >
                        <div className="link-logo-container">
                          {getIcon("liveDesktopIcon", "live-icon")}
                        </div>
                      </Badge>

                      <div className="link-label">
                        {t("mini-profile-tabs.live")}
                      </div>
                    </Link>
                  )}
                </div>
              </div>

              <div className="navigation-right-container">
                <div className="login-container">
                  <NotificationButton />

                  {isDaddy && getUserAioData?.data?.modules?.wallet_enabled && (
                    <Link
                      className="sb-coin-icon-container"
                      to={routeConst.profile.wallet.path}
                      onClick={onRedirectToWallet}
                    >
                      {getIcon("sbCoinIcon", "sb-coin-icon")}
                    </Link>
                  )}

                  {!isDaddy &&
                    getUserAioData?.data?.modules?.my_diamonds_enabled && (
                      <Link
                        className="diamond-icon-container"
                        to={routeConst.profile.streamerCenter.path}
                        onClick={onRedirectToStreamerCenter}
                      >
                        {getIcon("sbDiamondIcon", "diamond-icon")}
                      </Link>
                    )}

                  {(getUserAioData?.data?.modules
                    ?.private_calls_leaderboard_enabled ||
                    getUserAioData?.data?.modules
                      ?.live_streaming_leaderboard_enabled) && (
                    <Link
                      className="leaderboard-new-icon-container"
                      to={routeConst.leaderboard.path}
                      onClick={onRedirectToLeaderboard}
                    >
                      {getIcon("leaderboardNewIcon", "leaderboard-new-icon")}
                    </Link>
                  )}

                  {getProfileData?.data?.membership_type ===
                    userConst.membershipType.free && (
                    <Link
                      className="upgrade-button"
                      to={routeConst.upgrade.path}
                      onClick={onUpgrade}
                    >
                      {t("upgrade.upgrade")}
                    </Link>
                  )}

                  <div className="profile-container">
                    <div
                      className="profile-logo-container"
                      onClick={onViewProfile}
                    >
                      <CustomAvatar
                        className="profile-logo"
                        src={
                          getProfileData?.data?.profile_photo?.original_photo
                        }
                        role={getProfileData?.data?.role}
                      />
                    </div>

                    <div
                      className="dropdown-logo-container"
                      onClick={onOpenMenu("settings")}
                    >
                      {getIcon("keyboardArrowDownIcon", "")}
                    </div>

                    <Menu
                      id="settings-menu"
                      anchorEl={anchorElSettings}
                      open={openSettingsMenu}
                      onClose={() => onCloseMenu("sugarbook-verified")}
                    >
                      {!getProfileData?.data?.verifications?.facial?.verified &&
                        getProfileData?.data?.verifications?.facial
                          ?.verification_required && (
                          <MenuItem
                            onClick={() => {
                              onCloseMenu("sugarbook-verified");
                              onNavigate(
                                routeConst.verify.sugarbookVerified.path
                              );
                            }}
                          >
                            Get Sugarbook Verified{" "}
                            {getIcon(
                              "verifiedProfileBadge",
                              "navbar-verified-profile-badge"
                            )}
                          </MenuItem>
                        )}

                      {!getProfileData?.data?.verifications?.facial?.verified &&
                        getProfileData?.data?.verifications?.facial
                          ?.verification_required && <Divider />}

                      <MenuItem
                        onClick={() => {
                          onCloseMenu("settings");
                          onNavigate(routeConst.profile.edit.path);
                        }}
                      >
                        {getProfileCheckData?.payload?.percent < 100
                          ? t("profile.complete_profile")
                          : t("profile.edit_profile")}
                      </MenuItem>

                      <Divider />

                      {!isDaddy && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigate(routeConst.profile.streamerCenter.path);
                          }}
                        >
                          <div className="menu-item-container">
                            <div className="menu-item-label">
                              {t("profile.my_diamonds")}
                            </div>
                            <div className="menu-item-suffix-container">
                              {getIcon("sbDiamondIcon", "diamond-icon")}
                              <div className="suffix-label">
                                {getCompactNumbers(
                                  getStreamerCenterData?.data?.diamonds
                                    ?.balance || 0
                                ) || 0}
                              </div>
                            </div>
                          </div>
                        </MenuItem>
                      )}

                      {!isDaddy && <Divider />}

                      {getUserAioData?.data?.modules?.wallet_enabled && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigate(routeConst.profile.wallet.path);
                          }}
                        >
                          <div className="menu-item-container">
                            <div className="menu-item-label">
                              {t("profile.wallet")}
                            </div>
                            <div className="menu-item-suffix-container">
                              {getIcon("sbCoinIcon", "coin-icon")}
                              <div className="suffix-label">
                                {getCoinsData?.data?.balance?.toLocaleString() ||
                                  0}
                              </div>
                            </div>
                          </div>
                        </MenuItem>
                      )}

                      {getUserAioData?.data?.modules?.wallet_enabled && (
                        <Divider />
                      )}

                      {getUserAioData?.data?.modules?.private_calls_enabled && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigate(routeConst.settings.videoCall.path);
                          }}
                        >
                          {t("profile.1on1_video_call_settings")}
                        </MenuItem>
                      )}

                      {getUserAioData?.data?.modules?.private_calls_enabled && (
                        <Divider />
                      )}

                      {getUserAioData?.data?.modules?.levelling_enabled && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigate(routeConst.profile.leveling.path);
                          }}
                        >
                          {t("profile.levelling_centre")}
                        </MenuItem>
                      )}

                      {getUserAioData?.data?.modules?.levelling_enabled && (
                        <Divider />
                      )}

                      <MenuItem
                        onClick={() => {
                          onCloseMenu("settings");
                          onNavigate(routeConst.support.path);
                        }}
                      >
                        {t("profile.support")}
                      </MenuItem>

                      <Divider />

                      <MenuItem>
                        <a
                          className="navbar-no-decoration"
                          href={routeConst.zendesk.path}
                          target="_blank"
                        >
                          {t("profile.faq")}
                        </a>
                      </MenuItem>

                      <Divider />

                      {getUserAioData?.data?.modules?.blogs_page_enabled && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigateToBlogs();
                          }}
                        >
                          {t("profile.blogs")}
                        </MenuItem>
                      )}

                      {getUserAioData?.data?.modules?.blogs_page_enabled && (
                        <Divider />
                      )}

                      {getUserAioData?.data?.modules?.events_page_enabled && (
                        <MenuItem
                          onClick={() => {
                            onCloseMenu("settings");
                            onNavigateToEvents();
                          }}
                        >
                          {t("profile.events")}
                        </MenuItem>
                      )}

                      {getUserAioData?.data?.modules?.events_page_enabled && (
                        <Divider />
                      )}

                      <MenuItem
                        onClick={() => {
                          onCloseMenu("settings");
                          onNavigate(routeConst.settings.path);
                        }}
                      >
                        {t("profile.settings")}
                      </MenuItem>

                      <Divider />

                      <MenuItem
                        onClick={() => {
                          onCloseMenu("settings");
                          onLogout();
                        }}
                      >
                        {t("settings.log_out")}
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isLoggedIn && (
            <div className="not-login-view">
              <div onClick={onRedirectToLanding}>
                <div className="sugarbook-logo-container">
                  {getIcon("sugarbookLogo", "sugarbook-logo")}
                </div>
              </div>

              <span className="spacer"></span>

              <div className="navigation-right-container">
                <div className="not-login-container">
                  <div
                    className="login-button-container"
                    onClick={() => onNavigate(routeConst.login.path)}
                  >
                    <div className="login-button">{t("login.login")}</div>
                  </div>
                  <div
                    className="join-button-container"
                    onClick={() => onNavigate(routeConst.join.path)}
                  >
                    <div className="join-button">{t("login.join")}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default NavbarDesktopView;
