// General
import { useState, useEffect, useRef } from "react";
// Services
import { API_CONFIG_HEADERS } from "../../../../const/apiConst";
import { sessionService } from "../../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateMessagingPusherPayload } from "../../../../redux/store/pusherStore";
import { updateGlobalSnackbar } from "../../../../redux/store/publicStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
import {
  updateMessagingChannelState,
  updateMessagingPusherState,
} from "../../../../redux/store/debugStore";
// Pusher-js
import Pusher from "pusher-js";

const MessagingPusher = () => {
  // General variables
  const subscribeIsMounted = useRef(false);
  const unsubscribeIsMounted = useRef(false);
  const resetIsMounted = useRef(false);
  const destroyIsMounted = useRef(false);

  // Redux variables
  const messagingPusherSubscribe = useSelector(
    (state) => state.pusher.messagingPusherSubscribe
  );
  const messagingPusherUnsubscribe = useSelector(
    (state) => state.pusher.messagingPusherUnsubscribe
  );
  const messagingPusherReset = useSelector(
    (state) => state.pusher.messagingPusherReset
  );
  const messagingPusherDestroy = useSelector(
    (state) => state.pusher.messagingPusherDestroy
  );
  const conversationId = useSelector((state) => state.inbox.conversationId);
  const showLog = useSelector((state) => state.debug.showLog);
  const dispatch = useDispatch();

  // Pusher variables
  let authEndpoint = `${process.env["REACT_APP_SPI_API"]}broadcasting/auth`;
  let [pusherInstance, setPusherInstance] = useState(null);
  let [channel, setChannel] = useState(null);

  // Lifecycle | Initiate
  useEffect(() => {
    if (subscribeIsMounted.current) {
      if (!messagingPusherSubscribe || pusherInstance) return;

      let headers = {
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        Authorization: `${sessionService.getApiToken()}`,
      };

      setPusherInstance(
        new Pusher(process.env["REACT_APP_PUSHER_APP_KEY"], {
          authEndpoint: authEndpoint,
          cluster: "ap1",
          auth: {
            headers: headers,
          },
        })
      );
    } else {
      subscribeIsMounted.current = true;
    }
  }, [messagingPusherSubscribe]);

  // Lifecycle | Check for update | Subscribe
  useEffect(() => {
    if (!pusherInstance) return;

    pusherInstance?.connection?.bind("state_change", (state) => {
      dispatch(updateMessagingPusherState(state.current));

      switch (state.current) {
        case "initialized":
          break;
        case "connecting":
          break;
        case "connected":
          break;
        case "disconnected":
          break;
        case "unavailable":
          break;
        case "failed":
          break;
        case "disconnected":
          break;
        default:
          break;
      }
    });

    setChannel(pusherInstance.subscribe("private-channel-" + conversationId));
  }, [pusherInstance]);

  // Lifecycle | Check for update | Unsubscribe
  useEffect(() => {
    if (unsubscribeIsMounted.current) {
      if (!messagingPusherUnsubscribe) return;

      pusherInstance.unsubscribe("private-channel-" + conversationId);

      dispatch(updateMessagingChannelState(false));
    } else {
      unsubscribeIsMounted.current = true;
    }
  }, [messagingPusherUnsubscribe]);

  // Lifecycle | Check for update | Payload
  useEffect(() => {
    if (!channel) return;

    // Event Listener | State
    channel?.bind("pusher:subscription_succeeded", () => {
      // console.log("Subscribed to channel");
      dispatch(updateMessagingChannelState(true));
    });
    channel?.bind("pusher:subscription_error", (error) => {
      // console.log("Error subscribing to channel: ", error);
      dispatch(updateMessagingChannelState(false));
    });

    channel.bind("private-message-event", (payload) => {
      if (showLog) {
        console.log(payload);
      }

      if (payload?.message?.type) {
        switch (payload?.message?.type) {
          case "private-messages":
            let conversationId;

            if (payload?.message?.conversation_id) {
              conversationId = payload?.message?.conversation_id;
            }

            // if (conversationId === id) {
            if (payload?.message?.data?.message) {
              const message = payload?.message?.data?.message;
              dispatch(updateMessagingPusherPayload(message));
            }
            // }
            break;
          default:
            break;
        }
      }
    });
  }, [channel]);

  // Lifecycle | Check for update | Reset Pusher
  useEffect(() => {
    if (resetIsMounted.current) {
      if (!messagingPusherReset) return;

      if (pusherInstance) {
        // Disconnect Pusher and its channels
        pusherInstance.disconnect();
        setPusherInstance(null);

        // Update State
        dispatch(updateMessagingChannelState(false));
      } else {
        const toastObj = {
          message: "Messaging Pusher Instance is unavailable",
          autoClose: 3000,
        };
        dispatch(updateErrorToast(toastObj));
      }
    } else {
      resetIsMounted.current = true;
    }
  }, [messagingPusherReset]);

  // Lifecycle | Check for update | Destroy Pusher
  useEffect(() => {
    if (destroyIsMounted.current) {
      if (!messagingPusherDestroy) return;

      if (pusherInstance) {
        pusherInstance.disconnect();
        setPusherInstance(null);

        // Update State
        dispatch(updateMessagingChannelState(false));
      }
    } else {
      destroyIsMounted.current = true;
    }
  }, [messagingPusherDestroy]);

  return <div id="messaging-pusher-shadow-component"></div>;
};

export default MessagingPusher;
