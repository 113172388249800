// General
import "./empty-list.scss";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const EmptyList = () => {
  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div
      id="leaderboard-section-empty-list-subcomponent"
      className="empty-list-container"
    >
      <div className="message-icon-container">
        {getIcon("viewedMeOutlineIcon", "message-icon")}
      </div>
      <div className="no-message-text">Leaderboard Empty</div>
    </div>
  );
};

export default EmptyList;
