// General
import "./search-input.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
import { removeHTMLEscape } from "../../../../../services/regex.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateKeyword } from "../../../../../redux/store/searchFilterStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// i18next
import { useTranslation } from "react-i18next";
// Components
import PremiumLock from "../../../elements/premium-lock/premium-lock";

const SearchInput = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const keyword = useSelector((state) => state.searchFilter.keyword);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | MUI Text Fields
  const onInputChange = (event) => {
    if (removeHTMLEscape.test(event.target.value)) {
      // Remove HTML Escape (prevent XSS)
      dispatch(updateKeyword(""));

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-SearchFilter-Dialog-Search-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-SearchFilter-Dialog-Search-Input-HTML-Escape-Detected",
        },
      });
    } else {
      dispatch(updateKeyword(event.target.value));
    }
  };

  // Utility Functions
  const shouldShowPremiumLock = () => {
    if (
      isDaddy &&
      getProfileData?.data?.membership_type === userConst.membershipType.free
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div id="search-filter-search-input-subcomponent">
      <div className="filter-padding-container">
        <div
          className={`form-container ${
            shouldShowPremiumLock() ? "locked" : ""
          }`}
        >
          <SearchIcon className="search-icon" />

          <TextField
            className="form-field"
            value={keyword}
            onChange={onInputChange}
            disabled={shouldShowPremiumLock()}
            placeholder={t("filter.search_a_username")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          ></TextField>

          {shouldShowPremiumLock() && <PremiumLock />}
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
