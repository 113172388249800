// General
import "./onboarding-video-call-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Services
import { sessionService } from "../../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateOnboardingVideoCallDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const OnboardingVideoCallDialog = () => {
  // General variables
  const [page, setPage] = useState(1);

  // Render variables
  let pageView;
  let titleView;
  let descriptionView;
  let backgroundImageView;

  // Redux variables
  const onboardingVideoCallDialog = useSelector(
    (state) => state.dialog.onboardingVideoCallDialog
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!onboardingVideoCallDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-OnboardingVideoCall-Dialog",
      },
    });
  }, [onboardingVideoCallDialog]);

  // Event Handlers | Button
  const onPrevious = () => {
    setPage((prevPage) => {
      if (prevPage === 1) {
        return 1;
      }
      return prevPage - 1;
    });
  };
  const onNext = () => {
    setPage((prevPage) => {
      if (prevPage === 2) {
        return 2;
      }
      return prevPage + 1;
    });
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    // Save to local storage
    sessionService.setHasShownVideoCallDialog(true);
    dispatch(updateOnboardingVideoCallDialog(false));
  };

  // Render Functions
  switch (page) {
    case 1:
      pageView = <div className="page-container">{page}/2</div>;
      titleView = (
        <div className="live-title">
          <Trans
            i18nKey={
              isDaddy
                ? "onboarding.meet_your_sugar_baby_title"
                : "onboarding.meet_your_sugar_daddy_title"
            }
            components={{ b: <span className="x5-text" /> }}
          />
        </div>
      );
      descriptionView = (
        <div className="live-description">
          <ul>
            <li>
              <Trans
                i18nKey={
                  isDaddy
                    ? "onboarding.meet_your_sugar_baby_point_1"
                    : "onboarding.meet_your_sugar_daddy_point_1"
                }
              />
            </li>
            <li>
              <Trans
                i18nKey={
                  isDaddy
                    ? "onboarding.meet_your_sugar_baby_point_2"
                    : "onboarding.meet_your_sugar_daddy_point_2"
                }
                components={{ b: <b className="highlight-green" /> }}
              />
            </li>
            <li>
              <Trans
                i18nKey={
                  isDaddy
                    ? "onboarding.meet_your_sugar_baby_point_3"
                    : "onboarding.meet_your_sugar_daddy_point_3"
                }
                components={{ b: <b className="highlight-green" /> }}
              />
            </li>
          </ul>
        </div>
      );
      backgroundImageView = (
        <img
          className="live-background"
          src={getAsset("onboardingVideoCallSb1Background")}
        />
      );
      break;
    case 2:
      pageView = <div className="page-container">{page}/2</div>;
      titleView = (
        <div className="live-title">
          <Trans i18nKey="onboarding.call_title" />
        </div>
      );
      descriptionView = (
        <div className="live-description">
          <Trans
            i18nKey={
              isDaddy ? "onboarding.call_sd_desc" : "onboarding.call_sb_desc"
            }
          />
        </div>
      );
      backgroundImageView = (
        <img
          className="live-background"
          src={
            isDaddy
              ? getAsset("onboardingVideoCallSd2Background")
              : getAsset("onboardingVideoCallSb2Background")
          }
        />
      );
      break;
    default:
      break;
  }

  return (
    <Dialog
      className={
        isMobile ? "custom-dialog-transparent" : "custom-radius35-dialog"
      }
      fullScreen={isMobile ? true : false}
      open={onboardingVideoCallDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div
        id="onboarding-video-call-dialog"
        className={isMobile ? "" : "max-width-container"}
      >
        {isMobile && (
          <div className="header">
            <div className="close-button-container"></div>
          </div>
        )}

        <div className="padding-container">
          {pageView}

          {titleView}

          {descriptionView}

          <div className="live-background-container">
            <div className="arrow-back-icon-container" onClick={onPrevious}>
              {page !== 1 && <ArrowBackIosIcon />}
            </div>
            {backgroundImageView}

            <div className="arrow-forward-icon-container" onClick={onNext}>
              {page !== 2 && <ArrowForwardIosIcon />}
            </div>
          </div>
        </div>

        {page === 2 && (
          <div className="footer">
            <div className="done-button" onClick={onCloseDialog}>
              {t("common.done")}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default OnboardingVideoCallDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
