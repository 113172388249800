// General
import "./daily-tab.scss";
import { useEffect, useRef } from "react";
// Services
import {
  useLazyGetLivestreamingLeaderboardQuery,
  useLazyGetPrivateCallsLeaderboardQuery,
  useLazyGetGamingLeaderboardQuery,
} from "../../../../services/data.service";
// Static Data
import leaderboardConst from "../../../../const/leaderboardConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Livestreaming Leaderboard Functions
  updateLivestreamingTopStreamersDaily,
  updateLivestreamingTopGiftersDaily,
  resetLivestreamingTopStreamersDaily,
  resetLivestreamingTopGiftersDaily,

  // Casino Leaderboard Functions
  updateCasinoTopPlayersDaily,
  updateCasinoTopWagersDaily,
  resetCasinoTopPlayersDaily,
  resetCasinoTopWagersDaily,
} from "../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Banner from "../../elements/banner/banner";
import LeaderboardSection from "../leaderboard-section/leaderboard-section";

const DailyTab = (props) => {
  const { state, gameId } = props;

  // API variables
  const [
    getLivestreamingLeaderboardTopStreamers,
    {
      data: getLivestreamingLeaderboardTopStreamersData,
      error: getLivestreamingLeaderboardTopStreamersErrorData,
      isFetching: getLivestreamingLeaderboardTopStreamersFetching,
      isLoading: getLivestreamingLeaderboardTopStreamersLoading,
      isSuccess: getLivestreamingLeaderboardTopStreamersSuccess,
      isError: getLivestreamingLeaderboardTopStreamersError,
    },
  ] = useLazyGetLivestreamingLeaderboardQuery();
  const [
    getLivestreamingLeaderboardTopGifters,
    {
      data: getLivestreamingLeaderboardTopGiftersData,
      error: getLivestreamingLeaderboardTopGiftersErrorData,
      isFetching: getLivestreamingLeaderboardTopGiftersFetching,
      isLoading: getLivestreamingLeaderboardTopGiftersLoading,
      isSuccess: getLivestreamingLeaderboardTopGiftersSuccess,
      isError: getLivestreamingLeaderboardTopGiftersError,
    },
  ] = useLazyGetLivestreamingLeaderboardQuery();
  const [
    // Not in Use
    getPrivateCallLeaderboardTopStreamers,
    {
      data: getPrivateCallLeaderboardTopStreamersData,
      error: getPrivateCallLeaderboardTopStreamersErrorData,
      isFetching: getPrivateCallLeaderboardTopStreamersFetching,
      isLoading: getPrivateCallLeaderboardTopStreamersLoading,
      isSuccess: getPrivateCallLeaderboardTopStreamersSuccess,
      isError: getPrivateCallLeaderboardTopStreamersError,
    },
  ] = useLazyGetPrivateCallsLeaderboardQuery();
  const [
    // Not in use
    getPrivateCallLeaderboardTopGifters,
    {
      data: getPrivateCallLeaderboardTopGiftersData,
      error: getPrivateCallLeaderboardTopGiftersErrorData,
      isFetching: getPrivateCallLeaderboardTopGiftersFetching,
      isLoading: getPrivateCallLeaderboardTopGiftersLoading,
      isSuccess: getPrivateCallLeaderboardTopGiftersSuccess,
      isError: getPrivateCallLeaderboardTopGiftersError,
    },
  ] = useLazyGetPrivateCallsLeaderboardQuery();
  const [
    getGamingTopPlayerLeaderboard,
    {
      data: getGamingTopPlayerLeaderboardData,
      error: getGamingTopPlayerLeaderboardErrorData,
      isFetching: getGamingTopPlayerLeaderboardFetching,
      isLoading: getGamingTopPlayerLeaderboardLoading,
      isSuccess: getGamingTopPlayerLeaderboardSuccess,
      isError: getGamingTopPlayerLeaderboardError,
    },
  ] = useLazyGetGamingLeaderboardQuery();
  const [
    getGamingTopWagerLeaderboard,
    {
      data: getGamingTopWagerLeaderboardData,
      error: getGamingTopWagerLeaderboardErrorData,
      isFetching: getGamingTopWagerLeaderboardFetching,
      isLoading: getGamingTopWagerLeaderboardLoading,
      isSuccess: getGamingTopWagerLeaderboardSuccess,
      isError: getGamingTopWagerLeaderboardError,
    },
  ] = useLazyGetGamingLeaderboardQuery();

  // General variables
  const isMounted = useRef(false);

  // Redux variables
  const livestreamingTopStreamersDaily = useSelector(
    (state) => state.leaderboard.livestreamingTopStreamersDaily
  );
  const livestreamingTopGiftersDaily = useSelector(
    (state) => state.leaderboard.livestreamingTopGiftersDaily
  );
  const casinoTopPlayersDaily = useSelector(
    (state) => state.leaderboard.casinoTopPlayersDaily
  );
  const casinoTopWagersDaily = useSelector(
    (state) => state.leaderboard.casinoTopWagersDaily
  );
  const leaderboardTabValue = useSelector(
    (state) => state.leaderboard.leaderboardTabValue
  );
  const leaderboardPullToRefreshPassthrough = useSelector(
    (state) => state.leaderboard.leaderboardPullToRefreshPassthrough
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  const topStreamersParam = {
    board_type: "top_streamers",
    board_period_type: leaderboardConst.tabs.daily,
  };
  const topGiftersParam = {
    board_type: "top_gifters",
    board_period_type: leaderboardConst.tabs.daily,
  };
  const casinoTopPlayerParam = {
    board_period_type: leaderboardConst.tabs.daily,
    board_type: "top_players",
    game_id: gameId,
    pagination_token: "",
  };
  const casinoTopWagerParam = {
    board_period_type: leaderboardConst.tabs.daily,
    board_type: "top_wagers",
    game_id: gameId,
    pagination_token: "",
  };

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (state === leaderboardConst.type.livestreaming) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Leaderboard-Page-LivestreamingTab-DailyTab",
        },
      });
    } else if (state === leaderboardConst.type.privateCall) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Leaderboard-Page-PrivateCallTab-DailyTab",
        },
      });
    } else if (state === leaderboardConst.type.casino) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Leaderboard-Page-CasinoTab-DailyTab",
        },
      });
    }

    onCallApi(false);
  }, [leaderboardTabValue]);

  // Lifecycle | Check for update | Livestreaming Leaderboard Top Streamers API Response
  useEffect(() => {
    if (
      getLivestreamingLeaderboardTopStreamersFetching ||
      getLivestreamingLeaderboardTopStreamersLoading
    ) {
    } else if (getLivestreamingLeaderboardTopStreamersSuccess) {
      if (
        getLivestreamingLeaderboardTopStreamersData?.status === 1 &&
        getLivestreamingLeaderboardTopStreamersData?.data?.top_streamers
          ?.results?.length > 0
      ) {
        dispatch(resetLivestreamingTopStreamersDaily());
        dispatch(
          updateLivestreamingTopStreamersDaily(
            getLivestreamingLeaderboardTopStreamersData?.data?.top_streamers
              ?.results
          )
        );
      }
    } else if (getLivestreamingLeaderboardTopStreamersError) {
      if (getLivestreamingLeaderboardTopStreamersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingLeaderboardTopStreamersFetching,
    getLivestreamingLeaderboardTopStreamersLoading,
    getLivestreamingLeaderboardTopStreamersSuccess,
    getLivestreamingLeaderboardTopStreamersError,
  ]);

  // Lifecycle | Check for update | Livestreaming Leaderboard Top Gifters API Response
  useEffect(() => {
    if (
      getLivestreamingLeaderboardTopGiftersFetching ||
      getLivestreamingLeaderboardTopGiftersLoading
    ) {
    } else if (getLivestreamingLeaderboardTopGiftersSuccess) {
      if (
        getLivestreamingLeaderboardTopGiftersData?.status === 1 &&
        getLivestreamingLeaderboardTopGiftersData?.data?.top_gifters?.results
          ?.length > 0
      ) {
        dispatch(resetLivestreamingTopGiftersDaily());
        dispatch(
          updateLivestreamingTopGiftersDaily(
            getLivestreamingLeaderboardTopGiftersData?.data?.top_gifters
              ?.results
          )
        );
      }
    } else if (getLivestreamingLeaderboardTopGiftersError) {
      if (getLivestreamingLeaderboardTopGiftersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingLeaderboardTopGiftersFetching,
    getLivestreamingLeaderboardTopGiftersLoading,
    getLivestreamingLeaderboardTopGiftersSuccess,
    getLivestreamingLeaderboardTopGiftersError,
  ]);

  // Lifecycle | Check for update | Private Call Leaderboard Top Earners API Response | Not in Use
  useEffect(() => {
    if (
      getPrivateCallLeaderboardTopStreamersFetching ||
      getPrivateCallLeaderboardTopStreamersLoading
    ) {
    } else if (getPrivateCallLeaderboardTopStreamersSuccess) {
    } else if (getPrivateCallLeaderboardTopStreamersError) {
      if (getPrivateCallLeaderboardTopStreamersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallLeaderboardTopStreamersFetching,
    getPrivateCallLeaderboardTopStreamersLoading,
    getPrivateCallLeaderboardTopStreamersSuccess,
    getPrivateCallLeaderboardTopStreamersError,
  ]);

  // Lifecycle | Check for update | Private Call Leaderboard Top Gifters API Response | Not in Use
  useEffect(() => {
    if (
      getPrivateCallLeaderboardTopGiftersFetching ||
      getPrivateCallLeaderboardTopGiftersLoading
    ) {
    } else if (getPrivateCallLeaderboardTopGiftersSuccess) {
    } else if (getPrivateCallLeaderboardTopGiftersError) {
      if (getPrivateCallLeaderboardTopGiftersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallLeaderboardTopGiftersFetching,
    getPrivateCallLeaderboardTopGiftersLoading,
    getPrivateCallLeaderboardTopGiftersSuccess,
    getPrivateCallLeaderboardTopGiftersError,
  ]);

  // Lifecycle | Check for update | Gaming Top Player Leaderboard API Response
  useEffect(() => {
    if (
      getGamingTopPlayerLeaderboardFetching ||
      getGamingTopPlayerLeaderboardLoading
    ) {
    } else if (getGamingTopPlayerLeaderboardSuccess) {
      if (
        getGamingTopPlayerLeaderboardData?.status === 1 &&
        getGamingTopPlayerLeaderboardData?.data?.results?.length > 0 &&
        getGamingTopPlayerLeaderboardData?.data?.results?.toString() !==
          casinoTopPlayersDaily?.toString()
      ) {
        dispatch(resetCasinoTopPlayersDaily());
        dispatch(
          updateCasinoTopPlayersDaily(
            getGamingTopPlayerLeaderboardData?.data?.results
          )
        );
      }
    } else if (getGamingTopPlayerLeaderboardError) {
    }
  }, [
    getGamingTopPlayerLeaderboardFetching,
    getGamingTopPlayerLeaderboardLoading,
    getGamingTopPlayerLeaderboardSuccess,
    getGamingTopPlayerLeaderboardError,
  ]);

  // Lifecycle | Check for update | Gaming Top Wager Leaderboard API Response
  useEffect(() => {
    if (
      getGamingTopWagerLeaderboardFetching ||
      getGamingTopWagerLeaderboardLoading
    ) {
    } else if (getGamingTopWagerLeaderboardSuccess) {
      dispatch(resetCasinoTopWagersDaily());
      dispatch(
        updateCasinoTopWagersDaily(
          getGamingTopWagerLeaderboardData?.data?.results
        )
      );
    } else if (getGamingTopWagerLeaderboardError) {
    }
  }, [
    getGamingTopWagerLeaderboardFetching,
    getGamingTopWagerLeaderboardLoading,
    getGamingTopWagerLeaderboardSuccess,
    getGamingTopWagerLeaderboardError,
  ]);

  // Lifecycle | Check for update | leaderboardPullToRefreshPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (!leaderboardPullToRefreshPassthrough) return;

      onCallApi(false);
    } else {
      isMounted.current = true;
    }
  }, [leaderboardPullToRefreshPassthrough]);

  // Utility Functions
  const onCallApi = (getFromCache) => {
    if (leaderboardTabValue === state) {
      switch (state) {
        case leaderboardConst.type.livestreaming:
          getLivestreamingLeaderboardTopStreamers(
            `?board_type=${topStreamersParam.board_type}&board_period_type=${topStreamersParam.board_period_type}`,
            getFromCache
          );
          getLivestreamingLeaderboardTopGifters(
            `?board_type=${topGiftersParam.board_type}&board_period_type=${topGiftersParam.board_period_type}`,
            getFromCache
          );
          break;
        case leaderboardConst.type.privateCall:
          getPrivateCallLeaderboardTopStreamers(
            `?board_type=${topStreamersParam.board_type}&board_period_type=${topStreamersParam.board_period_type}`,
            getFromCache
          );
          getPrivateCallLeaderboardTopGifters(
            `?board_type=${topGiftersParam.board_type}&board_period_type=${topGiftersParam.board_period_type}`,
            getFromCache
          );
          break;
        case leaderboardConst.type.casino:
          if (!getFromCache) {
            resetCasinoTopPlayersDaily();
            resetCasinoTopWagersDaily();
          }

          // getGamingTopPlayerLeaderboard(
          //   `?board_period_type=${casinoTopPlayerParam.board_period_type}&board_type=${casinoTopPlayerParam.board_type}&game_id=${casinoTopPlayerParam.game_id}`,
          //   getFromCache
          // );
          getGamingTopWagerLeaderboard(
            `?board_period_type=${casinoTopWagerParam.board_period_type}&board_type=${casinoTopWagerParam.board_type}&game_id=${casinoTopWagerParam.game_id}`,
            getFromCache
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <div id="daily-tab-shared-subcomponent">
      <Banner
        type={
          state === leaderboardConst.type.livestreaming
            ? "leaderboard-livestreaming-normal"
            : state === leaderboardConst.type.privateCall
            ? "leaderboard-private-call-normal"
            : "leaderboard-casino-normal"
        }
        view={"stack"}
      />

      {/* Livestreaming | Top Streamer */}
      {state === leaderboardConst.type.livestreaming && (
        <LeaderboardSection
          type={leaderboardConst.type.livestreaming}
          recurrence={leaderboardConst.tabs.daily}
          isLoading={
            !livestreamingTopStreamersDaily?.length &&
            (getLivestreamingLeaderboardTopStreamersFetching ||
              getLivestreamingLeaderboardTopStreamersLoading)
          }
          title={t("leaderboard.top_streamers")}
          requireInfoIcon={false}
          userList={livestreamingTopStreamersDaily}
          userType={leaderboardConst.userType.streamer}
        />
      )}

      {/* Livestreaming | Top Gifter */}
      {state === leaderboardConst.type.livestreaming && (
        <LeaderboardSection
          type={leaderboardConst.type.livestreaming}
          recurrence={leaderboardConst.tabs.daily}
          isLoading={
            !livestreamingTopGiftersDaily?.length &&
            (getLivestreamingLeaderboardTopGiftersFetching ||
              getLivestreamingLeaderboardTopGiftersLoading)
          }
          title={t("leaderboard.top_gifters")}
          requireInfoIcon={false}
          userList={livestreamingTopGiftersDaily}
          userType={leaderboardConst.userType.gifter}
        />
      )}

      {/* Private Call | Top Earner */}
      {state === leaderboardConst.type.privateCall && (
        <LeaderboardSection
          type={leaderboardConst.type.privateCall}
          recurrence={leaderboardConst.tabs.daily}
          isLoading={
            getPrivateCallLeaderboardTopStreamersFetching ||
            getPrivateCallLeaderboardTopStreamersLoading
          }
          title={t("leaderboard.top_earners")}
          requireInfoIcon={false}
          userList={
            getPrivateCallLeaderboardTopStreamersData?.data?.top_streamers
              ?.results
          }
          userType={leaderboardConst.userType.streamer}
        />
      )}

      {/* Private Call | Top Gifter */}
      {state === leaderboardConst.type.privateCall && (
        <LeaderboardSection
          type={leaderboardConst.type.privateCall}
          recurrence={leaderboardConst.tabs.daily}
          isLoading={
            getPrivateCallLeaderboardTopGiftersFetching ||
            getPrivateCallLeaderboardTopGiftersLoading
          }
          title={t("leaderboard.top_gifters")}
          requireInfoIcon={false}
          userList={
            getPrivateCallLeaderboardTopGiftersData?.data?.top_gifters?.results
          }
          userType={leaderboardConst.userType.gifter}
        />
      )}

      {/* Casino | Top Wager */}
      {state === leaderboardConst.type.casino && (
        <LeaderboardSection
          type={leaderboardConst.type.casino}
          recurrence={leaderboardConst.tabs.daily}
          isLoading={
            getGamingTopWagerLeaderboardFetching ||
            getGamingTopWagerLeaderboardLoading
          }
          title={"Top Wagers"}
          requireInfoIcon={false}
          userList={casinoTopWagersDaily}
          userType={leaderboardConst.userType.wager}
          gameId={gameId}
        />
      )}

      {/* Casino | Top Player */}
      {false && state === leaderboardConst.type.casino && (
        <LeaderboardSection
          type={leaderboardConst.type.casino}
          recurrence={leaderboardConst.tabs.daily}
          isLoading={
            getGamingTopPlayerLeaderboardFetching ||
            getGamingTopPlayerLeaderboardLoading
          }
          title={"Top Players"}
          requireInfoIcon={false}
          userList={casinoTopPlayersDaily}
          userType={leaderboardConst.userType.player}
          gameId={gameId}
        />
      )}
    </div>
  );
};

export default DailyTab;
