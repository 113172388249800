// General
import "./upgrade-to-premium-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import {
  useLazyGetUserAioQuery,
  useLazyGetProfileQuery,
} from "../../../../services/data.service";
import { sessionService } from "../../../../services/session.service";
// Static Data
import userConst from "../../../../const/userConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUpgradeToPremiumDialog,
  updateUpdateTorDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const UpgradeToPremiumDialog = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const upgradeToPremiumDialog = useSelector(
    (state) => state.dialog.upgradeToPremiumDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!upgradeToPremiumDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UpgradeToPremium-Dialog",
      },
    });

    getUserAio(null, true);
    getProfile(null, true);
  }, [upgradeToPremiumDialog]);

  // Lifecycle | Check for update | User AIO API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      let promotionImg = null;
      let cta = null;

      const promotionConstant =
        getProfileData?.data?.membership_type === userConst.membershipType.free
          ? "fifty_percent_off.fullscreen"
          : "twenty_percent_off.fullscreen";

      const graphics = getUserAioData?.data?.promotions?.p_20210818?.graphics;

      if (graphics) {
        for (let i = 0; i < graphics?.length; i++) {
          if (graphics[i].constant === promotionConstant) {
            promotionImg = graphics[i].asset_url;
            cta = graphics[i].cta;
            break;
          }
        }
      }

      if (!promotionImg) {
        onCloseDialog();
      }
    } else if (getUserAioError) {
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  // Event Handlers | Button
  const onRedirectToPage = (cta) => {
    let dataLayerEvent = null;

    switch (cta) {
      case "premium":
        dataLayerEvent = "PWA-UpgradeToPremium-Dialog-UpgradePremium-Button";
        onNavigate(routeConst.upgrade.path);
        break;
      case "tor":
        dataLayerEvent = "PWA-UpgradeToPremium-Dialog-UpdateTorDialog-Button";
        dispatch(updateUpdateTorDialog(true));
        break;
      case "video_call_landing":
        dataLayerEvent = "PWA-UpgradeToPremium-Dialog-VideoCall-Button";
        onNavigate(routeConst.live.path);
        break;
      default:
        dataLayerEvent = "PWA-UpgradeToPremium-Dialog-UpgradePremium-Button";
        onNavigate(routeConst.upgrade.path);
        break;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: dataLayerEvent,
      },
    });

    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    sessionService.setSearchPageFullscreenGeneralDialogLastShown(
      moment().toISOString()
    );
    dispatch(updateUpgradeToPremiumDialog(false));
  };

  if (
    getUserAioFetching ||
    getUserAioLoading ||
    getProfileFetching ||
    getProfileLoading
  ) {
  } else if (getUserAioSuccess && getProfileSuccess) {
    let promotionImg;
    let cta;

    const promotionConstant =
      getProfileData?.data?.membership_type === userConst.membershipType.free
        ? "fifty_percent_off.fullscreen"
        : "twenty_percent_off.fullscreen";

    const graphics = getUserAioData?.data?.promotions?.p_20210818?.graphics;

    if (graphics) {
      for (let i = 0; i < graphics?.length; i++) {
        if (graphics[i].constant === promotionConstant) {
          promotionImg = graphics[i].asset_url;
          cta = graphics[i].cta;
          break;
        }
      }
    }

    if (promotionImg) {
      return (
        <Dialog
          className={isMobile ? "" : "custom-radius35-dialog"}
          fullScreen={isMobile ? true : false}
          open={upgradeToPremiumDialog}
          TransitionComponent={Transition}
          onClose={onCloseDialog}
        >
          <div
            id="upgrade-to-premium-dialog"
            className={isMobile ? "" : "upgrade-max-width-container"}
          >
            <div
              className="pwa-background-container"
              onClick={() => onRedirectToPage(cta)}
            >
              <img className="pwa-background" src={promotionImg} />
            </div>

            <div className="header">
              <div className="close-button-container">
                <CloseIcon className="close-button" onClick={onCloseDialog} />
              </div>
            </div>
          </div>
        </Dialog>
      );
    }
  }
};

export default UpgradeToPremiumDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
