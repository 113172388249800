// General
import "./App.scss";
// Components
import I18nMounted from "./components/utility/app-mounted/i18n-mounted/i18n-mounted";
import MomentjsMounted from "./components/utility/app-mounted/momentjs-mounted/momentjs-mounted";
import ApiMounted from "./components/utility/app-mounted/api-mounted/api-mounted";
import CheckLoginMounted from "./components/utility/app-mounted/check-login-mounted/check-login-mounted";
import GeolocationPermissionMounted from "./components/utility/app-mounted/geolocation-permission-mounted/geolocation-permission-mounted";
import NotificationPermissionMounted from "./components/utility/app-mounted/notification-permission-mounted/notification-permission-mounted";
import GoogleMapsMounted from "./components/utility/app-mounted/google-maps-mounted/google-maps-mounted";
import PusherMounted from "./components/utility/app-mounted/pusher-mounted/pusher-mounted";
import SearchParamMounted from "./components/utility/app-mounted/search-param-mounted/search-param-mounted";
import OrientationMounted from "./components/utility/app-mounted/orientation-mounted/orientation-mounted";
import UtmMounted from "./components/utility/app-mounted/utm-mounted/utm-mounted";
import UtilityMounted from "./components/utility/app-mounted/utility-mounted/utility-mounted";
import HelperMounted from "./components/utility/app-mounted/helper-mounted/helper-mounted";
import ToastNotificationManager from "./components/utility/manager/toast-notification-manager/toast-notification-manager";
import DebugInfo from "./components/shared/debug-info/debug-info";
import GlobalDialogMounted from "./components/utility/app-mounted/global-dialog-mounted/global-dialog-mounted";
import GlobalSnackbarMounted from "./components/utility/app-mounted/global-snackbar-mounted/global-snackbar-mounted";
import GlobalToastMounted from "./components/utility/app-mounted/global-toast-mounted/global-toast-mounted";
import UpdateApp from "./components/shared/update-app/update-app";
import NavBar from "./components/shared/elements/navbar/navbar";
import AppRouting from "./components/app-routing/app-routing";
import StreamerButtons from "./components/shared/elements/streamer-buttons/streamer-buttons";
import PrivateRoomMinimizeBar from "./components/shared/elements/private-room-minimize-bar/private-room-minimize-bar";
import BottomNavbar from "./components/shared/elements/bottom-navbar/bottom-navbar";

function App() {
  return (
    <div id="App" className="App">
      {/* Shadow Components */}
      <I18nMounted />
      <MomentjsMounted />
      <UtmMounted />
      <ApiMounted />
      <CheckLoginMounted />
      <GeolocationPermissionMounted />
      <NotificationPermissionMounted />
      <GoogleMapsMounted />
      <PusherMounted />
      <SearchParamMounted />
      <OrientationMounted />
      <UtilityMounted />
      <HelperMounted />

      {/* Manager */}
      <ToastNotificationManager />

      {/* Viewable Front End Starts Here */}
      {/* Debug Info Overlay */}
      <DebugInfo />

      {/* Global Dialog */}
      <GlobalDialogMounted />

      {/* Global Snackbar */}
      <GlobalSnackbarMounted />

      {/* Global Toast */}
      <GlobalToastMounted />

      {/* Manual App Update */}
      <UpdateApp />

      {/* Desktop Navbar */}
      <NavBar />

      <AppRouting />

      {/* Go Private & Go Live Button */}
      <StreamerButtons />

      {/* Private room standby minimize */}
      <PrivateRoomMinimizeBar />

      <BottomNavbar />
    </div>
  );
}

export default App;
