// General
import "./casino-tab.scss";
import { useEffect } from "react";
// Services
import { useLazyGetGamingListQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCasinoTab } from "../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// lottie-react
import Lottie from "lottie-react";
// Material UI
import { Tabs, Tab } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import GameListTab from "./game-list-tab/game-list-tab";

const CasinoTab = () => {
  // API variables
  const [
    getGamingList,
    {
      data: getGamingListData,
      error: getGamingListErrorData,
      isFetching: getGamingListFetching,
      isLoading: getGamingListLoading,
      isSuccess: getGamingListSuccess,
      isError: getGamingListError,
    },
  ] = useLazyGetGamingListQuery();

  // Redux variables
  const leaderboardTab = useSelector(
    (state) => state.leaderboard.leaderboardTab
  );
  const casinoTab = useSelector((state) => state.leaderboard.casinoTab);
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Leaderboard-Page-Casino-Tab",
      },
    });

    getGamingList(null, true);
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(casinoTab);

    emblaApi?.on("select", () => {
      dispatch(updateCasinoTab(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | leaderboardTab
  useEffect(() => {
    if (leaderboardTab === 2) {
      const casinoLottie = document.getElementById(
        "casino-tab-lottie-container"
      );

      if (casinoLottie) {
        casinoLottie.style.zIndex = 1;

        setTimeout(() => {
          casinoLottie.style.zIndex = 0;
        }, 2000);
      }
    }
  }, [leaderboardTab]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, index) => {
    emblaApi?.scrollTo(index);
  };

  // Render Functions | API Response
  if (getGamingListFetching || getGamingListLoading) {
    return <Spinner />;
  } else if (getGamingListSuccess) {
    if (getGamingListData?.status === 1) {
      return (
        <div id="leaderboard-casino-tab-subcomponent">
          {leaderboardTab === 2 && (
            <div id="casino-tab-lottie-container">
              <div className="lottie-center-container">
                <Lottie
                  animationData={getAsset("casinoLeaderboardLottie")}
                  autoPlay={true}
                  loop={false}
                />
              </div>
            </div>
          )}

          <div className="tab-container">
            <Tabs
              className="custom-tabs"
              value={casinoTab}
              onChange={onTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
              centered
            >
              {getGamingListData?.data?.games?.map((game, index) => (
                <Tab label={game?.title} key={index} />
              ))}
            </Tabs>

            <div ref={emblaRef} className="embla">
              <div className="embla__container">
                {getGamingListData?.data?.games?.map((game, index) => (
                  <div className="embla__slide" key={index}>
                    {casinoTab === index && (
                      <GameListTab gameId={game?.game_id} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else if (getGamingListError) {
    if (getGamingListErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default CasinoTab;
