// General
import "./standby-info-overlay.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetUserAioQuery,
  useClaimPrivateCallDiamondsMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  updateIsHunterMode,

  // Diamonds Functions
  updateTotalDiamonds,
  updateCallRequestDiamonds,
  updateClaimedDiamonds,
  updateUnclaimedDiamonds,

  // Calls Functions
  updateCallsAccepted,
} from "../../../../redux/store/privateCallStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const StandbyInfoOverlay = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    claimPrivateCallDiamonds,
    {
      data: claimPrivateCallDiamondsData,
      error: claimPrivateCallDiamondsErrorData,
      isLoading: claimPrivateCallDiamondsLoading,
      isSuccess: claimPrivateCallDiamondsSuccess,
      isError: claimPrivateCallDiamondsError,
    },
  ] = useClaimPrivateCallDiamondsMutation();

  // General variables
  const [proTips, setProTips] = useState([]);
  const [displayProTips, setDisplayProTips] = useState();
  const [isVisible, setIsVisible] = useState(false);

  // Redux variables
  const callStatus = useSelector((state) => state.privateCall.callStatus);
  const privateCallStandbyTimer = useSelector(
    (state) => state.privateCall.privateCallStandbyTimer
  );
  const claimedDiamonds = useSelector(
    (state) => state.privateCall.claimedDiamonds
  );
  const unclaimedDiamonds = useSelector(
    (state) => state.privateCall.unclaimedDiamonds
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getUserAio(null, true);
  }, []);

  // Lifecycle | Check for update | User AIO API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      if (getUserAioData?.status === 1) {
        if (proTips?.length > 0) return;

        setProTips(getUserAioData?.data?.private_calls?.pro_tips);
      }
    } else if (getUserAioError) {
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  // Lifecycle | Check for update | Claim Diamonds API Response
  useEffect(() => {
    if (claimPrivateCallDiamondsLoading) {
    } else if (claimPrivateCallDiamondsSuccess) {
      if (claimPrivateCallDiamondsData?.status === 0) {
        dispatch(
          updateTotalDiamonds(
            claimPrivateCallDiamondsData?.data?.standby?.stats?.total_diamonds
          )
        );
        dispatch(
          updateCallRequestDiamonds(
            claimPrivateCallDiamondsData?.data?.standby?.stats
              ?.call_requested_diamonds
          )
        );
        dispatch(
          updateUnclaimedDiamonds(
            claimPrivateCallDiamondsData?.data?.standby?.stats
              ?.unclaimed_diamonds
          )
        );
        dispatch(
          updateClaimedDiamonds(
            claimPrivateCallDiamondsData?.data?.standby?.stats?.claimed_diamonds
          )
        );
        dispatch(
          updateCallsAccepted(
            claimPrivateCallDiamondsData?.data?.standby?.stats?.calls_accepted
          )
        );
      }
    } else if (claimPrivateCallDiamondsError) {
      if (claimPrivateCallDiamondsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    claimPrivateCallDiamondsLoading,
    claimPrivateCallDiamondsSuccess,
    claimPrivateCallDiamondsError,
  ]);

  // Lifecycle | Check for update | proTips
  useEffect(() => {
    if (proTips?.length <= 0) return;
    // Set first pro tips
    setDisplayProTips(proTips[0]);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 9000);

    // Set interval for pro tips
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * proTips.length);
      setDisplayProTips(proTips[randomIndex]);
      setIsVisible(true);

      setTimeout(() => {
        setIsVisible(false);
      }, 9000);
    }, 10000);

    // Clear interval
    return () => clearInterval(intervalId);
  }, [proTips]);

  // Event Handlers | Button
  const onMinimizePrivateRoomStandby = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PrivateStandby-Page-EnableHunterMode-Button",
      },
    });

    setTimeout(() => {
      dispatch(updateIsHunterMode(true));
      onNavigate(routeConst.search.path);
    }, 1000);
  };
  const onClaimDiamonds = () => {
    if (unclaimedDiamonds <= 0) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PrivateStandby-Page-ClaimDiamonds-Button",
      },
    });

    claimPrivateCallDiamonds({});
  };

  // Utility Functions
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  };

  if (callStatus === "STANDBY") {
    return (
      <div id="private-standby-standby-info-overlay-subcomponent">
        <div className="standby-info-container">
          <div
            className="header-container"
            onClick={onMinimizePrivateRoomStandby}
          >
            <div className="minimize-icon-container">
              <RemoveCircleOutlineIcon className="minimize-icon" />
            </div>

            <div className="subheader">
              <Trans
                className="subheader"
                i18nKey="1on1.tap_here_to_minimize_screen"
                components={{ u: <u /> }}
              />
            </div>

            <div className="right-container"></div>
          </div>

          <div className="content-container">
            <div className="header-section">
              <Trans className="header">
                <div className="header">
                  {t("1on1.youre_on_standby_in_the_private_room")}
                </div>
              </Trans>

              <div className="header-description">
                {t("1on1.your_profile_can_be_viewed")}
              </div>
            </div>

            <div className="timer">
              {getFormattedTime(privateCallStandbyTimer)}
            </div>

            <div className="diamond-section">
              <div className="diamonds-earned-text">
                {t("1on1.currently_earning_while_waiting")}
              </div>

              <div className="diamonds-earned-container">
                {getIcon("sbDiamondIcon", "diamond-icon")}

                <div className="diamonds-earned">x{claimedDiamonds}</div>
              </div>
            </div>

            <div
              className={`${
                unclaimedDiamonds <= 0 ? "no-diamonds" : "claim-diamonds-button"
              }`}
              onClick={onClaimDiamonds}
            >
              {t("1on1.claim_diamond", { n: unclaimedDiamonds }) +
                " " +
                t("live.diamonds")}
            </div>
          </div>

          <div className="tips-container">
            <div className={`tips-title ${isVisible ? "show" : ""}`}>
              {proTips?.length > 0 && displayProTips?.title}
            </div>
            <div className={`tips-description ${isVisible ? "show" : ""}`}>
              {proTips?.length > 0 && displayProTips?.messages[0]}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default StandbyInfoOverlay;
