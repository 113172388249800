// General
import { createSlice } from "@reduxjs/toolkit";
// Credit Card Type
import creditCardType from "credit-card-type";

const initialState = {
  // General variables
  cardNumber: "",
  cardType: "",
  expiryDate: {
    month: "",
    year: "",
  },
  cvv: "",
  cardHolderName: {
    firstName: "",
    lastName: "",
  },
  country: "",

  // CCBill Link variables
  ccbillLink: null,

  // Payment Method Selection variables
  paymentMethodSelection: null,

  // Payment State variables
  paymentState: {},

  // Payment Step variables
  securionPayTokenDetails: {},

  // Payment Error variables
  paymentErrorInfo: {},

  // Utility variables
  isFormValid: false,
  afterCardAddedPassthrough: null,

  // API Response variables
  cardDetail: [],
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    // General Functions
    updateCardNumber: (state, action) => {
      state.cardNumber = action.payload;

      let cardType = creditCardType(action.payload);
      if (cardType?.length > 0) {
        state.cardType = cardType[0].type;
      }
    },
    updateExpiryDateMonth: (state, action) => {
      state.expiryDate.month = action.payload;
    },
    updateExpiryDateYear: (state, action) => {
      state.expiryDate.year = action.payload;
    },
    updateCVV: (state, action) => {
      state.cvv = action.payload;
    },
    updateCardHolderNameFirstName: (state, action) => {
      state.cardHolderName.firstName = action.payload;
    },
    updateCardHolderNameLastName: (state, action) => {
      state.cardHolderName.lastName = action.payload;
    },
    updateCountry: (state, action) => {
      state.country = action.payload;
    },

    // CCBill Link Functions
    updateCCBillLink: (state, action) => {
      state.ccbillLink = action.payload;
    },

    // Payment Method Selection Functions
    updatePaymentMethodSelection: (state, action) => {
      state.paymentMethodSelection = action.payload;
    },

    // Payment State Functions
    updatePaymentState: (state, action) => {
      state.paymentState = action.payload;
    },

    // Payment Step Functions
    updateSecurionPayTokenDetails: (state, action) => {
      state.securionPayTokenDetails = action.payload;
    },

    // Payment Error Functions
    updatePaymentErrorInfo: (state, action) => {
      state.paymentErrorInfo = action.payload;
    },

    // Utililty Functions
    resetPaymentStore: () => {
      return { ...initialState };
    },
    updateIsFormValid: (state, action) => {
      state.isFormValid = action.payload;
    },
    resetCreditCardDetails: (state) => {
      state.cardNumber = "";
      state.cardType = "";
      state.expiryDate = {
        month: "",
        year: "",
      };
      state.cvv = "";
      state.cardHolderName = {
        firstName: "",
        lastName: "",
      };
      state.country = "";
    },
    updateAfterCardAddedPassthrough: (state) => {
      state.afterCardAddedPassthrough = {};
    },

    // API Response Functions
    updateCardDetail: (state, action) => {
      state.cardDetail = action.payload;
    },
  },
});

export const {
  // General Functions
  updateCardNumber,
  updateExpiryDateMonth,
  updateExpiryDateYear,
  updateCVV,
  updateCardHolderNameFirstName,
  updateCardHolderNameLastName,
  updateCountry,

  // CCBill Link Functions
  updateCCBillLink,

  // Payment Method Selection Functions
  updatePaymentMethodSelection,

  // Payment State Functions
  updatePaymentState,

  // Payment Step Functions
  updateSecurionPayTokenDetails,

  // Payment Error Functions
  updatePaymentErrorInfo,

  // Utililty Functions
  resetPaymentStore,
  updateIsFormValid,
  resetCreditCardDetails,
  updateAfterCardAddedPassthrough,

  // API Response Functions
  updateCardDetail,
} = paymentSlice.actions;

export default paymentSlice.reducer;
