// General
import "./filters-selected.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  resetSearchBodyFilter,
  resetTorListSelected,
} from "../../../../redux/store/searchStore";
import {
  resetSearchFilterStore,
  updateIsFiltered,
  composeSelectedFilters,
} from "../../../../redux/store/searchFilterStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";

const FiltersSelected = () => {
  // Redux variables
  const triggerSearchFilteredPassthrough = useSelector(
    (state) => state.searchFilter.triggerSearchFilteredPassthrough
  );
  const selectedFilters = useSelector(
    (state) => state.searchFilter.selectedFilters
  );
  const torList = useSelector((state) => state.search.torList);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Check for update | isFiltered
  useEffect(() => {
    if (!triggerSearchFilteredPassthrough) return;

    dispatch(composeSelectedFilters(torList));
  }, [triggerSearchFilteredPassthrough]);

  // Event Handlers | Button
  const onResetFilter = () => {
    TagManager.dataLayer({
      dataLayer: {
        event:
          "PWA-Search-Page-FiltersSelected-Subcomponent-ClearFilters-Button",
      },
    });

    dispatch(resetSearchBodyFilter());
    dispatch(resetSearchFilterStore());
    dispatch(resetTorListSelected());
    dispatch(updateIsFiltered());
  };

  // Utility Functions
  const getSelectedFilters = () => {
    if (selectedFilters?.length > 0) {
      return selectedFilters.map((key) => t(key)).join(", ");
    } else {
      return "";
    }
  };

  return (
    <div id="search-filters-selected-subcomponent">
      <div className="filters-selected-padding-container">
        <div className="filters-selected-container">
          <div className="filters-selected-label">
            {t("filter.filters_selected")}
          </div>
          <div className="clear-filters-button" onClick={onResetFilter}>
            {t("filter.clear_filters")}
          </div>
        </div>

        <div className="selected-filters-container">{getSelectedFilters()}</div>
      </div>
    </div>
  );
};

export default FiltersSelected;
