// General
import "./tip-request-accepted-dialog.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCustomizeTipRequestAcceptedDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const TipRequestAcceptedDialog = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const customizeTipRequestAcceptedDialog = useSelector(
    (state) => state.dialog.customizeTipRequestAcceptedDialog
  );
  const customizeTipRequestAcceptedInfo = useSelector(
    (state) => state.privateCall.customizeTipRequestAcceptedInfo
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!customizeTipRequestAcceptedDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-CustomizeTipRequestAccepted-Dialog",
      },
    });

    getProfile(null, true);
  }, [customizeTipRequestAcceptedDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateCustomizeTipRequestAcceptedDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={customizeTipRequestAcceptedDialog}
      onClose={onCloseDialog}
    >
      <div id="tip-request-dialog">
        <div className="padding-container">
          <div className="tip-image-container">
            {getIcon("tipCoinsAccepted", "tip-image")}
          </div>
          <div className="header">
            {t("1on1.user_accepted_request_title", {
              username:
                getProfileData?.data?.id ===
                customizeTipRequestAcceptedInfo?.data?.call_request
                  ?.callee_user_id
                  ? customizeTipRequestAcceptedInfo?.data?.call_request?.caller
                      ?.username
                  : customizeTipRequestAcceptedInfo?.data?.call_request?.callee
                      ?.username,
              tip_action:
                customizeTipRequestAcceptedInfo?.data?.tipping_request
                  ?.description,
            })}
          </div>
          <div className="description">
            {t("1on1.user_accepted_request_desc")}
          </div>
          <div className="accept-button" onClick={onCloseDialog}>
            {t("common.got_it")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TipRequestAcceptedDialog;
