// General
import "./leaderboard-section.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updatePKInfoDialog,
  updateTierInfoDialog,
  updateTopLeaderboardListDialog,
} from "../../../../redux/store/dialogStore";
import {
  updateLeaderboardTitle,

  // Top Leaderboard List Info Functions
  updateTopLeaderboardListInfo,
} from "../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../elements/spinner/spinner";
import UserList from "./user-list/user-list";
import leaderboardConst from "../../../../const/leaderboardConst";

const LeaderboardSection = (props) => {
  const {
    type,
    recurrence,
    isLoading,
    title,
    requireInfoIcon,
    infoType,
    userList,
    userType,
    seeAllButtonType = "page",
    gameId,
  } = props;

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onSeeAllUsers = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LeaderboardSection-Subcomponent-SeeAll-Button",
      },
    });

    dispatch(updateLeaderboardTitle(title));

    switch (seeAllButtonType) {
      case "page":
        onNavigate(
          `${
            routeConst.leaderboard.list.path
          }?type=${type}&recurrence=${recurrence}&userType=${userType}${
            type === leaderboardConst.type.casino ? `&gameId=${gameId}` : ""
          }`
        );
        break;
      case "dialog":
        const obj = {
          type,
          recurrence,
          userType,
          gameId,
        };
        dispatch(updateTopLeaderboardListInfo(obj));
        dispatch(updateTopLeaderboardListDialog(true));
        break;
      default:
        break;
    }
  };

  // Event Handlers | MUI Dialog
  const onOpenDialog = () => {
    switch (infoType) {
      case "tier":
        dispatch(updateTierInfoDialog(true));
        break;
      case "pk":
        dispatch(updatePKInfoDialog(true));
        break;
      default:
        break;
    }
  };

  return (
    <div id="leaderboard-section-shared-subcomponent">
      <div className="section-padding-container">
        <div className="section-container">
          <div className="header-container">
            <div className="header">{title}</div>
            {requireInfoIcon && (
              <div className="info-icon-container" onClick={onOpenDialog}>
                <ErrorOutlineIcon className="info-icon" />
              </div>
            )}

            <div className="see-all-button-container">
              <div className="see-all-button" onClick={onSeeAllUsers}>
                {t("common.see_all")}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="padding-container">
        {isLoading ? (
          <Spinner />
        ) : (
          <UserList
            type={type}
            userList={userList}
            userType={userType}
            renderLimit={5}
          />
        )}
      </div>
    </div>
  );
};

export default LeaderboardSection;
