// General
import "./current-location.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useUpdateUserLocationMutation,
} from "../../../../services/data.service";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  resetFilteredLists,
  resetRecentlyActiveLists,
  resetNewestLists,
  resetCollegeLists,
  resetPremiumLists,
  updateRecentlyActivePagination,
  updateNewestPagination,
  updateCollegePagination,
  updatePremiumPagination,
  updateSetCurrentLocationPassthrough,
} from "../../../../redux/store/searchStore";
// Material UI
import { TextField } from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
// React Google Maps/api
import { Autocomplete } from "@react-google-maps/api";
// i18next
import { useTranslation } from "react-i18next";

const CurrentLocation = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    updateUserLocation,
    {
      data: updateUserLocationData,
      error: updateUserLocationErrorData,
      isLoading: updateUserLocationLoading,
      isSuccess: updateUserLocationSuccess,
      isError: updateUserLocationError,
    },
  ] = useUpdateUserLocationMutation();

  // General variables
  const [formattedAddress, setFormattedAddress] = useState("");
  const [searchResult, setSearchResult] = useState();

  // Redux variables
  const googleMapsLoaded = useSelector(
    (state) => state.noReset.googleMapsLoaded
  );
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        setFormattedAddress(getProfileData?.data?.location?.full_address);
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Update User Location API Response
  useEffect(() => {
    if (updateUserLocationLoading) {
    } else if (updateUserLocationSuccess) {
      if (updateUserLocationData?.status === 1) {
        getProfile(null, false);

        // dispatch(resetFilteredLists());
        dispatch(resetRecentlyActiveLists());
        dispatch(updateRecentlyActivePagination(null));

        dispatch(resetNewestLists());
        dispatch(updateNewestPagination(null));

        dispatch(resetCollegeLists());
        dispatch(updateCollegePagination(null));

        dispatch(resetPremiumLists());
        dispatch(updatePremiumPagination(null));

        dispatch(updateSetCurrentLocationPassthrough());
      }
    } else if (updateUserLocationError) {
    }
  }, [
    updateUserLocationLoading,
    updateUserLocationSuccess,
    updateUserLocationError,
  ]);

  // Event Handlers | MUI Text Fields
  const onInputChange = (event) => {
    // dispatch(updateLocationFormattedAddress(event.target.value));
    setFormattedAddress(event.target.value);
  };

  // Event Handlers | Google Maps Autocomplete
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      let location_data = {};

      if (place?.address_components) {
        for (let i = 0; i < place?.address_components?.length; i++) {
          if (place?.address_components[i]?.types?.length > 0) {
            switch (place?.address_components[i]?.types[0]) {
              case "locality":
                location_data.city = place?.address_components[i]?.long_name;
                break;
              case "neighborhood":
                if (location_data.city !== null) {
                  location_data.neighborhood =
                    place?.address_components[i]?.long_name;
                }
                break;
              case "administrative_area_level_1":
                location_data.state = place?.address_components[i]?.short_name;
                break;
              case "country":
                location_data.country = place?.address_components[i]?.long_name;
                location_data.country_iso =
                  place?.address_components[i]?.short_name;
                break;
              default:
                break;
            }
          }
        }
      }

      setFormattedAddress(place.name);

      const locationObj = {
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
        // ip: null,
        location_data,
        type: "user_defined",
      };

      updateUserLocation(locationObj);
    }
  };
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  if (
    releaseChannel !== utilityConst.releaseChannel.stable &&
    googleMapsLoaded
  ) {
    return (
      <div id="search-current-location-subcomponent">
        <div className="filter-padding-container">
          <FmdGoodIcon className="pin-icon" />

          <div className="location-form-container">
            <Autocomplete
              className="autocomplete-wrapper"
              onPlaceChanged={onPlaceChanged}
              onLoad={onLoad}
            >
              <TextField
                id="search-current-location-text-field"
                className="form-field custom-text-field-label-color"
                label={t("filter.current_location")}
                onChange={onInputChange}
                value={formattedAddress}
                placeholder={t("filter.location")}
                variant="standard"
                InputProps={{ disableUnderline: true }}
                type="search"
              ></TextField>
            </Autocomplete>
          </div>

          <div className="change-location-label">
            {t("filter.change_location")}
          </div>
        </div>
      </div>
    );
  }
};

export default CurrentLocation;
