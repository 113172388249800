// General
import "./verified-caller-description-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVerifiedCallerDescriptionDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const VerifiedCallerDescriptionDialog = () => {
  // Redux variables
  const verifiedCallerDescriptionDialog = useSelector(
    (state) => state.dialog.verifiedCallerDescriptionDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!verifiedCallerDescriptionDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VerifiedCallerDescription-Dialog",
      },
    });
  }, [verifiedCallerDescriptionDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(false));
  };

  return (
    <Dialog
      className="custom-radius25-dialog"
      open={verifiedCallerDescriptionDialog}
      onClose={onCloseDialog}
    >
      <div id="verified-caller-description-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="verified-caller-title-container">
            <div className="verified-caller-title">
              {t("popup.verified_caller_title")}
            </div>
            <div className="verified-caller-badge-container">
              {getIcon("verifiedCallerBadge", "verified-caller-badge")}
            </div>
          </div>

          <div className="verified-caller-description">
            {t("popup.verified_caller_desc")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VerifiedCallerDescriptionDialog;
