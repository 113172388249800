// General
import "./settings.scss";
import { useEffect } from "react";
// Environment
import environment from "../../../environment/environment";
// Services
import { useLazyGetAllSettingsQuery } from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateShowHiddenSettings } from "../../../redux/store/appStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import AccountSettings from "./account-settings/account-settings";
import YourActivitySettings from "./your-activity-settings/your-activity-settings";
import NotificationSettings from "./notification-settings/notification-settings";
import LanguageSettings from "./language-settings/language-settings";
import ThemeSettings from "./theme-settings/theme-settings";
import SupportSettings from "./support-settings/support-settings";
import AboutSettings from "./about-settings/about-settings";
import DebugSettings from "./debug-settings/debug-settings";
import LoggingSettings from "./logging-settings/logging-settings";
import PusherSettings from "./pusher-settings/pusher-settings";
import ReleaseChannelSettings from "./release-channel-settings/release-channel-settings";
import DarkModeSettings from "./dark-mode-settings/dark-mode-settings";
import AppSettings from "./app-settings/app-settings";

const Settings = () => {
  // API variables
  const [
    getAllSettings,
    {
      data: allSettingsData,
      error: allSettingsErrorData,
      isFetching: allSettingsFetching,
      isLoading: allSettingsLoading,
      isSuccess: allSettingsSuccess,
      isError: allSettingsError,
    },
  ] = useLazyGetAllSettingsQuery();

  // General variables
  let debugCounter = 0;

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const showHiddenSettings = useSelector(
    (state) => state.app.showHiddenSettings
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Settings-Page",
      },
    });

    getAllSettings(null, true);
  }, [isLoggedIn]);

  // Event Handlers | Button
  const onLogout = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Settings-Page-Logout-Button",
      },
    });

    onNavigate(routeConst.logout.path);
  };

  // Utility Functions
  const onShowDebugInfo = () => {
    debugCounter = debugCounter += 1;

    if (!showHiddenSettings) {
      if (debugCounter >= 10) {
        debugCounter = 0;
        dispatch(updateShowHiddenSettings(true));
      }
    } else {
      if (debugCounter >= 3) {
        debugCounter = 0;
        dispatch(updateShowHiddenSettings(false));
      }
    }
  };

  return (
    <div id="settings-page">
      <div className="max-width-container">
        <AccountSettings />

        <Divider />

        <YourActivitySettings />

        <Divider />

        <NotificationSettings />

        <Divider />

        <LanguageSettings />

        <Divider />

        <ThemeSettings />

        <Divider />

        <SupportSettings />

        <Divider />

        <AboutSettings />

        <Divider />

        <AppSettings />

        <Divider />

        <div className="logout-button-container" onClick={onLogout}>
          <div className="logout-button">{t("settings.log_out")}</div>
        </div>

        <Divider />

        <div className="version-container">
          <div className="app-icon-container" onClick={onShowDebugInfo}>
            {getIcon("sugarbookIcon", "app-icon")}
          </div>
          <div className="version">
            {t("settings.pwa_version")} {environment.appVersion}
          </div>
        </div>

        <DebugSettings />

        <LoggingSettings />

        <Divider />

        <PusherSettings />

        <Divider />

        <ReleaseChannelSettings />

        <Divider />

        <DarkModeSettings />
      </div>
    </div>
  );
};

export default Settings;
