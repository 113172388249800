// General
import "./component-navbar.scss";
import { useState, useEffect } from "react";
// Static Data
import profileConst from "../../../../const/profileConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateUserProfileDialog } from "../../../../redux/store/dialogStore";
import { resetUserCardStore } from "../../../../redux/store/userCardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import MoreOptionsButton from "../profile/more-options-button/more-options-button";

const ComponentNavbar = (props) => {
  const { userProfileType } = props;

  // General variables
  let [showNavbar, setShowNavbar] = useState(true);

  // Redux variables
  const id = useSelector((state) => state.userCard.id);
  const idInt = useSelector((state) => state.userCard.idInt);
  const username = useSelector((state) => state.userCard.username);
  const privatePhotos = useSelector((state) => state.userCard.privatePhotos);
  const privatePhotoRequested = useSelector(
    (state) => state.userCard.privatePhotoRequested
  );
  const privatePhotoApproval = useSelector(
    (state) => state.userCard.privatePhotoApproval
  );
  const requestedMyPrivatePhoto = useSelector(
    (state) => state.userCard.requestedMyPrivatePhoto
  );
  const approvedMyPrivatePhoto = useSelector(
    (state) => state.userCard.approvedMyPrivatePhoto
  );
  const isBlocked = useSelector((state) => state.userCard.isBlocked);
  const ownMembershipType = useSelector((state) => state.user.membershipType);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | Hide navbar
  useEffect(() => {
    if (isMobile) {
      setShowNavbar(true);
    } else {
      setShowNavbar(true); // Previously false // TODO: Remove this if unnecessary
    }
  }, [isMobile]);

  // Event Handlers | MUI Dialog
  const onCloseProfile = () => {
    if (userProfileType === profileConst.userProfileType.dialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-UserProfile-Page-CloseDialog-Button",
        },
      });

      dispatch(updateUserProfileDialog(false));
      dispatch(resetUserCardStore());
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-UserProfile-Page-CloseProfile-Button",
        },
      });

      onNavigate(-1);
    }
  };

  if (showNavbar) {
    return (
      <div
        id="component-navbar-shared"
        className={`${
          isMobile || userProfileType === profileConst.userProfileType.dialog
            ? "opaque-gradient"
            : ""
        } ${isMobile ? "fixed" : ""} ${
          userProfileType === profileConst.userProfileType.dialog
            ? "border-radius"
            : ""
        }`}
      >
        <div className="navbar-container">
          <div className="space-around">
            <div className="mobile-view">
              <div className="left-container">
                <div className="back-icon-container" onClick={onCloseProfile}>
                  {isMobile ||
                  userProfileType === profileConst.userProfileType.dialog
                    ? getIcon("backLightIcon", "back-icon")
                    : getIcon("backIcon", "back-icon")}
                </div>
              </div>

              <div className="middle-container">
                <div className="middle-label">
                  {ownMembershipType === userConst.membershipType.admin && (
                    <div>
                      {idInt || "-"} {username || "-"}
                    </div>
                  )}
                </div>
              </div>

              <div className="right-container">
                {isMobile && (
                  <MoreOptionsButton
                    id={id}
                    idInt={idInt}
                    privatePhotos={privatePhotos}
                    privatePhotoRequested={privatePhotoRequested}
                    privatePhotoApproval={privatePhotoApproval}
                    requestedMyPrivatePhoto={requestedMyPrivatePhoto}
                    approvedMyPrivatePhoto={approvedMyPrivatePhoto}
                    showClearMessage={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ComponentNavbar;
