// General
import "./global-dialog-mounted.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import { sessionService } from "../../../../services/session.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateSaveToHomescreenDialog } from "../../../../redux/store/dialogStore";
// react-device-detect
import { deviceDetect, isIOS, isAndroid } from "react-device-detect";
// Components
import WhatsARoleDialog from "../../../shared/dialog-content/whats-a-role-dialog/whats-a-role-dialog";
import SaveToHomescreenDialog from "../../../shared/dialog-content/save-to-homescreen-dialog/save-to-homescreen-dialog";
import SaveToHomescreenTutorialDialog from "../../../shared/dialog-content/save-to-homescreen-tutorial-dialog/save-to-homescreen-tutorial-dialog";
import LiveIntroductionDialog from "../../../shared/dialog-content/live-introduction-dialog/live-introduction-dialog";
import VpnBlockDialog from "../../../shared/dialog-content/vpn-block-dialog/vpn-block-dialog";
import WhatsNewDialog from "../../../shared/dialog-content/whats-new-dialog/whats-new-dialog";
import DailyCheckInDialog from "../../../shared/dialog-content/daily-check-in-dialog/daily-check-in-dialog";
import CheckInStreakDialog from "../../../shared/dialog-content/check-in-streak-dialog/check-in-streak-dialog";
import LevelsDescriptionDialog from "../../../shared/dialog-content/levels-description-dialog/levels-description-dialog";
import GetTheFullSugarExperienceDialog from "../../../shared/dialog-content/get-the-full-sugar-experience-dialog/get-the-full-sugar-experience-dialog";
import OnboardingDiamondsInfoDialog from "../../../shared/dialog-content/onboarding-diamonds-info-dialog/onboarding-diamonds-info-dialog";
import OnboardingVideoCallDialog from "../../../shared/dialog-content/onboarding-video-call-dialog/onboarding-video-call-dialog";
import OnboardingLiveStreamingDialog from "../../../shared/dialog-content/onboarding-live-streaming-dialog/onboarding-live-streaming-dialog";
import ThankYouForPurchaseDialog from "../../../shared/dialog-content/thank-you-for-purchase-dialog/thank-you-for-purchase-dialog";
import CoinsDiscountDialog from "../../../shared/dialog-content/coins-discount-dialog/coins-discount-dialog";
import SearchFilterDialog from "../../../shared/dialog-content/search-filter-dialog/search-filter-dialog";
import MyWalletDialog from "../../../shared/dialog-content/my-wallet-dialog/my-wallet-dialog";
import DiamondsInfoDialog from "../../../shared/dialog-content/diamonds-info-dialog/diamonds-info-dialog";
import ClearMessagesDialog from "../../../shared/dialog-content/clear-messages-dialog/clear-messages-dialog";
import GoPrivateDontSwitchAppDialog from "../../../shared/dialog-content/go-private-dont-switch-app-dialog/go-private-dont-switch-app-dialog";
import GoPrivateChecklistDialog from "../../../shared/dialog-content/go-private-checklist-dialog/go-private-checklist-dialog";
import GoPrivatePermissionDialog from "../../../shared/dialog-content/go-private-permission-dialog/go-private-permission-dialog";
import JoinPrivateAndroidWarningDialog from "../../../shared/dialog-content/join-private-android-warning-dialog/join-private-android-warning-dialog";
import VideoCallCameraTesterDialog from "../../../shared/dialog-content/video-call-camera-tester-dialog/video-call-camera-tester-dialog";
import RestartPhoneDescriptionDialog from "../../../shared/dialog-content/restart-phone-description-dialog/restart-phone-description-dialog";
import PrivateCallPreJoinSugarBabyWarningDialog from "../../../shared/dialog-content/private-call-pre-join-sugarbaby-warning-dialog/private-call-pre-join-sugarbaby-warning-dialog";
import PrivateCallPreJoinDialog from "../../../shared/dialog-content/private-call-pre-join-dialog/private-call-pre-join-dialog";
import BusyInPrivateDialog from "../../../shared/dialog-content/busy-in-private-dialog/busy-in-private-dialog";
import PrivateCallCallingDialog from "../../../shared/dialog-content/private-call-calling-dialog/private-call-calling-dialog";
import PrivateCallDeclineDialog from "../../../shared/dialog-content/private-call-decline-dialog/private-call-decline-dialog";
import PrivateCallWaitingRoomDialog from "../../../shared/dialog-content/private-call-waiting-room-dialog/private-call-waiting-room-dialog";
import PrivateCallAcceptedDialog from "../../../shared/dialog-content/private-call-accepted-dialog/private-call-accepted-dialog";
import IncomingPrivateCallDialog from "../../../shared/dialog-content/incoming-private-call-dialog/incoming-private-call-dialog";
import VideoCallInfoDialog from "../../../shared/dialog-content/video-call-info-dialog/video-call-info-dialog";
import VideoCallErrorDialog from "../../../shared/dialog-content/video-call-error-dialog/video-call-error-dialog";
import ChargedMessagePopupDialog from "../../../shared/dialog-content/charged-message-popup-dialog/charged-message-popup-dialog";
import KickUserDialog from "../../../shared/dialog-content/kick-user-dialog/kick-user-dialog";
import EndCallDialog from "../../../shared/dialog-content/end-call-dialog/end-call-dialog";
import GoLiveInfoDialog from "../../../shared/dialog-content/go-live-info-dialog/go-live-info-dialog";
import ProductPurchaseDialog from "../../../shared/dialog-content/product-purchase-dialog/product-purchase-dialog";
import CoinsPackageDialog from "../../../shared/dialog-content/coins-package-dialog/coins-package-dialog";
import TipRequestDialog from "../../../shared/dialog-content/tip-request-dialog/tip-request-dialog";
import TipRequestAcceptedDialog from "../../../shared/dialog-content/tip-request-accepted-dialog/tip-request-accepted-dialog";
import UserProfileDialog from "../../../shared/dialog-content/user-profile-dialog/user-profile-dialog";
import UserIsLiveDialog from "../../../shared/dialog-content/user-is-live-dialog/user-is-live-dialog";
import ProfileAchievementBadgeDialog from "../../../shared/dialog-content/profile-achievement-badge-dialog/profile-achievement-badge-dialog";
import VerifiedCallerDescriptionDialog from "../../../shared/dialog-content/verified-caller-description-dialog/verified-caller-description-dialog";
import PreviewPhotoDialog from "../../../shared/dialog-content/preview-photo-dialog/preview-photo-dialog";
import TorDescriptionDialog from "../../../shared/dialog-content/tor-description-dialog/tor-description-dialog";
import CompleteTermsOfRelationshipDialog from "../../../shared/dialog-content/complete-terms-of-relationship-dialog/complete-terms-of-relationship-dialog";
import AddPaymentMethodDialog from "../../../shared/dialog-content/add-payment-method-dialog/add-payment-method-dialog";
import AddCardLoadingDialog from "../../../shared/dialog-content/add-card-loading-dialog/add-card-loading-dialog";
import PaymentLoadingDialog from "../../../shared/dialog-content/payment-loading-dialog/payment-loading-dialog";
import PaymentErrorDialog from "../../../shared/dialog-content/payment-error-dialog/payment-error-dialog";
import CasinoGameDialog from "../../../shared/dialog-content/casino-game-dialog/casino-game-dialog";
import UpdateTermsOfRelationshipDialog from "../../../shared/dialog-content/update-terms-of-relationship-dialog/update-terms-of-relationship-dialog";
import LowPowerModeDialog from "../../../shared/dialog-content/low-power-mode-dialog/low-power-mode-dialog";
import GeneralLoadingDialog from "../../../shared/dialog-content/general-loading-dialog/general-loading-dialog";
import PreventLandscapeDialog from "../../../shared/dialog-content/prevent-landscape-dialog/prevent-landscape-dialog";
import ReportDialog from "../../../shared/dialog-content/report-dialog/report-dialog";
import TorFiltersDialog from "../../../shared/dialog-content/additional-filters-dialog/tor-filters-dialog/tor-filters-dialog";
import OnboardingVideoCallRoomDialog from "../../../shared/dialog-content/onboarding-video-call-room-dialog/onboarding-video-call-room-dialog";
import PrivateRoomOnboardingDaddyDialog from "../../../shared/dialog-content/private-room-onboarding-daddy-dialog/private-room-onboarding-daddy-dialog";
import PrivateRoomOnboardingBabyDialog from "../../../shared/dialog-content/private-room-onboarding-baby-dialog/private-room-onboarding-baby-dialog";
import GeolocationPermissionPromptDialog from "../../../shared/dialog-content/geolocation-permission-prompt-dialog/geolocation-permission-prompt-dialog";
import GeolocationPermissionDeniedDialog from "../../../shared/dialog-content/geolocation-permission-denied-dialog/geolocation-permission-denied-dialog";
import NotificationPermissionPromptDialog from "../../../shared/dialog-content/notification-permission-prompt-dialog/notification-permission-prompt-dialog";
import SurveyMonkeyDialog from "../../../shared/dialog-content/survey-monkey-dialog/survey-monkey-dialog";
import RedirectFromOldApp from "../../../shared/dialog-content/redirect-from-old-app-dialog/redirect-from-old-app";
import VideoSplashScreenDialog from "../../../shared/dialog-content/video-splash-screen-dialog/video-splash-screen-dialog";
import LoginPageTroubleshootDialog from "../../../shared/dialog-content/login-page-troubleshoot-dialog/login-page-troubleshoot-dialog";
import ConfirmAppResetDialog from "../../../shared/dialog-content/confirm-app-reset-dialog/confirm-app-reset-dialog";

const GlobalDialogMounted = () => {
  // Render variables
  const [dialogView, setDialogView] = useState(null);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    if (isIOS || isAndroid) {
      // Check if the app is installed as PWA
      if (!window.matchMedia("(display-mode: standalone)").matches) {
        dispatch(updateSaveToHomescreenDialog(true));
      }
    }
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Location
  useEffect(() => {
    if (!location) return;

    if (location.pathname.includes(routeConst.search.path)) {
      setDialogView(
        <div>
          <SearchFilterDialog />
          <TorFiltersDialog />
        </div>
      );
    } else if (location.pathname.includes(routeConst.videoCall.path)) {
      setDialogView(
        <div>
          <OnboardingVideoCallDialog />
          <OnboardingVideoCallRoomDialog />
          <TipRequestDialog />
          <TipRequestAcceptedDialog />
        </div>
      );
    } else if (location.pathname === routeConst.live.path) {
      setDialogView(
        <div>
          <OnboardingLiveStreamingDialog />
        </div>
      );
    } else if (
      location.pathname.includes(routeConst.profile.streamerCenter.path)
    ) {
      setDialogView(
        <div>
          <DiamondsInfoDialog />
          <OnboardingDiamondsInfoDialog />
        </div>
      );
    } else if (location.pathname.includes(routeConst.profile.wallet.path)) {
      setDialogView(
        <div>
          <MyWalletDialog />
        </div>
      );
    } else if (location.pathname.includes(routeConst.inbox.path)) {
      setDialogView(
        <div>
          <PrivateCallCallingDialog />
        </div>
      );
    } else if (location.pathname.includes(routeConst.join.path)) {
      setDialogView(
        <div>
          <WhatsARoleDialog />
        </div>
      );
    } else {
      setDialogView(null);
    }
  }, [location]);

  return (
    <div id="global-dialog-mounted">
      {/* Daily */}
      <DailyCheckInDialog />
      <CheckInStreakDialog />
      <LevelsDescriptionDialog />

      {/* Search Dialogs */}
      <GetTheFullSugarExperienceDialog />

      {/* Purchase Dialogs */}
      <ThankYouForPurchaseDialog />
      <CoinsDiscountDialog />

      {/* Private Call Dialogs */}
      <GoPrivateDontSwitchAppDialog />
      <GoPrivateChecklistDialog />
      <GoPrivatePermissionDialog />
      <JoinPrivateAndroidWarningDialog />
      <VideoCallCameraTesterDialog />
      <RestartPhoneDescriptionDialog />
      <PrivateCallPreJoinSugarBabyWarningDialog />
      <PrivateCallPreJoinDialog />
      <BusyInPrivateDialog />
      <PrivateCallDeclineDialog />
      <PrivateCallAcceptedDialog />
      <PrivateCallWaitingRoomDialog />
      <IncomingPrivateCallDialog />
      <VideoCallInfoDialog />
      <VideoCallErrorDialog />
      <ChargedMessagePopupDialog />
      <KickUserDialog />
      <EndCallDialog />

      {/* Go Live */}
      <GoLiveInfoDialog />

      {/* Product Purchase Dialog */}
      <ProductPurchaseDialog />
      <CoinsPackageDialog />

      {/* User Profile Dialog */}
      <UserProfileDialog />
      <UserIsLiveDialog />

      {/* Profile Badge Dialog */}
      <ProfileAchievementBadgeDialog />

      {/* Verified Caller Dialog */}
      <VerifiedCallerDescriptionDialog />

      {/* Preview Photo Dialog */}
      <PreviewPhotoDialog />

      {/* Tor Dialog */}
      <TorDescriptionDialog />
      <CompleteTermsOfRelationshipDialog />
      <UpdateTermsOfRelationshipDialog />

      {/* Payment Dialog */}
      <AddPaymentMethodDialog />
      <AddCardLoadingDialog />
      <PaymentLoadingDialog />
      <PaymentErrorDialog />

      {/* Games Dialog */}
      <CasinoGameDialog />

      {/* Global */}
      <ClearMessagesDialog />
      <LowPowerModeDialog />
      <GeneralLoadingDialog />
      <PreventLandscapeDialog />
      <ReportDialog />

      {dialogView}

      {/* Onboarding Dialogs */}
      <SaveToHomescreenDialog />
      <SaveToHomescreenTutorialDialog />
      <LiveIntroductionDialog />
      <VpnBlockDialog />
      <WhatsNewDialog />
      <PrivateRoomOnboardingDaddyDialog />
      <PrivateRoomOnboardingBabyDialog />

      {/* Geolocation Permission Dialog */}
      <GeolocationPermissionPromptDialog />
      <GeolocationPermissionDeniedDialog />

      {/* Notification Permission Dialog */}
      <NotificationPermissionPromptDialog />

      {/* Survey Monkey Dialog */}
      <SurveyMonkeyDialog />

      <RedirectFromOldApp />

      <VideoSplashScreenDialog />

      {/* Login Page Troubleshoot Dialog */}
      <LoginPageTroubleshootDialog />
      <ConfirmAppResetDialog />
    </div>
  );
};

export default GlobalDialogMounted;
