// General
import "./tip-request-rejected-element.scss";
// i18next
import { useTranslation } from "react-i18next";

const TipRequestRejectedElement = (props) => {
  const { username, levellingBadge, role } = props;

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="chat-section-tip-request-rejected-element-subcomponent">
      <div
        className={`padding-container ${role !== "SD" ? "red-background" : ""}`}
      >
        <div className="chat-info-container">
          <div className="chat-info">
            <span className="username">{username || "-"}</span>

            {levellingBadge && (
              <img
                className="levelling-badge"
                src={levellingBadge}
                alt="levelling-badge"
              />
            )}

            <span className="joined-text">
              {t("pusher.denied_the_tip_request")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipRequestRejectedElement;
