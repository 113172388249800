// General
import "./gift-alert-overlay.scss";
import { useState, useEffect, useRef } from "react";
// Redux
import { useSelector } from "react-redux";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../../shared/elements/custom-avatar/custom-avatar";

const GiftAlertOverlay = () => {
  // General variables
  const [counter, setCounter] = useState(0);
  const counterRef = useRef(0);

  // Render variables
  const [giftAlertView, setGiftAlertView] = useState([]);

  // Redux variables
  const livestreamGiftAnimation = useSelector(
    (state) => state.livestreaming.livestreamGiftAnimation
  );

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Check for update | livestreamGiftAnimation
  useEffect(() => {
    if (livestreamGiftAnimation) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-Livestreaming-Play-Gift-Animation",
        },
      });

      handleGiftAlertView(livestreamGiftAnimation, counterRef.current);

      setCounter((oldCounter) => oldCounter + 1);
      counterRef.current += 1;
    }
  }, [livestreamGiftAnimation]);

  // Utility Functions
  const handleGiftAlertView = (livestreamGiftAnimation, counter) => {
    // Create a new div element
    const giftDiv = (
      <div
        className={`gift-element-container ${assignBackgroundGradient()}`}
        key={counter}
      >
        <div className="profile-picture-container">
          <CustomAvatar
            className="profile-picture"
            src={livestreamGiftAnimation?.user_photo}
            role={null}
          />
        </div>

        <div className="chat-info-container">
          <div className="user-info-container">
            <div className="username">
              {livestreamGiftAnimation?.username || "-"}
            </div>
            {livestreamGiftAnimation?.levelling_profile?.badge && (
              <div className="levelling-badge-container">
                <img
                  className="levelling-badge"
                  src={livestreamGiftAnimation?.levelling_profile?.badge}
                  alt="levelling-badge"
                />
              </div>
            )}
            <span className="sent-text">{t("pusher.sent")}</span>
          </div>

          <div className="gift-name">
            {livestreamGiftAnimation?.data?.gift_name}
          </div>
        </div>

        <div className="gift-icon-container">
          <img
            className="gift-icon"
            src={livestreamGiftAnimation?.data?.gift_graphic}
            alt="gift"
          />
        </div>
      </div>
    );

    // Push the gift alert to the gift alert view
    setGiftAlertView((oldArray) => [...oldArray, giftDiv]);

    // Remove the gift alert after 2 seconds, FIFO
    setTimeout(() => {
      setGiftAlertView((oldArray) => oldArray.slice(1));
    }, 3000);
  };
  const assignBackgroundGradient = () => {
    const randomNumber = Math.floor(Math.random() * 10) + 1;
    return `gift-element-gradient-${randomNumber}`;
  };

  return (
    <div id="livestream-gift-alert-overlay-subcomponent">
      <div className="padding-container">{giftAlertView}</div>
    </div>
  );
};

export default GiftAlertOverlay;
