// General
import "./floating-game-launcher.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCasinoGameDialog } from "../../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Custom Hooks
import AssetManager from "../../../../../utility/manager/asset-manager/asset-manager";

const FloatingGameLauncher = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getUserAio(null, true);
  }, []);

  // Event Handlers | Button
  const onOpenGameLauncher = () => {
    dispatch(updateCasinoGameDialog(true));

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Livestream-Page-FloatingGameLauncher-Button",
      },
    });
  };

  if (getUserAioSuccess) {
    if (getUserAioData?.data?.modules?.gaming_enabled) {
      return (
        <div id="floating-game-launcher-subcomponent">
          <div
            className="game-launcher-icon-container"
            onClick={onOpenGameLauncher}
          >
            <img className="chip-logo" src={getAsset("casinoGameIcon")} />
          </div>
        </div>
      );
    }
  }
};

export default FloatingGameLauncher;
