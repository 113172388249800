// General
import "./deactivate-account.scss";
import { useState, useEffect } from "react";
// Services
import { removeHTMLEscape } from "../../../services/regex.service";
// Static Data
import deactivateAccountConst from "../../../const/deactivateAccountConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateReason,
  updateSuggestion,
} from "../../../redux/store/deactivateStore";
import { updateDeactivateAccountDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { Box, TextField, MenuItem, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import UtilityDesktopNavbar from "../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";
import DeactivateAccountDialog from "../../shared/dialog-content/deactivate-account-dialog/deactivate-account-dialog";

const DeactivateAccount = () => {
  // Constant variables
  const reasonList = deactivateAccountConst.reason;

  // General variables
  const [reasonErrorMessage, setReasonErrorMessage] = useState("");
  const [reasonFocus, setReasonFocus] = useState(false);
  const [reasonValid, setReasonValid] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [suggestionErrorMessage, setSuggestionErrorMessage] = useState("");
  const [suggestionFocus, setSuggestionFocus] = useState(false);
  const [suggestionValid, setSuggestionValid] = useState(false);
  const [suggestionError, setSuggestionError] = useState(false);

  // Redux variables
  const reason = useSelector((state) => state.deactivate.reason);
  const suggestion = useSelector((state) => state.deactivate.suggestion);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DeactivateAccount-Page",
      },
    });
  }, []);

  // Lifecycle | Check for update | Check for suggestion validation
  useEffect(() => {
    reasonValidation();
    suggestionValidation();
  }, [reason, reasonFocus, suggestion, suggestionFocus]);

  // Event Handlers | MUI Select
  const handleSelectChange = (event) => {
    dispatch(updateReason(event.target.value));
  };
  const onReasonFocus = (e) => {
    setReasonFocus(true);
  };
  const reasonValidation = () => {
    let valid = true;
    let error = false;

    if (!reasonFocus) {
      valid = false;
      error = false;
    } else if (reasonFocus && reason === "") {
      setReasonErrorMessage(t("settings.please_select_your_reason."));
      valid = false;
      error = true;
    } else {
      setReasonErrorMessage("");
      valid = true;
      error = false;
    }

    setReasonValid(valid);
    setReasonError(error);
  };

  // Event Handlers | MUI Text Fields
  const onSuggestionChange = (e) => {
    dispatch(updateSuggestion(e.target.value));
  };
  const onSuggestionFocus = (e) => {
    setSuggestionFocus(true);
  };
  const suggestionValidation = () => {
    let valid = true;
    let error = false;

    if (!suggestionFocus) {
      valid = false;
      error = false;
    } else if (suggestionFocus && suggestion === "") {
      setSuggestionErrorMessage(t("settings.suggestion_desc"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(suggestion)) {
      valid = false;
      error = true;
      dispatch(updateSuggestion(""));

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-DeactivateAccount-Page-Suggestion-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event:
            "PWA-DeactivateAccount-Page-Suggestion-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setSuggestionErrorMessage("");
      valid = true;
      error = false;
    }

    setSuggestionValid(valid);
    setSuggestionError(error);
  };

  // Event Handlers | MUI Dialog
  const onOpenDialog = () => {
    dispatch(updateDeactivateAccountDialog(true));
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (reasonValid && suggestionValid) {
      valid = true;
    }

    return valid;
  };

  return (
    <Box id="deactivate-account-page">
      <div className="max-width-container">
        <UtilityDesktopNavbar leftButton={"back"} />

        <div className="padding-container">
          <div className="deactivating-description">
            {t("settings.account_deactivation_desc")}
          </div>

          <div className="reason-form-field-container">
            <TextField
              className="reason-form-field custom-text-field"
              select
              required
              value={reason}
              error={reasonError}
              onChange={handleSelectChange}
              label={t("settings.select_your_reason")}
              placeholder="reason"
              helperText={reasonErrorMessage}
              variant="standard"
              onFocus={onReasonFocus}
            >
              {reasonList.map((option) => (
                <MenuItem value={option.label} key={option.label}>
                  {t(option.value)}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="improvement-description">
            {t("settings.suggestion")}
          </div>

          <div className="suggestion-form-field-container">
            <TextField
              className="suggestion-form-field"
              sx={{
                "& .MuiInput-underline:after": { borderBottomColor: "#710D0D" },
                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
              }}
              required
              value={suggestion}
              error={suggestionError}
              onChange={onSuggestionChange}
              label={t("settings.your_suggestion")}
              type="text"
              placeholder="suggestion"
              helperText={suggestionErrorMessage}
              variant="standard"
              onFocus={onSuggestionFocus}
            />
          </div>

          <Button
            className={`deactivate-account-button ${
              isFormValid() ? "" : "disabled-button"
            }`}
            variant="text"
            onClick={onOpenDialog}
            disabled={!isFormValid()}
          >
            {t("settings.deactivate_account")}
          </Button>

          <div className="bottom-text">
            {t("settings.account_deactivation_note")}
          </div>
        </div>
      </div>

      <DeactivateAccountDialog />
    </Box>
  );
};

export default DeactivateAccount;
