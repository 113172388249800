// General
import "./edit-profile.scss";
// Components
import BasicInformation from "./basic-information/basic-information";
import TermsOfRelationship from "./terms-of-relationship/terms-of-relationship";
import FinancialInformation from "./financial-information/financial-information";
import Location from "./location/location";
import Appearance from "./apperance/appearance";
import PersonalInformation from "./personal-information/personal-information";
import Description from "./description/description";

const EditProfile = () => {
  return (
    <div id="profile-edit-edit-profile-subcomponent">
      <BasicInformation />

      <TermsOfRelationship />

      <FinancialInformation />

      <Location />

      <Appearance />

      <PersonalInformation />

      <Description />
    </div>
  );
};

export default EditProfile;
