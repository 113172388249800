// General
import { useState, useEffect, useRef } from "react";
// Services
import { API_CONFIG_HEADERS } from "../../../../const/apiConst";
import { sessionService } from "../../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  updateShowVideoCallEndingOverlay,

  // Chat Functions
  updateVideoCallChatMessages,
  clearVideoCallChatMessages,

  // Gift Animation Functions
  updatePrivateCallGiftAnimation,

  // Tips Functions
  updateCustomizeTipRequestInfo,
  updateCustomizeTipRequestAcceptedInfo,

  // Utility Functions
  updateNotifyEndVideoCallPassthrough,
  updateChargedMessageRefreshPassthrough,
} from "../../../../redux/store/privateCallStore";
import {
  updateVideoCallPusherPayload,
  updateVideoCallPusherSubscribe,
  updateVideoCallPusherUnsubscribe,
} from "../../../../redux/store/pusherStore";
import {
  updateCustomizeTipRequestDialog,
  updateCustomizeTipRequestAcceptedDialog,
} from "../../../../redux/store/dialogStore";
import { updateGlobalSnackbar } from "../../../../redux/store/publicStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
import {
  updateVideoCallChannelState,
  updateVideoCallPusherState,
} from "../../../../redux/store/debugStore";
// Pusher-js
import Pusher from "pusher-js";

const VideoCallPusher = () => {
  // General variables
  const subscribeIsMounted = useRef(false);
  const unsubscribeIsMounted = useRef(false);
  const resetIsMounted = useRef(false);
  const destroyIsMounted = useRef(false);

  // Redux variables
  const videoCallPusherSubscribe = useSelector(
    (state) => state.pusher.videoCallPusherSubscribe
  );
  const videoCallPusherUnsubscribe = useSelector(
    (state) => state.pusher.videoCallPusherUnsubscribe
  );
  const videoCallPusherReset = useSelector(
    (state) => state.pusher.videoCallPusherReset
  );
  const videoCallPusherDestroy = useSelector(
    (state) => state.pusher.videoCallPusherDestroy
  );
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const requestId = useSelector((state) => state.privateCall.requestId);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const showLog = useSelector((state) => state.debug.showLog);
  const dispatch = useDispatch();

  // Pusher variables
  let authEndpoint = `${process.env["REACT_APP_SPI_API"]}broadcasting/auth`;
  let [pusherInstance, setPusherInstance] = useState(null);
  let [channel, setChannel] = useState(null);

  // Lifecycle | Initiate
  useEffect(() => {
    if (subscribeIsMounted.current) {
      if (!videoCallPusherSubscribe || pusherInstance) return;

      let headers = {
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        Authorization: `${sessionService.getApiToken()}`,
      };

      setPusherInstance(
        new Pusher(process.env["REACT_APP_PUSHER_APP_KEY"], {
          authEndpoint: authEndpoint,
          cluster: "ap1",
          auth: {
            headers: headers,
          },
        })
      );
    } else {
      subscribeIsMounted.current = true;
    }
  }, [videoCallPusherSubscribe]);

  // Lifecycle | Check for update | Subscribe
  useEffect(() => {
    if (!pusherInstance || !requestId) return;

    pusherInstance?.connection?.bind("state_change", (state) => {
      dispatch(updateVideoCallPusherState(state.current));

      switch (state.current) {
        case "initialized":
          break;
        case "connecting":
          break;
        case "connected":
          break;
        case "disconnected":
          break;
        case "unavailable":
          break;
        case "failed":
          break;
        case "disconnected":
          break;
        default:
          break;
      }
    });

    setChannel(pusherInstance.subscribe("private-call-" + requestId));
  }, [pusherInstance, requestId]);

  // Lifecycle | Check for update | Unsubscribe
  useEffect(() => {
    if (unsubscribeIsMounted.current) {
      if (!videoCallPusherUnsubscribe || !requestId) return;

      channel.unsubscribe("private-call-" + requestId);

      dispatch(updateVideoCallChannelState(false));
    } else {
      unsubscribeIsMounted.current = true;
    }
  }, [videoCallPusherUnsubscribe]);

  // Lifecycle | Check for update | Payload
  useEffect(() => {
    if (!channel) return;

    // Event Listener | State
    channel?.bind("pusher:subscription_succeeded", () => {
      // console.log("Subscribed to channel");
      dispatch(updateVideoCallChannelState(true));
    });
    channel?.bind("pusher:subscription_error", (error) => {
      // console.log("Error subscribing to channel: ", error);
      dispatch(updateVideoCallChannelState(false));
    });

    // Event Listener | Response
    channel.bind("private-calls-event", (payload) => {
      if (showLog) {
        console.log("private-calls-event", payload);
      }

      if (payload?.message?.type) {
        switch (payload?.message?.type) {
          case "call_minutes_earned":
            if (showLog) {
              console.log("VC Pusher: Call minutes earned");
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            break;
          case "emoji":
            if (showLog) {
              console.log("VC Pusher: Emoji");
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            break;
          case "message":
            if (showLog) {
              console.log("VC Pusher: Message");
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            break;
          case "gift_request":
            if (showLog) {
              console.log("VC Pusher: Gift request");
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            break;
          case "coin_request":
            if (showLog) {
              console.log("VC Pusher: Coin request");
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            break;
          case "gifting":
            if (showLog) {
              console.log("VC Pusher: Gifting");
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            dispatch(updatePrivateCallGiftAnimation(payload?.message));
            break;
          case "tipping_sent":
            if (showLog) {
              console.log("VC Pusher: Tipping sent");
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            break;
          case "tipping_coins_sent":
            if (showLog) {
              console.log("VC Pusher: Tipping coins sent");
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            break;
          case "tipping_request_sent":
            if (showLog) {
              console.log("VC Pusher: Tipping request sent");
            }

            if (!isDaddy) {
              dispatch(updateCustomizeTipRequestInfo(payload?.message));
              dispatch(updateCustomizeTipRequestDialog(true));
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            break;
          case "tipping_request_accepted":
            if (showLog) {
              console.log("VC Pusher: Tipping request accepted");
            }

            if (isDaddy) {
              dispatch(updateCustomizeTipRequestAcceptedInfo(payload?.message));
              dispatch(updateCustomizeTipRequestAcceptedDialog(true));
            }

            dispatch(updateVideoCallChatMessages(payload?.message));

            break;
          case "tipping_request_rejected":
            if (showLog) {
              console.log("VC Pusher: Tipping request rejected");
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            break;
          case "menu_changed":
            if (showLog) {
              console.log("VC Pusher: Menu changed");
            }
            break;
          case "end_call":
            if (showLog) {
              console.log("VC Pusher: End call");
            }

            dispatch(updateNotifyEndVideoCallPassthrough({}));
            dispatch(clearVideoCallChatMessages());
            dispatch(updateShowVideoCallEndingOverlay(true));
            break;
          case "low_balance":
            if (showLog) {
              console.log("VC Pusher: Low balance");
            }
            break;
          case "kick_user":
            if (showLog) {
              console.log("VC Pusher: Kick user");
            }
            break;
          case "refresh":
            if (showLog) {
              console.log("VC Pusher: Refresh");
            }

            dispatch(updateChargedMessageRefreshPassthrough({}));
            break;
          default:
            break;
        }
      }
    });
    channel.bind("client-private-calls-event", (payload) => {
      if (showLog) {
        console.log("client-private-calls-event", payload);
      }

      if (payload?.message?.type) {
        switch (payload?.message?.type) {
          case "emoji_animation":
            if (showLog) {
              console.log("VC Pusher: Emoji animation");
            }

            dispatch(updateVideoCallChatMessages(payload?.message));
            break;
          default:
            break;
        }
      }
    });
  }, [channel]);

  // Lifecycle | Check for update | Reset Pusher
  useEffect(() => {
    if (resetIsMounted.current) {
      if (!videoCallPusherReset) return;

      if (pusherInstance) {
        // Disconnect Pusher and its channels
        pusherInstance.disconnect();
        setPusherInstance(null);

        // Update State
        dispatch(updateVideoCallChannelState(false));

        setTimeout(() => {
          dispatch(updateVideoCallPusherSubscribe({}));
        }, 2000);
      } else {
        const toastObj = {
          message: "Video Call Pusher Instance is unavailable",
          autoClose: 3000,
        };
        dispatch(updateErrorToast(toastObj));
      }
    } else {
      resetIsMounted.current = true;
    }
  }, [videoCallPusherReset]);

  // Lifecycle | Check for update | Destroy Pusher
  useEffect(() => {
    if (destroyIsMounted.current) {
      if (!videoCallPusherDestroy) return;

      if (pusherInstance) {
        pusherInstance.disconnect();
        setPusherInstance(null);

        // Update State
        dispatch(updateVideoCallChannelState(false));
      }
    } else {
      destroyIsMounted.current = true;
    }
  }, [videoCallPusherDestroy]);

  return <div id="video-call-pusher-shadow-component"></div>;
};

export default VideoCallPusher;
