// General
import "./appearance.scss";
import { useEffect } from "react";
// Services
import { useLazyGetAioQuery } from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateRace,
  updateEyeColor,
  updateHairColor,
} from "../../../../../redux/store/userStore";
// Material UI
import { TextField, MenuItem } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const Appearance = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();

  // Redux variables
  const oldUserProfile = useSelector((state) => state.user.oldUserProfile);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "height":
        dispatch(updateHeight(event.target.value));
        break;
      case "body-type":
        dispatch(updateBodyType(event.target.value));
        break;
      case "ethnicity":
        dispatch(updateEthnicity(event.target.value));
        break;
      case "race":
        dispatch(updateRace(event.target.value));
        break;
      case "eye-color":
        dispatch(updateEyeColor(event.target.value));
        break;
      case "hair-color":
        dispatch(updateHairColor(event.target.value));
        break;
      default:
        break;
    }
  };

  return (
    <div id="edit-profile-appearance-subcomponent">
      <div className="appearance-label">{t("profile.appearance")}</div>

      {/* Height */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.height ? "" : "incomplete-field"
            }`}
          >
            {t("profile.height")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="form-field"
            select
            value={oldUserProfile?.profiledetails?.height || ""}
            onChange={onInputChange("height")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          >
            {getAioData?.payload?.dropdown?.height?.map((height, index) => (
              <MenuItem key={index} value={height?.value}>
                {t(`${height?.name}`)}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      {/* Body Type */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.bodytype ? "" : "incomplete-field"
            }`}
          >
            {t("profile.body_type")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="form-field"
            select
            value={oldUserProfile?.profiledetails?.bodytype || ""}
            onChange={onInputChange("body-type")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          >
            {getAioData?.payload?.dropdown?.body_type?.map(
              (bodyType, index) => (
                <MenuItem key={index} value={bodyType?.value}>
                  {t(`${bodyType?.name}`)}
                </MenuItem>
              )
            )}
          </TextField>
        </div>
      </div>

      {/* Ethnicity */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.ethnicity
                ? ""
                : "incomplete-field"
            }`}
          >
            {t("profile.ethnicity")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="form-field"
            select
            value={oldUserProfile?.profiledetails?.ethnicity || ""}
            onChange={onInputChange("ethnicity")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          >
            {getAioData?.payload?.dropdown?.ethnicity?.map(
              (ethnicity, index) => (
                <MenuItem key={index} value={ethnicity?.value}>
                  {t(`${ethnicity?.name}`)}
                </MenuItem>
              )
            )}
          </TextField>
        </div>
      </div>

      {/* Race */}
      {oldUserProfile?.profiledetails?.ethnicity === "asian" &&
        getAioData?.payload?.dropdown?.race[0]?.child_value?.length > 0 && (
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${
                  oldUserProfile?.profiledetails?.race ? "" : "incomplete-field"
                }`}
              >
                {t("profile.race")}
              </div>
            </div>

            <div className="form-container">
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                className="form-field"
                select
                value={oldUserProfile?.profiledetails?.race || ""}
                onChange={onInputChange("race")}
                variant="standard"
                InputProps={{ disableUnderline: true }}
              >
                {getAioData?.payload?.dropdown?.race[0]?.child_value?.map(
                  (race, index) => (
                    <MenuItem key={index} value={race?.value}>
                      {t(`${race?.name}`)}
                    </MenuItem>
                  )
                )}
              </TextField>
            </div>
          </div>
        )}

      {/* Eye Color */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.eyecolor ? "" : "incomplete-field"
            }`}
          >
            {t("profile.eye_color")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="form-field"
            select
            value={oldUserProfile?.profiledetails?.eyecolor || ""}
            onChange={onInputChange("eye-color")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          >
            {getAioData?.payload?.dropdown?.eye_color?.map(
              (eyeColor, index) => (
                <MenuItem key={index} value={eyeColor?.value}>
                  {t(`${eyeColor?.name}`)}
                </MenuItem>
              )
            )}
          </TextField>
        </div>
      </div>

      {/* Hair Color */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.haircolor
                ? ""
                : "incomplete-field"
            }`}
          >
            {t("profile.hair_color")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="form-field"
            select
            value={oldUserProfile?.profiledetails?.haircolor || ""}
            onChange={onInputChange("hair-color")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          >
            {getAioData?.payload?.dropdown?.hair_color?.map(
              (hairColor, index) => (
                <MenuItem key={index} value={hairColor?.value}>
                  {t(`${hairColor?.name}`)}
                </MenuItem>
              )
            )}
          </TextField>
        </div>
      </div>
    </div>
  );
};

export default Appearance;
