// General
import "./unfollow-user-dialog.scss";
import { useEffect } from "react";
// Services
import { usePostUnfollowUserMutation } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateUnfollowUserDialog } from "../../../../redux/store/dialogStore";
import { updateFollowState } from "../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Materail UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";
import Spinner from "../../../shared/elements/spinner/spinner";

const UnfollowUserDialog = () => {
  // API variables
  const [
    postUnfollowUser,
    {
      data: postUnfollowUserData,
      error: postUnfollowUserErrorData,
      isLoading: postUnfollowUserLoading,
      isSuccess: postUnfollowUserSuccess,
      isError: postUnfollowUserError,
    },
  ] = usePostUnfollowUserMutation();

  // Redux variables
  const unfollowUserDialog = useSelector(
    (state) => state.dialog.unfollowUserDialog
  );
  const otherUserInfo = useSelector((state) => state.leaderboard.otherUserInfo);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!unfollowUserDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UnfollowUser-Dialog",
      },
    });
  }, [unfollowUserDialog]);

  // Lifecycle | Check for update | Unfollow User API Response
  useEffect(() => {
    if (postUnfollowUserLoading) {
    } else if (postUnfollowUserSuccess) {
      if (postUnfollowUserData?.status === 1) {
        const obj = {
          userId: otherUserInfo?.id,
          isFollowing: false,
        };
        dispatch(updateFollowState(obj));
        dispatch(updateUnfollowUserDialog(false));
      }
    } else if (postUnfollowUserError) {
    }
  }, [postUnfollowUserLoading, postUnfollowUserSuccess, postUnfollowUserError]);

  // Event Handlers | Button
  const onUnfollowUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UnfollowUser-Dialog-UnfollowUser-Button",
      },
    });

    const obj = {
      user_id: otherUserInfo?.id,
    };

    postUnfollowUser(obj);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateUnfollowUserDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={unfollowUserDialog}
      onClose={onCloseDialog}
    >
      <div id="unfollow-user-dialog">
        <div className="padding-container">
          <CustomAvatar
            className="profile-photo"
            src={otherUserInfo?.profile_photo?.optimized_photo}
            role={otherUserInfo?.role}
          />

          <div className="unfollow-description">
            {t("leaderboard.dialog_unfollow_title", {
              user: otherUserInfo?.username,
            })}
          </div>

          <div className="buttons-container">
            <div className="unfollow-button" onClick={onUnfollowUser}>
              {postUnfollowUserLoading ? (
                <Spinner
                  isPadding={false}
                  size={25}
                  thickness={3}
                  color={"white-spinner"}
                />
              ) : (
                t("common.unfollow")
              )}
            </div>

            <div className="cancel-button" onClick={onCloseDialog}>
              {t("common.cancel")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UnfollowUserDialog;
