// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: "",
  birthdate: "",
  location: {
    lat: null,
    lng: null,
    location_data: {
      city: null,
      state: null,
      formatted_address: "",
      country: null,
      country_iso: null,
    },
    type: "user_defined",
  },
  aboutMe: "",
  lookingFor: "",
  tagline: "",
  annualIncome: "",
  networth: "",
  occupation: "",
  lifestyle: "",
  education: "",
  relationshipStatus: "",
  smoking: "",
  drinking: "",
  height: "",
  bodyType: "",
  ethnicity: "",
  race: "",
  eyeColor: "",
  hairColor: "",
  children: "",
};

export const editProfileSlice = createSlice({
  name: "editProfile",
  initialState,
  reducers: {
    // General Functions
    updateUsername: (state, action) => {
      state.username = action.payload;
    },
    updateBirthdate: (state, action) => {
      state.birthdate = action.payload;
    },
    updateLocation: (state, action) => {
      state.location = action.payload;
    },
    updateAboutMe: (state, action) => {
      state.aboutMe = action.payload;
    },
    updateLookingFor: (state, action) => {
      state.lookingFor = action.payload;
    },
    updateTagline: (state, action) => {
      state.tagline = action.payload;
    },
    updateAnnualIncome: (state, action) => {
      state.annualIncome = action.payload;
    },
    updateNetworth: (state, action) => {
      state.networth = action.payload;
    },
    updateOccupation: (state, action) => {
      state.occupation = action.payload;
    },
    updateLifestyle: (state, action) => {
      state.lifestyle = action.payload;
    },
    updateEducation: (state, action) => {
      state.education = action.payload;
    },
    updateRelationshipStatus: (state, action) => {
      state.relationshipStatus = action.payload;
    },
    updateSmoking: (state, action) => {
      state.smoking = action.payload;
    },
    updateDrinking: (state, action) => {
      state.drinking = action.payload;
    },
    updateHeight: (state, action) => {
      state.height = action.payload;
    },
    updateBodyType: (state, action) => {
      state.bodyType = action.payload;
    },
    updateEthnicity: (state, action) => {
      state.ethnicity = action.payload;
    },
    updateRace: (state, action) => {
      state.race = action.payload;
    },
    updateEyeColor: (state, action) => {
      state.eyeColor = action.payload;
    },
    updateHairColor: (state, action) => {
      state.hairColor = action.payload;
    },
    updateChildren: (state, action) => {
      state.children = action.payload;
    },

    // Location Functions
    updateEditLocation: (state, action) => {
      state.location = action.payload;
    },
    updateEditLocationFormattedAddress: (state, action) => {
      state.location.location_data.formatted_address = action.payload;
    },
    resetEditLocation: (state) => {
      state.location = {
        lat: null,
        lng: null,
        location_data: {
          city: null,
          state: null,
          formatted_address: "",
          country: null,
          country_iso: null,
        },
        type: "user_defined",
      };
    },

    // Utility Functions
    resetEditProfileStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateUsername,
  updateBirthdate,
  updateLocation,
  updateAboutMe,
  updateLookingFor,
  updateTagline,
  updateAnnualIncome,
  updateNetworth,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationshipStatus,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateRace,
  updateEyeColor,
  updateHairColor,
  updateChildren,

  // Location Functions
  updateEditLocation,
  updateEditLocationFormattedAddress,
  resetEditLocation,

  // Utility Functions
  resetEditProfileStore,
} = editProfileSlice.actions;

export default editProfileSlice.reducer;
