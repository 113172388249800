// General
import "./private-call-accepted-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import { useLazyJoinPrivateCallWaitingRoomQuery } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePrivateCallAcceptedDialog,
  updatePrivateCallWaitingRoomDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const PrivateCallAcceptedDialog = () => {
  // API variables
  const [
    joinPrivateCallWaitingRoom,
    {
      data: joinPrivateCallWaitingRoomData,
      error: joinPrivateCallWaitingRoomErrorData,
      isFetching: joinPrivateCallWaitingRoomFetching,
      isLoading: joinPrivateCallWaitingRoomLoading,
      isSuccess: joinPrivateCallWaitingRoomSuccess,
      isError: joinPrivateCallWaitingRoomError,
    },
  ] = useLazyJoinPrivateCallWaitingRoomQuery();

  // Redux variables
  const requestId = useSelector((state) => state.privateCall.requestId);
  const privateCallAcceptedDialog = useSelector(
    (state) => state.dialog.privateCallAcceptedDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!privateCallAcceptedDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PrivateCallAccepted-Dialog",
      },
    });

    const obj = {
      call_request_id: requestId,
    };

    joinPrivateCallWaitingRoom(obj);
  }, [privateCallAcceptedDialog]);

  // Lifecycle | Check for update | Join Private Call Waiting Room API Response
  useEffect(() => {
    if (
      joinPrivateCallWaitingRoomFetching ||
      joinPrivateCallWaitingRoomLoading
    ) {
    } else if (joinPrivateCallWaitingRoomSuccess) {
      onCloseDialog();
      dispatch(updatePrivateCallWaitingRoomDialog(true));
    } else if (joinPrivateCallWaitingRoomError) {
    }
  }, [
    joinPrivateCallWaitingRoomFetching,
    joinPrivateCallWaitingRoomLoading,
    joinPrivateCallWaitingRoomSuccess,
    joinPrivateCallWaitingRoomError,
  ]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updatePrivateCallAcceptedDialog(false));
  };

  return (
    <Dialog
      className="custom-fullscreen-transparent-dialog"
      fullScreen
      open={privateCallAcceptedDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div id="private-call-accepted-dialog">
        <div className="padding-container">
          <div className="accept-container">
            <div className="call-accept-animation-container">
              {getIcon("callAcceptGif", "call-accept-animation")}
            </div>

            <div className="accept-label">{t("inbox.call_accepted")}</div>
            <div className="text">{t("inbox.calling_accepted_hold_on")}</div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PrivateCallAcceptedDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
