// General
import "./customize.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetPrivateCallSettingsQuery,
  useUpdatePrivateCallSettingsMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEnableCallInfoDialog,
  updateRatesInfoDialog,
} from "../../../../redux/store/dialogStore";
import { updateVideoCallSettings } from "../../../../redux/store/privateCallStore";
import { updateSuccessToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider, Switch, TextField, MenuItem } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import EnableCallDialog from "../../../shared/dialog-content/enable-call-dialog/enable-call-dialog";
import RatesDialog from "../../../shared/dialog-content/rates-dialog/rates-dialog";

const CustomizeVideoCallSettings = () => {
  // API variables
  const [
    getPrivateCallSettings,
    {
      data: getPrivateCallSettingsData,
      error: getPrivateCallSettingsErrorData,
      isFetching: getPrivateCallSettingsFetching,
      isLoading: getPrivateCallSettingsLoading,
      isSuccess: getPrivateCallSettingsSuccess,
      isError: getPrivateCallSettingsError,
    },
  ] = useLazyGetPrivateCallSettingsQuery();
  const [
    updatePrivateCallSettings,
    {
      data: updatePrivateCallSettingsData,
      error: updatePrivateCallSettingsErrorData,
      isLoading: updatePrivateCallSettingsLoading,
      isSuccess: updatePrivateCallSettingsSuccess,
      isError: updatePrivateCallSettingsError,
    },
  ] = useUpdatePrivateCallSettingsMutation();

  // Constant variables
  const hoursList = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];
  const availableDaysList = [
    { title: "Weekdays", value: "weekdays" },
    { title: "Weekends", value: "weekends" },
    { title: "All Days", value: "all" },
  ];
  const acceptCallsFromList = [
    { title: "Premium Only", value: "premium" },
    { title: "Everyone", value: "all" },
  ];
  const ratesList = [0.5];

  // General variables
  const [enableCallLabel, setEnableCallLabel] = useState("Enabled");
  const [enableCall, setEnableCall] = useState(false);
  const [availableHoursLabel, setAvailableHoursLabel] = useState("Always");
  const [availableHours, setAvailableHours] = useState(false);
  const [availableFrom, setAvailableFrom] = useState("00:00");
  const [availableTo, setAvailableTo] = useState("00:00");
  const [availableDays, setAvailableDays] = useState("all");
  const [acceptCallsFrom, setAcceptCallsFrom] = useState(
    acceptCallsFromList[1]
  );
  const [rates, setRates] = useState(0.5);
  const isMounted = useRef(false);

  // Render variables
  let settingsView;

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const saveVideoCallSettingsPassthrough = useSelector(
    (state) => state.user.saveVideoCallSettingsPassthrough
  );
  const videoCallSettings = useSelector(
    (state) => state.privateCall.videoCallSettings
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VideoCallSettingsCustomize-Page",
      },
    });

    getPrivateCallSettings();
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Initialize Values
  useEffect(() => {
    if (!videoCallSettings?.call_settings) return;

    setEnableCall(videoCallSettings?.call_settings?.enable_calls);
    setAvailableHours(videoCallSettings?.call_settings?.available_hours_always);
    setAvailableFrom(videoCallSettings?.call_settings?.available_hours_from);
    setAvailableTo(videoCallSettings?.call_settings?.available_hours_to);
    setAvailableDays(videoCallSettings?.call_settings?.available_days);
    setAcceptCallsFrom(videoCallSettings?.call_settings?.accept_calls_from);
    setRates(videoCallSettings?.call_settings?.rates_per_minute || 0.5);

    if (videoCallSettings?.call_settings?.enable_calls) {
      setEnableCallLabel("Enabled");
    } else {
      setEnableCallLabel("Disabled");
    }

    if (videoCallSettings?.call_settings?.available_hours_always) {
      setAvailableHoursLabel("Always");
    } else {
      setAvailableHoursLabel("Custom");
    }
  }, [videoCallSettings]);

  // Lifecycle | Check for update | Private Call Settings API Response
  useEffect(() => {
    if (getPrivateCallSettingsLoading) {
    } else if (getPrivateCallSettingsSuccess) {
      if (getPrivateCallSettingsData?.status === 0) {
        dispatch(updateVideoCallSettings(getPrivateCallSettingsData.data));
      }
    } else if (getPrivateCallSettingsError) {
      if (getPrivateCallSettingsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallSettingsFetching,
    getPrivateCallSettingsLoading,
    getPrivateCallSettingsSuccess,
    getPrivateCallSettingsError,
  ]);

  // Lifecycle | Check for update | Update Private Call Settings API Response
  useEffect(() => {
    if (updatePrivateCallSettingsLoading) {
    } else if (updatePrivateCallSettingsSuccess) {
      if (updatePrivateCallSettingsData?.status === 0) {
        const toastObj = {
          message: t("settings.alert_updated"),
          autoClose: 3000,
        };
        dispatch(updateSuccessToast(toastObj));

        onNavigate(-1);
      }
    } else if (updatePrivateCallSettingsError) {
      if (updatePrivateCallSettingsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    updatePrivateCallSettingsLoading,
    updatePrivateCallSettingsSuccess,
    updatePrivateCallSettingsError,
  ]);

  // Lifecycle | Check for update | Navbar Right Button Event
  useEffect(() => {
    if (isMounted.current) {
      const obj = {
        enable_calls: enableCall,
        available_hours_from: availableFrom,
        available_hours_to: availableTo,
        available_hours_always: availableHours,
        available_days: availableDays,
        accept_calls_from: acceptCallsFrom,
        rates_per_minute: rates,
      };

      updatePrivateCallSettings(obj);
    } else {
      isMounted.current = true;
    }
  }, [saveVideoCallSettingsPassthrough]);

  // Event Handlers | MUI Switch
  const onSwitchChange = (state, checked) => (event) => {
    switch (state) {
      case "enable-call":
        setEnableCall(event.target.checked);

        if (event.target.checked) {
          setEnableCallLabel("Enabled");
        } else {
          setEnableCallLabel("Disable");
        }
        break;
      case "available-hours":
        setAvailableHours(event.target.checked);
        if (event.target.checked) {
          setAvailableHoursLabel("Always");
        } else {
          setAvailableHoursLabel("Custom");
        }
        break;
      default:
        break;
    }
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "available-from":
        setAvailableFrom(event.target.value);
        break;
      case "available-to":
        setAvailableTo(event.target.value);
        break;
      case "available-days":
        setAvailableDays(event.target.value);
        break;
      case "accept-calls-from":
        setAcceptCallsFrom(event.target.value);
        break;
      case "rates":
        setRates(event.target.value);
        break;
      default:
        break;
    }
  };

  // Event Handlers | MUI Dialog
  const onOpenDialog = (state) => {
    switch (state) {
      case "enable-call":
        dispatch(updateEnableCallInfoDialog(true));
        break;
      case "rates":
        dispatch(updateRatesInfoDialog(true));
        break;
      default:
        break;
    }
  };

  // Render Functions | API Response
  if (getPrivateCallSettingsFetching || getPrivateCallSettingsLoading) {
    settingsView = <Spinner />;
  } else if (getPrivateCallSettingsSuccess) {
    settingsView = (
      <div className="settings-container">
        <div className="setting-container">
          <div className="label-container">
            <div className="label">{t("profile.enable_call")}</div>

            <ErrorOutlineIcon
              className="info-icon"
              onClick={() => onOpenDialog("enable-call")}
            />
          </div>

          <div className="output">{enableCallLabel}</div>

          <div className="toggle">
            <Switch
              className="custom-switch"
              checked={enableCall}
              onChange={onSwitchChange("enable-call")}
            />
          </div>
        </div>

        <Divider />

        {enableCall && (
          <div className="enabled-call-settings-container">
            <div className="setting-container">
              <div className="label-container">
                <div className="label">{t("profile.available_hours")}</div>
              </div>

              <div className="output">{availableHoursLabel}</div>

              <div className="toggle">
                <Switch
                  className="custom-switch"
                  checked={availableHours}
                  onChange={onSwitchChange("available-hours")}
                />
              </div>
            </div>

            <Divider />

            {!availableHours && (
              <div>
                <div className="setting-container">
                  <div className="label-container">
                    <div className="label">Available From</div>
                  </div>

                  <div className="form-container">
                    <TextField
                      sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                      className="form-field"
                      select
                      value={availableFrom}
                      onChange={onInputChange("available-from")}
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                    >
                      {hoursList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                <Divider />

                <div className="setting-container">
                  <div className="label-container">
                    <div className="label">Available To</div>
                  </div>

                  <div className="form-container">
                    <TextField
                      sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                      className="form-field"
                      select
                      value={availableTo}
                      onChange={onInputChange("available-to")}
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                    >
                      {hoursList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                <Divider />
              </div>
            )}

            <div className="setting-container">
              <div className="label-container">
                <div className="label">{t("profile.available_days")}</div>
              </div>

              <div className="form-container">
                <TextField
                  sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                  className="form-field"
                  select
                  value={availableDays}
                  onChange={onInputChange("available-days")}
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                >
                  {availableDaysList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            <Divider />

            <div className="setting-container">
              <div className="label-container">
                <div className="label">{t("profile.accept_calls_from")}</div>
              </div>

              <div className="form-container">
                <TextField
                  sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                  className="form-field"
                  select
                  value={acceptCallsFrom}
                  onChange={onInputChange("accept-calls-from")}
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                >
                  {acceptCallsFromList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            <Divider />

            {false && (
              <div className="setting-container">
                <div className="label-container">
                  <div className="label">{t("profile.rates")}</div>

                  <ErrorOutlineIcon
                    className="info-icon"
                    onClick={() => onOpenDialog("rates")}
                  />
                </div>

                <div className="form-container">
                  <TextField
                    sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                    className="form-field"
                    select
                    value={rates}
                    onChange={onInputChange("rates")}
                    disabled={true}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                  >
                    {ratesList.map((option) => (
                      <MenuItem key={option} value={option}>
                        USD {option} per min
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            )}

            {false && (
              <div className="unavailable-text">
                Feature is currently unavailable. Stay tuned.
              </div>
            )}
          </div>
        )}

        <div className="timezone-label">(Timezone is in GMT +08:00)</div>
      </div>
    );
  } else if (getPrivateCallSettingsError) {
  }

  return (
    <div id="video-call-customize-page">
      <div className="max-width-container">
        <div className="settings-label">{t("settings.settings")}</div>

        <Divider />

        {settingsView}
      </div>

      <EnableCallDialog />
      <RatesDialog />
    </div>
  );
};

export default CustomizeVideoCallSettings;
