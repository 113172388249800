// General
import "./location.scss";
import { useState, useEffect, useRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Edit Profile Functions
  updateEditLocation,
  updateEditLocationFormattedAddress,
  resetEditLocation,
} from "../../../../../redux/store/userStore";
// Material UI
import { TextField, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ReplayIcon from "@mui/icons-material/Replay";
// React Google Maps/api
import { Autocomplete } from "@react-google-maps/api";
// i18next
import { useTranslation } from "react-i18next";

const Location = () => {
  // General variables
  const [searchResult, setSearchResult] = useState();

  // Redux variables
  const oldUserProfile = useSelector((state) => state.user.oldUserProfile);
  const editLocation = useSelector((state) => state.user.editLocation);
  const googleMapsLoaded = useSelector(
    (state) => state.noReset.googleMapsLoaded
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onReloadGoogleMaps = () => {
    window.location.reload(true);
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (event) => {
    dispatch(updateEditLocationFormattedAddress(event.target.value));
  };
  const onClearLocation = () => {
    dispatch(updateEditLocationFormattedAddress(""));
  };

  // Event Handlers | Google Maps Autocomplete
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      let location_data = {
        formatted_address: place?.formatted_address,
      };

      if (place?.address_components) {
        for (let i = 0; i < place?.address_components?.length; i++) {
          if (place?.address_components[i]?.types?.length > 0) {
            switch (place?.address_components[i]?.types[0]) {
              case "locality":
                location_data.city = place?.address_components[i]?.long_name;
                break;
              case "neighborhood":
                if (location_data.city !== null) {
                  location_data.neighborhood =
                    place?.address_components[i]?.long_name;
                }
                break;
              case "administrative_area_level_1":
                location_data.state = place?.address_components[i]?.short_name;
                break;
              case "country":
                location_data.country = place?.address_components[i]?.long_name;
                location_data.country_iso =
                  place?.address_components[i]?.short_name;
                break;
              default:
                break;
            }
          }
        }
      }

      const locationObj = {
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
        location_data,
        type: "user_defined",
      };

      dispatch(updateEditLocation(locationObj));
    }
  };
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  if (googleMapsLoaded) {
    return (
      <div id="edit-profile-location-subcomponent">
        <div className="location-label">{t("profile.location")}</div>

        <div className="form-field-container">
          <div className="label-container">
            <div className="label">{t("profile.location")}</div>
          </div>

          {/* Location */}
          <div className="form-container">
            <Autocomplete
              className="autocomplete-wrapper"
              onPlaceChanged={onPlaceChanged}
              onLoad={onLoad}
            >
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                className="form-field"
                onChange={onInputChange}
                value={editLocation?.location_data?.formatted_address}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <IconButton
                      sx={{
                        visibility: oldUserProfile?.location
                          ? "visible"
                          : "hidden",
                      }}
                      onClick={onClearLocation}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              ></TextField>
            </Autocomplete>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="edit-profile-location-subcomponent">
        <div className="reload-location-container" onClick={onReloadGoogleMaps}>
          <ReplayIcon className="reload-icon" />
          <div className="reload-location-label">Location error. Reload</div>
        </div>
      </div>
    );
  }
};

export default Location;
