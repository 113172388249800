// General
import "./add-payment-method.scss";
import { useState, useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { resetCreditCardDetails } from "../../../redux/store/paymentStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useSecurionCreateCardFlow from "../../utility/custom-hooks/useSecurionCreateCardFlow-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import CardDetails from "../../shared/payment/card-details/card-details";
import AddCardLoadingDialog from "../../shared/dialog-content/add-card-loading-dialog/add-card-loading-dialog";

const AddPaymentMethod = () => {
  // General variables
  const [tnc, setTnc] = useState(false);

  // Redux variables
  const isFormValid = useSelector((state) => state.payment.isFormValid);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const securionCreateCardFlow = useSecurionCreateCardFlow();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-AddPaymentMethod-Page",
      },
    });

    dispatch(resetCreditCardDetails());
  }, []);

  // Event Handlers | Button
  const onAddCard = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-AddPaymentMethod-Page-AddCard-Button",
      },
    });

    securionCreateCardFlow();
  };

  // Event Handlers | MUI Checkbox
  const onCheckboxChange = (event) => {
    setTnc(event.target.checked);
  };

  return (
    <div id="add-payment-method-page">
      <div className="max-width-container">
        <div className="padding-container">
          <CardDetails />

          <div className="we-accept-label">{t("purchase.we_accept")}:</div>

          <div className="credit-card-company-image-container">
            {getIcon("visaLogo", "visa-logo")}
            {getIcon("mastercardLogo", "mastercard-logo")}
            {getIcon("maestroLogo", "maestro-logo")}
            {getIcon("visaElectronLogo", "electron-logo")}
          </div>

          <div className="agreement-checkbox-container">
            <FormGroup className="checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    className="custom-checkbox"
                    onChange={onCheckboxChange}
                  />
                }
                label={t("purchase.i_accept_tnc")}
              />
            </FormGroup>
          </div>

          <div className="discretion-container">
            {t("purchase.cc_note")}
            <div>"CCBill.com *SGRBOOK" / "Sovrnt Ltd. * PAY@SGRBK.COM"</div>
          </div>
        </div>

        <div
          className={`pay-button ${
            isFormValid && tnc ? "" : "disabled-button"
          }`}
          onClick={onAddCard}
        >
          {t("common.submit")}
        </div>
      </div>
    </div>
  );
};

export default AddPaymentMethod;
