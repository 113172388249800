// General
import "./save-to-homescreen-tutorial-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSaveToHomescreenTutorialDialog,
  updateSaveToHomescreenDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const SaveToHomescreenTutorialDialog = () => {
  // Redux variables
  const saveToHomescreenTutorialDialog = useSelector(
    (state) => state.dialog.saveToHomescreenTutorialDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!saveToHomescreenTutorialDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-SaveToHomescreenTutorial-Dialog",
      },
    });
  }, [saveToHomescreenTutorialDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateSaveToHomescreenTutorialDialog(false));
    dispatch(updateSaveToHomescreenDialog(false));
  };

  return (
    <Dialog
      fullScreen
      open={saveToHomescreenTutorialDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="save-to-homescreen-tutorial-dialog">
        <div className="tutorial-background-container">
          <img
            className="tutorial-background"
            src={getAsset("pwaTutorialBackground")}
          />
        </div>

        <div className="got-it-button" onClick={onCloseDialog}>
          {t("common.got_it")}
        </div>
      </div>
    </Dialog>
  );
};

export default SaveToHomescreenTutorialDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
