// General
import "./livestream-profile-view-dialog.scss";
import { useEffect, forwardRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLivestreamProfileViewDialog,
  updateDialogSearchParam,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Divider } from "@mui/material";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import UserInfo from "./user-info/user-info";
import ViewerInfo from "./viewer-info/viewer-info";

const LivestreamProfileViewDialog = () => {
  // Redux variables
  const livestreamProfileViewDialog = useSelector(
    (state) => state.dialog.livestreamProfileViewDialog
  );
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (livestreamProfileViewDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-LivestreamProfileView-Dialog",
        },
      });

      dispatch(
        updateDialogSearchParam({
          key: "livestreamProfileViewDialog=true",
          value: true,
        })
      );
    } else {
      if (location?.search) {
        if (location?.search?.includes("livestreamProfileViewDialog=true")) {
          dispatch(
            updateDialogSearchParam({
              key: "livestreamProfileViewDialog=true",
              value: false,
            })
          );
          onNavigate(-1, { replace: true });
        }
      }
    }
  }, [livestreamProfileViewDialog]);

  // Lifecycle | Check for update | searchParams
  useEffect(() => {
    if (searchParams?.get("livestreamProfileViewDialog") === "true") return;

    dispatch(updateLivestreamProfileViewDialog(false));
  }, [searchParams]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateLivestreamProfileViewDialog(false));
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={livestreamProfileViewDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="livestream-profile-view-dialog">
        <UserInfo />

        <Divider />

        <ViewerInfo state={"dialog"} />
      </div>
    </Dialog>
  );
};

export default LivestreamProfileViewDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
