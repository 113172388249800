// General
import "./gift-element.scss";
// Redux
import { useSelector } from "react-redux";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const GiftElement = (props) => {
  const {
    state,
    livestreamingAchievementBadge,
    profilePhoto,
    username,
    levellingBadge,
    giftName,
    giftValue,
    giftImage,
  } = props;

  // Redux variables
  const isDaddy = useSelector((state) => state.user.isDaddy);

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div
      id="chat-section-gift-element-subcomponent"
      className={`${state === "private-call" ? "added-background-color" : ""}`}
    >
      <div className="profile-picture-container">
        <CustomAvatar className="profile-picture" src={profilePhoto} />
      </div>

      <div className="chat-info-container">
        <div className="user-info-container">
          {livestreamingAchievementBadge?.length > 0 && (
            <div className="livestreaming-achievement-badge-container">
              {livestreamingAchievementBadge?.map((badge, index) => (
                <img
                  className="livestreaming-achievement-badge"
                  src={badge?.url}
                  alt={badge?.type}
                  key={index}
                />
              ))}
            </div>
          )}

          <div
            className={`username ${
              state === "livestream" && !isMobile ? "" : "yellow"
            }`}
          >
            {username || "-"}
          </div>

          {levellingBadge && (
            <div className="levelling-badge-container">
              <img
                className="levelling-badge"
                src={levellingBadge}
                alt="levelling-badge"
              />
            </div>
          )}
          <span
            className={`sent-text ${
              state === "livestream" && !isMobile ? "" : "yellow"
            }`}
          >
            {t("common.sent_single")}
          </span>
        </div>

        <div
          className={`gift-name ${
            state === "livestream" && !isMobile ? "" : "yellow"
          }`}
        >
          {giftName}
        </div>

        {!isDaddy && giftValue && (
          <div className="gift-diamond-count">
            <div className="diamond-icon-container">
              {getIcon("sbDiamondIcon", "diamond-icon")}
            </div>
            +{giftValue}
          </div>
        )}
      </div>

      <div className="gift-icon-container">
        <img className="gift-icon" src={giftImage} alt="gift" />
      </div>
    </div>
  );
};

export default GiftElement;
