// General
import "./personal-information.scss";
import { useEffect } from "react";
// Services
import { useLazyGetAioQuery } from "../../../../../services/data.service";
import { removeHTMLEscape } from "../../../../../services/regex.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateOccupation,
  updateEducation,
  updateRelationship,
  updateChildren,
  updateSmoking,
  updateDrinking,
} from "../../../../../redux/store/userStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { TextField, MenuItem } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const PersonalInformation = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();

  // Redux variables
  const oldUserProfile = useSelector((state) => state.user.oldUserProfile);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle| Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "occupation":
        if (removeHTMLEscape.test(event.target.value)) {
          // Remove HTML Escape (prevent XSS)
          dispatch(updateOccupation(""));

          // Send to Sentry
          Sentry.captureMessage(
            "PWA-EditProfile-Page-Occupation-Input-HTML-Escape-Detected"
          );

          // Send to GTM
          TagManager.dataLayer({
            dataLayer: {
              event:
                "PWA-EditProfile-Page-Occupation-Input-HTML-Escape-Detected",
            },
          });
        } else {
          dispatch(updateOccupation(event.target.value));
        }
        break;
      case "education":
        dispatch(updateEducation(event.target.value));
        break;
      case "relationship":
        dispatch(updateRelationship(event.target.value));
        break;
      case "children":
        dispatch(updateChildren(event.target.value));
        break;
      case "smoking":
        dispatch(updateSmoking(event.target.value));
        break;
      case "drinking":
        dispatch(updateDrinking(event.target.value));
        break;
      default:
        break;
    }
  };

  return (
    <div id="edit-profile-personal-information-subcomponent">
      <div className="personal-information-label">
        {t("profile.personal_information")}
      </div>

      {/* Occupation */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.occupation
                ? ""
                : "incomplete-field"
            }`}
          >
            {t("profile.occupation")}
          </div>
        </div>

        <div className="input-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="form-field"
            value={oldUserProfile?.profiledetails?.occupation || ""}
            placeholder={t("profile.to_be_completed")}
            onChange={onInputChange("occupation")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          ></TextField>
        </div>
      </div>

      {/* Education */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.education
                ? ""
                : "incomplete-field"
            }`}
          >
            {t("profile.education")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="dropdown-field"
            select
            value={oldUserProfile?.profiledetails?.education || ""}
            onChange={onInputChange("education")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          >
            {getAioData?.payload?.dropdown?.education?.map(
              (education, index) => (
                <MenuItem key={index} value={education?.value}>
                  {t(`${education?.name}`)}
                </MenuItem>
              )
            )}
          </TextField>
        </div>
      </div>

      {/* Relationship */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.relationship
                ? ""
                : "incomplete-field"
            }`}
          >
            {t("profile.relationship")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="dropdown-field"
            select
            value={oldUserProfile?.profiledetails?.relationship || ""}
            onChange={onInputChange("relationship")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          >
            {getAioData?.payload?.dropdown?.relationship?.map(
              (relationship, index) => (
                <MenuItem key={index} value={relationship?.value}>
                  {t(`${relationship?.name}`)}
                </MenuItem>
              )
            )}
          </TextField>
        </div>
      </div>

      {/* Children */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.children ? "" : "incomplete-field"
            }`}
          >
            {t("profile.children")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="dropdown-field"
            select
            value={oldUserProfile?.profiledetails?.children || ""}
            onChange={onInputChange("children")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          >
            {getAioData?.payload?.dropdown?.children?.map((children, index) => (
              <MenuItem key={index} value={children?.value}>
                {t(`${children?.name}`)}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      {/* Smoking */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.smoking ? "" : "incomplete-field"
            }`}
          >
            {t("profile.smoking")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="dropdown-field"
            select
            value={oldUserProfile?.profiledetails?.smoking || ""}
            onChange={onInputChange("smoking")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          >
            {getAioData?.payload?.dropdown?.smoking?.map((smoking, index) => (
              <MenuItem key={index} value={smoking?.value}>
                {t(`${smoking?.name}`)}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      {/* Drinking */}
      <div className="form-field-container">
        <div className="label-container">
          <div
            className={`label ${
              oldUserProfile?.profiledetails?.drinking ? "" : "incomplete-field"
            }`}
          >
            {t("profile.drinking")}
          </div>
        </div>

        <div className="form-container">
          <TextField
            sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
            className="dropdown-field"
            select
            value={oldUserProfile?.profiledetails?.drinking || ""}
            onChange={onInputChange("drinking")}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          >
            {getAioData?.payload?.dropdown?.drinking?.map((drinking, index) => (
              <MenuItem key={index} value={drinking?.value}>
                {t(`${drinking?.name}`)}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
