// General
import "./profile-info.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Material UI
import { Skeleton } from "@mui/material";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import useGetMembershipBadge from "../../../utility/custom-hooks/useGetMembershipBadge-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../shared/elements/custom-avatar/custom-avatar";

const ProfileInfo = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getMembershipBadge = useGetMembershipBadge();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  if (getProfileFetching || getProfileLoading) {
    return (
      <div id="overview-profile-info-subcomponent">
        <div className="profile-photo-container">
          <Skeleton className="profile-photo" variant="circular" />
        </div>

        <div className="username-container">
          <Skeleton className="username-skeleton" variant="rounded" />
        </div>

        <div className="badge-container">
          <Skeleton className="badge-skeleton" variant="rounded" />
        </div>
      </div>
    );
  } else if (getProfileSuccess) {
    return (
      <div id="overview-profile-info-subcomponent">
        <div className="profile-photo-container">
          <CustomAvatar
            className="profile-photo"
            src={
              getProfileData?.data?.profile_photo?.photo_status !== "REJECTED"
                ? getProfileData?.data?.profile_photo?.original_photo
                : null
            }
            role={getProfileData?.data?.role}
          />
        </div>

        <div className="username-container">
          <div className="name">{getProfileData?.data?.username}</div>
          {(getProfileData?.data?.membership_type ===
            userConst.membershipType.premium ||
            getProfileData?.data?.membership_type ===
              userConst.membershipType.freePremium) &&
            getIcon("premiumMiniBadge", "premium-mini-badge")}
        </div>

        <div className="badge-container">
          {(getProfileData?.data?.membership_type ===
            userConst.membershipType.premium ||
            getProfileData?.data?.membership_type ===
              userConst.membershipType.freePremium) && (
            <div className="premium-badge-container">
              <img
                className="premium-badge"
                src={getMembershipBadge(getProfileData?.data?.membership_type)}
              />
            </div>
          )}

          {getProfileData?.data?.levelling_profile?.badge && (
            <div className="levelling-badge-container">
              <img
                className="levelling-badge"
                src={getProfileData?.data?.levelling_profile?.badge}
              />
            </div>
          )}
        </div>
      </div>
    );
  } else if (getProfileError) {
    if (getProfileErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default ProfileInfo;
