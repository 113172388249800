// General
import "./private-call-pre-join-sugarbaby-warning-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePreJoinSugarBabyWarningDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const PrivateCallPreJoinSugarBabyWarningDialog = () => {
  // Redux variables
  const preJoinSugarBabyWarningDialog = useSelector(
    (state) => state.dialog.preJoinSugarBabyWarningDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!preJoinSugarBabyWarningDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PreJoinSugarBabyWarning-Dialog",
      },
    });
  }, [preJoinSugarBabyWarningDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updatePreJoinSugarBabyWarningDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={preJoinSugarBabyWarningDialog}
      onClose={onCloseDialog}
    >
      <div
        id="private-call-pre-join-sugarbaby-warning-dialog"
        className="border-gradient"
      >
        <div className="padding-container">
          <div className="warning-image-container">
            <img
              className="warning-image"
              src={getAsset("sbPrivateRoomWarningImage")}
              alt="warning"
            />
          </div>

          <div className="header">{t("profile.room_off_limits_title")}</div>
          <div className="text">
            <Trans
              i18nKey={"profile.room_off_limits_desc"}
              components={{
                privateroomicon: getIcon(
                  "privateRoomBadge",
                  "private-room-icon"
                ),
              }}
            />
          </div>

          <div className="close-button" onClick={onCloseDialog}>
            {t("common.got_it")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PrivateCallPreJoinSugarBabyWarningDialog;
