// General
import "./inbox.scss";
import { useState, useEffect, useRef } from "react";
// Static Data
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateConversationSelectPassthrough } from "../../../redux/store/inboxStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../utility/manager/asset-manager/asset-manager";
// Components
import Banner from "../../shared/elements/banner/banner";
import MessagesTab from "./messages-tab/messages-tab";
import UnreadTab from "./unread-tab/unread-tab";
import ChatConversation from "../chat-conversation/chat-conversation";

const Inbox = () => {
  // General variables
  const isMounted = useRef(false);

  // Redux variables
  const conversationSelectPassthrough = useSelector(
    (state) => state.inbox.conversationSelectPassthrough
  );
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // MUI variables
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery("(max-width: 850px)");

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted & Unmounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Inbox-Page",
      },
    });

    return () => {
      // Reset conversationSelectPassthrough
      dispatch(updateConversationSelectPassthrough({}));
    };
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(value);

    emblaApi?.on("select", () => {
      setValue(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | conversationSelectPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (!conversationSelectPassthrough) return;
    } else {
      isMounted.current = true;
    }
  }, [conversationSelectPassthrough]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    emblaApi?.scrollTo(newValue);
  };

  return (
    <div id="inbox-page" className="inbox-page-container">
      {releaseChannel !== utilityConst.releaseChannel.stable && (
        <div className="desktop-banner-container">
          <Banner type={"inbox"} view={"carousel"} />
        </div>
      )}

      <div className="max-width-container">
        <div className="tab-container">
          <Tabs
            className="custom-tabs"
            value={value}
            onChange={onTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
            variant="fullWidth"
          >
            <Tab label={t("inbox.messages")} />
            <Tab label={t("inbox.unread")} />
          </Tabs>

          <div className="mobile-banner-container">
            <Banner type={"inbox"} view={"carousel"} />
          </div>

          <div ref={emblaRef} className="embla">
            <div className="embla__container">
              <div className="embla__slide">
                <MessagesTab />
              </div>

              <div className="embla__slide">
                <UnreadTab />
              </div>
            </div>
          </div>
        </div>

        {!isMobile && (
          <div className="conversation-container">
            {conversationSelectPassthrough?.id ? (
              <ChatConversation />
            ) : (
              <img
                className="background"
                src={getAsset("messagingStartBackground")}
                alt="no-conversation"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Inbox;
