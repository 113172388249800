// General
import "./private-call-tab.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Static Data
import leaderboardConst from "../../../../const/leaderboardConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePrivateCallTab } from "../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import EventsTab from "./events-tab/events-tab";
import WeeklyTab from "../../../shared/leaderboard/weekly-tab/weekly-tab";
import MonthlyTab from "../../../shared/leaderboard/monthly-tab/monthly-tab";

const PrivateCallTab = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const privateCallTab = useSelector(
    (state) => state.leaderboard.privateCallTab
  );
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Leaderboard-Page-Private-Call-Tab",
      },
    });

    getUserAio(null, true);
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(privateCallTab);

    emblaApi?.on("select", () => {
      dispatch(updatePrivateCallTab(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    emblaApi?.scrollTo(newValue);
  };

  // Render Functions | API Response
  if (getUserAioFetching || getUserAioLoading) {
    return <Spinner />;
  } else if (getUserAioSuccess) {
    if (getUserAioData?.status === 1) {
      return (
        <div id="leaderboard-one-on-one-tab-subcomponent">
          <div className="tab-container">
            <Tabs
              className="custom-tabs"
              value={privateCallTab}
              onChange={onTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
              centered
            >
              {getUserAioData?.data?.private_call_leaderboard_config?.map(
                (tab, index) => (
                  <Tab label={t(tab?.name)} key={index} />
                )
              )}
            </Tabs>

            <div ref={emblaRef} className="leaderboard-private-call-tab-embla">
              <div className="embla__container">
                {getUserAioData?.data?.private_call_leaderboard_config?.map(
                  (tab, index) => (
                    <div className="embla__slide" key={index}>
                      {index === privateCallTab ? (
                        !tab?.board_period_type ? (
                          <EventsTab
                            state={leaderboardConst.type.privateCall}
                          />
                        ) : tab?.board_period_type ===
                          leaderboardConst.boardPeriodType
                            .daily ? null : tab?.board_period_type ===
                          leaderboardConst.boardPeriodType.weekly ? (
                          <WeeklyTab
                            state={leaderboardConst.type.privateCall}
                          />
                        ) : tab?.board_period_type ===
                          leaderboardConst.boardPeriodType.monthly ? (
                          <MonthlyTab
                            state={leaderboardConst.type.privateCall}
                          />
                        ) : null
                      ) : null}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else if (getUserAioError) {
    if (getUserAioErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default PrivateCallTab;
