// General
import "./description.scss";
import { useState, useEffect } from "react";
// Services
import { removeHTMLEscape } from "../../../../../services/regex.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAboutMe,
  updateLookingFor,
} from "../../../../../redux/store/userStore";
import { updateProfileRejectedInfoDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { TextField } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
// i18next
import { useTranslation } from "react-i18next";

const Description = () => {
  // General variables
  const [aboutMeErrorMessage, setAboutMeErrorMessage] = useState("");
  const [aboutMeFocus, setAboutMeFocus] = useState(false);
  const [aboutMeValid, setAboutMeValid] = useState(false);
  const [aboutMeError, setAboutMeError] = useState(false);
  const [lookingForErrorMessage, setLookingForErrorMessage] = useState("");
  const [lookingForFocus, setLookingForFocus] = useState(false);
  const [lookingForValid, setLookingForValid] = useState(false);
  const [lookingForError, setLookingForError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Redux variables
  const oldUserProfile = useSelector((state) => state.user.oldUserProfile);
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!oldUserProfile) return;
    if (isLoaded) return;

    setIsLoaded(true);
    checkAboutMeValue();
    checkLookingForValue();
  }, [oldUserProfile]);

  // Event Handlers | Button
  const onRejectReasonClick = () => {
    dispatch(updateProfileRejectedInfoDialog(true));
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "about-me":
        dispatch(updateAboutMe(event.target.value));
        break;
      case "looking-for":
        dispatch(updateLookingFor(event.target.value));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const aboutMeValidation = () => {
    let valid = true;
    let error = false;

    if (oldUserProfile?.profile?.aboutme === "") {
      setAboutMeErrorMessage(t("signup.about_me_is_required"));
      valid = false;
      error = true;
    } else if (
      // Update TW to 10 when backend is ready
      i18n.language.toLowerCase().includes("zh-tw") &&
      oldUserProfile?.profile?.aboutme?.length < 25
    ) {
      setAboutMeErrorMessage(
        t("signup.about_me_must_be_at_least_n_characters", {
          n: 25,
        })
      );
      valid = false;
      error = true;
    } else if (
      i18n.language.toLowerCase().includes("zh-tw") === false &&
      oldUserProfile?.profile?.aboutme.length < 25
    ) {
      setAboutMeErrorMessage(
        t("signup.about_me_must_be_at_least_n_characters", {
          n: 25,
        })
      );
      valid = false;
      error = true;
    } else if (oldUserProfile?.profile?.aboutme.length > 500) {
      setAboutMeErrorMessage(
        t("signup.about_me_must_be_less_than_n_characters", {
          n: 500,
        })
      );
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(oldUserProfile?.profile?.aboutme)) {
      valid = false;
      error = true;
      dispatch(updateAboutMe(""));

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-EditProfile-Page-AboutMe-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-EditProfile-Page-AboutMe-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setAboutMeErrorMessage("");
      valid = true;
      error = false;
    }

    setAboutMeValid(valid);
    setAboutMeError(error);
  };
  const lookingForValidation = () => {
    let valid = true;
    let error = false;

    if (oldUserProfile?.profile?.lookingfordetails === "") {
      setLookingForErrorMessage(t("signup.looking_for_is_required"));
      valid = false;
      error = true;
    } else if (
      // Update to 10 when backend is ready
      i18n.language.toLowerCase().includes("zh-tw") &&
      oldUserProfile?.profile?.lookingfordetails.length < 25
    ) {
      setLookingForErrorMessage(
        t("signup.looking_for_must_be_at_least_n_characters", { n: 25 })
      );
      valid = false;
      error = true;
    } else if (
      i18n.language.toLowerCase().includes("zh-tw") === false &&
      oldUserProfile?.profile?.lookingfordetails.length < 25
    ) {
      setLookingForErrorMessage(
        t("signup.looking_for_must_be_at_least_n_characters", { n: 25 })
      );
      valid = false;
      error = true;
    } else if (oldUserProfile?.profile?.lookingfordetails.length > 500) {
      setLookingForErrorMessage(
        t("signup.looking_for_must_be_less_than_n_characters", { n: 500 })
      );
      valid = false;
      error = true;
    } else if (
      removeHTMLEscape.test(oldUserProfile?.profile?.lookingfordetails)
    ) {
      valid = false;
      error = true;
      dispatch(updateLookingFor(""));

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-EditProfile-Page-LookingFor-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-EditProfile-Page-LookingFor-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setLookingForErrorMessage("");
      valid = true;
      error = false;
    }

    setLookingForValid(valid);
    setLookingForError(error);
  };
  const checkAboutMeValue = () => {
    if (
      oldUserProfile?.profile?.aboutme_state ===
      userConst.comUserApprovalState.rejected
    ) {
      dispatch(updateAboutMe(t("common.rejected")));
    } else {
      if (oldUserProfile?.profile?.aboutme) {
        return oldUserProfile?.profile?.aboutme;
      } else {
        return "";
      }
    }
  };
  const checkLookingForValue = () => {
    if (
      oldUserProfile?.profile?.lookingfordetails_state ===
      userConst.comUserApprovalState.rejected
    ) {
      dispatch(updateLookingFor(t("common.rejected")));
    } else {
      if (oldUserProfile?.profile?.lookingfordetails_state) {
        return oldUserProfile?.profile?.lookingfordetails;
      } else {
        return "";
      }
    }
  };
  const returnAboutMeColor = (type) => {
    switch (type) {
      case "title":
        if (
          !oldUserProfile?.profile?.aboutme ||
          oldUserProfile?.profile?.aboutme_state ===
            userConst.comUserApprovalState.rejected
        ) {
          return "incomplete-field";
        } else {
        }
        break;
      case "value":
        if (
          oldUserProfile?.profile?.aboutme_state ===
          userConst.comUserApprovalState.rejected
        ) {
          return "rejected-value";
        } else {
        }
        break;
      default:
        break;
    }
  };
  const returnLookingForColor = (type) => {
    switch (type) {
      case "title":
        if (
          !oldUserProfile?.profile?.lookingfordetails ||
          oldUserProfile?.profile?.lookingfordetails_state ===
            userConst.comUserApprovalState.rejected
        ) {
          return "incomplete-field";
        } else {
        }
        break;
      case "value":
        if (
          oldUserProfile?.profile?.lookingfordetails_state ===
          userConst.comUserApprovalState.rejected
        ) {
          return "rejected-value";
        } else {
        }
        break;
      default:
        break;
    }
  };

  // Check for about me and looking for validation
  useEffect(() => {
    aboutMeValidation();
    lookingForValidation();
  }, [
    oldUserProfile?.profile?.aboutme,
    aboutMeFocus,
    aboutMeFocus,
    aboutMeErrorMessage,
    oldUserProfile?.profile?.lookingfordetails,
    lookingForFocus,
    lookingForErrorMessage,
  ]);

  return (
    <div id="edit-profile-description-subcomponent">
      <div className="description-label">{t("profile.description")}</div>

      {/* About Me */}
      <div className="form-field-container">
        <div className="label-container">
          <div className={`label ${returnAboutMeColor("title")}`}>
            {t("profile.about_me")}
          </div>

          {oldUserProfile?.profile?.aboutme_state ===
            userConst.comUserApprovalState.inReview && (
            <div className="in-review-container">{t("profile.in_review")}</div>
          )}

          {oldUserProfile?.profile?.aboutme_state ===
            userConst.comUserApprovalState.rejected && (
            <HelpIcon
              className="rejected-reason-icon"
              onClick={onRejectReasonClick}
            />
          )}
        </div>

        <div className="form-container">
          <TextField
            className={`form-field ${returnAboutMeColor("value")}`}
            placeholder={t("profile.about_me")}
            value={oldUserProfile?.profile?.aboutme || ""}
            error={aboutMeError}
            onChange={onInputChange("about-me")}
            multiline
            rows={5}
            helperText={aboutMeErrorMessage}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          ></TextField>
          <div className="character-limit-label">
            {oldUserProfile?.profile?.aboutme.length}/500
          </div>
        </div>
      </div>

      {/* Looking For */}
      <div className="form-field-container">
        <div className="label-container">
          <div className={`label ${returnLookingForColor("title")}`}>
            {t("profile.looking_for")}
          </div>

          {oldUserProfile?.profile?.lookingfordetails_state ===
            userConst.comUserApprovalState.inReview && (
            <div className="in-review-container">{t("profile.in_review")}</div>
          )}

          {oldUserProfile?.profile?.lookingfordetails_state ===
            userConst.comUserApprovalState.rejected && (
            <HelpIcon
              className="rejected-reason-icon"
              onClick={onRejectReasonClick}
            />
          )}
        </div>

        <div className="form-container">
          <TextField
            className={`form-field ${returnLookingForColor("value")}`}
            placeholder={t("profile.looking_for")}
            value={oldUserProfile?.profile?.lookingfordetails || ""}
            error={lookingForError}
            onChange={onInputChange("looking-for")}
            multiline
            rows={5}
            helperText={lookingForErrorMessage}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          ></TextField>
          <div className="character-limit-label">
            {oldUserProfile?.profile?.lookingfordetails.length}/500
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
