// General
import "./game-list-tab.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCasinoPeriodTab } from "../../../../../redux/store/leaderboardStore";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import DailyTab from "../../../../shared/leaderboard/daily-tab/daily-tab";
import WeeklyTab from "../../../../shared/leaderboard/weekly-tab/weekly-tab";
import MonthlyTab from "../../../../shared/leaderboard/monthly-tab/monthly-tab";

const GameListTab = (props) => {
  const { gameId } = props;

  // Redux variables
  const casinoPeriodTab = useSelector(
    (state) => state.leaderboard.casinoPeriodTab
  );
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {}, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(casinoPeriodTab);

    emblaApi?.on("select", () => {
      dispatch(updateCasinoPeriodTab(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, index) => {
    emblaApi?.scrollTo(index);
  };

  return (
    <div id="leaderboard-game-list-tab-subcomponent">
      <div className="tab-container">
        <Tabs
          className="custom-tabs"
          value={casinoPeriodTab}
          onChange={onTabChange}
          TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
          centered
        >
          <Tab label={t("leaderboard.tabs.daily")} />
          <Tab label={t("leaderboard.tabs.weekly")} />
          <Tab label={t("leaderboard.tabs.monthly")} />
        </Tabs>

        <div ref={emblaRef} className="embla">
          <div className="embla__container">
            <div className="embla__slide">
              {casinoPeriodTab === 0 && (
                <DailyTab state={"casino"} gameId={gameId} />
              )}
            </div>

            <div className="embla__slide">
              {casinoPeriodTab === 1 && (
                <WeeklyTab state={"casino"} gameId={gameId} />
              )}
            </div>

            <div className="embla__slide">
              {casinoPeriodTab === 2 && (
                <MonthlyTab state={"casino"} gameId={gameId} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameListTab;
