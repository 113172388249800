// General
import "./caller-camera-feed.scss";
import { useState, useEffect, useRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Permissions Functions
  updateCameraPermissions,
  updateMicrophonePermissions,
  updatePermissionErrorMessage,

  // Devices Functions
  updateAllDevices,
  updateCameraDevices,
  updateMicrophoneDevices,
} from "../../../../../redux/store/privateCallStore";
import { updateVideoCallErrorDialog } from "../../../../../redux/store/dialogStore";
import { updateWarningToast } from "../../../../../redux/store/toastStore";
// Tencent Real Time Communication (TRTC)
import TRTC from "trtc-js-sdk";
// react-device-detect
import { isFirefox, isSafari } from "react-device-detect";
// Material UI
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
// Custom Hooks
import useIsMounted from "../../../../utility/custom-hooks/useIsMounted-hook";

const CallerCameraFeed = (props) => {
  const { trtcClient } = props;

  // General variables
  const [localStream, setLocalStream] = useState(null);
  const [localStreamInitialized, setLocalStreamInitialized] = useState(false);
  const [localStreamReinitialized, setLocalStreamReinitialized] =
    useState(false); // Somehow during autopilot, wrote this variable and it works
  const isMounted = useRef(false);

  // Redux variables
  const callerId = useSelector((state) => state.privateCall.callerId);
  const roomId = useSelector((state) => state.privateCall.roomId);
  const callStatus = useSelector((state) => state.privateCall.callStatus);
  const selectedCameraDevice = useSelector(
    (state) => state.privateCall.selectedCameraDevice
  );
  const selectedMicrophoneDevice = useSelector(
    (state) => state.privateCall.selectedMicrophoneDevice
  );
  const reinitializeCameraStreamPassthrough = useSelector(
    (state) => state.privateCall.reinitializeCameraStreamPassthrough
  );
  const changeCameraMicrophonePassthrough = useSelector(
    (state) => state.privateCall.changeCameraMicrophonePassthrough
  );
  const swapVideoViewPassthrough = useSelector(
    (state) => state.privateCall.swapVideoViewPassthrough
  );
  const toggleCameraPassthrough = useSelector(
    (state) => state.privateCall.toggleCameraPassthrough
  );
  const toggleRemoteCameraPassthrough = useSelector(
    (state) => state.privateCall.toggleRemoteCameraPassthrough
  );
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const mounted = useIsMounted();

  // Lifecycle | Mounted
  useEffect(() => {
    initTRTCDevices();

    if (!isFirefox && !isSafari) {
      // Firefox does not support permissions API (camera & microphone)
      // Safari does not support on listen event for camera & microphone
      if (onCheckMediaDevicesAPIExists()) {
        // Mainly to get on change events for camera & microphone permissions
        onCheckCameraPermissions();
        onCheckMicrphonePermissions();
      }
    }
  }, []);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        if (localStream) {
          localStream.close();
          setLocalStream(null);
        }
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | trtcClient, callerId, roomId & localStreamInitialized
  useEffect(() => {
    if (
      !trtcClient ||
      !callerId ||
      !roomId ||
      !localStreamInitialized ||
      localStreamReinitialized
    )
      return;

    trtcClient
      .join({
        roomId: roomId,
      })
      .then(() => {
        trtcClient.publish(localStream);
      });
  }, [trtcClient, callerId, roomId, localStreamInitialized]);

  // Lifecycle | Check for update | localStream
  useEffect(() => {
    if (!localStream) return;

    localStream
      .initialize()
      .then(() => {
        // Initialize camera and microphone info to use for camera and microphone selection
        initTRTCDevices();

        // Reason we use localStreamInitialized to determine if we should publish the localStream is because we want to make sure that the localStream is initialized before we publish it
        // Using localStream will not work sometimes because localStream might be created but not initialized, thus TRTC throwing error
        setLocalStreamInitialized(true);
        localStream.play("caller-video");

        if (!localStreamReinitialized) return;

        trtcClient.publish(localStream);
      })
      .catch((err) => {
        console.log("TRTC createLocalStream err:", err);

        // Open Toast
        const toastObj = {
          message: err?.toString(),
          autoClose: 3000,
        };
        dispatch(updateWarningToast(toastObj));

        // Open Dialog
        if (err.toString().includes("user denied permission")) {
          dispatch(
            updatePermissionErrorMessage(
              "Unable to access camera or microphone"
            )
          );
        } else if (err.toString().includes("no camera detected")) {
          dispatch(updatePermissionErrorMessage("No camera detected"));
        } else {
          // TODO: Find more errors
        }

        dispatch(updateVideoCallErrorDialog(true));
      });
  }, [localStream, localStreamReinitialized]);

  // Lifecycle | Check for update | callerId
  useEffect(() => {
    if (!callerId || localStream) return;

    // Get Camera Access
    createLocalStream();
  }, [callerId, localStream]);

  // Lifecycle | Check for update | reinitializeCameraStreamPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (!reinitializeCameraStreamPassthrough || !localStream || !trtcClient)
        return;

      // Unpublish Local Stream
      trtcClient.unpublish(localStream).then(() => {
        setLocalStreamReinitialized(true);

        // Reset Local Stream
        setLocalStreamInitialized(false);
        localStream.close();
        setLocalStream(null);
      });
    } else {
      isMounted.current = true;
    }
  }, [reinitializeCameraStreamPassthrough]);

  // Lifecycle | Check for update | changeCameraMicrophonePassthrough
  useEffect(() => {
    if (!changeCameraMicrophonePassthrough) return;

    // Switch video source
    localStream?.switchDevice("video", selectedCameraDevice || "user");

    // Switch audio source
    // localStream.switchDevice("audio", selectedMicrophoneDevice);
  }, [changeCameraMicrophonePassthrough]);

  // Lifecycle | Check for update | swapVideoViewPassthrough
  useEffect(() => {
    if (!swapVideoViewPassthrough) return;

    switch (swapVideoViewPassthrough) {
      case "mini":
        localStream?.play("caller-video");
        break;
      case "full":
        localStream?.play("callee-video");
        break;
      default:
        localStream?.play("caller-video");
        break;
    }
  }, [swapVideoViewPassthrough]);

  // Lifecycle | Check for update | toggleCameraPassthrough
  useEffect(() => {
    if (toggleCameraPassthrough) {
      localStream.muteVideo();
    } else if (toggleCameraPassthrough === false) {
      localStream.unmuteVideo();
    }
  }, [toggleCameraPassthrough]);

  // TRTC Functions | TRTC Method to get all Devices
  const initTRTCDevices = () => {
    TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.ERROR);

    TRTC.getDevices()
      .then((devices) => {
        const devicesObj = devices?.map((device) => {
          return {
            deviceId: device?.deviceId,
            groudId: device?.groupId,
            kind: device?.kind,
            label: device?.label,
          };
        });

        dispatch(updateAllDevices(devicesObj));
      })
      .catch((err) => {
        console.log("TRTC getDevices err:", err);
      });

    TRTC.getCameras()
      .then((cameras) => {
        let cameraObj = cameras?.map((camera) => {
          return {
            deviceId: camera?.deviceId,
            groudId: camera?.groupId,
            kind: camera?.kind,
            label: camera?.label,
          };
        });

        dispatch(updateCameraDevices(cameraObj));
      })
      .catch((err) => {
        console.log("TRTC getCameras err:", err);
      });

    TRTC.getMicrophones()
      .then((microphones) => {
        let microphoneObj = microphones?.map((microphone) => {
          return {
            deviceId: microphone?.deviceId,
            groudId: microphone?.groupId,
            kind: microphone?.kind,
            label: microphone?.label,
          };
        });

        dispatch(updateMicrophoneDevices(microphoneObj));
      })
      .catch((err) => {
        console.log("TRTC getMicrophones err:", err);
      });
  };

  // TRTC Functions | TRTC Method to get Camera & Microphone Access
  const createLocalStream = () => {
    setLocalStream(
      TRTC.createStream({
        audio: true,
        video: true,
        userId: callerId,
      })
    );
  };

  // Utility Functions
  const onCheckMediaDevicesAPIExists = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      return true;
    } else {
      return false;
    }
  };
  const onCheckCameraPermissions = () => {
    navigator.permissions.query({ name: "camera" }).then((result) => {
      if (result.state === "prompt") {
        dispatch(updateCameraPermissions(false));
      } else if (result.state === "granted") {
        dispatch(updateCameraPermissions(true));
      } else if (result.state === "denied") {
        dispatch(updateCameraPermissions(false));
        console.log("User need to manually enable camera permissions");
      }

      // Check for changes in camera permissions
      result.onchange = function () {
        if (result.state === "prompt") {
          dispatch(updateCameraPermissions(false));
        } else if (result.state === "granted") {
          dispatch(updateCameraPermissions(true));
        } else if (result.state === "denied") {
          dispatch(updateCameraPermissions(false));
          console.log("User need to manually enable camera permissions");
        }
      };
    });
  };
  const onCheckMicrphonePermissions = () => {
    navigator.permissions.query({ name: "microphone" }).then((result) => {
      if (result.state === "prompt") {
        dispatch(updateMicrophonePermissions(false));
      } else if (result.state === "granted") {
        dispatch(updateMicrophonePermissions(true));
      } else if (result.state === "denied") {
        dispatch(updateMicrophonePermissions(false));
      }

      // Check for changes in microphone permissions
      result.onchange = function () {
        if (result.state === "prompt") {
          dispatch(updateMicrophonePermissions(false));
        } else if (result.state === "granted") {
          dispatch(updateMicrophonePermissions(true));
        } else if (result.state === "denied") {
          dispatch(updateMicrophonePermissions(false));
        }
      };
    });
  };

  // Camera & Microphone Functions | Native JS Method
  const onGetCameraAccess = () => {
    const video = document.getElementById("caller-video");

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "user" } })
        .then((stream) => {
          video.srcObject = stream;

          var playPromise = video.play();

          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const onGetMicrophoneAccess = () => {
    const video = document.getElementById("caller-video");

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          video.srcObject = stream;

          var playPromise = video.play();

          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div id="video-call-room-caller-camera-feed-subcomponent">
      <div className="padding-container">
        <div id="caller-video"></div>
        {((callStatus === "JOIN" &&
          swapVideoViewPassthrough === "full" &&
          !toggleRemoteCameraPassthrough) ||
          ((!swapVideoViewPassthrough || swapVideoViewPassthrough === "mini") &&
            toggleCameraPassthrough)) && (
          <div className="camera-disabled-container">
            <VideocamOffIcon className="camera-disabled-icon" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CallerCameraFeed;
