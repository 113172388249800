// General
import "./description-signup.scss";
import { useState, useEffect } from "react";
// Services
import {
  useUpdateProfileMutation,
  useUpdateUserProfileMutation,
} from "../../../services/data.service";
import { removeHTMLEscape } from "../../../services/regex.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAboutMe,
  updateLookingForDescription,
} from "../../../redux/store/signupStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { Box, TextField, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const DescriptionSignup = () => {
  // API variables
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateProfileMutation();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateUserProfileMutation();

  // General variables
  const [aboutMeErrorMessage, setAboutMeErrorMessage] = useState("");
  const [aboutMeFocus, setAboutMeFocus] = useState(false);
  const [aboutMeValid, setAboutMeValid] = useState(false);
  const [aboutMeError, setAboutMeError] = useState(false);
  const [lookingForErrorMessage, setLookingForErrorMessage] = useState("");
  const [lookingForFocus, setLookingForFocus] = useState(false);
  const [lookingForValid, setLookingForValid] = useState(false);
  const [lookingForError, setLookingForError] = useState(false);

  // Redux variables
  const aboutMe = useSelector((state) => state.signup.aboutMe);
  const lookingForDescription = useSelector(
    (state) => state.signup.lookingForDescription
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Profile Update API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      onNavigate(routeConst.lifestyleSignup.path);
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | Profile Update API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      onNavigate(routeConst.lifestyleSignup.path);
    } else if (updateUserProfileError) {
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DescriptionSignup-Page-Save-Button",
      },
    });

    const oldObj = {
      new_aboutme: aboutMe,
      new_lookingfor: lookingForDescription,
    };

    const obj = {
      aboutme: aboutMe,
      lookingfor: lookingForDescription,
    };

    updateProfile(oldObj);
    // updateUserProfile(obj); // Backend not ready yet, does not update GET profile API
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DescriptionSignup-Page-Skip-Button",
      },
    });

    onNavigate(routeConst.lifestyleSignup.path);
  };

  // Event Handlers | MUI Text Field
  const onValueChange = (state) => (event) => {
    switch (state) {
      case "aboutMe":
        dispatch(updateAboutMe(event.target.value));
        break;
      case "lookingFor":
        dispatch(updateLookingForDescription(event.target.value));
        break;
      default:
        break;
    }
  };
  const onAboutMeFocus = () => {
    setAboutMeFocus(true);
  };
  const onLookingForFocus = () => {
    setLookingForFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (aboutMeValid && lookingForValid) {
      valid = true;
    }

    return valid;
  };
  const aboutMeValidation = () => {
    let valid = true;
    let error = false;

    if (aboutMe === "") {
      setAboutMeErrorMessage(t("signup.about_me_is_required"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(aboutMe)) {
      valid = false;
      error = true;
      dispatch(updateAboutMe(""));

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-DescriptionSignup-AboutMe-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-DescriptionSignup-AboutMe-Input-HTML-Escape-Detected",
        },
      });
    } else if (
      // Update to 10 when backend is ready
      i18n.language.toLowerCase().includes("zh-tw") &&
      aboutMe.length < 25
    ) {
      setAboutMeErrorMessage(
        t("signup.about_me_must_be_at_least_n_characters", { n: 25 })
      );
      valid = false;
      error = true;
    } else if (
      i18n.language.toLowerCase().includes("zh-tw") === false &&
      aboutMe.length < 25
    ) {
      setAboutMeErrorMessage(
        t("signup.about_me_must_be_at_least_n_characters", { n: 25 })
      );
      valid = false;
      error = true;
    } else if (aboutMe.length > 500) {
      setAboutMeErrorMessage(
        t("signup.about_me_must_be_less_than_n_characters", { n: 500 })
      );
      valid = false;
      error = true;
    } else {
      setAboutMeErrorMessage("");
      valid = true;
      error = false;
    }

    setAboutMeValid(valid);
    setAboutMeError(error);
  };
  const lookingForValidation = () => {
    let valid = true;
    let error = false;

    if (lookingForDescription === "") {
      setLookingForErrorMessage(t("signup.looking_for_is_required"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(lookingForDescription)) {
      valid = false;
      error = true;
      dispatch(updateLookingForDescription(""));

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-DescriptionSignup-Page-LookingFor-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event:
            "PWA-DescriptionSignup-Page-LookingFor-Input-HTML-Escape-Detected",
        },
      });
    } else if (
      // Update to 10 when backend is ready
      i18n.language.toLowerCase("zh-tw") &&
      lookingForDescription.length < 25
    ) {
      setLookingForErrorMessage(
        t("signup.looking_for_must_be_at_least_n_characters", { n: 25 })
      );
      valid = false;
      error = true;
    } else if (
      i18n.language.toLowerCase().includes("zh-tw") === false &&
      lookingForDescription.length < 25
    ) {
      setLookingForErrorMessage(
        t("signup.looking_for_must_be_at_least_n_characters", { n: 25 })
      );
      valid = false;
      error = true;
    } else if (lookingForDescription.length > 500) {
      setLookingForErrorMessage(
        t("signup.looking_for_must_be_less_than_n_characters", { n: 500 })
      );
      valid = false;
      error = true;
    } else {
      setLookingForErrorMessage("");
      valid = true;
      error = false;
    }

    setLookingForValid(valid);
    setLookingForError(error);
  };

  // Check for about me and looking for validation
  useEffect(() => {
    aboutMeValidation();
    lookingForValidation();
  }, [
    aboutMe,
    aboutMeFocus,
    aboutMeErrorMessage,
    lookingForDescription,
    lookingForFocus,
    lookingForErrorMessage,
  ]);

  return (
    <Box id="description-signup-page">
      <div className="max-width-container">
        <div className="steps-label">
          {t("signup.step_n_max", { n: 1, max: 3 })}
        </div>

        <div className="step-description-label">
          {t("signup.tell_us_about_yourself")}
        </div>

        {/* About Me */}
        <div className="about-me-form-field-container">
          <TextField
            className="about-me-form-field custom-text-field"
            value={aboutMe}
            error={aboutMeError}
            onChange={onValueChange("aboutMe")}
            label={t("profile.about_me")}
            multiline
            rows={5}
            helperText={aboutMeErrorMessage}
            variant="standard"
            onFocus={onAboutMeFocus}
          />
          <div className="character-limit-label">{aboutMe?.length}/500</div>
        </div>

        {/* Looking For */}
        <div className="looking-for-form-field-container">
          <TextField
            className="looking-for-form-field custom-text-field"
            value={lookingForDescription}
            error={lookingForError}
            onChange={onValueChange("lookingFor")}
            label={t("profile.looking_for")}
            multiline
            rows={5}
            helperText={lookingForErrorMessage}
            variant="standard"
            onFocus={onLookingForFocus}
          />
          <div className="character-limit-label">
            {lookingForDescription?.length}/500
          </div>
        </div>

        <div className="button-container">
          <Button
            className={`next-button ${isFormValid() ? "" : "disabled-button"}`}
            variant="text"
            onClick={onSaveDetails}
            disabled={!isFormValid()}
          >
            {updateProfileLoading ? (
              <Spinner size={20} isPadding={false} />
            ) : (
              t("signup.next")
            )}
          </Button>

          <Button className="skip-button" onClick={onSkip}>
            {t("signup.skip_for_now")}
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default DescriptionSignup;
