// General
import { useEffect } from "react";
// Services
import { useSubscribeSecurionPayMutation } from "../../../services/data.service";
import { spiApi } from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePaymentState,
  updateSecurionPayTokenDetails,
} from "../../../redux/store/paymentStore";
import {
  updateWarningToast,
  updateErrorToast,
} from "../../../redux/store/toastStore";
import {
  updateUpgradeStatus,
  updatePremiumSubscribeInfo,
} from "../../../redux/store/upgradeStore";
import { updatePaymentProcessDialog } from "../../../redux/store/dialogStore";
// Custom Hooks
import useCustomNavigate from "./useCustomNavigate-hook";

// TODO: Payment State
const useSecurionPaymentFlowWithoutCard = () => {
  // API variables
  const [
    subscribeSecurionPay,
    {
      data: subscribeSecurionPayData,
      error: subscribeSecurionPayErrorData,
      isLoading: subscribeSecurionPayLoading,
      isSuccess: subscribeSecurionPaySuccess,
      isError: subscribeSecurionPayError,
    },
  ] = useSubscribeSecurionPayMutation();

  // Redux variables
  const selectedUpgradePackage = useSelector(
    (state) => state.upgrade.selectedUpgradePackage
  );
  const cardHolderNameFirstName = useSelector(
    (state) => state.payment.cardHolderName.firstName
  );
  const cardHolderNameLastName = useSelector(
    (state) => state.payment.cardHolderName.lastName
  );
  const specialEvent = useSelector((state) => state.upgrade.specialEvent);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Subscribe SecurionPay API Response
  useEffect(() => {
    if (subscribeSecurionPayLoading) {
    } else if (subscribeSecurionPaySuccess) {
      // For /upgrade-status usage
      dispatch(updateUpgradeStatus("success"));

      // Close loading dialog
      dispatch(updatePaymentProcessDialog(false));

      switch (subscribeSecurionPayData?.status) {
        case 0:
          // Recall GET User Profile API
          dispatch({
            type: `${spiApi.reducerPath}/invalidateTags`,
            payload: ["OwnProfile"],
          });

          // Get subscribe info for Upgrade Status Page
          dispatch(updatePremiumSubscribeInfo(subscribeSecurionPayData?.data));

          // Redirect to Payment Success Page
          onNavigate(routeConst.upgrade.status.path);
          break;
        case -1:
          // Payment card is wrong
          // Ongoing subscription
          onNavigate(routeConst.search.path);

          const toastObj = {
            message: subscribeSecurionPayData?.message,
            autoClose: 10000,
          };
          dispatch(updateWarningToast(toastObj));
          break;
        default:
          break;
      }
    } else if (subscribeSecurionPayError) {
      // For /upgrade-status usage
      dispatch(updateUpgradeStatus("error"));

      dispatch(updatePaymentProcessDialog(false));

      // This error says there is an ongoing subscription
      const toastObj = {
        message: subscribeSecurionPayErrorData?.data?.message,
        autoClose: 10000,
      };
      dispatch(updateErrorToast(toastObj));

      // TODO: Navigate to payment unsuccessful page
      onNavigate(routeConst.upgrade.status.path);
    }
  }, [
    subscribeSecurionPayLoading,
    subscribeSecurionPaySuccess,
    subscribeSecurionPayError,
  ]);

  // Main Function
  const securionPaymentFlow = () => {
    addCard();
  };

  // Part 1 | Call SecurionPay that we are going to make a custom form payment
  const addCard = () => {
    // Open loading dialog
    dispatch(updatePaymentProcessDialog(true));

    const form = document.getElementById("payment-form");

    Securionpay.setPublicKey(process.env["REACT_APP_SP_PUBLIC_KEY"]);
    Securionpay.createCardToken(form, createCardTokenCallback);

    // Prevent default form submission
    return false;
  };

  // Part 2 | SecurionPay informs if payment attempt is available
  const createCardTokenCallback = (token) => {
    dispatch(updateSecurionPayTokenDetails(token));

    if (token?.error) {
      // Close loading dialog
      dispatch(updatePaymentProcessDialog(false));

      // Display error message
      // re-enable form submit button
      const toastObj = {
        message: token?.error?.message,
        autoClose: 10000,
      };
      dispatch(updateErrorToast(toastObj));

      // Update Payment State to Store
      // TODO
    } else {
      let param3Ds = {
        amount: selectedUpgradePackage?.billed_today
          .toString()
          .replace(/\./g, ""), // no decimal
        currency: selectedUpgradePackage?.currency,
        card: token.id,
      };

      // Open frame with 3D Secure process
      Securionpay.setPublicKey(process.env["REACT_APP_SP_PUBLIC_KEY"]);
      Securionpay.verifyThreeDSecure(param3Ds, verifyThreeDSecureCallback);
    }
  };

  // Part 3 | Attempt 3DSecure when SecurionPay says good.
  const verifyThreeDSecureCallback = (token) => {
    if (token?.error) {
      // Close loading dialog
      dispatch(updatePaymentProcessDialog(false));

      // Display error message
      // Re-enable form submit button

      // Update Payment State to Store
      // TODO
    } else {
      // Re-enable form submit button
      const commonSubscribeObj = {
        card_holder_country: token?.fraudCheckData?.ipCountry,
        card_holder_name: `${cardHolderNameFirstName} ${cardHolderNameLastName}`,
        card_token: token?.id,
        product_hash: selectedUpgradePackage?.product_hash,
        special_event: specialEvent ?? "",
      };

      let subscribeObj;

      if (process.env.REACT_APP_ENVIRONMENT === "development") {
        subscribeObj = {
          ...commonSubscribeObj,
          test_mode_token: process.env["REACT_APP_TEST_MODE_TOKEN"],
        };
      } else if (process.env["REACT_APP_ENVIRONMENT"] === "production") {
        subscribeObj = commonSubscribeObj;
      }
      subscribeSecurionPay(subscribeObj);
    }
  };

  return securionPaymentFlow;
};

export default useSecurionPaymentFlowWithoutCard;
