// General
import "./favorited-you-element.scss";
// Static Data
import userConst from "../../../const/userConst";
import routeConst from "../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updatePublicPhotos,
  updatePrivatePhotos,
  updateShouldCallApi,
} from "../../../redux/store/userCardStore";
import {
  updateNotificationIsSeen,
  updateShowNotificationBadge,

  // Utility Functions
  updateCloseNotificationTabPassthrough,
} from "../../../redux/store/notificationStore";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../shared/elements/custom-avatar/custom-avatar";

const FavoritedYouElement = (props) => {
  const {
    notificationId,
    id,
    idInt,
    profilePhoto,
    publicPhotos,
    privatePhotos,
    username,
    isVerifiedProfile,
    membershipType,
    isLock,
    timestamp,
    isSeen,
  } = props;

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onViewUserProfile = () => {
    markAsSeen();
    onCloseNotificationTab();

    if (isLock) {
      onNavigate(routeConst.upgrade.path);
    } else {
      dispatch(updatePublicPhotos(publicPhotos));
      dispatch(updatePrivatePhotos(privatePhotos));
      dispatch(updateShouldCallApi(true));
      onNavigate(`${routeConst.profile.view.clearPath}${id}`);
    }
  };

  // Utility Functions
  const markAsSeen = () => {
    const obj = {
      notificationId,
      isSeen: true,
    };
    dispatch(updateNotificationIsSeen(obj));
    setTimeout(() => {
      dispatch(updateShowNotificationBadge());
    }, 1000);
  };
  const onCloseNotificationTab = () => {
    dispatch(updateCloseNotificationTabPassthrough());
  };

  return (
    <div
      id="notification-favorited-you-element-subcomponent"
      className={isSeen ? "" : "notification-unseen"}
      onClick={onViewUserProfile}
    >
      <div className="avatar-container">
        <CustomAvatar
          className="profile-photo"
          src={profilePhoto}
          isBlur={isLock}
          isLock={isLock}
        />
      </div>

      <div className="favorited-you-body-container">
        <span className="username">{username || "-"}</span>{" "}
        {membershipType !== userConst.membershipType.free && (
          <span className="premium-mini-badge-container">
            {getIcon("premiumMiniBadge", "premium-mini-badge")}
          </span>
        )}
        {isVerifiedProfile && (
          <span className="notification-verified-profile-badge-container">
            {getIcon(
              "verifiedProfileBadge",
              "notification-verified-profile-badge"
            )}
          </span>
        )}
        {t("notification.user_favorited_you")}{" "}
        <span className="timestamp">{timestamp}</span>
        {false && <span className="red-dot"></span>}
      </div>
    </div>
  );
};

export default FavoritedYouElement;
