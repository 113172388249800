// General
import "./interactable-overlay.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetLivestreamingTopBannersQuery } from "../../../../services/data.service";
// Components
import TopBanner from "./top-banner/top-banner";
import Topbar from "./topbar/topbar";
import OngoingEvents from "./ongoing-events/ongoing-events";
import PinChat from "./pin-chat/pin-chat";
import ChatSection from "../../../shared/elements/chat-section/chat-section";
import GiftsSection from "../../../shared/elements/gifts-section/gifts-section";
import ChatInput from "./chat-input/chat-input";

const MobileVideoOverlay = () => {
  // API variables
  const [
    getLivestreamingTopBanners,
    {
      data: getLivestreamingTopBannersData,
      error: getLivestreamingTopBannersErrorData,
      isFetching: getLivestreamingTopBannersFetching,
      isLoading: getLivestreamingTopBannersLoading,
      isSuccess: getLivestreamingTopBannersSuccess,
      isError: getLivestreamingTopBannersError,
    },
  ] = useLazyGetLivestreamingTopBannersQuery();

  // General variables
  const [topBannerExists, setTopBannerExists] = useState(false);

  // Lifecycle | Mounted
  useEffect(() => {
    getLivestreamingTopBanners(null, true);
  }, []);

  // Lifecycle | Check for update | Livestreaming Top Banners API Response
  useEffect(() => {
    if (
      getLivestreamingTopBannersFetching ||
      getLivestreamingTopBannersLoading
    ) {
    } else if (getLivestreamingTopBannersSuccess) {
      if (
        getLivestreamingTopBannersData?.status === 0 &&
        getLivestreamingTopBannersData?.data?.top_banners?.length > 0
      ) {
        setTopBannerExists(true);
      }
    } else if (getLivestreamingTopBannersError) {
    }
  }, [
    getLivestreamingTopBannersFetching,
    getLivestreamingTopBannersLoading,
    getLivestreamingTopBannersSuccess,
    getLivestreamingTopBannersError,
  ]);

  return (
    <div id="livestream-interactable-overlay-subcomponent">
      <div className="top-section">
        <TopBanner />

        <div className="padding-container">
          <Topbar />
          <OngoingEvents />
        </div>
      </div>

      <div className="bottom-section">
        <ChatSection state={"livestream"} />
        <PinChat />
        <GiftsSection state={"livestream"} />
        <ChatInput />
      </div>
    </div>
  );
};

export default MobileVideoOverlay;
