// General
import "./emoji-element.scss";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const EmojiElement = (props) => {
  const {
    state,
    livestreamingAchievementBadge,
    profilePhoto,
    username,
    levellingBadge,
    emoji,
  } = props;

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="chat-section-emoji-element-subcomponent">
      <div className="profile-picture-container">
        <CustomAvatar className="profile-picture" src={profilePhoto} />
      </div>

      <div className="chat-info-container">
        {livestreamingAchievementBadge?.length > 0 && (
          <div className="livestreaming-achievement-badge-container">
            {livestreamingAchievementBadge?.map((badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            ))}
          </div>
        )}

        <div
          className={`username ${
            state === "livestream" && !isMobile ? "" : "yellow"
          }`}
        >
          {username || "-"}
        </div>

        {levellingBadge && (
          <div className="levelling-badge-container">
            <img
              className="levelling-badge"
              src={levellingBadge}
              alt="levelling-badge"
            />
          </div>
        )}

        <div
          className={`emoji-icon-container ${
            state === "livestream" && !isMobile ? "" : "yellow"
          }`}
        >
          {t("pusher.sent_a_emoji", { emoji: emoji })}
        </div>
      </div>
    </div>
  );
};

export default EmojiElement;
