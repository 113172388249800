// General
import "./lifestyle-signup.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useUpdateProfileMutation,
  useUpdateUserProfileMutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateNetWorth,
  updateAnnualIncome,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateRace,
  updateEyeColor,
  updateHairColor,
  updateLifestyle,
} from "../../../redux/store/signupStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Box, TextField, MenuItem, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const LifestyleSignup = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateProfileMutation();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateUserProfileMutation();

  // General variables
  const [annualIncomeFocus, setAnnualIncomeFocus] = useState(false);
  const [annualIncomeValid, setAnnualIncomeValid] = useState(false);
  const [annualIncomeError, setAnnualIncomeError] = useState(false);
  const [bodyTypeFocus, setBodyTypeFocus] = useState(false);
  const [bodyTypeValid, setBodyTypeValid] = useState(false);
  const [bodyTypeError, setBodyTypeError] = useState(false);
  const [ethnicityFocus, setEthnicityFocus] = useState(false);
  const [ethnicityValid, setEthnicityValid] = useState(false);
  const [ethnicityError, setEthnicityError] = useState(false);
  const [raceFocus, setRaceFocus] = useState(false);
  const [raceValid, setRaceValid] = useState(false);
  const [raceError, setRaceError] = useState(false);
  const [hairColorFocus, setHairColorFocus] = useState(false);
  const [hairColorValid, setHairColorValid] = useState(false);
  const [hairColorError, setHairColorError] = useState(false);
  const [eyeColorFocus, setEyeColorFocus] = useState(false);
  const [eyeColorValid, setEyeColorValid] = useState(false);
  const [eyeColorError, setEyeColorError] = useState(false);
  const [heightFocus, setHeightFocus] = useState(false);
  const [heightValid, setHeightValid] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [lifestyleFocus, setLifestyleFocus] = useState(false);
  const [lifestyleValid, setLifestyleValid] = useState(false);
  const [lifestyleError, setLifestyleError] = useState(false);
  const [netWorthFocus, setNetWorthFocus] = useState(false);
  const [netWorthValid, setNetWorthValid] = useState(false);
  const [netWorthError, setNetWorthError] = useState(false);

  // Redux variables
  const lookingFor = useSelector((state) => state.signup.lookingFor);
  const lifestyle = useSelector((state) => state.signup.lifestyle);
  const netWorth = useSelector((state) => state.signup.netWorth);
  const annualIncome = useSelector((state) => state.signup.annualIncome);
  const height = useSelector((state) => state.signup.height);
  const bodyType = useSelector((state) => state.signup.bodyType);
  const ethnicity = useSelector((state) => state.signup.ethnicity);
  const race = useSelector((state) => state.signup.race);
  const eyeColor = useSelector((state) => state.signup.eyeColor);
  const hairColor = useSelector((state) => state.signup.hairColor);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LifestyleSignup-Page",
      },
    });

    getAio(null, true);
  }, []);

  // Lifecycle | Check for update | Profile Update API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      onNavigate(routeConst.detailSignup.path);
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | Profile Update API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      onNavigate(routeConst.detailSignup.path);
    } else if (updateUserProfileError) {
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LifestyleSignup-Page-Save-Button",
      },
    });

    const oldObj = {
      annualincome: annualIncome,
      bodytype: bodyType,
      ethnicity,
      eyecolor: eyeColor,
      haircolor: hairColor,
      height,
      lifestyle,
      networth: netWorth,
      race,
    };
    const obj = {
      annual_income: annualIncome,
      body_type: bodyType,
      ethnicity: ethnicity,
      eye_color: eyeColor,
      hair_color: hairColor,
      height: height,
      lifestyle_budget: lifestyle,
      net_worth: netWorth,
      race: "",
    };

    updateProfile(oldObj);
    // updateUserProfile(obj); // Backend not ready yet, does not update GET profile API
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LifestyleSignup-Page-Skip-Button",
      },
    });

    onNavigate(routeConst.detailSignup.path);
  };

  // Event Handlers | MUI Select
  const handleSelectChange = (type) => (event) => {
    switch (type) {
      case "monthlySpendingHabit":
        dispatch(updateLifestyle(event.target.value));
        break;
      case "netWorth":
        dispatch(updateNetWorth(event.target.value));
        break;
      case "annualIncome":
        dispatch(updateAnnualIncome(event.target.value));
        break;
      case "height":
        dispatch(updateHeight(event.target.value));
        break;
      case "bodyType":
        dispatch(updateBodyType(event.target.value));
        break;
      case "ethnicity":
        dispatch(updateEthnicity(event.target.value));
        if (event.target.value !== "asian") {
          dispatch(updateRace(""));
        }
        break;
      case "race":
        dispatch(updateRace(event.target.value));
        break;
      case "eyeColor":
        dispatch(updateEyeColor(event.target.value));
        break;
      case "hairColor":
        dispatch(updateHairColor(event.target.value));
        break;
      default:
        break;
    }
  };
  const onLifestyleFocus = () => {
    setLifestyleFocus(true);
  };
  const onNetWorthFocus = () => {
    setNetWorthFocus(true);
  };
  const onAnnualIncomeFocus = () => {
    setAnnualIncomeFocus(true);
  };
  const onHeightFocus = () => {
    setHeightFocus(true);
  };
  const onBodyTypeFocus = () => {
    setBodyTypeFocus(true);
  };
  const onEthnicityFocus = () => {
    setEthnicityFocus(true);
  };
  const onRaceFocus = () => {
    setRaceFocus(true);
  };
  const onEyeColorFocus = () => {
    setEyeColorFocus(true);
  };
  const onHairColorFocus = () => {
    setHairColorFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    if (lookingFor === "sugarbaby") {
      return (
        lifestyle &&
        netWorthValid &&
        annualIncomeValid &&
        heightValid &&
        bodyTypeValid &&
        ethnicityValid &&
        eyeColorValid &&
        hairColorValid
      );
    } else {
      return (
        lifestyleValid &&
        heightValid &&
        bodyTypeValid &&
        ethnicityValid &&
        eyeColorValid &&
        hairColorValid
      );
    }
  };
  const lifestyleValidation = () => {
    let valid = true;
    let error = false;

    if (lifestyle === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setLifestyleValid(valid);
    setLifestyleError(error);
  };
  const netWorthValidation = () => {
    let valid = true;
    let error = false;

    if (netWorth === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setNetWorthValid(valid);
    setNetWorthError(error);
  };
  const annualIncomeValidation = () => {
    let valid = true;
    let error = false;

    if (annualIncome === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setAnnualIncomeValid(valid);
    setAnnualIncomeError(error);
  };
  const heightValidation = () => {
    let valid = true;
    let error = false;

    if (height === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setHeightValid(valid);
    setHeightError(error);
  };
  const bodyTypeValidation = () => {
    let valid = true;
    let error = false;

    if (bodyType === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setBodyTypeValid(valid);
    setBodyTypeError(error);
  };
  const ethnicityValidation = () => {
    let valid = true;
    let error = false;

    if (ethnicity === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setEthnicityValid(valid);
    setEthnicityError(error);
  };
  const raceValidation = () => {
    let valid = true;
    let error = false;

    if (race === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setRaceValid(valid);
    setRaceError(error);
  };
  const eyeColorValidation = () => {
    let valid = true;
    let error = false;

    if (eyeColor === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setEyeColorValid(valid);
    setEyeColorError(error);
  };
  const hairColorValidation = () => {
    let valid = true;
    let error = false;

    if (hairColor === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setHairColorValid(valid);
    setHairColorError(error);
  };

  // Check for text field validation
  useEffect(() => {
    lifestyleValidation();
    netWorthValidation();
    annualIncomeValidation();
    heightValidation();
    bodyTypeValidation();
    ethnicityValidation();
    eyeColorValidation();
    hairColorValidation();
  }, [
    lifestyle,
    lifestyleFocus,
    netWorth,
    netWorthFocus,
    annualIncome,
    annualIncomeFocus,
    height,
    heightFocus,
    bodyType,
    bodyTypeFocus,
    ethnicity,
    ethnicityFocus,
    eyeColor,
    eyeColorFocus,
    hairColor,
    hairColorFocus,
  ]);

  if (getAioFetching || getAioLoading) {
    return <Spinner />;
  } else if (getAioSuccess) {
    return (
      <Box id="lifestyle-signup-page">
        <div className="max-width-container">
          <div className="steps-label">
            {t("signup.step_n_max", { n: 2, max: 3 })}
          </div>

          <div className="step-description-label">
            {t("signup.your_lifestyle_appearance")}
          </div>

          <div className="form-field-container">
            {/* Lifestyle */}
            <div className="monthly-habit-form-field-container">
              <TextField
                className="monthly-habit-form-field custom-text-field"
                select
                label={t("signup.monthly_spending_habit")}
                value={lifestyle}
                error={lifestyleError}
                onChange={handleSelectChange("monthlySpendingHabit")}
                variant="standard"
                onFocus={onLifestyleFocus}
              >
                {getAioData?.payload?.dropdown?.lifestyle?.map(
                  (lifestyle, index) => (
                    <MenuItem value={lifestyle?.value} key={index}>
                      {t(`${lifestyle?.name}`)}
                    </MenuItem>
                  )
                )}
              </TextField>
            </div>

            {/* Net Worth */}
            {lookingFor === "sugarbaby" && (
              <div className="net-worth-form-field-container">
                <TextField
                  className="net-worth-form-field custom-text-field"
                  select
                  label={t("filter.net_worth")}
                  value={netWorth}
                  error={netWorthError}
                  onChange={handleSelectChange("netWorth")}
                  variant="standard"
                  onFocus={onNetWorthFocus}
                >
                  {getAioData?.payload?.dropdown?.networth?.map(
                    (networth, index) => (
                      <MenuItem value={networth?.value} key={index}>
                        {t(`${networth?.name}`)}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>
            )}

            {/* Annual Income */}
            {lookingFor === "sugarbaby" && (
              <div className="annual-income-form-field-container">
                <TextField
                  className="annual-income-form-field custom-text-field"
                  select
                  label={t("filter.annual_income")}
                  value={annualIncome}
                  error={annualIncomeError}
                  onChange={handleSelectChange("annualIncome")}
                  variant="standard"
                  onFocus={onAnnualIncomeFocus}
                >
                  {getAioData?.payload?.dropdown?.annual_income?.map(
                    (income, index) => (
                      <MenuItem value={income?.value} key={index}>
                        {t(`${income?.name}`)}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>
            )}

            {/* Height */}
            <div className="flex-container">
              <div className="height-form-field-container">
                <TextField
                  className="height-form-field custom-text-field"
                  select
                  label={t("profile.height")}
                  value={height}
                  error={heightError}
                  onChange={handleSelectChange("height")}
                  variant="standard"
                  onFocus={onHeightFocus}
                >
                  {getAioData?.payload?.dropdown?.height?.map(
                    (height, index) => (
                      <MenuItem value={height?.value} key={index}>
                        {height?.value}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>

              {/* Body Type */}
              <div className="body-type-form-field-container">
                <TextField
                  className="body-type-form-field custom-text-field"
                  select
                  label={t("profile.body_type")}
                  value={bodyType}
                  error={bodyTypeError}
                  onChange={handleSelectChange("bodyType")}
                  variant="standard"
                  onFocus={onBodyTypeFocus}
                >
                  {getAioData?.payload?.dropdown?.body_type?.map(
                    (bodyType, index) => (
                      <MenuItem value={bodyType?.value} key={index}>
                        {t(`${bodyType?.name}`)}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>
            </div>

            {/* Ethnicity */}
            <div className="ethnicity-form-field-container">
              <TextField
                className="ethnicity-form-field custom-text-field"
                select
                label={t("profile.ethnicity")}
                value={ethnicity}
                error={ethnicityError}
                onChange={handleSelectChange("ethnicity")}
                variant="standard"
                onFocus={onEthnicityFocus}
              >
                {getAioData?.payload?.dropdown?.ethnicity?.map(
                  (ethnicity, index) => (
                    <MenuItem value={ethnicity?.value} key={index}>
                      {t(`${ethnicity?.name}`)}
                    </MenuItem>
                  )
                )}
              </TextField>
            </div>

            {/* Race */}
            {getAioData?.payload?.dropdown?.race[0]?.child_value?.length > 0 &&
              ethnicity === "asian" && (
                <div className="race-form-field-container">
                  <TextField
                    className="race-form-field custom-text-field"
                    select
                    label={t("profile.race")}
                    value={race}
                    error={raceError}
                    onChange={handleSelectChange("race")}
                    variant="standard"
                    onFocus={onRaceFocus}
                  >
                    {getAioData?.payload?.dropdown?.race[0]?.child_value?.map(
                      (race, index) => (
                        <MenuItem value={race?.value} key={index}>
                          {t(`${race?.name}`)}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </div>
              )}

            {/* Eye Color */}
            <div className="flex-container">
              <div className="eye-color-form-field-container">
                <TextField
                  className="eye-color-form-field custom-text-field"
                  select
                  label={t("profile.eye_color")}
                  value={eyeColor}
                  error={eyeColorError}
                  onChange={handleSelectChange("eyeColor")}
                  variant="standard"
                  onFocus={onEyeColorFocus}
                >
                  {getAioData?.payload?.dropdown?.eye_color?.map(
                    (eyeColor, index) => (
                      <MenuItem value={eyeColor?.value} key={index}>
                        {t(`${eyeColor?.name}`)}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>

              {/* Hair Color */}
              <div className="hair-color-form-field-container">
                <TextField
                  className="hair-color-form-field custom-text-field"
                  select
                  label={t("profile.hair_color")}
                  value={hairColor}
                  error={hairColorError}
                  onChange={handleSelectChange("hairColor")}
                  variant="standard"
                  onFocus={onHairColorFocus}
                >
                  {getAioData?.payload?.dropdown?.hair_color?.map(
                    (hairColor, index) => (
                      <MenuItem value={hairColor?.value} key={index}>
                        {t(`${hairColor?.name}`)}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </div>
            </div>
          </div>

          <div className="button-container">
            <Button
              className={`next-button ${
                isFormValid() ? "" : "disabled-button"
              }`}
              variant="text"
              onClick={onSaveDetails}
              disabled={!isFormValid()}
            >
              {updateProfileLoading ? (
                <Spinner size={20} isPadding={false} />
              ) : (
                t("signup.next")
              )}
            </Button>

            <Button className="skip-button" onClick={onSkip}>
              {t("signup.skip_for_now")}
            </Button>
          </div>
        </div>
      </Box>
    );
  } else {
  }
};

export default LifestyleSignup;
