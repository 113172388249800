const livestreamingConst = {
  loadingCopywriting: [
    "Sit tight, she’s cumming",
    "Sliding into cleavage..",
    "Loading orgasms..",
    "Almost cumming..",
    "Waiting for her...",
    "Eat, pray, smash..loading",
    "Sexiness is loading...",
    "Loading love vibes...",
    "Loading sexiness..",
    "Something sexy awaits…",
    "Cooking orgasms..",
    "Something orgasmic is brewing..",
    "Finding love, almost there..",
    "Loading heat..",
    "Loading seduction..",
    "Loading playmates..",
    "Steamy moments incoming..",
    "Flirt, laugh, date.. Loading",
    "Playful stream loading..",
    "Ready, set, flirt.. loading",
  ],
  chatLimit: 80,
};

export default livestreamingConst;
