// General
import "./achievements-info.scss";
import { useState } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProfileBadgeInfo } from "../../../../../redux/store/profileStore";
import { updateProfileAchievementBadgeDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";

const ProfileAchievements = (props) => {
  const { mode = "dialog" } = props;

  // General variables
  const [badgeInfo, setBadgeInfo] = useState(null);

  // Redux variables
  const profileAchievementBadge = useSelector(
    (state) => state.userCard.profileAchievementBadge
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onBadgeInfo = (badge) => {
    switch (mode) {
      case "page":
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-UserProfile-Page-ViewAchievementInfo-Button",
          },
        });

        setBadgeInfo(badge);
        break;
      case "dialog":
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-UserProfile-Page-OpenAchievementInfo-Dialog",
          },
        });

        dispatch(updateProfileBadgeInfo(badge));
        dispatch(updateProfileAchievementBadgeDialog(true));
        break;
      default:
        break;
    }
  };

  if (profileAchievementBadge) {
    return (
      <div id="profile-view-achievements-subcomponent">
        <div className="padding-container">
          <div className="achievements-label">{t("profile.achievements")}</div>

          <div className="achievements-container">
            {profileAchievementBadge?.map((badge, index) => (
              <div
                className="achievement-image-container"
                onClick={() => onBadgeInfo(badge)}
                key={index}
              >
                <img
                  className={`achievement-image ${
                    mode === "page" ? "larger-image" : ""
                  }`}
                  src={badge?.url}
                  alt={badge?.ranking}
                />
              </div>
            ))}
          </div>

          {mode === "page" && badgeInfo && (
            <div className="achievement-info-container">
              <div className="achievement-image-container">
                <img
                  className="achievement-image"
                  src={badgeInfo?.url}
                  alt={"badge"}
                />
              </div>

              <div
                className="badge-description"
                dangerouslySetInnerHTML={{ __html: badgeInfo?.description }}
              ></div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    if (mode === "page") {
      return (
        <div className="livestream-achievement-empty">
          {t("profile.livestream_achievement_empty")}
        </div>
      );
    }
  }
};

export default ProfileAchievements;
