// General
import "./photo-info.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  usePhotoPermissionRequestMutation,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
import userCardConst from "../../../../../const/userCardConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePrivatePhotoRequested } from "../../../../../redux/store/userCardStore";
import { updateLocalSearchList } from "../../../../../redux/store/searchStore";
import { updateLocalLivestreamUserData } from "../../../../../redux/store/livestreamingStore";
import {
  updateLivestreamProfileViewDialog,
  updateUserProfileDialog,
  updateVerifiedCallerDescriptionDialog,
} from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Button, Divider, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../spinner/spinner";

const PhotoInfo = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    photoPermissionRequest,
    {
      data: photoPermissionRequestData,
      error: photoPermissionRequestErrorData,
      isLoading: photoPermissionRequestLoading,
      isSuccess: photoPermissionRequestSuccess,
      isError: photoPermissionRequestError,
    },
  ] = usePhotoPermissionRequestMutation();

  // General variables
  const [isCenter, setIsCenter] = useState(false);

  // Render variables
  let carouselView = [];

  // Redux variables
  const id = useSelector((state) => state.userCard.id);
  const idInt = useSelector((state) => state.userCard.idInt);
  const profilePhoto = useSelector((state) => state.userCard.profilePhoto);
  const publicPhotos = useSelector((state) => state.userCard.publicPhotos);
  const privatePhotos = useSelector((state) => state.userCard.privatePhotos);
  const profileStatus = useSelector((state) => state.userCard.profileStatus);
  const privatePhotoRequested = useSelector(
    (state) => state.userCard.privatePhotoRequested
  );
  const privatePhotoApproval = useSelector(
    (state) => state.userCard.privatePhotoApproval
  );
  const role = useSelector((state) => state.userCard.role);
  const verifiedCaller = useSelector((state) => state.userCard.verifiedCaller);
  const userProfileDialog = useSelector(
    (state) => state.dialog.userProfileDialog
  );
  const ownMembershipType = useSelector((state) => state.user.membershipType);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Unknown | TBA
  useEffect(() => {
    setIsCenter((prev) => !prev);
  }, [userProfileDialog]);

  // Lifecycle | Check for update | Photo Permission Request API Response
  useEffect(() => {
    if (photoPermissionRequestLoading) {
    } else if (photoPermissionRequestSuccess) {
      switch (photoPermissionRequestData?.status) {
        case 1:
          // Update User Card
          dispatch(updatePrivatePhotoRequested(true));

          // Update Search List
          const obj = {
            idInt,
            privatePhotoRequested: true,
          };
          dispatch(updateLocalSearchList(obj));
          dispatch(updateLocalLivestreamUserData(obj));
          break;
        default:
          break;
      }
    } else if (photoPermissionRequestError) {
      if (photoPermissionRequestErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    photoPermissionRequestLoading,
    photoPermissionRequestSuccess,
    photoPermissionRequestError,
  ]);

  // Event Handlers | Button
  const onRequestPrivatePhoto = () => {
    if (isDaddy) {
      if (
        getProfileData?.data?.membership_type === userConst.membershipType.free
      ) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-UserProfile-Page-RequestPrivatePhotoUpgrade-Button",
          },
        });

        dispatch(updateUserProfileDialog(false));
        dispatch(updateLivestreamProfileViewDialog(false));
        onNavigate(routeConst.upgrade.path);
      } else {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-UserProfile-Page-RequestPrivatePhoto-Button",
          },
        });

        const obj = {
          user_id: id,
        };
        photoPermissionRequest(obj);
      }
    }
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  // Utility Functions
  const getProfileStatus = (status) => {
    switch (status) {
      case "live":
        return "LIVE";
      case "video-call":
        return "VIDEO CALL";
      default:
        break;
    }
  };
  const getFallbackImage = () => {
    switch (role?.toLowerCase()) {
      case userConst.userRole.sugardaddy.toLowerCase():
        return getAsset("avatarFallbackMan");
      case userConst.userRole.maleSugarbaby.toLowerCase():
        return getAsset("avatarFallbackMan");
      case userConst.userRole.sugarmommy.toLowerCase():
        return getAsset("avatarFallbackWoman");
      case userConst.userRole.sugarbaby.toLowerCase():
        return getAsset("avatarFallbackWoman");
      default:
        return getAsset("avatarFallbackNeutral");
    }
  };

  // Render Functions | Carousel Items
  if (getProfileData?.data?.id_int === idInt) {
    // Own Profile
    let rawCombinedPhotos = [...publicPhotos, ...privatePhotos];
    let combinedPhotos = [];

    for (let i = 0; i < rawCombinedPhotos?.length; i++) {
      if (rawCombinedPhotos[i]?.status !== "REJECTED") {
        combinedPhotos.push(rawCombinedPhotos[i]);
      }
    }

    if (combinedPhotos?.length <= 0) {
      carouselView = (
        <swiper-slide key={0}>
          <div className="image-container">
            <img className="carousel-image" src={getFallbackImage()} />
          </div>
        </swiper-slide>
      );
    } else {
      carouselView = combinedPhotos?.map((photo, index) => (
        <swiper-slide key={index}>
          <div className="image-container">
            <img className="carousel-image" src={photo?.photoUrl} />
          </div>
        </swiper-slide>
      ));
    }
  } else if (
    privatePhotoApproval ||
    ownMembershipType === userConst.membershipType.admin
  ) {
    // Other Profile | Private Photo Approved OR ownMembershipType === admin
    let combinedPhotos =
      publicPhotos?.length > 0 || privatePhotos?.length > 0
        ? [...publicPhotos, ...privatePhotos]
        : [];

    if (combinedPhotos?.length <= 0) {
      carouselView = (
        <swiper-slide key={0}>
          <div className="image-container">
            <img className="carousel-image" src={getFallbackImage()} />
          </div>
        </swiper-slide>
      );
    } else {
      carouselView = combinedPhotos?.map((photo, index) => (
        <swiper-slide key={index}>
          <div className="image-container">
            <img className="carousel-image" src={photo?.photoUrl} />
          </div>
        </swiper-slide>
      ));
    }
  } else {
    // Other Profile | Private Photo Not Approved
    if (publicPhotos?.length <= 0) {
      carouselView = [0].map(() => (
        <swiper-slide key={0}>
          <div className="image-container">
            <img className="carousel-image" src={getFallbackImage()} />
          </div>
        </swiper-slide>
      ));
    } else {
      carouselView = publicPhotos?.map((photo, index) => (
        <swiper-slide key={index}>
          <div className="image-container">
            <img className="carousel-image" src={photo?.photoUrl} />
          </div>
        </swiper-slide>
      ));
    }

    if (privatePhotos?.length > 0) {
      carouselView?.push(
        <swiper-slide key={1000}>
          <div className="private-container">
            <div className="private-body-container">
              <div className="lock-icon-container">
                {getIcon("privatePhotoLockIcon", "lock-icon")}
              </div>

              <div className="private-photo-label">
                {t("profile.private_photos")}
              </div>

              <div className="private-photo-count">
                {t("profile.private_n_photos", { n: privatePhotos.length })}
              </div>

              <Button
                className={`request-button ${
                  privatePhotoRequested && "disabled-button"
                }`}
                variant="text"
                onClick={onRequestPrivatePhoto}
                disabled={privatePhotoRequested}
              >
                {photoPermissionRequestLoading ? (
                  <Spinner size={20} isPadding={false} color="white-spinner" />
                ) : privatePhotoRequested ? (
                  t("profile.private_requested")
                ) : isDaddy &&
                  ownMembershipType === userConst.membershipType.free ? (
                  t("profile.private_upgrade_to_request")
                ) : (
                  t("profile.private_request_to_view")
                )}
              </Button>
            </div>
          </div>
        </swiper-slide>
      );
    }
  }

  if (getProfileSuccess) {
    return (
      <div
        id="profile-view-photo-info-subcomponent"
        className={isMobile ? "viewport-height" : "dynamic-height"}
      >
        {isMobile && (
          <div className="carousel-container">
            <swiper-container
              class="swiper-container"
              navigation="false"
              pagination="true"
              scrollbar="false"
              loop="false"
              speed="300"
            >
              {carouselView}
            </swiper-container>

            {/* User Status - Live */}
            {profileStatus === userCardConst.userProfileStatus.live && (
              <div className="live-status">{t("search.badge.live")}</div>
            )}

            {/* User Status - Available for Private Call */}
            {profileStatus ===
              userCardConst.userProfileStatus.inPrivateAvailable && (
              <div className="private-call-available">
                {t("search.badge.private_room")}

                {/* Verified Caller Badge */}
                {verifiedCaller && (
                  <div
                    className="verified-caller-badge-container"
                    onClick={onOpenVerifiedCallerDialog}
                  >
                    {getIcon("verifiedCallerBadge", "verified-caller-badge")}
                  </div>
                )}
              </div>
            )}

            {/* User Status - Busy in Private Call */}
            {profileStatus ===
              userCardConst.userProfileStatus.inPrivateBusy && (
              <div className="private-call-busy">
                {t("search.badge.busy_in_private")}

                {/* Verified Caller Badge */}
                {verifiedCaller && (
                  <div className="verified-caller-badge-container">
                    {getIcon("verifiedCallerBadge", "verified-caller-badge")}
                  </div>
                )}
              </div>
            )}

            {/* User Status - Video Call */}
            {profileStatus === userCardConst.userProfileStatus.videoCall && (
              <div className="video-call">{t("search.badge.video_call")}</div>
            )}
          </div>
        )}

        {!isMobile && (
          <div className="stacked-container">
            {false && profilePhoto && (
              <div className="profile-photo-container">
                <img
                  className="profile-photo"
                  src={profilePhoto}
                  alt="public"
                />
              </div>
            )}

            {publicPhotos?.length > 0 && (
              <div className="public-photo-container">
                <div className="public-photo-label">
                  {t("profile.public_photos")}
                </div>

                <div className="public-photo-image-container">
                  {publicPhotos?.map((photo, index) => (
                    <div className="public-image-container" key={index}>
                      <img
                        className="public-image"
                        src={photo?.photoUrl}
                        alt="public"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {privatePhotoApproval && privatePhotos?.length > 0 && (
              <div className="private-photo-container">
                <div className="private-photo-label">
                  {t("profile.private_photos")}
                </div>

                <div className="private-photo-image-container">
                  {privatePhotos?.map((photo, index) => (
                    <div className="private-image-container" key={index}>
                      <img
                        className="private-image"
                        src={photo?.photoUrl}
                        alt="private"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {!privatePhotoRequested && privatePhotos?.length > 0 && (
              <div className="private-photo-container">
                <div className="label-container">
                  <div className="private-photo-label">
                    {t("profile.private_photos")}
                  </div>
                  <Button
                    className={`request-button ${
                      privatePhotoRequested && "disabled-button"
                    }`}
                    variant="text"
                    onClick={onRequestPrivatePhoto}
                    disabled={privatePhotoRequested}
                  >
                    {photoPermissionRequestLoading ? (
                      <Spinner
                        size={20}
                        isPadding={false}
                        color="white-spinner"
                      />
                    ) : privatePhotoRequested ? (
                      t("profile.private_requested")
                    ) : (
                      t("profile.private_request_to_view")
                    )}
                  </Button>
                </div>

                <div className="private-photo-image-container">
                  {privatePhotos?.map((photo, index) => (
                    <div className="private-container" key={index}>
                      <div className="private-body-container">
                        <div className="lock-icon-container">
                          {getIcon("privatePhotoLockIcon", "lock-icon")}
                        </div>

                        <div className="private-photo-label">
                          {t("profile.private_photos")}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        <Divider />
      </div>
    );
  }
};

export default PhotoInfo;
