// General
import "./forgot-password.scss";
import { useState, useEffect } from "react";
// Services
import { usePasswordRecoveryMutation } from "../../services/data.service";
import { emailRegex } from "../../services/regex.service";
// Redux
import { useDispatch } from "react-redux";
import {
  updateSuccessToast,
  updateWarningToast,
  updateInfoToast,
} from "../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Box, TextField, Button, CircularProgress } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../utility/custom-hooks/useCustomNavigate-hook";

const ForgotPassword = () => {
  // API variables
  const [
    passwordRecovery,
    {
      data: passwordRecoveryData,
      error: passwordRecoveryErrorData,
      isLoading: passwordRecoveryLoading,
      isSuccess: passwordRecoverySuccess,
      isError: passwordRecoveryError,
    },
  ] = usePasswordRecoveryMutation();

  // General variables
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Forgot-Password-Page",
      },
    });
  }, []);

  // Lifecycle | Check for update | Password Recovery API Response
  useEffect(() => {
    if (passwordRecoveryLoading) {
    } else if (passwordRecoverySuccess) {
      switch (passwordRecoveryData?.status) {
        case 0:
          // No message
          const warningToastObj = {
            message: passwordRecoveryData?.message || "",
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
        case 1:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-ForgotPassword-Page-PasswordRecovery-Success",
            },
          });

          const successToastObj = {
            message: passwordRecoveryData?.message,
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(successToastObj));
          break;
        default:
          const infoToastObj = {
            message: passwordRecoveryData?.message,
            autoClose: 3000,
          };
          dispatch(updateInfoToast(infoToastObj));
          break;
      }

      if (passwordRecoveryData?.message) {
        onNavigate(-1);
      }
    } else if (passwordRecoveryError) {
    }
  }, [passwordRecoveryLoading, passwordRecoverySuccess, passwordRecoveryError]);

  // Event Handlers | Button
  const onResetPassword = () => {
    const obj = {
      email,
    };
    passwordRecovery(obj);
  };

  // Event Handlers | MUI Text Field
  const onEmailChange = (event) => {
    setEmail(event.target.value.trim().toLowerCase());
  };
  const onEmailFocus = (event) => {
    setEmailFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (emailValid) {
      valid = true;
    }

    return valid;
  };
  const emailValidation = () => {
    let valid = true;
    let error = false;

    const regex = emailRegex;

    if (!emailFocus) {
      valid = false;
      error = false;
    } else if (emailFocus && email === "") {
      setEmailErrorMessage(t("login.email_address_is_required"));
      valid = false;
      error = true;
    } else if (!regex.test(email)) {
      setEmailErrorMessage(t("login.email_address_is_not_valid"));
      valid = false;
      error = true;
    } else {
      setEmailErrorMessage("");
      valid = true;
      error = false;
    }

    setEmailValid(valid);
    setEmailError(error);
  };

  // Check for email validation
  useEffect(() => {
    emailValidation();
  }, [email, emailFocus, emailErrorMessage]);

  return (
    <Box
      id="forgot-password-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="forgot-password-label">
          {t("signup.forgot_password")}
        </div>

        <div className="email-form-field-container">
          <TextField
            className="email-form-field custom-text-field"
            required
            value={email}
            error={emailError}
            onChange={onEmailChange}
            label={t("login.email_address")}
            placeholder={t("login.email_address")}
            helperText={emailErrorMessage}
            variant="standard"
            onFocus={onEmailFocus}
          />
        </div>

        <div className="button-container">
          <Button
            className={`reset-button ${isFormValid() ? "" : "disabled-button"}`}
            variant="text"
            onClick={onResetPassword}
            disabled={!isFormValid()}
          >
            {passwordRecoveryLoading ? (
              <CircularProgress className="button-spinner" size={24} />
            ) : (
              t("signup.reset_password")
            )}
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default ForgotPassword;
