// General
import "./video-call.scss";
import { useEffect } from "react";
// Services
import { sessionService } from "../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVideoCallTabValue } from "../../../redux/store/privateCallStore";
import { updateOnboardingVideoCallDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Banner from "../../shared/elements/banner/banner";
import VideoCallTab from "./video-call-tab/video-call-tab";
import VideoCallRecentTab from "./video-call-recent-tab/video-call-recent-tab";

const VideoCall = () => {
  // Redux variables
  const videoCallTabValue = useSelector(
    (state) => state.privateCall.videoCallTabValue
  );
  const dispatch = useDispatch();

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 720px)");

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VideoCall-Page",
      },
    });

    // Check if "has-shown-video-call-dialog" in local storage is true or false
    if (!sessionService.getHasShownVideoCallDialog()) {
      dispatch(updateOnboardingVideoCallDialog(true));
    }
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(videoCallTabValue);

    emblaApi?.on("select", () => {
      dispatch(updateVideoCallTabValue(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-VideoCall-Page-TabChangeSwipe-Button-${
          newValue === 0 ? "PrivateRoom" : "Recent"
        }`,
      },
    });

    emblaApi?.scrollTo(newValue);
  };

  return (
    <div id="video-call-page">
      {isDesktop && (
        <div className="desktop-banner-container">
          <Banner type={"video-call"} view={"carousel"} />
        </div>
      )}

      <div className="max-width-container">
        <div className="tab-container">
          <Tabs
            className="custom-tabs"
            value={videoCallTabValue}
            onChange={onTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
            variant={isDesktop ? "standard" : "fullWidth"}
          >
            <Tab label={t("1on1.private_room")} />
            <Tab label={t("inbox.recent")} />
          </Tabs>

          <div ref={emblaRef} className="embla">
            <div className="embla__container">
              <div className="embla__slide">
                <VideoCallTab />
              </div>

              <div className="embla__slide">
                <VideoCallRecentTab />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCall;
