// General
import "./video-call-info-dialog.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetUserAioQuery,
  usePostSingleConversationMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateInitiatePrivateCall } from "../../../../redux/store/inboxStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
} from "../../../../redux/store/userCardStore";
import { updateNewUserConversation } from "../../../../redux/store/inboxStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
import {
  updateVideoCallInfoDialog,
  updateUserProfileDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";
import Spinner from "../../elements/spinner/spinner";

const VideoCallInfoDialog = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getConversationDetails,
    {
      data: getConversationDetailsData,
      error: getConversationDetailsErrorData,
      isLoading: getConversationDetailsLoading,
      isSuccess: getConversationDetailsSuccess,
      isError: getConversationDetailsError,
    },
  ] = usePostSingleConversationMutation();

  // Redux variables
  const videoCallInfoDialog = useSelector(
    (state) => state.dialog.videoCallInfoDialog
  );
  const videoCallHistoryInfo = useSelector(
    (state) => state.privateCall.videoCallHistoryInfo
  );
  const dispatch = useDispatch();

  // MUI variables
  const inboxIsMobile = useMediaQuery("(max-width: 850px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!videoCallInfoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VideoCallInfo-Dialog",
      },
    });

    getUserAio(null, true);
  }, [videoCallInfoDialog]);

  // Lifecycle | Check for update | conversationDetails API Response
  useEffect(() => {
    if (getConversationDetailsLoading) {
    } else if (getConversationDetailsSuccess) {
      if (getConversationDetailsData?.status === 0) {
        onCloseDialog();

        if (inboxIsMobile) {
          onNavigate(
            `${routeConst.inbox.messaging.chat.chatId.clearPath}${getConversationDetailsData?.data?.single_conversation?.id}`
          );
        } else {
          const obj = {
            conversation_accepted: false,
            conversation_id:
              getConversationDetailsData?.data?.single_conversation?.id,
            conversation_id_int: null,
            last_message: null,
            other_user:
              getConversationDetailsData?.data?.single_conversation?.other_user,
          };

          dispatch(updateNewUserConversation(obj));
          onNavigate(routeConst.inbox.path);
        }
      }
    } else if (getConversationDetailsError) {
      const toastObj = {
        message: getConversationDetailsErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
    }
  }, [
    getConversationDetailsLoading,
    getConversationDetailsSuccess,
    getConversationDetailsError,
  ]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateVideoCallInfoDialog(false));
  };
  const onMessageUser = () => {
    if (inboxIsMobile) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-VideoCallInfo-Dialog-Mobile-MessageUser-Button",
        },
      });

      onCloseDialog();
      onNavigate(
        `${routeConst.inbox.messaging.chat.chatId.clearPath}${videoCallHistoryInfo?.conversationId}`
      );
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-VideoCallInfo-Dialog-Desktop-MessageUser-Button",
        },
      });

      const obj = {
        recipient_user_id: videoCallHistoryInfo?.id,
      };
      getConversationDetails({ id: null, body: obj });
    }
  };
  const onVideoCallUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VideoCallInfo-Dialog-VideoCallUser-Button",
      },
    });

    dispatch(updateInitiatePrivateCall(true));
    onCloseDialog();
    onNavigate(
      `${routeConst.inbox.messaging.chat.chatId.clearPath}${videoCallHistoryInfo?.conversationId}`
    );
  };
  const onViewProfileProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VideoCallInfo-Dialog-ViewProfile-Button",
      },
    });

    dispatch(updateId(videoCallHistoryInfo?.id));
    dispatch(updateIdInt(videoCallHistoryInfo?.idInt));
    dispatch(updateShouldCallApi(true));

    dispatch(updateVideoCallInfoDialog(false));

    onNavigate(
      `${routeConst.profile.view.clearPath}${videoCallHistoryInfo?.id}`
    );
  };

  // Utility Functions
  const getCallStatus = (callStatus) => {
    switch (callStatus) {
      case "rejected":
        return t("inbox.call_declined");
      case "cancelled":
        return t("inbox.call_cancelled");
      case "ended":
        return t("inbox.call_ended");
      case "missed":
        return t("inbox.call_not_answered");
      default:
        break;
    }
  };
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  };
  const getReadableDate = (date) => {
    return moment(date).format("ddd DD/MM/YYYY");
  };
  const getStandardTime = (date) => {
    return moment(date).format("HH:mm");
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={videoCallInfoDialog}
      onClose={onCloseDialog}
    >
      <div id="video-call-info-dialog">
        <div className="min-width-container">
          <div className="top-header">
            <div className="close-button-container" onClick={onCloseDialog}>
              <CloseIcon />
            </div>
          </div>

          <div className="padding-container">
            <CustomAvatar
              className="profile-photo"
              src={videoCallHistoryInfo?.profilePhoto}
            />

            <div className="private-call-title">
              {videoCallHistoryInfo?.username || "-"}
            </div>

            <div className="first-row">
              <div className="type-container">
                <div className="type-header">{t("common.status")}</div>

                <div className="type-text">
                  {getCallStatus(videoCallHistoryInfo?.status)}
                </div>
              </div>

              <div className="duration-container">
                <div className="duration-header">{t("1on1.duration")}</div>

                <div className="duration-text">
                  {videoCallHistoryInfo?.duration
                    ? getFormattedTime(videoCallHistoryInfo?.duration)
                    : "-"}
                </div>
              </div>
            </div>

            <div className="second-row">
              <div className="date-container">
                <div className="date-header">{t("common.date")}</div>

                <div className="date-text">
                  {videoCallHistoryInfo?.callStartAt
                    ? getReadableDate(videoCallHistoryInfo?.callStartAt)
                    : "-"}
                </div>
              </div>

              <div className="time-container">
                <div className="time-header">{t("common.time")}</div>

                <div className="time-text">
                  {videoCallHistoryInfo?.callStartAt
                    ? getStandardTime(videoCallHistoryInfo?.callStartAt)
                    : "-"}
                </div>
              </div>
            </div>

            <div className="buttons-container">
              <div
                className="send-message-button-container"
                onClick={onMessageUser}
              >
                <div className="send-message-button">
                  {getConversationDetailsLoading ? (
                    <Spinner
                      size={20}
                      isPadding={false}
                      color={"white-spinner"}
                    />
                  ) : (
                    getIcon("profileMessageIcon", "send-message-icon")
                  )}
                </div>

                <div className="send-message-label">{t("common.message")}</div>
              </div>

              {getUserAioData?.data?.modules?.private_calls_enabled && (
                <div
                  className="video-call-button-container"
                  onClick={onVideoCallUser}
                >
                  <div className="video-call-button">
                    {getIcon("profileVideoCallIcon", "video-call-icon")}
                  </div>

                  <div className="video-call-label">{t("1on1.video_call")}</div>
                </div>
              )}

              <div
                className="view-profile-button-container"
                onClick={onViewProfileProfile}
              >
                <div className="view-profile-button">
                  {getIcon("viewProfileIcon", "view-profile-icon")}
                </div>

                <div className="view-profile-label">
                  {t("profile.view_profile")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VideoCallInfoDialog;
