// General
import "./premium-lock.scss";
// Redux
import { useDispatch } from "react-redux";
import { updateFullSugarExperienceDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const PremiumLock = () => {
  // Redux variables
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | Button
  const onOpenDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PremiumLock-Subcomponent-OpenDialog-Button",
      },
    });

    dispatch(updateFullSugarExperienceDialog(true));
  };

  return (
    <div id="premium-lock-shared-component" onClick={onOpenDialog}>
      {getIcon("goldLockIcon", "lock-icon")}
    </div>
  );
};

export default PremiumLock;
