// General
import "./join-private-android-warning-dialog.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateJoinPrivateAndroidWarningDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const JoinPrivateAndroidWarningDialog = () => {
  // Redux variables
  const joinPrivateAndroidWarningDialog = useSelector(
    (state) => state.dialog.joinPrivateAndroidWarningDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!joinPrivateAndroidWarningDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-JoinPrivateAndroidWarning-Dialog",
      },
    });
  }, [joinPrivateAndroidWarningDialog]);

  // Event Handlers | Button
  const onRedirectToAndroidApk = () => {
    window.open(routeConst.misc.sugarbookAndroidApk.path, "_blank");
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateJoinPrivateAndroidWarningDialog(false));
  };

  return (
    <Dialog
      className="custom-radius25-dialog"
      maxWidth="xs"
      open={joinPrivateAndroidWarningDialog}
      onClose={onCloseDialog}
    >
      <div id="join-private-android-warning-dialog">
        <div className="padding-container">
          <div className="popup-action-icon-container">
            {getIcon("actionAlertIcon", "action-icon")}
          </div>

          <div className="heads-up-title">
            {t("profile.room_android_title")}
          </div>

          <div className="join-private-android-warning-description">
            {t("profile.room_android_desc")}
          </div>

          <div className="buttons-container">
            <div className="download-button" onClick={onRedirectToAndroidApk}>
              {t("upgrade.download_now")}
            </div>

            <div className="close-button" onClick={onCloseDialog}>
              {t("common.close")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default JoinPrivateAndroidWarningDialog;
