// General
import "./your-activity-settings.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetAllSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Switch, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import PremiumLock from "../../../shared/elements/premium-lock/premium-lock";

const YourActivitySettings = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getAllSettings,
    {
      data: allSettingsData,
      error: allSettingsErrorData,
      isFetching: allSettingsFetching,
      isLoading: allSettingsLoading,
      isSuccess: allSettingsSuccess,
      isError: allSettingsError,
    },
  ] = useLazyGetAllSettingsQuery();
  const [
    updateNotificationSettings,
    {
      data: updateNotificationSettingsData,
      error: updateNotificationSettingsErrorData,
      isLoading: updateNotificationSettingsLoading,
      isSuccess: updateNotificationSettingsSuccess,
      isError: updateNotificationSettingsError,
    },
  ] = useUpdateNotificationSettingsMutation();

  // MUI variables
  const [switchEvent, setSwitchEvent] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [visibleLastActive, setVisibleLastActive] = useState(false);
  const [visibleJoinDate, setVisibleJoinDate] = useState(false);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    getAllSettings(null, true);
  }, []);

  // Lifecycle | Check for update | All Settings API Response
  useEffect(() => {
    if (allSettingsFetching || allSettingsLoading) {
    } else if (allSettingsSuccess) {
      if (allSettingsData?.status === 0) {
        setVisibleLastActive(
          allSettingsData?.payload?.settings?.display_location === 1
            ? true
            : false
        );
        setVisibleJoinDate(
          allSettingsData?.payload?.settings?.joindatedisplay === 1
            ? true
            : false
        );
      }
    } else if (allSettingsError) {
    }
  }, [
    allSettingsFetching,
    allSettingsLoading,
    allSettingsSuccess,
    allSettingsError,
  ]);

  // Lifecycle | Check for update | Update Notification Settings API Response
  useEffect(() => {
    if (updateNotificationSettingsLoading) {
    } else if (updateNotificationSettingsSuccess) {
      onOpenSnackbar();
    } else if (updateNotificationSettingsError) {
      if (updateNotificationSettingsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    updateNotificationSettingsLoading,
    updateNotificationSettingsSuccess,
    updateNotificationSettingsError,
  ]);

  // Lifecycle | Check for update | Switch event & Call Update Notification API
  useEffect(() => {
    if (switchEvent) {
      const obj = {
        display_location: visibleLastActive,
        joindatedisplay: visibleJoinDate,
      };

      updateNotificationSettings(obj);
    }
  }, [switchEvent, visibleLastActive, visibleJoinDate]);

  // Event Handlers | MUI Switch
  const onSwitchChange = (state) => (event) => {
    let eventName = "";
    setSwitchEvent(true);

    switch (state) {
      case "last-active":
        setVisibleLastActive(event.target.checked);
        eventName = "LastActive";
        break;
      case "join-date":
        setVisibleJoinDate(event.target.checked);
        eventName = "JoinDate";
        break;
      default:
        break;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-Settings-Page-${eventName}-Toggle-${
          event.target.checked === true ? "On" : "Off"
        }`,
      },
    });
  };

  // Event Handlers | MUI Snackbar
  const onOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const onCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <div id="settings-your-activity-settings-subcomponent">
      <div className="padding-container">
        <div className="activity-label-container">
          <div className="activity-label">{t("settings.your_activity")}</div>
          {getProfileData?.data?.membership_type ===
            userConst.membershipType.free && <PremiumLock />}
        </div>

        <div className="switch-list-container">
          <div className="switch-container">
            <div className="switch-label">
              {t("settings.visible_last_active_date")}
            </div>
            <div className="suffix">
              <Switch
                className="custom-switch"
                checked={visibleLastActive}
                onChange={onSwitchChange("last-active")}
                disabled={
                  getProfileData?.data?.membership_type ===
                  userConst.membershipType.free
                }
              />
            </div>
          </div>

          <div className="switch-container">
            <div className="switch-label">
              {t("settings.visible_join_date")}
            </div>
            <div className="suffix">
              <Switch
                className="custom-switch"
                checked={visibleJoinDate}
                onChange={onSwitchChange("join-date")}
                disabled={
                  getProfileData?.data?.membership_type ===
                  userConst.membershipType.free
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        message={t("settings.alert_updated")}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={"top" + "center"}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={onCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {t("settings.alert_updated")}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default YourActivitySettings;
