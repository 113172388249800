// General
import "./thank-you-for-purchase-dialog.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateThankYouForPurchaseDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const ThankYouForPurchaseDialog = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const thankYouForPurchaseDialog = useSelector(
    (state) => state.dialog.thankYouForPurchaseDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!thankYouForPurchaseDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ThankYouForPurchase-Dialog",
      },
    });

    getUserAio(null, true);
  }, [thankYouForPurchaseDialog]);

  // Event Handlers | Button
  const onOpenSocialChannel = (url) => {
    window.open(url);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateThankYouForPurchaseDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={thankYouForPurchaseDialog}
      onClose={onCloseDialog}
    >
      {getUserAioSuccess && (
        <div id="thank-you-for-purchase-dialog">
          <div className="header">
            <div className="close-button-container">
              <CloseIcon className="close-button" onClick={onCloseDialog} />
            </div>
          </div>

          <div className="action-success-icon-container">
            <img
              className="action-success-icon"
              src={getAsset("thankYouVIPImage")}
              alt="thank-you"
            />
          </div>
          <div className="padding-container">
            <div className="thank-you-label">
              {t("purchase.thank_you_for_recent_purchase")}
            </div>

            <div className="description">
              {t("purchase.thank_you_for_recent_purchase_desc")}
            </div>

            {getUserAioData?.data?.social_channels?.map((social, index) => (
              <div
                className={`social-media-button-container ${
                  social?.channel === "line"
                    ? "line-button"
                    : social?.channel === "telegram"
                    ? "telegram-button"
                    : ""
                }`}
                onClick={() => onOpenSocialChannel(social?.url)}
                key={index}
              >
                <div className="social-icon-container">
                  <img
                    className="social-icon"
                    src={social?.logo}
                    alt={social?.channel}
                  />
                </div>

                <div className="social-label">{social?.name}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ThankYouForPurchaseDialog;
