// General
import "./privacy-policy.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../const/routeConst";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Components
import UtilityDesktopNavbar from "../shared/elements/utility-desktop-navbar/utility-desktop-navbar";

const PrivacyPolicy = () => {
  // Router variables
  const { i18n } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PrivacyPolicy-Page",
      },
    });
  }, []);

  // Utility Functions
  const getPrivacyPolicyPath = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return routeConst.privacy.pathAltZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return routeConst.privacy.pathAltJaJp;
    }

    return routeConst.privacy.pathAlt;
  };

  return (
    <div id="privacy-policy-page">
      <UtilityDesktopNavbar leftButton={"back"} />

      <iframe
        className="iframe-container"
        src={getPrivacyPolicyPath()}
      ></iframe>
    </div>
  );
};

export default PrivacyPolicy;
