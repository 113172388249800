// General
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector } from "react-redux";
// react-toastify
import { toast } from "react-toastify";
// Custom Hooks
import useCustomNavigate from "../../custom-hooks/useCustomNavigate-hook";
// Components
import MessageNotification from "../../../shared/elements/toast/message-notification/message-notification";
import SuccessNotification from "../../../shared/elements/toast/success-notification/success-notification";
import ErrorNotification from "../../../shared/elements/toast/error-notification/error-notification";
import WarningNotification from "../../../shared/elements/toast/warning-notification/warning-notification";
import InfoNotification from "../../../shared/elements/toast/info-notification/info-notification";
import InAppNotification from "../../../shared/elements/toast/in-app-notification/in-app-notification";

const ToastNotificationManager = () => {
  // Redux variables
  const messageToast = useSelector((state) => state.toast.messageToast);
  const successToast = useSelector((state) => state.toast.successToast);
  const errorToast = useSelector((state) => state.toast.errorToast);
  const warningToast = useSelector((state) => state.toast.warningToast);
  const infoToast = useSelector((state) => state.toast.infoToast);
  const inAppToast = useSelector((state) => state.toast.inAppToast);

  // Router variables
  const location = useLocation();

  // Lifecycle | Check for update | messageToast
  useEffect(() => {
    if (!messageToast) return;

    if (
      location.pathname.includes(
        `${routeConst.inbox.messaging.chat.chatId.clearPath}${messageToast?.conversation_id}`
      )
    )
      return;

    toast(
      <MessageNotification
        profilePhoto={messageToast?.sender?.profile_photo?.original_photo}
        username={messageToast?.sender?.username}
        message={messageToast?.data?.message?.message_content}
        conversationId={messageToast?.conversation_id}
      />,
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        closeButton: false,
      }
    );
  }, [messageToast]);

  // Lifecycle | Check for update | successToast
  useEffect(() => {
    if (!successToast) return;

    toast.success(<SuccessNotification message={successToast?.message} />, {
      position: "top-center",
      autoClose: successToast?.autoClose || 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
      closeButton: false,
      icon: false,
    });
  }, [successToast]);

  // Lifecycle | Check for update | errorToast
  useEffect(() => {
    if (!errorToast) return;

    toast.error(<ErrorNotification message={errorToast?.message} />, {
      position: "top-center",
      autoClose: errorToast?.autoClose || 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
      closeButton: false,
      icon: false,
    });
  }, [errorToast]);

  // Lifecycle | Check for update | warningToast
  useEffect(() => {
    if (!warningToast) return;

    toast.warning(<WarningNotification message={warningToast?.message} />, {
      position: "top-center",
      autoClose: warningToast?.autoClose || 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
      closeButton: false,
      icon: false,
    });
  }, [warningToast]);

  // Lifecycle | Check for update | infoToast
  useEffect(() => {
    if (!infoToast) return;

    toast.info(<InfoNotification message={infoToast?.message} />, {
      position: "top-center",
      autoClose: infoToast?.autoClose || 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
      closeButton: false,
      icon: false,
    });
  }, [infoToast]);

  // Lifecycle | Check for update | inAppToast
  useEffect(() => {
    if (!inAppToast) return;

    if (
      location.pathname.includes(routeConst.live.liveId.clearPath) ||
      location.pathname.includes(routeConst.videoCall.privateStandby.path) ||
      location.pathname.includes(routeConst.videoCall.room.path)
    )
      return;

    toast(
      <InAppNotification
        type={inAppToast?.action}
        profilePhoto={inAppToast?.user_photo}
        username={inAppToast?.username}
        userId={inAppToast?.user_id_string}
        userIdInt={inAppToast?.user_id}
        isVerifiedProfile={inAppToast?.is_verified_profile}
        membershipType={inAppToast?.membership_type}
        relatedId={inAppToast?.related_id}
      />,
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        closeButton: false,
      }
    );
  }, [inAppToast]);
};

export default ToastNotificationManager;
