// General
import "./joining-element.scss";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const JoiningElement = (props) => {
  const {
    state,
    livestreamingAchievementBadge,
    profilePhoto,
    username,
    levellingBadge,
  } = props;

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="chat-section-joining-element-subcomponent">
      <div className="profile-picture-container">
        <CustomAvatar className="profile-picture" src={profilePhoto} />
      </div>

      <div className="chat-info-container">
        <div className="chat-info">
          {livestreamingAchievementBadge?.length > 0 && (
            <span className="livestreaming-achievement-badge-container">
              {livestreamingAchievementBadge?.map((badge, index) => (
                <img
                  className="livestreaming-achievement-badge"
                  src={badge?.url}
                  alt={badge?.type}
                  key={index}
                />
              ))}
            </span>
          )}

          <span
            className={`username ${
              state === "livestream" && !isMobile ? "red" : "yellow"
            }`}
          >
            {username || "-"}
          </span>

          {levellingBadge && (
            <span className="levelling-badge-container">
              <img
                className="levelling-badge"
                src={levellingBadge}
                alt="levelling-badge"
              />
            </span>
          )}

          <span
            className={`joined-text ${
              state === "livestream" && !isMobile ? "red" : "yellow"
            }`}
          >
            {t("pusher.joined")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default JoiningElement;
