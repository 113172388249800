// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Join variables
  gender: "",
  lookingFor: "",

  // Phone Auth variables
  country: "",
  phone: "",
  sessionToken: "",

  // Account Signup variables
  email: "",
  password: "",

  // Info Signup variables
  name: "",
  dob: "",
  latitude: null,
  longitude: null,

  // Description Signup variables
  aboutMe: "",
  lookingForDescription: "",

  // Lifestyle Signup variables
  lifestyle: "",
  netWorth: "",
  annualIncome: "",
  height: "",
  bodyType: "",
  ethnicity: "",
  race: "",
  eyeColor: "",
  hairColor: "",

  // Detail Signup variables
  occupation: "",
  education: "",
  relationshipStatus: "",
  children: "",
  smoking: "",
  drinking: "",

  // Utility variables
  role: "",
  is_premium: "",
  signup_date: "",
  user_id: "",
};

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    // Join Functions
    updateGender: (state, action) => {
      state.gender = action.payload;
    },
    updateLookingFor: (state, action) => {
      state.lookingFor = action.payload;

      switch (`${state.gender}-${state.lookingFor}`) {
        case "man-sugarbaby":
          state.role = "sd";
          break;
        case "man-sugardaddy":
          state.role = "sm";
          break;
        case "women-sugardaddy":
          state.role = "sb";
          break;
        case "women-sugarbaby":
          state.role = "sbm";
          break;
        default:
          break;
      }
    },

    // Phone Auth Functions
    updateCountry: (state, action) => {
      state.country = action.payload;
    },
    updatePhone: (state, action) => {
      state.phone = action.payload;
    },
    updateSessionToken: (state, action) => {
      state.sessionToken = action.payload;
    },

    // Account Signup Functions
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updatePassword: (state, action) => {
      state.password = action.payload;
    },

    // Info Signup Functions
    updateName: (state, action) => {
      state.name = action.payload;
    },
    updateDob: (state, action) => {
      state.dob = action.payload;
    },
    updateLatitude: (state, action) => {
      state.latitude = action.payload;
    },
    updateLongitude: (state, action) => {
      state.longitude = action.payload;
    },

    // Description Signup Functions
    updateAboutMe: (state, action) => {
      state.aboutMe = action.payload;
    },
    updateLookingForDescription: (state, action) => {
      state.lookingForDescription = action.payload;
    },

    // Lifestyle Signup Functions
    updateLifestyle: (state, action) => {
      state.lifestyle = action.payload;
    },
    updateNetWorth: (state, action) => {
      state.netWorth = action.payload;
    },
    updateAnnualIncome: (state, action) => {
      state.annualIncome = action.payload;
    },
    updateHeight: (state, action) => {
      state.height = action.payload;
    },
    updateBodyType: (state, action) => {
      state.bodyType = action.payload;
    },
    updateEthnicity: (state, action) => {
      state.ethnicity = action.payload;
    },
    updateRace: (state, action) => {
      state.race = action.payload;
    },
    updateEyeColor: (state, action) => {
      state.eyeColor = action.payload;
    },
    updateHairColor: (state, action) => {
      state.hairColor = action.payload;
    },

    // Detail Signup Functions
    updateOccupation: (state, action) => {
      state.occupation = action.payload;
    },
    updateEducation: (state, action) => {
      state.education = action.payload;
    },
    updateRelationshipStatus: (state, action) => {
      state.relationshipStatus = action.payload;
    },
    updateChildren: (state, action) => {
      state.children = action.payload;
    },
    updateSmoking: (state, action) => {
      state.smoking = action.payload;
    },
    updateDrinking: (state, action) => {
      state.drinking = action.payload;
    },

    // Utility Functions
    resetSignupStore: () => {
      return { ...initialState };
    },
    updateIsPremium: (state, action) => {
      state.is_premium = action.payload;
    },
    updateSignupDate: (state, action) => {
      state.signup_date = action.payload;
    },
    updateUserId: (state, action) => {
      state.user_id = action.payload;
    },
  },
});

export const {
  // Join Functions
  updateGender,
  updateLookingFor,

  // Phone Auth Functions
  updateCountry,
  updatePhone,
  updateSessionToken,

  // Account Signup Functions
  updateEmail,
  updatePassword,

  // Info Signup Functions
  updateName,
  updateDob,
  updateLatitude,
  updateLongitude,

  // Description Signup Functions
  updateAboutMe,
  updateLookingForDescription,

  // Detail Signup Functions
  updateLifestyle,
  updateNetWorth,
  updateAnnualIncome,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateRace,
  updateEyeColor,
  updateHairColor,

  // Detail Signup Functions
  updateOccupation,
  updateEducation,
  updateRelationshipStatus,
  updateChildren,
  updateSmoking,
  updateDrinking,

  // Utility Functions
  resetSignupStore,
  updateIsPremium,
  updateSignupDate,
  updateUserId,
} = signupSlice.actions;

export default signupSlice.reducer;
