// General
import { createSlice } from "@reduxjs/toolkit";
// Static Data
import userConst from "../../const/userConst";
import searchConst from "../../const/searchConst";

const initialState = {
  // Main Page variables
  filteredLists: [],
  recentlyActiveLists: [],
  nearbyLists: [],
  newestLists: [],
  collegeLists: [],
  premiumLists: [],

  // Pagination variables
  filteredPage: 1,
  filteredPagination: null,
  recentlyActivePagination: null,
  nearbyPagination: null,
  newestPagination: null,
  collegePagination: null,
  premiumPagination: null,

  // TOR variables
  torList: [],
  selectedTorAfterApply: [],

  // General variables
  selectedCategoryTab: "recently_active",
  selectedView: searchConst.userCardDisplay.grid, // list, grid

  // Utility variables
  setCurrentLocationPassthrough: null,

  // API Param & Body variables
  searchParam: {
    sort_by: "",
  },
  searchBody: {
    size: 16,
    location: {
      lat: null,
      lng: null,
      country: null,
      country_iso: null,
      city: null,
      state: null,
    },
    sorting: "", // nearest, newest, college, featured, recently_active
    filters: {
      search_term: null,
      premium_only: null,
      photos: null,
      viewed: null,
      unviewed: null,
      favorited: null,
      favorited_me: null,
      age: {
        min: searchConst.filters.ageRange.min,
        max: searchConst.filters.ageRange.max,
      },
      // height: {
      //   min: searchConst.filters.heightRange.min,
      //   max: searchConst.filters.heightRange.max,
      // },
      children: null,
      ethnicity: null,
      tor: null,
      lifestyle: null,
      relationship: null,
      body_type: null,
      drinking: null,
      smoking: null,
      education: null,
      eye_color: null,
      hair_color: null,
    },
  },

  // Scroll variables
  scrollYPosition: 0,
  searchScrollToTopPassthrough: {},
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    // Main Page Functions
    updateFilteredLists: (state, action) => {
      const filteredListsData = [...state.filteredLists, ...action.payload];
      state.filteredLists = filteredListsData;
    },
    updateRecentlyActiveLists: (state, action) => {
      const recentlyActiveListsData = [
        ...state.recentlyActiveLists,
        ...action.payload,
      ];
      state.recentlyActiveLists = recentlyActiveListsData;
    },
    updateNearbyLists: (state, action) => {
      const nearbyListsData = [...state.nearbyLists, ...action.payload];
      state.nearbyLists = nearbyListsData;
    },
    updateNewestLists: (state, action) => {
      const newestListsData = [...state.newestLists, ...action.payload];
      state.newestLists = newestListsData;
    },
    updateCollegeLists: (state, action) => {
      const collegeListsData = [...state.collegeLists, ...action.payload];
      state.collegeLists = collegeListsData;
    },
    updatePremiumLists: (state, action) => {
      const premiumListsData = [...state.premiumLists, ...action.payload];
      state.premiumLists = premiumListsData;
    },
    resetDatingSearchLists: (state) => {
      state.datingSearchLists = [];
    },
    resetFilteredLists: (state) => {
      state.filteredLists = [];
    },
    resetRecentlyActiveLists: (state) => {
      state.recentlyActiveLists = [];
    },
    resetNearbyLists: (state) => {
      state.nearbyLists = [];
    },
    resetNewestLists: (state) => {
      state.newestLists = [];
    },
    resetCollegeLists: (state) => {
      state.collegeLists = [];
    },
    resetPremiumLists: (state) => {
      state.premiumLists = [];
    },

    // Pagination Functions
    updateFilteredPage: (state, action) => {
      state.filteredPage = action.payload;
    },
    updateFilteredPagination: (state, action) => {
      state.filteredPagination = action.payload;
    },
    updateRecentlyActivePagination: (state, action) => {
      state.recentlyActivePagination = action.payload;
    },
    updateNearbyPagination: (state, action) => {
      state.nearbyPagination = action.payload;
    },
    updateNewestPagination: (state, action) => {
      state.newestPagination = action.payload;
    },
    updateCollegePagination: (state, action) => {
      state.collegePagination = action.payload;
    },
    updatePremiumPagination: (state, action) => {
      state.premiumPagination = action.payload;
    },

    // TOR Functions
    updateTorList: (state, action) => {
      state.torList = action.payload;
    },
    resetTorListSelected: (state) => {
      const torListData = [...state.torList];

      for (let i = 0; i < torListData.length; i++) {
        torListData[i].selected = false;
      }

      state.torList = torListData;
    },
    updateSelectedTorAfterApply: (state, action) => {
      // Used to highlight TOR in user profile if selected in search filter and applying
      state.selectedTorAfterApply = action.payload;
    },
    resetSelectedTorAfterApply: (state) => {
      state.selectedTorAfterApply = [];
    },

    // General Functions
    updateSelectedCategoryTab: (state, action) => {
      state.selectedCategoryTab = action.payload;
      state.searchParam.sort_by = action.payload;
    },
    updateSelectedView: (state, action) => {
      state.selectedView = action.payload;
    },

    // API Param & Body Functions
    updateSearchSortByParam: (state, action) => {
      // state.searchParam.sort_by = action.payload;
    },
    updateSearchPageBody: (state, action) => {
      state.searchBody.page = action.payload;
    },
    updateSearchKeywordBody: (state, action) => {
      state.searchBody.keyword = action.payload;
    },
    updateSearchLocationBody: (state, action) => {
      state.searchBody.location = action.payload;
    },
    updateSearchLocationDataBody: (state, action) => {
      state.searchBody.location_data = action.payload;
    },
    updateSearchMinAgeBody: (state, action) => {
      state.searchBody["min-age"] = action.payload;
    },
    updateSearchMaxAgeBody: (state, action) => {
      state.searchBody["max_age"] = action.payload;
    },
    updateSearchMinHeightBody: (state, action) => {
      state.searchBody["min-height"] = action.payload;
    },
    updateSearchMaxHeightBody: (state, action) => {
      state.searchBody["max-height"] = action.payload;
    },
    updateSearchOptionsBody: (state, action) => {
      state.searchBody.options = action.payload;
    },
    updateSearchLifestyleBody: (state, action) => {
      state.searchBody.lifestyle = action.payload;
    },
    updateSearchBodyTypeBody: (state, action) => {
      state.searchBody.bodytype = action.payload;
    },
    updateSearchEthnicityBody: (state, action) => {
      state.searchBody.ethnicity = action.payload;
    },
    updateSearchEyeColorBody: (state, action) => {
      state.searchBody.eyecolor = action.payload;
    },
    updateSearchHairColorBody: (state, action) => {
      state.searchBody.haircolor = action.payload;
    },
    updateSearchSmokingBody: (state, action) => {
      state.searchBody.smoking = action.payload;
    },
    updateSearchDrinkingBody: (state, action) => {
      state.searchBody.drinking = action.payload;
    },
    updateSearchRelationshipBody: (state, action) => {
      state.searchBody.relationship = action.payload;
    },
    updateSearchEducationBody: (state, action) => {
      state.searchBody.education = action.payload;
    },

    // Scroll Functions
    updateScrollYPosition: (state, action) => {
      state.scrollYPosition = action.payload;
    },
    updateSearchScrollToTopPassthrough: (state, action) => {
      state.searchScrollToTopPassthrough = action.payload;
    },

    // Utility Functions
    resetSearchStore: () => {
      return { ...initialState };
    },
    updateSetCurrentLocationPassthrough: (state) => {
      state.setCurrentLocationPassthrough = {};
    },
    resetSearchBodyFilter: (state) => {
      state.searchBody = {
        size: 16,
        location: {
          lat: null,
          lng: null,
          country: null,
          city: null,
          state: null,
        },
        sorting: "", // nearest, newest, college, featured, recently_active
        filters: {
          search_term: null,
          photos: null,
          viewed: null,
          unviewed: null,
          favorited: null,
          favorited_me: null,
          age: {
            min: searchConst.filters.ageRange.min,
            max: searchConst.filters.ageRange.max,
          },
          // height: {
          //   min: searchConst.filters.heightRange.min,
          //   max: searchConst.filters.heightRange.max,
          // },
          children: null,
          ethnicity: null,
          tor: null,
          lifestyle: null,
          relationship: null,
          body_type: null,
          drinking: null,
          smoking: null,
          education: null,
          eye_color: null,
          hair_color: null,
        },
      };
    },
    composeSearchBody: (state, action) => {
      let searchTerm = null;
      let ethnicity = [];
      let tor = [];
      let lifestyle = [];
      let relationship = [];
      let bodyType = [];
      let drinking = [];
      let smoking = [];
      let education = [];
      let eyeColor = [];
      let hairColor = [];

      // Keyword | Not working, filter from filtered-view instead
      if (action.payload.keyword && action.payload.keyword !== "") {
        searchTerm = action.payload.keyword;
      }
      state.searchBody.filters.search_term = searchTerm;

      // Location
      const locationObj = {
        lat: action.payload.location.lat,
        lng: action.payload.location.lng,
        country: action.payload.location_data.country,
        country_iso: action.payload.location_data.country_iso,
        city: action.payload.location_data.city,
        state: action.payload.location_data.state,
      };

      if (locationObj.lat && locationObj.lng) {
        state.searchBody.location = locationObj;
      } else {
        state.searchBody.location = {};
      }

      // Premium
      state.searchBody.filters.premium_only = action.payload.premiumOnly;

      // Photos
      state.searchBody.filters.photos = action.payload.photos;

      // Viewed
      state.searchBody.filters.viewed = action.payload.viewed;

      // Unviewed
      state.searchBody.filters.unviewed = action.payload.unviewed;

      // Favorited
      state.searchBody.filters.favorited = action.payload.favorited;

      // Favorited Me
      state.searchBody.filters.favorited_me = action.payload.favoritedMe;

      // Search Term
      state.searchBody.filters.search_term = action.payload.keyword;

      // Age
      state.searchBody.filters.age.min = action.payload.minAge;
      state.searchBody.filters.age.max = action.payload.maxAge;

      // Height | Removed
      // state.searchBody.filters.height.min = action.payload.minHeight;
      // state.searchBody.filters.height.max = action.payload.maxHeight;

      // Children | TBA

      // Ethnicity
      for (let i = 0; i < userConst.ethnicity.length; i++) {
        if (action.payload.ethnicity.asian) {
          if (userConst.ethnicity[i].value === "asian") {
            ethnicity.push(userConst.ethnicity[i].value);
          }
        }
        if (action.payload.ethnicity["black-or-african-descent"]) {
          if (userConst.ethnicity[i].value === "black-or-african-descent") {
            ethnicity.push("black");
          }
        }
        if (action.payload.ethnicity.latin) {
          if (userConst.ethnicity[i].value === "latin") {
            ethnicity.push(userConst.ethnicity[i].value);
          }
        }
        if (action.payload.ethnicity["east-indian"]) {
          if (userConst.ethnicity[i].value === "east-indian") {
            ethnicity.push(userConst.ethnicity[i].value);
          }
        }
        if (action.payload.ethnicity["middle-eastern"]) {
          if (userConst.ethnicity[i].value === "middle-eastern") {
            ethnicity.push(userConst.ethnicity[i].value);
          }
        }
        if (action.payload.ethnicity.mixed) {
          if (userConst.ethnicity[i].value === "mixed") {
            ethnicity.push(userConst.ethnicity[i].value);
          }
        }
        if (action.payload.ethnicity["native-american"]) {
          if (userConst.ethnicity[i].value === "native-american") {
            ethnicity.push(userConst.ethnicity[i].value);
          }
        }
        if (action.payload.ethnicity["pacific-islander"]) {
          if (userConst.ethnicity[i].value === "pacific-islander") {
            ethnicity.push(userConst.ethnicity[i].value);
          }
        }
        if (action.payload.ethnicity.caucasian) {
          if (userConst.ethnicity[i].value === "caucasian") {
            ethnicity.push(userConst.ethnicity[i].value);
          }
        }
        if (action.payload.ethnicity.other) {
          if (userConst.ethnicity[i].value === "other") {
            ethnicity.push(userConst.ethnicity[i].value);
          }
        }
      }
      state.searchBody.filters.ethnicity =
        ethnicity.length > 0 ? ethnicity : null;

      // TOR
      state.searchBody.filters.tor =
        action.payload.tor.length > 0 ? action.payload.tor : null;

      // Lifestyle
      for (let i = 0; i < userConst.lifestyle.length; i++) {
        if (action.payload.lifestyle.negotiable) {
          if (userConst.lifestyle[i].value === "negotiable") {
            lifestyle.push(userConst.lifestyle[i].value);
          }
        }
        if (action.payload.lifestyle.minimal) {
          if (userConst.lifestyle[i].value === "minimal") {
            lifestyle.push(userConst.lifestyle[i].value);
          }
        }
        if (action.payload.lifestyle.practical) {
          if (userConst.lifestyle[i].value === "practical") {
            lifestyle.push(userConst.lifestyle[i].value);
          }
        }
        if (action.payload.lifestyle.moderate) {
          if (userConst.lifestyle[i].value === "moderate") {
            lifestyle.push(userConst.lifestyle[i].value);
          }
        }
        if (action.payload.lifestyle.substantial) {
          if (userConst.lifestyle[i].value === "substantial") {
            lifestyle.push(userConst.lifestyle[i].value);
          }
        }
        if (action.payload.lifestyle.high) {
          if (userConst.lifestyle[i].value === "high") {
            lifestyle.push(userConst.lifestyle[i].value);
          }
        }
      }
      state.searchBody.filters.lifestyle =
        lifestyle.length > 0 ? lifestyle : null;

      // Relationship
      for (let i = 0; i < userConst.relationship.length; i++) {
        if (action.payload.relationshipStatus.single) {
          if (userConst.relationship[i].value === "single") {
            relationship.push(userConst.relationship[i].value);
          }
        }
        if (action.payload.relationshipStatus.divorced) {
          if (userConst.relationship[i].value === "divorced") {
            relationship.push(userConst.relationship[i].value);
          }
        }
        if (action.payload.relationshipStatus.separated) {
          if (userConst.relationship[i].value === "separated") {
            relationship.push(userConst.relationship[i].value);
          }
        }
        if (action.payload.relationshipStatus["married-but-looking"]) {
          if (userConst.relationship[i].value === "married-but-looking") {
            relationship.push(userConst.relationship[i].value);
          }
        }
        if (action.payload.relationshipStatus.widowed) {
          if (userConst.relationship[i].value === "widowed") {
            relationship.push(userConst.relationship[i].value);
          }
        }
      }
      state.searchBody.filters.relationship =
        relationship.length > 0 ? relationship : null;

      // Body Type
      for (let i = 0; i < userConst.bodyType.length; i++) {
        if (action.payload.bodyType.slim) {
          if (userConst.bodyType[i].value === "slim") {
            bodyType.push(userConst.bodyType[i].value);
          }
        }
        if (action.payload.bodyType.athletic) {
          if (userConst.bodyType[i].value === "athletic") {
            bodyType.push(userConst.bodyType[i].value);
          }
        }
        if (action.payload.bodyType.average) {
          if (userConst.bodyType[i].value === "average") {
            bodyType.push(userConst.bodyType[i].value);
          }
        }
        if (action.payload.bodyType["a-few-extra-pounds"]) {
          if (userConst.bodyType[i].value === "a-few-extra-pounds") {
            bodyType.push(userConst.bodyType[i].value);
          }
        }
        if (action.payload.bodyType["full-or-overweight"]) {
          if (userConst.bodyType[i].value === "full-or-overweight") {
            bodyType.push(userConst.bodyType[i].value);
          }
        }
        if (action.payload.bodyType.other) {
          if (userConst.bodyType[i].value === "other") {
            bodyType.push(userConst.bodyType[i].value);
          }
        }
      }
      state.searchBody.filters.body_type =
        bodyType.length > 0 ? bodyType : null;

      // Drinking
      for (let i = 0; i < userConst.drinking.length; i++) {
        if (action.payload.drinking["non-drinker"]) {
          if (userConst.drinking[i].value === "non-drinker") {
            drinking.push(userConst.drinking[i].value);
          }
        }
        if (action.payload.drinking["social-drinker"]) {
          if (userConst.drinking[i].value === "social-drinker") {
            drinking.push(userConst.drinking[i].value);
          }
        }
        if (action.payload.drinking["heavy-drinker"]) {
          if (userConst.drinking[i].value === "heavy-drinker") {
            drinking.push(userConst.drinking[i].value);
          }
        }
      }
      state.searchBody.filters.drinking = drinking.length > 0 ? drinking : null;

      // Smoking
      for (let i = 0; i < userConst.smoking.length; i++) {
        if (action.payload.smoking["non-smoker"]) {
          if (userConst.smoking[i].value === "non-smoker") {
            smoking.push(userConst.smoking[i].value);
          }
        }
        if (action.payload.smoking["light-smoker"]) {
          if (userConst.smoking[i].value === "light-smoker") {
            smoking.push(userConst.smoking[i].value);
          }
        }
        if (action.payload.smoking["heavy-smoker"]) {
          if (userConst.smoking[i].value === "heavy-smoker") {
            smoking.push(userConst.smoking[i].value);
          }
        }
      }
      state.searchBody.filters.smoking = smoking.length > 0 ? smoking : null;

      // Education
      for (let i = 0; i < userConst.education.length; i++) {
        if (action.payload.education["high-school"]) {
          if (userConst.education[i].value === "high-school") {
            education.push(userConst.education[i].value);
          }
        }
        if (action.payload.education["some-college"]) {
          if (userConst.education[i].value === "some-college") {
            education.push(userConst.education[i].value);
          }
        }
        if (action.payload.education["associates-degree"]) {
          if (userConst.education[i].value === "associates-degree") {
            education.push(userConst.education[i].value);
          }
        }
        if (action.payload.education["bachelors-degree"]) {
          if (userConst.education[i].value === "bachelors-degree") {
            education.push(userConst.education[i].value);
          }
        }
        if (action.payload.education["graduate-degree"]) {
          if (userConst.education[i].value === "graduate-degree") {
            education.push(userConst.education[i].value);
          }
        }
        if (action.payload.education["phd-or-post-doctoral"]) {
          if (userConst.education[i].value === "phd-or-post-doctoral") {
            education.push(userConst.education[i].value);
          }
        }
      }
      state.searchBody.filters.education =
        education.length > 0 ? education : null;

      // Eye Color
      for (let i = 0; i < userConst.eyeColor.length; i++) {
        if (action.payload.eyeColor.black) {
          if (userConst.eyeColor[i].value === "black") {
            eyeColor.push(userConst.eyeColor[i].value);
          }
        }
        if (action.payload.eyeColor.blue) {
          if (userConst.eyeColor[i].value === "blue") {
            eyeColor.push(userConst.eyeColor[i].value);
          }
        }
        if (action.payload.eyeColor.brown) {
          if (userConst.eyeColor[i].value === "brown") {
            eyeColor.push(userConst.eyeColor[i].value);
          }
        }
        if (action.payload.eyeColor.grey) {
          if (userConst.eyeColor[i].value === "grey") {
            eyeColor.push(userConst.eyeColor[i].value);
          }
        }
        if (action.payload.eyeColor.green) {
          if (userConst.eyeColor[i].value === "green") {
            eyeColor.push(userConst.eyeColor[i].value);
          }
        }
        if (action.payload.eyeColor.hazel) {
          if (userConst.eyeColor[i].value === "hazel") {
            eyeColor.push(userConst.eyeColor[i].value);
          }
        }
        if (action.payload.eyeColor.other) {
          if (userConst.eyeColor[i].value === "other") {
            eyeColor.push(userConst.eyeColor[i].value);
          }
        }
      }
      state.searchBody.filters.eye_color =
        eyeColor.length > 0 ? eyeColor : null;

      // Hair Color
      for (let i = 0; i < userConst.hairColor.length; i++) {
        if (action.payload.hairColor.auburn) {
          if (userConst.hairColor[i].value === "auburn") {
            hairColor.push(userConst.hairColor[i].value);
          }
        }
        if (action.payload.hairColor.black) {
          if (userConst.hairColor[i].value === "black") {
            hairColor.push(userConst.hairColor[i].value);
          }
        }
        if (action.payload.hairColor.blonde) {
          if (userConst.hairColor[i].value === "blonde") {
            hairColor.push(userConst.hairColor[i].value);
          }
        }
        if (action.payload.hairColor["light-brown"]) {
          if (userConst.hairColor[i].value === "light-brown") {
            hairColor.push(userConst.hairColor[i].value);
          }
        }
        if (action.payload.hairColor["dark-brown"]) {
          if (userConst.hairColor[i].value === "dark-brown") {
            hairColor.push(userConst.hairColor[i].value);
          }
        }
        if (action.payload.hairColor.grey) {
          if (userConst.hairColor[i].value === "grey") {
            hairColor.push(userConst.hairColor[i].value);
          }
        }
        if (action.payload.hairColor.red) {
          if (userConst.hairColor[i].value === "red") {
            hairColor.push(userConst.hairColor[i].value);
          }
        }
        if (action.payload.hairColor.white) {
          if (userConst.hairColor[i].value === "white") {
            hairColor.push(userConst.hairColor[i].value);
          }
        }
        if (action.payload.hairColor.other) {
          if (userConst.hairColor[i].value === "other") {
            hairColor.push(userConst.hairColor[i].value);
          }
        }
      }
      state.searchBody.filters.hair_color =
        hairColor.length > 0 ? hairColor : null;
    },
    updateLocalSearchList: (state, action) => {
      const idInt = action.payload.idInt;
      const privatePhotoRequested = action.payload.privatePhotoRequested;
      const isBlocked = action.payload.isBlocked;
      const isFavorited = action.payload.isFavorited;

      const filteredListsData = [...state.filteredLists];
      const recentlyActiveListsData = [...state.recentlyActiveLists];
      const nearbyListsData = [...state.nearbyLists];
      const newestListsData = [...state.newestLists];
      const collegeListsData = [...state.collegeLists];
      const premiumListsData = [...state.premiumLists];

      // Filtered Lists
      for (let i = 0; i < filteredListsData.length; i++) {
        if (
          filteredListsData[i].user &&
          filteredListsData[i].user.id_int === idInt
        ) {
          if (privatePhotoRequested !== null) {
            filteredListsData[i].user.interaction.private_photo_approval =
              privatePhotoRequested;
          }

          // Backend not done
          if (isBlocked !== null) {
            filteredListsData[i].user.interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            filteredListsData[i].user.interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      // Recently Active Lists
      for (let i = 0; i < recentlyActiveListsData.length; i++) {
        if (
          recentlyActiveListsData[i].user &&
          recentlyActiveListsData[i].user.id_int === idInt
        ) {
          if (privatePhotoRequested !== null) {
            recentlyActiveListsData[i].user.interaction.private_photo_approval =
              privatePhotoRequested;
          }

          // Backend not done
          if (isBlocked !== null) {
            recentlyActiveListsData[i].user.interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            recentlyActiveListsData[i].user.interaction.is_favorited =
              isFavorited;
          }
          break;
        }
      }

      // Nearby Lists
      for (let i = 0; i < nearbyListsData.length; i++) {
        if (
          nearbyListsData[i].user &&
          nearbyListsData[i].user.id_int === idInt
        ) {
          if (privatePhotoRequested !== null) {
            nearbyListsData[i].user.interaction.private_photo_approval =
              privatePhotoRequested;
          }

          // Backend not done
          if (isBlocked !== null) {
            nearbyListsData[i].user.interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            nearbyListsData[i].user.interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      // Newest Lists
      for (let i = 0; i < newestListsData.length; i++) {
        if (
          newestListsData[i].user &&
          newestListsData[i].user.id_int === idInt
        ) {
          if (privatePhotoRequested !== null) {
            newestListsData[i].user.interaction.private_photo_approval =
              privatePhotoRequested;
          }

          // Backend not done
          if (isBlocked !== null) {
            newestListsData[i].user.interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            newestListsData[i].user.interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      // College Lists
      for (let i = 0; i < collegeListsData.length; i++) {
        if (
          collegeListsData[i].user &&
          collegeListsData[i].user.id_int === idInt
        ) {
          if (privatePhotoRequested !== null) {
            collegeListsData[i].user.interaction.private_photo_approval =
              privatePhotoRequested;
          }

          // Backend not done
          if (isBlocked !== null) {
            collegeListsData[i].user.interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            collegeListsData[i].user.interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      // Premium Lists
      for (let i = 0; i < premiumListsData.length; i++) {
        if (
          premiumListsData[i].user &&
          premiumListsData[i].user.id_int === idInt
        ) {
          if (privatePhotoRequested !== null) {
            premiumListsData[i].user.interaction.private_photo_approval =
              privatePhotoRequested;
          }

          // Backend not done
          if (isBlocked !== null) {
            premiumListsData[i].user.interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            premiumListsData[i].user.interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      state.filteredLists = filteredListsData;
      state.recentlyActiveLists = recentlyActiveListsData;
      state.nearbyLists = nearbyListsData;
      state.newestLists = newestListsData;
      state.collegeLists = collegeListsData;
      state.premiumLists = premiumListsData;
    },
  },
});

export const {
  // Main Page Functions
  updateFilteredLists,
  updateRecentlyActiveLists,
  updateNearbyLists,
  updateNewestLists,
  updateCollegeLists,
  updatePremiumLists,
  resetDatingSearchLists,
  resetFilteredLists,
  resetRecentlyActiveLists,
  resetNearbyLists,
  resetNewestLists,
  resetCollegeLists,
  resetPremiumLists,

  // Pagination Functions
  updateFilteredPage,
  updateFilteredPagination,
  updateRecentlyActivePagination,
  updateNearbyPagination,
  updateNewestPagination,
  updateCollegePagination,
  updatePremiumPagination,

  // TOR Functions
  updateTorList,
  resetTorListSelected,
  updateSelectedTorAfterApply,
  resetSelectedTorAfterApply,

  // General Functions
  updateSelectedCategoryTab,
  updateSelectedView,

  // API Param & Body Functions
  updateSearchSortByParam,
  updateSearchPageBody,
  updateSearchKeywordBody,
  updateSearchLocationBody,
  updateSearchLocationDataBody,
  updateSearchMinAgeBody,
  updateSearchMaxAgeBody,
  updateSearchMinHeightBody,
  updateSearchMaxHeightBody,
  updateSearchOptionsBody,
  updateSearchLifestyleBody,
  updateSearchBodyTypeBody,
  updateSearchEthnicityBody,
  updateSearchEyeColorBody,
  updateSearchHairColorBody,
  updateSearchSmokingBody,
  updateSearchDrinkingBody,
  updateSearchRelationshipBody,
  updateSearchEducationBody,

  // Scroll Functions
  updateScrollYPosition,
  updateSearchScrollToTopPassthrough,

  // Utility Functions
  resetSearchStore,
  updateSetCurrentLocationPassthrough,
  resetSearchBodyFilter,
  composeSearchBody,
  updateLocalSearchList,
} = searchSlice.actions;

export default searchSlice.reducer;
