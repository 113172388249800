// General
import "./premium-empty-list.scss";
// Static Data
import routeConst from "../../../../../const/routeConst";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const PremiumEmptyList = () => {
  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  return (
    <div id="search-list-container-premium-empty-list-subcomponent">
      <div className="premium-empty-padding-container">
        <div className="empty-premium-list-image-container">
          {getIcon("premiumLockIcon", "empty-premium-list-image")}
        </div>

        <div className="title">{t("search.premium_locked_title")}</div>

        <div className="description">{t("search.premium_locked_sbf_desc")}</div>

        <div
          className="upgrade-now-button"
          onClick={() => onNavigate(routeConst.upgrade.path)}
        >
          {t("profile.upgrade_now")}
        </div>

        <div className="foot-note">{t("search.premium_locked_sbf_note")}</div>
      </div>
    </div>
  );
};

export default PremiumEmptyList;
