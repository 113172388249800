// General
import "./leaderboard.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../services/data.service";
// Static Data
import leaderboardConst from "../../../const/leaderboardConst";
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLeaderboardTab,
  updateLeaderboardPullToRefreshPassthrough,
  updateLeaderboardTabValue,
} from "../../../redux/store/leaderboardStore";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import LivestreamingTab from "./livestreaming-tab/livestreaming-tab";
import PrivateCallTab from "./private-call-tab/private-call-tab";
import CasinoTab from "./casino-tab/casino-tab";
import UnfollowUserDialog from "../../shared/dialog-content/unfollow-user-dialog/unfollow-user-dialog";

const Leaderboard = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const leaderboardTab = useSelector(
    (state) => state.leaderboard.leaderboardTab
  );
  const dispatch = useDispatch();

  // MUI variables
  const [alignment, setAlignment] = useState(leaderboardConst.type.privateCall);

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Leaderboard-Page",
      },
    });

    getUserAio(null, true);

    if (leaderboardTab === 0) {
      dispatch(updateLeaderboardTabValue(leaderboardConst.type.livestreaming));
      setAlignment(leaderboardConst.type.livestreaming);
    } else if (leaderboardTab === 1) {
      dispatch(updateLeaderboardTabValue(leaderboardConst.type.privateCall));
      setAlignment(leaderboardConst.type.privateCall);
    } else if (leaderboardTab === 2) {
      dispatch(updateLeaderboardTabValue(leaderboardConst.type.casino));
      setAlignment(leaderboardConst.type.casino);
    }
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(leaderboardTab);

    emblaApi?.on("select", () => {
      dispatch(updateLeaderboardTab(emblaApi?.selectedScrollSnap()));

      if (emblaApi?.selectedScrollSnap() === 0) {
        setAlignment(leaderboardConst.type.livestreaming);
        dispatch(
          updateLeaderboardTabValue(leaderboardConst.type.livestreaming)
        );
      } else if (emblaApi?.selectedScrollSnap() === 1) {
        setAlignment(leaderboardConst.type.privateCall);
        dispatch(updateLeaderboardTabValue(leaderboardConst.type.privateCall));
      } else if (emblaApi?.selectedScrollSnap() === 2) {
        setAlignment(leaderboardConst.type.casino);
        dispatch(updateLeaderboardTabValue(leaderboardConst.type.casino));
      }
    });
  }, [emblaApi]);

  // Event Handlers | MUI Toggle Button
  const onToggleButtonChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      if (newAlignment === leaderboardConst.type.livestreaming) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-Leaderboard-Page-Livestreaming-Tab-Button",
          },
        });

        emblaApi?.scrollTo(0);
        dispatch(updateLeaderboardTab(0));
        dispatch(
          updateLeaderboardTabValue(leaderboardConst.type.livestreaming)
        );
      } else if (newAlignment === leaderboardConst.type.privateCall) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-Leaderboard-Page-Private-Call-Tab-Button",
          },
        });

        emblaApi?.scrollTo(1);
        dispatch(updateLeaderboardTab(1));
        dispatch(updateLeaderboardTabValue(leaderboardConst.type.privateCall));
      } else if (newAlignment === leaderboardConst.type.casino) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-Leaderboard-Page-Casino-Tab-Button",
          },
        });

        emblaApi?.scrollTo(2);
        dispatch(updateLeaderboardTab(2));
        dispatch(updateLeaderboardTabValue(leaderboardConst.type.casino));
      }
    }
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    return new Promise((resolve) => {
      resolve(dispatch(updateLeaderboardPullToRefreshPassthrough({})));
    });
  };

  if (getUserAioFetching || getUserAioLoading) {
    return <Spinner />;
  } else if (getUserAioSuccess) {
    return (
      <div id="leaderboard-page">
        <div className="max-width-container">
          <div className="toggle-button-container">
            <ToggleButtonGroup
              className="custom-toggle-button"
              value={alignment}
              exclusive
              onChange={onToggleButtonChange}
            >
              {getUserAioData?.data?.modules
                ?.live_streaming_leaderboard_enabled && (
                <ToggleButton value={leaderboardConst.type.livestreaming}>
                  {t("leaderboard.livestreaming")}
                </ToggleButton>
              )}
              {getUserAioData?.data?.modules
                ?.private_calls_leaderboard_enabled && (
                <ToggleButton value={leaderboardConst.type.privateCall}>
                  {t("leaderboard.private_room")}
                </ToggleButton>
              )}
              {getUserAioData?.data?.modules?.gaming_enabled && (
                <ToggleButton value={leaderboardConst.type.casino}>
                  {"Casino"}
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </div>

          <PullToRefresh
            className="pull-to-refresh-container"
            onRefresh={pullToRefreshFunc}
            refreshingContent={t("common.release")}
            pullingContent={t("common.pull_to_refresh")}
          >
            <div ref={emblaRef} className="leaderboard-embla">
              <div className="embla__container">
                <div className="embla__slide">
                  {leaderboardTab === 0 && <LivestreamingTab />}
                </div>

                <div className="embla__slide">
                  {leaderboardTab === 1 && <PrivateCallTab />}
                </div>

                <div className="embla__slide">
                  {leaderboardTab === 2 && <CasinoTab />}
                </div>
              </div>
            </div>
          </PullToRefresh>
        </div>

        <UnfollowUserDialog />
      </div>
    );
  } else if (getUserAioError) {
  }
};

export default Leaderboard;
